import { getProcessInstHistory } from '@/utils/request'
import { isString, isArray, isPlainObject } from '@/utils/validate'
import { deepClone } from '@/utils/object'
import { isNumeric } from '@/utils/validate'
import { msToDurationDIY } from '@/filters'
import { getNameByUsername } from '@/service/api-web/hr'

export default {
  name: 'BacklogAuditTabMixin',
  props: {
    layout: {
      type: String,
      default: 'form|table|diagram',
      validator: (layout) => {
        return [
          '',
          'form|table|diagram',
          'form|table',
          'table|diagram',
          'form|diagram',
          'form',
          'form|',
          'table',
          'table|',
          'diagram',
          'diagram|'
        ].includes(layout)
      }
    },
    tabs: {
      type: Object,
      default: () => ({
        form: '处理信息',
        table: '处理环节',
        diagram: '流程图'
      })
    },
    titles: {
      type: Object,
      default: () => ({
        form: '处理信息',
        table: '处理环节',
        diagram: '流程图'
      })
    },
    showHandleForm: {
      type: Boolean,
      default: false
    },
    defaultFormModel: {
      type: Object,
      default: () => ({
        layout: 'inline',
        data: {}
      })
    },
    formItems: {
      type: Array,
      default: () => []
    },
    tableHeader: {
      type: Array,
      default: () => [
        {
          width: 100,
          label: '节点名称',
          prop: 'actName'
        },
        {
          label: '节点状态',
          prop: 'state',
          isComp: true
        },
        {
          width: 150,
          label: '动作主题',
          prop: 'remarks'
        },
        {
          width: 150,
          label: '处理时间',
          prop: 'endTime',
          require: true
        },
        {
          label: '处理时长',
          prop: 'duration',
          isComp: true
        },
        {
          width: 180,
          label: '处理人',
          prop: 'user'
        }
      ]
    },
    /**
     * @descrition 流程实例 ID， 用于查询 处理环节节点表格和流程实例图
     */
    processId: {
      type: String,
      default: ''
    },
    documentCode: {
      type: String,
      default: ''
    },
    processSubmitter: {
      type: Object,
      default: () => ({ id: '', name: '' }),
      validator: (obj) => {
        return !!(isString(obj?.id) && isString(obj?.name))
      }
    },
    // 当此属性为true时，处理环节表单的label可以动态变更，比如选择审核通过时，为详细意见，选择驳回时，为驳回理由
    dynamicFormLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {
        /**
         * 审核/处理表单
         */
        form: deepClone(this.defaultFormModel),
        /**
         * 流程节点记录表格
         */
        table: {
          loading: false,
          data: []
        },
        /**
         * 流程实例图
         */
        diagram: {
          type: 'instance',
          mode: 'view'
        }
      },

      proxyFormItems: this.formItems
    }
  },

  watch: {
    'model.form.data.isPass': function (newV) {
      const formItems = this.proxyFormItems
      // 防止影响别的自定义label的待办
      if (this.dynamicFormLabel) {
        this.model.form.clearValidate()

        const matchItem = formItems.find((item) => item.prop === 'remarks')

        if (matchItem) {
          const isPass = newV === 0
          matchItem.label = isPass ? '驳回理由' : '详细意见'
          matchItem.required = isPass
        }
      }
    },

    'model.form.data': {
      handler: function (newV) {
        this.$emit('change-form', newV)
      },
      deep: true
    }
  },

  computed: {
    showForm() {
      return (
        this.layout.split('|').includes('form') && this.showHandleForm === true
      )
    },
    showFormTitle() {
      return isString(this.titles?.form) && this.titles.form.trim().length > 0
    },
    showTable() {
      return (
        this.layout.split('|').includes('table') &&
        this.processId.trim().length > 0
      )
    },
    showTableTitle() {
      return isString(this.titles?.table) && this.titles.table.trim().length > 0
    },
    showDiagram() {
      return (
        this.layout.split('|').includes('diagram') &&
        this.processId.trim().length > 0
      )
    },
    showDiagramTitle() {
      return (
        isString(this.titles?.diagram) && this.titles.diagram.trim().length > 0
      )
    },
    show() {
      return this.showForm || this.showTable || this.showDiagram
    }
  },
  methods: {
    processDuration(row) {
      return (
        (isNumeric(row.duration)
          ? msToDurationDIY(row.duration * 1, { zh: true, end: 'm' })
          : row?.duration) || '⏤'
      )
    },
    updateHisNode(v) {
      const _v = { ...v }

      if (v.actType === 'startEvent') {
        _v.actName = v?.actName || '开始节点'
      }
      if (v.actType === 'endEvent') {
        _v.actName = v?.actName || '结束节点'
      }

      if (v.actType === 'exclusiveGateway') {
        _v.actName = v?.actName || '网关'
      }

      return _v
    },
    /**
     * 生成处理环节表格
     * @returns {Promise<void>}
     */
    async generateProcessHistoryTable() {
      this.model.table.loading = true

      const processNodeStatusDict = await this.$dict('sys.processNodeStatus')
      if (!isArray(processNodeStatusDict)) {
        return void console.error(
          '【generateProcessHistoryTable】illegal "processNodeStatusDict"',
          processNodeStatusDict
        )
      }
      const processHistory = this.processId
        ? (
            await getProcessInstHistory(
              this.processId,
              this.documentCode,
              'node'
            )
          ).node
        : []

      console.groupCollapsed('处理环节')
      console.log(
        '【generateProcessHistoryTable】processNodeStatusDict',
        processNodeStatusDict
      )
      Promise.all(
        processHistory.map(async (v) => {
          const _v = this.updateHisNode(v)

          let user = '⏤'

          const username = _v?.assignee ?? ''

          if (isString(username) && username.trim()) {
            const name = _v.realname || ''
            if (name) {
              user = `${name}@${username}`
            } else {
              const { code, data, msg } = await getNameByUsername({ username })
              if (
                code === 200 &&
                isPlainObject(data) &&
                Object.keys(data).length
              ) {
                user = Object.entries(data)
                  .map(([username, name]) =>
                    name && username ? `${name}@${username}` : ''
                  )
                  .join()
              } else {
                console.error(
                  '【generateProcessHistoryTable】getNameByUsername error',
                  code,
                  data,
                  msg
                )
              }
            }
          } else {
            console.error(
              '【generateProcessHistoryTable】losed "username"',
              v,
              _v
            )
          }

          const { state: originState, endTime = '' } = _v
          let state = null
          if (
            endTime &&
            isString(endTime) &&
            endTime.trim() &&
            endTime !== 'null'
          ) {
            // 驳回
            if (originState === 2) state = processNodeStatusDict[0]
            else state = processNodeStatusDict[2] // 完成
          } else state = processNodeStatusDict[1] // 当前
          console.log('节点:')
          console.table(_v)
          console.log('状态:')
          console.log(state)
          return {
            ..._v,
            originState,
            state,
            remarks: _v.remarks || '无',
            user:
              _v.actType === 'startEvent' ? this.processSubmitter?.name : user
          }
        })
      )
        .then((data) => {
          this.model.table.data = data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.model.table.loading = false
          console.log('table:', this.model.table.data)
          console.groupEnd()
        })
    }
  }
}
