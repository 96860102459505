/**
 * @module api-scm:supplyChain/warehouse/inventoryLock
 */

import service from '@/service'

/**
 * @property {string} keywords -
 * @property {string} sort -
 * @property {number} limit - 条数,示例值(10)
 * @property {number} page - 页码,示例值(1)
 * @property {string} lockName - 锁定名称
 * @property {string} warehouseCode - 仓库名称(编码code)
 * @description 库存锁定表列表
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 库存锁定   新增
 * @param {object} data
 * @return {Promise<Array>}
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/save',
    method: 'post',
    data
  })
}

/**
 * 库存锁定   修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/update',
    method: 'put',
    data
  })
}

/**
 * 库存锁定   详情
 * @param {string} id - row id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<number>}} data
 * @description 删除
 */
export function remove(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/delete',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<number>}} data
 * @return {Promise<Array>}
 * @description 库存锁定表锁定
 */
export function lock(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/lock',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<number>}} data
 * @return {Promise<Array>}
 * @description 库存锁定表解锁
 */
export function unlock(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/unlock',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {object} params
 * @returns {Promise<Array>}
 * @description  根据仓库、货主、商品编码、库位代码、批次号获取 库存数量以及可配数
 */
export function getNumByAll(params) {
  return service['api-scm']({
    url: '/public/inventory/getNumByAll',
    method: 'get',
    params
  })
}
/**
 *
 * @param {number} pageNum -条数
 * @param {number} pageSize -页码
 * @param {string} warehouseCode -仓库
 * @returns {Promise<Array>}
 * @description  根据仓库、查询指定仓库下的商品(sku)
 */
export function selectWarehouseSubProduct(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/selectWarehouseSubProduct',
    method: 'get',
    params
  })
}

/**
 * @param {type: formData}
 * @description 库存锁定导入
 */
export function importWarehouseLockData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/importWarehouseLockData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 下载模板
 */
export function downloadTemplate(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/downloadTemplate',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(string|number)} code
 * @description 树形表格下拉
 */
export function getListDown(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseLock/getListPull',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
