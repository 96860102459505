/**
 * @module custom-renderers:ElFileUploader
 * @path @/src/plugins/vxe-table/
 */
import VXETable from 'vxe-table'
// 自定义文件上传
VXETable.renderer.add('ElFileUploader', {
  renderEdit: ElFileUploaderRender,
  renderCell: ElFileUploaderRender
})

function ElFileUploaderRender(h, renderOpts, table) {
  // console.log('renderOpts', renderOpts, table)
  const {
    events: {
      getUploadedFileUrl = () => {},
      getUploadedFileName = () => {},
      getUploadedImageStatus = () => {},
      onUploadFile = () => {},
      onUploadImageLoaded = () => {},
      onUploadImageError = () => {},
      onRemoveFile = () => {},
      onChange = () => {}
      // onPreviewFile = () => {}
    },
    props
  } = renderOpts
  const { row, column } = table
  const disabled = renderOpts?.attrs?.disabled || false
  const fileListField = renderOpts?.attrs?.fileList
  const fileTypes = renderOpts?.attrs?.fileTypes
  const fileList = row[fileListField] || null
  const limitSize = props?.limitSize
  // console.log('fileList', fileList, row)
  // console.log('disabled', disabled)
  try {
    // console.log(table, row)
    if (row[column.property]) {
      const fileName = getUploadedFileName({ row, column })
      const fileUrl = getUploadedFileUrl({ row, column })
      const ext = fileUrl
        .slice(((fileUrl.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLowerCase()
      const imageStatus = getUploadedImageStatus({ row, column })
      return [
        <div
          class={[
            'uploaded-file-name-container',
            `uploaded-image-${imageStatus ? 'load-' + imageStatus : 'loading'}`
          ]}
        >
          <div class="uploaded-file-name-wrapper">
            {/(png|jpe?g|gif|svg)(\?.*)?$/.test(ext) ? (
              <div>
                <el-link type="primary" class="image-name">
                  {fileName}

                  <el-image
                    class="image"
                    fit="contain"
                    src={fileUrl}
                    alt={fileName}
                    preview-src-list={fileList || [fileUrl]}
                    onLoad={() => onUploadImageLoaded({ row, column })}
                    onError={() => onUploadImageError({ row, column })}
                  >
                    <div slot="placeholder" class="image-slot _placeholder">
                      <i class="el-icon-loading" />
                      &nbsp;加载中...&nbsp;
                    </div>
                    <div slot="error" class="image-slot _error">
                      <i class="el-icon-picture" />
                      &nbsp;加载失败&nbsp;
                    </div>
                  </el-image>
                </el-link>
                <span
                  class="sum"
                  style={
                    fileList && fileList?.length > 1
                      ? 'display:inline-block'
                      : 'display:none'
                  }
                >
                  +{fileList && fileList.length ? fileList.length : ''}
                </span>
              </div>
            ) : (
              <el-link type="primary" onClick={() => window.open(fileUrl)}>
                {fileName}
              </el-link>
            )}

            <i
              v-show={table.$table.isActiveByRow(row) && !disabled}
              class="el-icon-close"
              onClick={() => {
                onRemoveFile({ row, column })
                onChange({ row, column })
              }}
            />
          </div>
        </div>
      ]
    }
  } catch (error) {
    console.error(error)
  }
  return [
    <el-button
      status="primary"
      type="text"
      onClick={() => {
        onUploadFile({ row, column }, fileTypes, limitSize)
        onChange({ row, column })
      }}
      disabled={!table.$table.isActiveByRow(row) || disabled}
    >
      上传
    </el-button>
  ]
}
