/**
 * @module api-web:businessTodo/purchaseReplenishment/handle
 * @description 首页/采购补货待办  处理
 */
import service from '@/service'
/**
 * 获取推荐的采购组织
 * @param {{ids:string}} params
 */
export function getPurchasingOrganization(data) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/get/getPurchasingOrganization',
    method: 'post',
    data
  })
}

/**
 * 确认采购
 * @param {object} data
 */
export function confirmPurchase(data) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/manage/confirmPurchase',
    method: 'post',
    data
  })
}
/**
 * 终止待办
 * @param {object} data
 */
export function terminateTode(data) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/manage/terminateTode',
    method: 'post',
    data
  })
}
/**
 * 转发生产
 * @param {object} data
 */
export function forwardToProduction(data) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/manage/forwardToProduction',
    method: 'post',
    data
  })
}
/**
 * 转供应商库存
 * @param {object} data
 */
export function forwardToInventorySupplier(data) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/manage/forwardToInventorySupplier',
    method: 'post',
    data
  })
}

/**
 * 获取当前补货待办正在处理的商品信息表
 * @param {string} ids
 */
export function getHandleProductList(data) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/get/handle',
    method: 'post',
    data
  })
}
/**
 * 补货处理页面的供应商下拉框
 */
export function getSupplierByProduct(params) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/get/getSupplierByProduct',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 确认拆单提醒
 */
export function getRemainRules(params) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/get/getRemainRules',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 查询补货待办中的处理环节
 */
export function process(params) {
  return service['api-web']({
    url: 'public/getTodoReplenishmentDealListByTodoId',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 补货待办驳回
 */
export function reject(data) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * 补货待办驳回校验
 * @param {*} data
 * @returns
 */
export function rejectPreCheck(data) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/rejectPreCheck',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' },
    tipTypes: 'none'
  })
}

/**
 * 补货待办处理前的校验是否存在关联销售订单
 */
export function validateSale(params) {
  return service['api-scm']({
    url: '/public/purchase/getIsExistRelationSaleCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function exportData(data) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/orderDetailExport',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false // 文件流是否通过浏览器下载
  })
}

/**
 * 打印配送清单pdf获取(返回pdf在线地址链接)(批量)
 */
export function invoicePrinting(params) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/invoicePrinting',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 发货单打印(批量)
 */
export function shipInvoicePrint(params) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/shipInvoicePrint',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
