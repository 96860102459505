/**
 * @module api-web:financialAffairs/accountReceivable/expenseReceivable
 */
import service from '@/service'

/**
 * 查询 财务-费用财务应收列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *
 * @param {(string|number)} id
 * @description 树形表格下拉
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/public/getListDown',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 根据 ID 查询财务收款单详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 创建 新增
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/save',
    method: 'post',
    data
  })
}

/**
 * 提交审核
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 修改
 * @param {Department} data
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/update',
    method: 'put',
    data
  })
}
/**
 * 待办修改
 * @param {Department} data
 */
export function todoUpdate(data) {
  return service['api-web']({
    url: '/public/activiti/finFeeReceivableBill/update',
    method: 'put',
    data
  })
}
/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/finFeeReceivableBill/info',
    method: 'get',
    params: { id }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: '/fin/ar/finFeeReceivableBill/reject',
    url: '/public/activiti/finFeeReceivableBill/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/public/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/public/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object} data -id
 * @description 下推
 */
export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/public/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}}
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 费用财务应收导出
 */
export function finFeeReceivableBillExport(params) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/export',
    method: 'get',
    params,
    responseType: 'blob',
    saveAs: true,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 手动对接
 */
export function manualDocking(data) {
  return service['api-web']({
    url: '/fin/ar/finFeeReceivableBill/manualDocking',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
