import { isNumeric } from '@/utils/validate'
import VXETable from 'vxe-table'
import XEUtils from 'xe-utils'
import { nullToUndefined } from '@/utils/nullToUndefined'
import { toFixedWithoutRounding } from '@/utils/toFixedWithoutRounding'

/**
 * @description (全局复用) 格式化
 * @see https://vxetable.cn/v3/#/table/formats/api
 */

/**
 * 金额
 */
VXETable.formats.add(
  'formatterAmount',
  (
    { cellValue },
    digits = 2,
    options = {
      noRounding: false
    }
  ) => {
    let result = null
    const newValue = nullToUndefined(cellValue)
    try {
      result = isNumeric(newValue)
        ? options.noRounding
          ? Number(toFixedWithoutRounding(newValue, digits))
          : XEUtils.commafy(XEUtils.toNumber(newValue), { digits })
        : newValue
    } catch (error) {
      console.error('【formatterAmount】precision error', newValue, digits)
    }
    // console.log('【formatterAmount】', newValue)

    return result ?? newValue
  }
)

/**
 * 百分比
 */
VXETable.formats.add(
  'formatterPercent',
  /**
   *
   * @param {*} param0
   * @param {*} digits 精度
   * @param {*} calc 是否换算，换算乘以100， 不换算则只是增加百分号
   * @returns
   */
  ({ cellValue }, digits = 2, calc = false) => {
    let result = null
    try {
      result =
        isNumeric(cellValue) ||
        cellValue === null ||
        typeof cellValue === 'undefined'
          ? XEUtils.commafy(
              XEUtils.toNumber(calc === true ? cellValue * 100 : cellValue),
              { digits }
            )
          : cellValue
    } catch (error) {
      console.error('【formatterPercent】precision error', cellValue, digits)
    }

    if (isNumeric(result)) return `${result}%`
    else {
      console.warning(
        '【formatterPercent】failed',
        result,
        cellValue,
        digits,
        calc
      )
    }

    return cellValue
  }
)
