<template>
  <div class="navbar">
    <Hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <Breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <Search id="header-search" class="right-menu-item" />

        <!-- 错误日志按钮 -->
        <ErrorLog
          v-permission.fullname="['web:sys.sysLog']"
          class="errLog-container right-menu-item hover-effect"
        />

        <!-- 全屏按钮 -->
        <!-- <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->

        <!-- 全局字体大小 -->
        <!-- <el-tooltip content="Global Size" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip> -->
      </template>

      <el-dropdown
        class="user-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="user-wrapper">
          <img class="user-avatar" :src="userAvatar" />
          <span class="user-name">{{ name }}</span>
          <!-- <i class="el-icon-caret-bottom" /> -->
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- 增加路由如下 -->
          <!-- <router-link to="/demos/profile/index">
            <el-dropdown-item>Profile</el-dropdown-item>
          </router-link> -->

          <!-- 增加外链如下 -->
          <!-- <a target="_blank" href="https://xllily.github.io">
            <el-dropdown-item>Xllily</el-dropdown-item>
          </a> -->
          <el-dropdown-item @click.native="showUpdatePwd = true">
            <span style="display: block">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <UpdatePassword :show.sync="showUpdatePwd" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import defaultAvatar from '@/assets/avatar.png'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import UpdatePassword from '@/components/UpdatePassword'
import ErrorLog from '@/components/ErrorLog'
// import Screenfull from '@/components/Screenfull'
// import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
export default {
  components: {
    Breadcrumb,
    Hamburger,
    Search,
    UpdatePassword,
    ErrorLog
    // Screenfull,
    // SizeSelect
  },
  data() {
    return {
      showUpdatePwd: false
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'name', 'device']),
    userAvatar() {
      return this.avatar || defaultAvatar
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    logout() {
      this.$store.dispatch('user/logout').finally(() => {
        this.$router.replace('/login')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.navbar {
  height: $navBarHeight;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    height: $navBarHeight;
    line-height: $navBarHeight;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: $navBarHeight;
    line-height: $navBarHeight;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .right-menu-item + .right-menu-item {
      margin-left: 4px;
    }

    .user-container {
      margin-right: 30px;

      .user-wrapper {
        height: $navBarHeight;
        position: relative;
        cursor: pointer;

        .user-avatar {
          width: $avatarSize;
          height: $avatarSize;
          border-radius: 10px;
          margin-right: 7px;
          vertical-align: middle;
        }

        .user-name {
          vertical-align: top;
          display: inline-block;
          font-size: 14px;
          height: $navBarHeight;
          line-height: $navBarHeight;
          padding-top: 2px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
