/**
 * @module api-web:financialAffairs/accountPayable/finFeePayableBill
 */
import service from '@/service'

/**
 * 查询 财务-费用财务应付列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 查询财务付款单详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/info',
    method: 'get',
    params: { id }
  })
}

/**
 * @param {string} id
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/finFeePayableBill/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 待办修改
 * @param {Department} data
 */
export function todoUpdate(data) {
  return service['api-web']({
    url: '/public/activiti/finFeePayableBill/update',
    method: 'put',
    data
  })
}
/**
 * 创建 新增
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/save',
    method: 'post',
    data
  })
}

/**
 * 提交审核
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 修改
 * @param {Department} data
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/update',
    method: 'put',
    data
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: '/fin/ap/finFeePayableBill/reject',
    url: '/public/activiti/finFeePayableBill/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/public/reverseAudit',
    method: 'post',
    data: { id },
    // headers: { 'Content-Typedan': 'application/x-www-form-urlencoded' }
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/public/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<number>}} params - id
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<string>,type:number}}
 * @description 下推
 */
export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/public/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}}
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{code:string}}
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/public/getListDown',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 导出数据
 */
export function exportData(params) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/feePayableBillExport',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 费用财务应付单导入（手动导入时使用）
 */
export function importFinPayableBill(data) {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/importFinFeePayableBill',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 *  下载模板
 */
export function downloadExcel() {
  return service['api-web']({
    url: '/fin/ap/finFeePayableBill/public/downloadExcel',
    method: 'post',
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载， 下载的模板文件名需要有 buttonGroup 配置，所有不通过 axios 下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
