/**
 * @module custom-renderers:ElEditSpan
 * @path @/src/plugins/vxe-table/
 */
import VXETable from 'vxe-table'
import { isPlainObject, isString } from 'xe-utils'

VXETable.renderer.add('ElEditSpan', {
  renderEdit: ElEditSpanRender,
  renderCell: ElEditSpanRender
})

/**
 * @description
 * 非编辑模式使用 vxe 原生的 overflow
 * 编辑模式使用 el-tooltip
 */
function ElEditSpanRender(h, renderOpts, params) {
  const {
    attrs = {
      showOverflow,
      tooltipConfig,
      formatOn: 'change'
    }
  } = renderOpts

  const formatOn = attrs.formatOn || 'change'

  const showOverflow =
    typeof attrs.showOverflow === 'boolean' ||
    !!(
      isString(attrs.showOverflow) &&
      ['ellipsis', 'title', 'tooltip'].includes(attrs.showOverflow)
    )
      ? attrs.showOverflow
      : true

  const tooltipConfig = isPlainObject(attrs?.tooltipConfig)
    ? attrs.tooltipConfig
    : {}

  const theme = ['light', 'dark'].includes(tooltipConfig.theme)
    ? tooltipConfig.theme
    : showOverflow === 'title'
    ? 'light'
    : 'dark'

  const position = showOverflow === 'title' ? 'right-end' : 'top'
  const {
    row,
    $table,
    column: { property, formatter }
  } = params

  let text = ''
  try {
    if (typeof formatter === 'function') {
      switch (formatOn) {
        case 'change':
          text = formatter(params) || (attrs.showLoading ? 'loading' : '')
          break
        case 'save':
          text = row[property] || (attrs.showLoading ? 'loading' : '')
          break
      }
    }
  } catch (error) {
    console.error(error)
  }
  text = text || row[property]
  return [
    text === 'loading' ? (
      <i class="vxe-icon--more"></i>
    ) : (
      <el-tooltip
        disabled={!$table.isActiveByRow(row) && showOverflow !== 'tooltip'}
        content={`${text}`}
        effect={theme}
        placement={position}
      >
        <span
          class={[
            'span-editing-row',
            text === 0 || text ? '' : 'table-na-placeholder'
          ]}
        >
          {text || text === 0 ? text : ''}
        </span>
      </el-tooltip>
    )
  ]
}
