<template>
  <div v-if="needTitleBar" class="title-bar">
    <Sticky
      v-if="!cancelStyle"
      ref="title-bar-sticky"
      class-name="title-bar-sticky"
      :z-index="999"
      :support-expand-collapse="supportExpandCollapse"
      :sticky-top="stickyTop"
      :is-expand="isExpand"
      :no-tit="hideTit"
      @form-layout="onFormLayout"
    >
      <h1 v-if="!hideTit" class="title">{{ text || $route.meta.title }}</h1>
      <slot />
    </Sticky>
    <!-- <template v-if="supportExpandCollapse"> 暂时将判断按钮的显隐去掉不需要 -->
    <el-button
      v-show="showExpandCollapseSwitch"
      class="expand-collapse-switch"
      size="small"
      type="text"
      @click="onExpandCollapse"
    >
      {{ isExpand ? '收起' : '展开' }}
      <i class="el-icon-d-arrow-left" :class="isExpand ? '_up' : '_down'" />
    </el-button>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Sticky from './Sticky'
export default {
  name: 'TitleBar',
  components: { Sticky },
  props: {
    text: {
      type: String,
      default: ''
    },
    hideTit: {
      type: Boolean,
      default: false
    },
    cancelStyle: {
      type: Boolean,
      default: false
    },
    supportExpandCollapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isExpand: false,
      toggleCollapse: false,
      showExpandCollapseSwitch: false
    }
  },
  computed: {
    ...mapState({
      needTitleBar: (state) => state.settings.titleBar,
      fixedHeader: (state) => state.settings.fixedHeader,
      totalNavTagHeight: (state) => state.settings.totalNavTagHeight,
      titleMarginBottom: (state) => state.settings.titleMarginBottom
    }),
    stickyTop() {
      return this.fixedHeader ? this.totalNavTagHeight : 0
    },
    _titleMarginBottom() {
      return this.$pageType.isListPage(this.$route.name)
        ? `${this.titleMarginBottom}px`
        : '0px'
    }
  },

  methods: {
    /**
     *@description  自动显隐「展开/收起」按钮
     */
    initExpandCollapseSwitch($form, $formItems, $formItemsChildren, { width }) {
      return new Promise((resolve) => {
        if ($form && $formItems && $formItemsChildren) {
          let totalFormItemsWidth = 0
          for (var i = 0; i < $formItemsChildren.length; i++) {
            const $formItem = $formItemsChildren[i]
            if (
              totalFormItemsWidth + $formItem.offsetWidth + 16 >
              $form.offsetWidth
            ) {
              return resolve(true)
            }
            totalFormItemsWidth += $formItem.offsetWidth + 16
          }
        }
        resolve(false)
      })
    },
    async onExpandCollapse() {
      if (this.toggleCollapse === true) return

      this.toggleCollapse = true

      this.isExpand = !this.isExpand
      await this.$refs?.['title-bar-sticky']?.updateHeight()
      await this.$halt(16)
      this.$emit('expand-collapse', this.isExpand ? 'expand' : 'collapse')

      await this.$halt(640)
      this.toggleCollapse = false
    },
    onFormLayout({ $form, width, $formItems, $formItemsChildren }) {
      this.initExpandCollapseSwitch($form, $formItems, $formItemsChildren, {
        width
      }).then((hiddenFormItemExists) => {
        this.showExpandCollapseSwitch = hiddenFormItemExists
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import '~@/styles/variables.scss';
.title-bar {
  position: relative;
  .expand-collapse-switch {
    position: absolute;
    right: 16px;
    bottom: 0;
    z-index: 1000;
    margin-bottom: 24px;
    padding: 0;
    .el-icon-d-arrow-left {
      margin-left: 3px;
      transition: transform 400ms;
    }
    .el-icon-d-arrow-left._up {
      transform: rotate(90deg);
    }
    .el-icon-d-arrow-left._down {
      transform: rotate(-90deg);
    }
  }
}
:deep(.title-bar-sticky) {
  padding-top: $titleBarPaddingTop;
  padding-bottom: $titleBarPaddingBottom;
  padding-left: $titleBarPaddingLeft;
  padding-right: $titleBarPaddingRight;
  background-color: #fff;
}

:deep(.title) {
  font-size: $titleFontSize;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: v-bind(_titleMarginBottom);
  height: $titleHeight;
  line-height: $titleHeight;
}
</style>
