<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <template v-if="logo">
          <img v-if="validImg(logo)" :src="logo" class="sidebar-logo" />
          <h1 v-else-if="typeof logo === 'string'" class="sidebar-logo-txt">
            {{ logo }}
          </h1>
        </template>
        <h1 v-else class="sidebar-title">{{ title }}</h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <template v-if="logo">
          <img v-if="validImg(logo)" :src="logo" class="sidebar-logo" />
          <h1 v-else-if="typeof logo === 'string'" class="sidebar-logo-txt">
            {{ logo }}
          </h1>
        </template>
        <h1 class="sidebar-title">{{ title }}</h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
import { validImg } from '@/utils/validate'
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: '供应链管理系统',
      logo: 'ASR'
    }
  },
  methods: {
    validImg
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: $sidebarLogoConHeight;
  line-height: $sidebarLogoConHeight;
  background: $menuActiveText;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & > h1 {
      margin: 0 5px 0px 0px;
      display: inline-block;
      color: #fff;
      font-weight: 600;
      height: $sidebarLogoConHeight;
      line-height: $sidebarLogoConHeight;
      font-size: 14px;
    }

    & .sidebar-logo-txt {
      margin: 0 5px 0px 0px;
    }

    & .sidebar-title {
      margin: 0;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
