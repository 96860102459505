/**
 * @module store/modules/sysTree
 * @description 系统树
 */

import { login, logout } from '@/service/auth'

import {
  getToken,
  setToken,
  removeToken,
  setName,
  getName,
  setKey,
  removeKey,
  getKey
} from '@/utils/auth'

import { getFileInfo as convertAvatar } from '@/utils/request'

import { removePm } from '@/storage/session/permission'
import { isPlainObject, isString } from '@/utils/validate'

import { resetRouter } from '@/router'

import { clearQuery } from '@/storage/session/listQuery'
import { remove, set } from '@/storage'
import {
  getUserInfo,
  removeRoleMenu,
  removeUserInfo,
  setUserInfo
} from '@/utils/auth/user'

const state = () => ({
  key: getKey(),
  token: getToken(),
  id: '',
  name: getName(), // 登录帐号名（昵称）
  realname: '', // 真实姓名
  avatar: '', // 头像
  roles: [],
  roleNames: [],
  userStatus: null,
  sex: '',
  mail: '',
  idNumber: '',
  birthDate: '',
  age: null,
  contact: '',
  telephone: '',
  jobId: null,
  jobName: '',
  deptId: null,
  deptParentId: null,
  deptPath: null,
  deptName: '',
  workerId: '',
  workStatus: null
})

const mutations = {
  SET_KEY: (state, key) => {
    state.key = key
  },
  SET_TOKEN: (state, token) => {
    state.token = `Bearer ${token}`
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ROLE_NAMES: (state, roleNames) => {
    state.roleNames = roleNames
  },
  SET_REAL_NAME: (state, realname) => {
    state.realname = realname
  },
  SET_REST_USER_INFO: (state, restUserinfos) => {
    Object.keys(restUserinfos).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        state[key] = restUserinfos[key]
      } else {
        console.error(
          'Losed key:' + key + ' for "CHANGE_SETTING" that on state.user'
        )
      }
    })
  }
}

const actions = {
  // user login
  async login({ commit }, loginForm) {
    try {
      const { keypair, pk1, pk2, username, password, sms } = loginForm

      removeToken()
      removeKey()

      const loginRes = await login({
        keypair, // inject into request config, not send
        grant_type: 'captcha',
        username,
        password,
        captcha: sms,
        pk: pk1,
        pk2
      })
      const {
        data: { refreshToken }
      } = loginRes

      const refreshTokenRes = await login({
        keypair, // inject into request config, not send
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        username,
        password,
        captcha: sms,
        pk: pk1,
        pk2
      })
      const {
        data: { accessToken, key }
      } = refreshTokenRes

      commit('SET_KEY', key)
      commit('SET_TOKEN', accessToken)
      setToken(accessToken)
      setKey(key)

      if (refreshToken && accessToken && key) {
        set('local', 'ASR_USER_NAME', username)
        setName(username)
        commit('SET_NAME', username) // 昵称 (store)

        return Promise.resolve()
      } else {
        return Promise.reject({ ...loginRes, ...refreshTokenRes })
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  //
  /**
   *  set user info
   * @returns {Promise<{status:('success'|'failed'|'error'),msg:string,log:Array<any>}>}
   */
  init({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((data) => {
          setUserInfo(data)

          if (!data || !isPlainObject(data)) {
            resolve({
              status: 'failed',
              msg: '', // 获取用户信息失败
              log: ['【getInfo】: non response, try login again', '\n', data]
            })
          }

          const {
            headUrl,
            roleId,
            roleName,
            username: name,
            name: realname,
            ...rest
          } = data

          // 角色
          let roles = null
          if (!roleId || !isString(roleId) || roleId.length <= 0) {
            resolve({
              status: 'failed',
              msg: '获取角色信息异常',
              log: [`【getInfo】: illeagl roleId:${roleId}`]
            })
          } else roles = roleId.split(',')
          let roleNames = null
          if (!roleName || !isString(roleName) || roleName.length <= 0) {
            resolve({
              status: 'failed',
              msg: '获取角色信息异常',
              log: [`【getInfo】: illeagl roleName:${roleName}`]
            })
          } else roleNames = roleName.split(',')

          commit('SET_ROLES', roles) // 角色 IDs
          commit('SET_ROLE_NAMES', roleNames) // 角色 Names

          commit('SET_NAME', name) // 昵称 (store)
          setName(name) // 昵称 (cookie)
          set('local', 'ASR_USER_NAME', name)

          commit('SET_REAL_NAME', realname) // 真实姓名

          commit('SET_REST_USER_INFO', rest) // 其他

          convertAvatar(headUrl) // 头像
            .then(({ url: avatar }) => commit('SET_AVATAR', avatar))
            .finally(() => {
              resolve({ status: 'success', msg: 'getInfo: success', log: [] })
              console.groupCollapsed('当前用户')
              console.table({ ...state })
              console.groupEnd()
            })
        })
        .catch((error) => {
          resolve({
            status: 'error',
            msg: '获取用户信息异常',
            log: [`【getInfo】: error cathed`, '\n', error]
          })
        })
    })
  },

  logout(
    { commit, rootGetters, state, dispatch },
    payload = { request: true }
  ) {
    function callback() {
      return new Promise((resolve, reject) => {
        dispatch('resetAuth')
          .catch((e) => reject(e))
          .finally(() => {
            removePm(rootGetters.id)
            resetRouter()

            removeKey()
            removeToken()
            remove('local', 'ASR_USER_NAME')
            remove('local', 'USER_ID')

            resolve()
          })
      })
    }

    return new Promise((resolve, reject) => {
      if (payload?.request === true) {
        logout(state.token)
          .then(() => {
            callback()
              .then(() => resolve())
              .catch((error) => {
                console.error('Logout error without request[1]', error)
                reject(error)
              })
          })
          .catch((error) => {
            console.error('Logout error when request', error)
            reject(error)
          })
      } else {
        callback()
          .then(() => resolve())
          .catch((error) => {
            console.error('Logout error without request[2]', error)
            reject(error)
          })
      }
    })
  },

  resetAuth({ commit }) {
    return new Promise((resolve) => {
      try {
        commit('SET_TOKEN', '')
        removeToken()
        remove('local', 'ASR_USER_NAME')

        removeUserInfo()
        removeRoleMenu()

        // commit('SET_NAME', '')
        // removeName()

        clearQuery({ force: true })

        commit('SET_KEY', '')
        removeKey()

        commit('SET_ROLES', [])
      } catch (e) {
        console.error(e)
      }

      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
