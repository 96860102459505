// eslint-disable-next-line spaced-comment
/// <reference path="../../typedef.js" />

/**
 * @module permission/menus/routes/name
 */

import { isArray, isString } from '@/utils/validate'
import { camelCase, uppercaseFirst } from '@/utils/string'
import { recursiveParents } from '@/permission/utils'

export function createNameViaPathArr(pathArr) {
  const base = pathArr[0]
  base.charAt(0) === '/' ? (pathArr[0] = base.substring(1)) : null
  const name = pathArr
    .filter((v) => !!v)
    .map((v) =>
      v
        .replace('/:id', '')
        .split('-')
        .map((_v) => uppercaseFirst(camelCase(_v)))
    )
    .join('')

  return name
}
/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {string} 路由 name
 */
export function generateName(node, indexTable) {
  let result = ''
  const { path, pmUrl } = node
  try {
    const recursiveResult = recursiveParents(indexTable, node, [path], 'path')
    if (isArray(recursiveResult) && isString(recursiveResult[0])) {
      result = createNameViaPathArr(recursiveResult)
    } else {
      console.warn('Illegal "recursiveResult"', node, recursiveResult)
    }
  } catch (error) {
    console.error(error)
  }

  return (
    result ||
    `Error${pmUrl
      .split('/')
      .map((v) =>
        v
          .split('-')
          .map((_v) => uppercaseFirst(_v))
          .join('')
      )
      .join('')}`
  )
}

export default generateName
