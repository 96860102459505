export { parseTime, formatTime } from '@/utils/datetime'
export { uppercaseFirst } from '@/utils/string'
export * from './permission'

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      )
    }
  }
  return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * remove script contents from html string, XSS
 * @param {string} html
 * @returns {string}
 */
export function dropScripts(html) {
  try {
    return html.replace(/<script[^>]*>.*?<\/script>/gi, '')
  } catch (error) {
    console.error(error)
    return html.includes('<script>') ? '' : html
  }
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {*} ms
 * @returns {{days:number,hours:(string|0),minutes:(string|0),seconds:(string|0)}}
 */
export function getDHMS(ms) {
  try {
    const days = Math.round((ms * 1) / (1000 * 60 * 60 * 24))
    let hours = Math.round(
      ((ms * 1) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    let minutes = Math.round(((ms * 1) % (1000 * 60 * 60)) / (1000 * 60))
    let seconds = ((ms * 1) % (1000 * 60)) / 1000
    hours = hours < 10 && hours > 0 ? '0' + hours : hours
    minutes = minutes < 10 && minutes > 0 ? '0' + minutes : minutes
    seconds = seconds < 10 && seconds > 0 ? '0' + seconds : seconds
    return { days, hours, minutes, seconds }
  } catch (error) {
    console.error('getDHMS', ms, error)
    return { days: 0, hours: 0, minutes: 0, seconds: 0 }
  }
}
/**
 * 毫秒转时长(xx:xx:xx:xx.xxx)
 * @param {(stirng|number)} ms
 */
export function msToDuration(ms) {
  const { days, hours, minutes, seconds } = getDHMS(ms)
  return (days > 0 ? days + ':' : '') + hours + ':' + minutes + ':' + seconds
}

/**
 * 毫秒转时长 (xx 天 xx 时 xx 分 xx.xxx 秒)
 * @param {(stirng|number)} ms
 */
export function msToDurationZH(ms) {
  const { days, hours, minutes, seconds } = getDHMS(ms)
  return (
    (days > 0 ? days + ' 天 ' : '') +
    hours +
    ' 小时 ' +
    minutes +
    ' 分钟 ' +
    seconds +
    ' 秒'
  )
}

/**
 * @param {*} ms
 * @param {{zh:boolean,end:('d'|'h'|'m'|'s')}} options
 */
export function msToDurationDIY(ms, options = { zh: false, end: 's' }) {
  const _end = ['d', 'h', 'm', 's'].includes(options?.end) ? options.end : 's'
  const _zh = typeof options?.zh === 'boolean' ? options.zh : false

  if (_end === 's') {
    return _zh === true ? msToDurationZH(ms) : msToDuration(ms)
  } else {
    if (_zh === true) {
      const { days, hours, minutes, seconds } = getDHMS(ms)

      switch (_end) {
        case 'd':
          return days > 0
            ? `${days} 天`
            : `${days} 天 ${hours} 时 ${minutes} 分 ${seconds} 秒`
        case 'h':
          return days > 0
            ? hours > 0
              ? `${days} 天 ${hours} 时`
              : `${days} 天 ${hours} 时 ${minutes} 分 ${seconds} 秒`
            : hours > 0
            ? `${hours} 时`
            : `${hours} 时 ${minutes} 分 ${seconds} 秒`
        case 'm':
          return days > 0
            ? `${days} 天 ${hours} 时 ${minutes} 分`
            : hours > 0
            ? `${hours} 时 ${minutes} 分`
            : minutes > 0
            ? `${minutes} 分`
            : `${seconds} 秒`
      }
    } else return msToDuration(ms)
  }
}

/**
 * 毫秒转分
 * @param {*} ms
 * @returns xx.xx
 */
export const ms2min = function (ms) {
  return ((ms * 1) / 1000 / 60).toFixed(2)
}
/**
 * 毫秒转分
 * @param {*} ms
 * @returns xx.xx 分
 */
export const ms2minZH = function (ms) {
  return ms2min(ms) + ' 分'
}
/**
 * 毫秒转秒
 * @param {*} ms
 * @returns xx.xx
 */
export const ms2sec = function (ms) {
  return ((ms * 1) / 1000).toFixed(2)
}
/**
 * 毫秒转秒
 * @param {*} ms
 * @returns xx.xx 秒
 */
export const ms2secZH = function (ms) {
  return ms2sec(ms) + ' 秒'
}
/**
 * 秒转分
 * @param {*} sec xx.xx
 */
export const sec2min = function (sec) {
  return ((sec * 1) / 60).toFixed(2)
}
/**
 * 秒转分
 * @param {*} sec xx.xx 分
 */
export const sec2minZH = function (sec) {
  return sec2min(sec) + ' 分'
}
