/**
 * @module api-scm:warehouseOutMore
 */
import service from '@/service'

/**
 * @typedef {Object} warehouseOutMore 多货主调拨其他出库单列表
 * @property {number=} [limit=1] -
 * @property {number} page - 页码
 * @property {string} sort -
 * @property {number} deliveryWarehouseCode - 发货仓库
 * @property {string} productCode - 商码编号
 * @property {string} productName - 商品名称
 * @property {string} warehouseOutTimeEnd - 出库时间结束
 * @property {string} warehouseOutTimeStart - 出库时间起始
 *
 * @param {warehouseOutMore}
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/get/getList',
    method: 'get',
    params
  })
}

/**
 * 下拉
 * @param {{code:string}} code
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/get/getListDown',
    method: 'get',
    params: { code },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/info',
    // url: '/scm/warehouse/warehouseOutMore/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehouseOutMore/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{number:id}}
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseOutMore/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 作废
 * @param {{id:number}} id
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/pushDown',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {string} ids
 * @description 拣货单打印
 */
export function printPicklist(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/multipleCargoOwnersPrinting',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 手动对接
 * @param {string} ids
 */
export function manualDockingOfKingdee(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/manualDockingWarehouseAllotOutToKingDee',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出多货主调拨出库单
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOutMore/export',
    method: 'post',
    data,
    responseType: 'blob' // 文件流接收类型声明转成 blob
  })
}
