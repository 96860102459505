/**
 * @module api-web:job
 */
import service from '@/service'

/**
 * @param {{keywords:string,limit:number,page:number,sort:string}} params
 * @description 查询职务列表
 */
export function getList(params) {
  return service['api-web']({
    url: '/sys/sysJob/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{jobName:string}} data
 * @description 增加职务
 */
export function add(data) {
  return service['api-web']({
    url: '/sys/sysJob/save',
    method: 'post',
    data
  })
}

/**
 * @param {{jobId:number,jobName:string}} data
 * @description 修改职务
 */
export function update(data) {
  return service['api-web']({
    url: '/sys/sysJob/update',
    method: 'put',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params - ids
 * @description 单个/批量 删除职务
 */
export function remove(params) {
  return service['api-web']({
    url: '/sys/sysJob/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 根据 ID 查询职务详情
 */
export function getDetailsById(id) {
  return service['api-web']({
    url: '/sys/sysJob/info',
    method: 'get',
    params: { id }
  })
}
