<template>
  <el-autocomplete
    ref="el-auto-complete"
    v-model="proxyValue"
    :fetch-suggestions="proxyFetchSuggestions"
    :trigger-on-focus="triggerOnFocus"
    :placeholder="placeholder"
    :clearable="clearable"
    v-bind="$attrs"
    value-key="value"
    v-on="listeners"
  >
    <template slot-scope="{ item }">
      <a :title="item.value" style="text-decoration: none">{{ item.value }}</a>
    </template>
  </el-autocomplete>
</template>
<script>
import { autoCompleteInputFunc } from '@/utils/agelForm'
export default {
  name: 'AsrAutoComplete',
  compontents: {},
  props: {
    value: {
      type: [String, Number, null],
      default: null
    },
    payload: {
      type: Object,
      default: () => {}
    },
    fetchSuggestions: {
      type: Function,
      default: null
    },
    service: {
      type: String, // 服务名
      default: 'web'
    },
    api: {
      type: String, // 请求的方法名
      default: ''
    },
    paramKey: {
      type: String, // 请求的参数名
      default: 'keywords'
    },
    labelKey: {
      type: String, // 下拉选项 label
      default: 'label'
    },
    valueKey: {
      type: String, // 下拉选项 value
      default: 'value'
    },
    triggerOnFocus: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '精确查询，单选'
    }
  },
  data() {
    return {}
  },
  computed: {
    /**
     * @return {Function}
     */
    proxyFetchSuggestions() {
      const fetchSuggestions =
        typeof this.fetchSuggestions === 'function'
          ? this.fetchSuggestions
          : autoCompleteInputFunc(
              this.service,
              this.api,
              this.paramKey,
              this.labelKey,
              this.payload
            )
      return fetchSuggestions
    },
    listeners() {
      return this.getListeners(this.$listeners)
    },
    proxyValue: {
      get: function () {
        return this.value
      },
      set: function (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getListeners(events = {}) {
      const { blur, focus, change, input, clear, select } = events
      const evts = {
        change: (value) => {
          this.$emit('input', '')
          typeof change === 'function' && change(value)
        },
        blur: (e) => {
          this.$nextTick(() => {
            if (!this.value) {
              this.$emit('input', '')
              typeof clear === 'function' && clear()
            }
            typeof blur === 'function' && blur(e)
          })
        },
        select: (item) => {
          if (!item[this.valueKey]) {
            console.error(`【this.】losed item.${this.valueKey}`, item)
            typeof select === 'function' && select(item)
            return
          }
          if (!item[this.valueKey]) {
            console.error(`【this.】losed item.${this.valueKey}`, item)
            typeof select === 'function' && select(item)
            return
          }

          this.$emit('input', item[this.valueKey])
          typeof select === 'function' && select(item)
        },
        clear: (e) => {
          this.$emit('input', '')
          typeof clear === 'function' && clear(e)
        },
        focus: typeof focus === 'function' ? focus : undefined,
        input: typeof input === 'function' ? input : undefined
      }
      Object.keys(evts).forEach((key) =>
        evts[key] === undefined ? delete evts[key] : null
      )
      return evts
    }
  }
}
</script>
<style lang="scss" scoped></style>
