/**
 * @module api-web:financialAffairs/generalLedger/data/constItem/group
 * @description 财务/总账/资料/费用项目分组
 */
import service from '@/service'
/**
 *  总账-费用项目分组新增
 */
export function add(data) {
  return service['api-web']({
    url: 'fin/gl/data/finCostItemGroup/save',
    method: 'post',
    data
  })
}

/**
 * 总账-费用项目分组修改
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/gl/data/finCostItemGroup/update',
    method: 'put',
    data
  })
}
