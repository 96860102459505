/**
 * @module api-web:financialAffairs/generalLedger/data/accountBook
 */
import service from '@/service'

/**
 * 查询 财务-总账-资料-账薄
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountBook/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 详情
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountBook/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
