/**
 * @module utils/validate
 */
export { isInteger, isPlainObject } from 'lodash'

/**
 * @param {string} username
 * @returns {Boolean}
 */
export function validUsername(username) {
  try {
    return username.trim().length >= 2 && username.trim().length < 20
  } catch (error) {
    console.error(error)
    return false
  }
}
/**
 * @param {string} password
 * @returns {Boolean}
 */
export function validPassword(password) {
  try {
    return (
      isString(password) &&
      password.trim().length >= 6 &&
      password.trim().length < 1024
    )
  } catch (error) {
    console.error(error)
    return false
  }
}
/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} img
 * @returns {Boolean}
 */
export function validImg(img) {
  const reg = /\.(png|jpe?g|gif|svg)(\?.*)?$/
  return reg.test(img)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}
/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @param {any} value
 * @returns {boolean}
 */
export function isNumeric(value) {
  return /^-?\d+$/.test(value) || /^-?\d+(?:[.,]\d*?)?$/.test(value)
}

/**
 * 输入框中可能会从excel中复制列数据或者直接逗号拼接来批量查询的处理
 */
export function processingList(inputValue) {
  // 判断是否是逗号拼接
  const hasComma = inputValue.includes(',')
  let parsedArr = []
  if (hasComma) {
    parsedArr = inputValue
      .split(',')
      .filter((v) => !!v.trim())
      .map((item) => item.trim())
  } else {
    parsedArr = inputValue
      .split(' ')
      .filter((v) => !!v.trim())
      .map((item) => item.trim())

    parsedArr = processAndJoinStrings(parsedArr)
  }

  return parsedArr
}

export const processAndJoinStrings = (inputArray) => {
  const processedArray = inputArray.map((str) => {
    // 去除双引号和制表符
    return str.replace(/"/g, '').replace(/\t/g, '')
  })
  return processedArray
}
/**
 * @description 去除空属性
 */
export const removeEmptyProperties = (obj) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key]
    } else if (typeof obj[key] === 'object') {
      removeEmptyProperties(obj[key])
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    }
  }
}
