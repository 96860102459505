export default {
  path: 'vueuse',
  component: () => import('@/views/demos/vueuse'),
  redirect: '/demos/components/useVirtualList',
  name: 'Vueuse',
  meta: {
    title: 'VueUse',
    icon: 'vueuse'
  },
  children: [
    // {
    //   path: 'useDraggable',
    //   component: () => import('@/views/demos/vueuse/useDraggable'),
    //   name: 'UseDraggable',
    //   meta: { title: 'UseDraggable' }
    // },
    {
      path: 'useVirtualListHook',
      component: () => import('@/views/demos/vueuse/useVirtualListHook'),
      name: 'UseVirtualListHookDemo',
      meta: { title: 'UseVirtualListHook' }
    },
    {
      path: 'useVirtualListComp',
      component: () => import('@/views/demos/vueuse/useVirtualListComp'),
      name: 'UseVirtualListCompDemo',
      meta: { title: 'UseVirtualListComp' }
    }
  ]
}
