import { isString, isArray } from '@/utils/validate'
import settings from '@/settings'

// you can set in settings.js
// errorLog:'production'| 'development'|['production', 'development']
const { errorLog: needErrorLog } = settings

function checkNeed() {
  const env = process.env.VUE_APP_ENV
  if (isString(needErrorLog)) {
    return env === needErrorLog
  }
  if (isArray(needErrorLog)) {
    return needErrorLog.includes(env)
  }

  return false
}
export default (Vue, store) => {
  if (checkNeed()) {
    window.addEventListener(
      'error',
      (err) => {
        Vue.nextTick(() => {
          store.dispatch('errorLog/addErrorLog', {
            err: {
              ...err,
              message: err.message ?? 'window.onerror',
              stack:
                err.stack ??
                err.path
                  ?.map(
                    (v) =>
                      `${v?.tagName?.toLowerCase()}.${v?.className
                        ?.split(' ')
                        ?.join('.')}`
                  )
                  ?.join(' → ') ??
                'window'
            },
            vm: null,
            info: err.path?.[0]?.outerHTML ?? null,
            url: window?.location?.href
          })

          console.error(err)
        })
      },
      true
    )

    Vue.config.errorHandler = function (err, vm, info, a) {
      // Don't ask me why I use Vue.nextTick, it just a hack.
      // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
      Vue.nextTick(() => {
        store.dispatch('errorLog/addErrorLog', {
          err,
          vm,
          info,
          url: window?.location?.href
        })
        console.error(err, vm, info, a)
      })
    }
  }
}
