/**
 * @module api-web:supplierGroup
 */
import service from '@/service'

/**
 * 供应商分组 新增
 */
export function save(data) {
  return service['api-web']({
    url: '/data/supplierGroups/save',
    method: 'post',
    data
  })
}

/**
 * 供应商分组 修改
 */
export function update(data) {
  return service['api-web']({
    url: '/data/supplierGroups/update',
    method: 'post',
    data
  })
}
/**
 * 供应商分组 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/supplierGroups/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
