/**
 * @module custom-renderers:VxeEditButton
 * @path @/src/plugins/vxe-table/
 */
import VXETable from 'vxe-table'
import VxeEditBtn from './VxeEditBtn'

VXETable.renderer.add('VxeEditButton', {
  renderEdit: VxeEditButtonRender,
  renderCell: VxeEditButtonRender
})

function VxeEditButtonRender(h, renderOpts, params = {}) {
  try {
    const { events = {}, props = {} } = renderOpts
    const { content, ...rest } = props
    return [
      <VxeEditBtn
        content={content}
        scope={params}
        on={events}
        vxe-btn-props={rest}
      ></VxeEditBtn>
    ]
  } catch (error) {
    console.error(error)
  }
  return [<VxeEditBtn />]
}
