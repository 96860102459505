import { isArray, isString } from '@/utils/validate'

export default {
  /**
   * 流程图
   */
  name: 'workProcessMixin',
  data() {
    return {
      workProcessDialog: {
        title: '流程',
        top: '50px',
        with: '100vw',
        show: false
      }
    }
  },
  computed: {
    /**
     * @returns {boolean} 是否挂载 工作流程 弹窗
     * 1. 有流程实例 ID
     * 2. 有按钮 或者下拉
     */
    mountWorkProcessDialog() {
      if (!isArray(this.buttonGroup)) return false

      const { $_detail_cru_procInstIdOfInfo } = this.scope || {}
      const hasProcessInstId = !!(
        isString($_detail_cru_procInstIdOfInfo) && $_detail_cru_procInstIdOfInfo
      )
      if (!hasProcessInstId) return false

      const workProcessBtn = this.buttonGroup.filter(
        (v) => v.evtName === '工作流程' || v.label === '工作流程'
      )
      if (!workProcessBtn.length) {
        const arr = this.buttonGroup
        const len = arr.length

        let workProcessDropdown = []

        for (let i = 0; i < len; i++) {
          const item = arr[i]
          if (!isArray(item.dropdown)) continue
          workProcessDropdown = item.dropdown.filter(
            (v) => v.evtName === '工作流程' || v.label === '工作流程'
          )
          if (workProcessDropdown.length) break
        }

        if (!workProcessDropdown.length) return false
      }

      return true
    }
  },
  methods: {
    workProcess({ config, evtName, methodName, button }) {
      console.log(methodName)
      this.workProcessDialog.show = true
    }
  }
}
