// eslint-disable-next-line spaced-comment
/// <reference path="typedef.js" />

import { isPlainObject } from '@/utils/validate'

/**
 * @module permission/indexes
 */

/**
 * @param {RolePermissionTreeItem} node
 * @returns {string} key for "nameIndex"
 */
function createNameIndexKey({ pmUrl = '/' }) {
  let nameIndexKey = pmUrl
  try {
    const sliced = pmUrl
      .replace('api-', '') // remove 'api-' of api-web,api-activiti,api-scm,...
      .replace(/\{.*?\}\s?/g, '') // remove like {id},...
      .split('/')
      // .slice(2)
      .filter((v) => !!v && v !== '**')
    if (sliced.length) {
      const [serviceName, ...rest] = sliced
      if (sliced.length === 1) nameIndexKey = serviceName
      else nameIndexKey = `${serviceName}:${rest.join('.')}`
    }
  } catch (error) {
    console.error(error)
  }
  return nameIndexKey
}

/**
 * @param {RolePermissionFlat} rolePermissionList
 * @param {('create'|'append'))} [type='create']
 * @returns {Promise<PermissionIndexTable>} id index and pid index table
 */
export function createIndexes(rolePermissionList, type = 'create') {
  return new Promise((resolve) => {
    const idIndex = {}
    const pidIndex = {}
    const nameIndex = {}
    try {
      rolePermissionList.forEach((item, idx, arr) => {
        if (!isPlainObject(item)) {
          arr.splice(idx, 1)
          console.warn('Remove illegal permission item', item)
          return
        }
        const id = item['id']
        const pid =
          typeof item['pid'] === 'undefined' || item['pid'] === null
            ? -1
            : item['pid'] // 根节点 pid 为 undefined, 索引表中赋值为 -1
        idIndex[id] = item
        nameIndex[createNameIndexKey(item)] = item
        pidIndex[pid] = pidIndex[pid] ? [...pidIndex[pid], item] : [item]
      })
    } catch (error) {
      console.error(error)
    }
    console.groupCollapsed(`角色权限索引 (${type})`)
    console.log('- ID   索引:', JSON.parse(JSON.stringify(idIndex)))
    console.log('- PID  索引:', JSON.parse(JSON.stringify(pidIndex)))
    console.log('- Name 索引:', JSON.parse(JSON.stringify(nameIndex)))
    console.groupEnd()

    resolve({ idIndex, pidIndex, nameIndex })
  })
}

export default createIndexes
