<template>
  <el-select
    ref="el-selelct"
    v-model="proxyValue"
    v-loading="loading"
    :loading="loading"
    remote
    filterable
    :placeholder="placeholder || defaultPlaceholder"
    :clearable="clearable"
    :collapse-tags="collapseTags"
    :reserve-keyword="reserveKeyword"
    :remote-method="proxyRemoteMethod"
    v-bind="$attrs"
    v-on="proxyEvents"
    @change="onChange"
    @keyup.enter.native="onEnterPress"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>
<script>
import { isArray, isPlainObject } from '@/utils/validate'
import { autoSelectRemoteFunc } from '@/utils/agelForm'
export default {
  name: 'AsrRemoteSelect',
  compontents: {},
  props: {
    value: {
      type: [String, Number, null],
      default: null
    },
    service: {
      type: String, // 服务名
      default: 'web',
      validator: (service) =>
        ['web', 'scm', 'activiti', 'external'].includes(service)
    },
    api: {
      type: String, // 请求的方法名
      default: ''
    },
    paramKey: {
      type: String, // 请求的参数名
      default: ''
    },
    labelKey: {
      type: String, // 映射返回数据的 value
      default: 'name'
    },
    valueKey: {
      type: String, // 映射返回数据的 value
      default: 'id'
    },
    payload: {
      type: [Function, Object], // 额外的请求参数
      default: () => ({})
    },
    multiple: {
      type: Boolean,
      default: true
    },
    remoteMethod: {
      type: Function,
      default: null
    },
    collapseTags: {
      type: Boolean,
      default: true
    },
    reserveKeyword: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    filterType: {
      type: Number, // 过滤类型
      default: 1,
      validator: (type) => [1, 2, 3].includes(type)
    },
    disableType: {
      type: Number, // 禁用类型
      default: 1,
      validator: (type) => [1, 2, 3].includes(type)
    },
    enterChooseFirst: {
      type: Boolean, // 按下回车键后自动选择下拉框中第一个值
      default: false
    },
    selectLimit: {
      type: Number, // 下拉框options 限制条数
      default: null
    }
  },
  data() {
    return {
      options: [],
      loading: false
    }
  },
  computed: {
    defaultPlaceholder() {
      return `精确查询，${this.multiple === true ? '多选' : '单选'}`
    },
    /**
     * @return {Function}
     */
    proxyRemoteMethod() {
      if (typeof this.remoteMethod === 'function') {
        return async (query) => {
          this.loading = true
          const options = await this.remoteMethod(query)
          if (this.selectLimit !== null) {
            this.options = this.options.slice(0, this.selectLimit)
          }
          if (Array.isArray(options)) {
            this.options = options.map((o) => ({
              value: o?.[this.valueKey] ?? null,
              label: o?.[this.labelkey] ?? null,
              ...o
            }))
            return void this.$nextTick(() => void (this.loading = false))
          }
          this.options = []
          console.error(
            '【AsrRemoteSelect】Excepted Array, got',
            typeof options,
            'from "remoteMethod" function'
          )
          this.$nextTick(() => void (this.loading = false))
        }
      } else {
        return async (query) => {
          this.loading = true
          const options = await autoSelectRemoteFunc.call(this, {
            query,
            service: this.service,
            api: this.api,
            key: this.paramKey,
            payload: this.payload,
            mapper: { value: this.valueKey, label: this.labelKey }
          })

          if (Array.isArray(options)) {
            this.options = options
            if (this.selectLimit !== null) {
              this.options = this.options.slice(0, this.selectLimit)
            }
            return void this.$nextTick(() => void (this.loading = false))
          }

          this.options = []
          console.error(
            '【AsrRemoteSelect】Excepted Array, got',
            typeof options,
            'from "autoSelectRemoteFunc"'
          )
          return void this.$nextTick(() => void (this.loading = false))
        }
      }
    },
    proxyValue: {
      get: function () {
        return this.value
      },
      set: function (val) {
        this.$emit('input', val)
      }
    },
    proxyEvents() {
      // eslint-disable-next-line no-unused-vars
      const { change, ...rest } = this.$listeners
      return rest
    }
  },
  methods: {
    onChange(value) {
      const currSelectedItem = this.multiple
        ? this.options.filter(
            (item) =>
              isPlainObject(item) &&
              isArray(value) &&
              value.includes(item[this.valueKey])
          )
        : this.options.find(
            (item) => isPlainObject(item) && value === item[this.valueKey]
          ) || null

      if (this.options.length > 0) {
        if (
          (this.multiple &&
            isArray(value) &&
            value.length > 0 &&
            currSelectedItem.length === 0) ||
          (!this.multiple && value && !currSelectedItem)
        ) {
          console.error('【AsrRemoteSelect】: please check props:valueKey')
        }
      }

      this.$emit(
        'change',
        value,
        currSelectedItem || (this.multiple ? [] : null)
      )
    },
    /**
     * @description enterChooseFirst开启后，按下回车键后自动选择下拉框中第一个值
     */
    onEnterPress() {
      if (!this.enterChooseFirst) return
      if (this.options.length === 0) {
        this.proxyValue = ''
      } else {
        const value = this.options[0].value
        this.proxyValue = value
        this.onChange(value)
      }

      this.$refs['el-selelct'].blur()
    }
  }
}
</script>
<style lang="scss" scoped></style>
