const state = () => ({
  data: {
    detailData: []
  }
})

const mutations = {
  CHANGE__DATA: (state, data) => {
    const { detailData } = data
    state.data = {
      detailData: detailData
        ? detailData.map((v) => ({
            ...v,
            warehouseCode: data.requisitionWarehouse,
            warehouseOutNumber: v.applyNumber,
            inventoryUnitName: v.inventoryUnit
          }))
        : [],
      requisitionCode: data.purchaseCode
    }
  },
  CLEAR__DATA: (state) => {
    state.data = {
      detailData: []
    }
  }
}

const actions = {
  async handlePickUpData({ commit }, data) {
    commit('CHANGE__DATA', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
