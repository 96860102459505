/**
 * @module api-web:financialAffairs/accountReceivable/receiptManualOff
 */
import service from '@/service'
// 手工核销暂估应收单查询
export function getEstimatReceivableList(params) {
  return service['api-web']({
    // url: '/fin/ar/manualWriteOff/public/getManualList',
    url: '/fin/ar/manualWriteOff/public/getHandMarkEstimateList', // task:1074接口更换
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// 手工核销财务应收单查询
export function getFinanceReceivableList(params) {
  return service['api-web']({
    // url: '/fin/ar/manualWriteOff/public/getEstimationFilterList',
    url: '/fin/ar/manualWriteOff/public/getHandMarkReceivableList', // task:1074接口更换
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// 手动核销
export function chargeOff(data) {
  return service['api-web']({
    // url: '/fin/ar/manualWriteOff/public/estimationReceivablesManualWriteOff',
    url: '/fin/ar/manualWriteOff/public/estimationReceivablesHandMark', // task:1074接口更换
    method: 'put',
    data
  })
}
