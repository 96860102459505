/**
 * @module api-web:finPaymentCheckout
 */
import service from '@/service'

/**
 *
 * @param {object} params
 * @returns
 * @description 查询 财务-应付款结账
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ap/finPaymentCheckout/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {object} data
 * @returns
 * @description 反结账 财务-应付款结账
 */
export function reverseCheckout(data) {
  return service['api-web']({
    url: '/fin/ap/finPaymentCheckout/reverseCheckout',
    method: 'post',
    data
  })
}

/**
 *
 * @param {object} data
 * @returns
 * @description 结账 财务-应付款结账
 */
export function checkout(data) {
  return service['api-web']({
    url: '/fin/ap/finPaymentCheckout/checkout',
    method: 'post',
    data
  })
}
