/**
 * @module BaseInlineEditTable/mixins/row-external
 * 内联编辑表格 〉宏 〉表格行内部额外数据统一挂载 命名空间
 */
import { BIET_ROW_EXTERNAL_NAMESPACE } from '@/constants'
export default {
  methods: {
    /**
     * 在 row 上 挂载额外属性 BIET_ROW_EXTERNAL_NAMESPACE
     */
    setRowExternal({ row, key, value }) {
      if (!row) return

      if (row?.[BIET_ROW_EXTERNAL_NAMESPACE]) {
        this.$set(row[BIET_ROW_EXTERNAL_NAMESPACE], key, value)
      } else this.$set(row, BIET_ROW_EXTERNAL_NAMESPACE, { [key]: value })
    },
    /**
     * 获取 row 上 额外挂载的属性
     */
    getRowExternal({ row, key }) {
      try {
        if (row && row?.[BIET_ROW_EXTERNAL_NAMESPACE]) {
          return row[BIET_ROW_EXTERNAL_NAMESPACE][key]
        }
      } catch (error) {
        console.error('【BaseInlineEditTable row-external】: ' + error)
      }

      return null
    },
    /**
     * 初始化 row 上 额外挂载的属性
     * - isInsert 回显数据 false, 新增数据 true, 新增后二次编辑 false
     * - isCreate 回显数据 false, 新增数据 true, 新增后二次编辑 false
     * - hasSaved 回显数据 true, 新增数据 false, 新增后二次编辑 false
     */
    initRowExternal(data) {
      return data.map((row) => {
        const isCreate = this.getRowExternal({ row, key: 'isCreate' })
        const hasSaved = this.getRowExternal({ row, key: 'hasSaved' })

        if (
          typeof isCreate !== 'undefined' &&
          typeof hasSaved !== 'undefined'
        ) {
          if (typeof isCreate === 'boolean') {
            this.setRowExternal({
              row,
              key: 'hasSaved',
              value: !isCreate
            })
          } else {
            this.setRowExternal({ row, key: 'isInsert', value: false })
            this.setRowExternal({ row, key: 'isCreate', value: false })
            this.setRowExternal({ row, key: 'hasSaved', value: true })
          }
        }

        this.setRowExternal({
          row,
          key: 'disabledUpdate',
          value: row[this.disabledRowUpdateField] ?? false
        })

        this.setRowExternal({
          row,
          key: 'disabledRemove',
          value: row[this.disabledRowRemoveField] ?? false
        })

        return row
      })
    }
  }
}
