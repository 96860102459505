/**
 * @module api-scm:warehouseCheckPlan
 */
import service from '@/service'

/**
 * 申请库存盘点   表查询
 * @param {{limit:number,page:number,sort:string,warehouseCheckPlanName:string,warehouseCheckPlanTimeEnd:string,warehouseCheckPlanTimeStart:string,warehouseCode:string}} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/getList',
    method: 'get',
    params
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehouseCheckPlan/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseCheckPlan/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 作废
 * @param {{ids:Array<string>}} id
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 申请延期
 * @param {object} data
 */

export function applyPostpone(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/applyPostpone',
    method: 'post',
    data
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出申请库存盘点
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheckPlan/warehouseCheckPlanData/export',
    method: 'post',
    data,
    responseType: 'blob' // 文件流接收类型声明转成 blob
  })
}
