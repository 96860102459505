// eslint-disable-next-line spaced-comment
/// <reference path="../../typedef.js" />

/**
 * @module permission/menus/routes/index
 */
import layout from '@/layout'
import LazyLoading from '@/components/LazyLoading'
import LazyLoadError from '@/components/LazyLoadError'
import { isArray, isString, validURL } from '@/utils/validate'
import { recursiveParents, validPath } from '@/permission/utils'
import { hidden } from '@/router/config'

import { camelCase } from '@/utils/string'
import { default as generateName, createNameViaPathArr } from './name'
import generatePath from './path'
import generateRedirect from './redirect'
import generateComponent from './component'
import generateConfigs from './configs'
import { NETWORK_TIMEOUT } from '@/constants'

export function lazyLoadView(path) {
  try {
    const asyncComponent = () => ({
      component: import(
        /* webpackChunkName: "[request]" */ `@/views/${path}/index.vue`
      ),
      loading: LazyLoading,
      error: LazyLoadError,
      // delay: 16,
      timeout: NETWORK_TIMEOUT.asyncComponent
    })
    return Promise.resolve({
      functional: true,
      name: createNameViaPathArr(path.split('/')),
      render(h, { data, children }) {
        // Transparently pass any props or children
        // to the view component.
        return h(asyncComponent, data, children)
      }
    })
  } catch (error) {
    console.error(error, path)
    return import(
      /* webpackChunkName: "page-not-found-404" */ '@/views/error/404'
    )
  }
}
/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {string} 路由配置参数
 */
const processNode = (node, indexTable) => {
  try {
    const _recursivePath = recursivePath(node, indexTable)

    const result = {
      name: generateName(node, indexTable),
      path: generatePath(node, indexTable),
      redirect: generateRedirect(node, indexTable),
      component: generateComponent(node, indexTable, _recursivePath),
      ...generateConfigs(node, indexTable, _recursivePath)
    }
    return result
  } catch (error) {
    console.error(error)
    return {
      path: `/error-${Date.now()}`
    }
  }
}

/**
 * 将第一个路由转化成符合 vue-element-admin 的根路由
 * @param {Array<import('vue-router').Route>} routes
 * @returns {import('vue-router').Route}
 */
export const transformFirstToRoot = (routes) => {
  try {
    const first = routes.splice(
      routes.findIndex(
        (v) =>
          typeof v.hidden === 'boolean' &&
          !v.hidden &&
          validPath(v.path) &&
          !validURL(v.path)
      ),
      1
    )[0]

    first.meta.affix = true

    const { path, children } = first
    const component = () => lazyLoadView(path)

    if (!isArray(children) || !children.length) {
      delete first.children
      return {
        path: '/',
        redirect: path,
        component: layout,
        children: [
          {
            ...first,
            component,
            path: path.substring(1)
          }
        ]
      }
    }
    return first
  } catch (error) {
    console.error(error, routes)
    return {
      path: `/error-${Date.now()}`
    }
  }
}

/**
 * 将由接口返回的权限列表转化而来的权限树列表，二次映射成路由列表
 * @param {RolePermissionTree} tree
 * @param {PermissionIndexTable} indexTable
 * @returns route info
 */
export const generateRoutes = (tree = [], indexTable = {}) => {
  return isArray(tree)
    ? tree.map((node) => {
        const _node = markNestedNavs(node, indexTable)

        try {
          const { component, redirect, ...routeInfos } = processNode(
            _node,
            indexTable
          )
          const route = {
            ...routeInfos,
            children: generateRoutes(_node.children, indexTable)
          }

          return redirect && component
            ? { redirect, component, ...route }
            : redirect
            ? { redirect, ...route }
            : component
            ? { component, ...route }
            : route
        } catch (error) {
          console.error(error)
        }
      })
    : []
}

/**
 * 标记为内嵌导航
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 */
function markNestedNavs(node, indexTable) {
  try {
    const { pid, id } = node

    if (!indexTable.idIndex[pid]) {
      console.error('Losed parent in "idIndex":', node)
      return node
    }

    if (!indexTable.idIndex[id]) {
      console.error('Losed self in "idIndex":', node)
      return node
    }

    if (indexTable.idIndex[pid].isNestedNav) {
      return { ...node, isNestedNav: true }
    } else if (indexTable.idIndex[id].isNestedNav) {
      node.children = node.children.map((v) => ({ ...v, isNestedNav: true }))
      return node
    }
  } catch (error) {
    console.error(error, node)
  }
  return node
}
/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {string}
 */
function recursivePath(node, indexTable) {
  try {
    const { pmType, id, path } = node

    const children = indexTable.pidIndex[id]

    if (isArray(children)) {
      if (!children.length) {
        // 一级菜单
        if (pmType === 0) return ''
      }

      if (
        /**
         * children 所有节点均是外链
         */
        children.every((v) => isString(v.path) && validURL(v.path))
      ) {
        return null
      }

      if (
        /**
         * children 任一节点不隐藏且节点 path 不是外链且是合法路径
         */
        children.some(
          (v) =>
            (!hidden.includes(v.id) ||
              (typeof v.hidden === 'boolean' && !v.hidden)) &&
            !validURL(v.path) &&
            validPath(v.path)
        )
      ) {
        // 一级菜单
        if (pmType === 0) return ''
      }
    } else {
      // 一级菜单
      if (pmType === 0) return ''
    }

    let recursiveResult = recursiveParents(indexTable, node, [path], 'path')

    if (isArray(recursiveResult) && isString(recursiveResult[0])) {
      const base = recursiveResult[0]
      base.charAt(0) === '/' ? (recursiveResult[0] = base.substring(1)) : null
      recursiveResult = recursiveResult.map((v) => {
        try {
          return v.includes('-') || v.includes('/')
            ? v.replace('/:id', '')
            : camelCase(v.replace('/:id', ''))
        } catch (error) {
          console.error(error)
        }
      })

      return recursiveResult.filter((v) => !!v).join('/')
    } else {
      console.warn('Illegal "recursiveResult"', node, recursiveResult)
    }
  } catch (error) {
    console.error(error)
  }
  return ''
}
