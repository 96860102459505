<template>
  <div />
</template>
<script>
export default {
  data() {
    return {
      loading: null
    }
  },
  created() {
    this.loading = this.$loading({
      lock: true,
      text: 'Loading...'
    })
  },
  destroyed() {
    typeof this.loading?.close === 'function' &&
      this.$halt(400, () => this.loading.close())
  },
  methods: {}
}
</script>
