import service from '@/service'

/**
 * @description 查询当前登录用户是否为部门负责人
 */
export function isResponsibility() {
  return service['api-web']({
    url: '/public/hr/getUserIsResponsibility',
    method: 'get'
  })
}
/**
 * @param {number} deptId
 * @description 查询部门负责人
 */
export function getLeaderByDeptId(deptId) {
  return service['api-web']({
    url: '/public/hr/getLeaderByDeptId',
    method: 'get',
    params: { deptId }
  })
}
/**
 * @param {number} deptId
 * @description 查询部门所有人, 无法查询嵌套的子部门
 */
export function getUserByDeptId(deptId) {
  return service['api-web']({
    url: '/public/hr/getUserByDeptId',
    method: 'get',
    params: { deptId }
  })
}

/**
 * @param {{username:string}} params - account name
 * @description 支持批量查询，参数逗号拼接
 */
export function getNameByUsername(params) {
  return service['api-web']({
    url: '/public/hr/getNameByUsername',
    method: 'get',
    params
  })
}
