/**
 * @module api-scm:orderManage/awaitSeparateBill
 */
import service from '@/service'

/**
 * 列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string
 * }} data
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/getList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

export function getUserPullList(params) {
  return service['api-web']({
    url: '/public/hr/getUserPullList',
    method: 'get',
    params
  })
}

export function info(id) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/info/infoAll',
    method: 'get',
    params: { id }
  })
}
/**
 * @param {{keywords:string,id:(string|number)}} params
 * @descrption 根据商品名称/编码查询当前拆单明细
 */
export function querySepareteDetail(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/querySepareteDetail',
    method: 'get',
    params: params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 拆单详情下拉列表
 */
export function getSeparateListPull(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/info/getList',
    method: 'get',
    params
  })
}

/**
 * @param {{ids:string}} params
 * @description 拆单详情删除
 */
export function orderSeparateRemove(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 拆单新增
 */
export function orderSeparateCreate(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/save',
    method: 'post',
    data
  })
}
/**
 * @description 拆单修改
 */
export function orderSeparateUpdate(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/update',
    method: 'post',
    data
  })
}
/**
 * @param {{modeDespatch:number,deliveryWarehouse?:string}} params
 * @description 拆单明细 发货仓/调出仓库下拉,
 * 筛选表单查询订单时，使用 { modeDespatch:1 } 发货方式为采购时的仓库
 */
export function getAllocateWarehouse(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/getAllocateWarehouse',
    method: 'get',
    params
  })
}

/**
 * @param {*}
 * @description 发货仓
 */
export function getDeliveryWarehouse(params) {
  return service['api-scm']({
    url: '/public/order/get/getWarehouse',
    method: 'get',
    params
  })
}

/**
 * @param {*}
 * @description 模糊搜索发货仓
 */
export function getWarehouseLikeName(params) {
  return service['api-web']({
    url: '/public/warehouse/getWarehouseLikeName',
    method: 'get',
    params
  })
}

/**
 * @description  待拆单页面拆单明细选择调拨仓带出可调拨数量allocateWarehouse:多个调拨仓code','隔开
 */
export function getAllocateNumber(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/getAllocateNumber',
    method: 'get',
    params
  })
}

/**
 * @description 待拆单页面拆单明细选择仓库带出商品可用量
 */
export function getAvailableNumber(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/getAvailableNumber',
    method: 'get',
    params
  })
}

/**
 * @param {{productCode:string}} params
 * @description 拆单采购时下拉商品对应的所有采购人员
 * @see /src/service/api-web/index.js - getBuyerByProductCode
 * @see /public/product/queryBuyerByProductCode
 */
export function getPurchaseUser(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/recommendBuyer',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} orderCode
 * @description  确认拆单
 */
export function confirmSeparate(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/confirmSeparate',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} orderCode
 * @description  批量拆单
 */
export function batchSeparateOrder(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batchSeparateOrder',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} orderCode
 * @description  是否有售后订单
 */
export function afterSaleOrNot(data) {
  return service['api-scm']({
    url: '/public/order/afterSaleOrNot',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} code
 * @description  重新匹配
 */
export function anewMatching(code) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/anewMatching',
    method: 'post',
    data: { code },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{productCode:string,separateId:string}} params
 * @description  拆单内部联络单详情
 */
export function interiorLiaisonDetail(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/interiorLiaison/info',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object} data
 * @description  拆单内部联络单新增
 */
export function interiorLiaisonSave(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/interiorLiaison/save',
    method: 'post',
    data
  })
}

/**
 * @param {object} data
 * @description 联络单列表
 */
export function interiorLiaisonQueryList(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/interiorLiaison/queryList',
    method: 'post',
    data
  })
}
/**
 * @param {object} data
 * @description 联络单修改
 */
export function interiorLiaisonUpdate(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/interiorLiaison/update',
    method: 'post',
    data
  })
}
/**
 * @param {string} orderCode
 * @description  终结弹窗中的商品下拉
 */
export function queryOrderDetail(orderCode) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/queryOrderDetail',
    method: 'get',
    params: { orderCode },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} orderCode
 * @description  终结弹窗中的商品表格
 */
export function queryEndOrderDetail(orderCode) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/queryEndOrderDetail',
    method: 'get',
    params: { orderCode },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {object} data
 * @description  终结商品/取消终结，使用参数字段 数组控制新增终结和取消终结，
 * 如：空数组即为取消所有终结
 */
export function endOrderDetail(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/endOrderDetail',
    method: 'post',
    data
  })
}
/**
 * @param {number} ids
 * @description 作废
 */
export function invalid(ids) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/cancellationOrder',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 确认拆单提醒
 */
export function getRemainRules(params) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/get/getRemainRules',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{keywords:string=}} params
 * @description 下拉选项：用户 (根据真实姓名 name 字段 模糊查询)
 */
export function getUserOpts(params) {
  return service['api-web']({
    url: '/public/findUserList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 批量修改卖家备注
 */
export function batchUpdateSellerRemark(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batch/updateSellerRemark',
    method: 'post',
    data
  })
}
/**
 * @param {object} data
 * @description 修改收货地址
 */
export function editReceiveGoodsAddress(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/editReceiveGoodsAddress',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} businessState
 * @param {Array} orderIdList
 * @description 订单整单关闭-反关闭
 */
export function orderClose(data) {
  return service['api-scm']({
    url: '/scm/order/orderInfo/orderClose',
    method: 'post',
    data
  })
}
/**
 * @description 系统标签数量接口
 */
export function getSystemMarkAmount(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/getSystemMarkAmount',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 刷新系统标签
 */
export function flushSystemMark(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/flushSystemMark',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 获取所有启用的订单标记
 */
export function getAllEnableOrderMark(params) {
  return service['api-web']({
    url: '/sys/orderMark/get/getAllEnableOrderMark',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{keywords:string}} params
 */
export function getUserlists(params) {
  return service['api-web']({
    url: '/public/getUserlists',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 物流商模糊下拉
 * @param {{carrierName:string}} params
 */
export function findCarrierList(params) {
  return service['api-scm']({
    url: '/public/logistics/findCarrierList',
    method: 'get',
    params: {
      ...params,
      flag: 0 // 启用
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {Array} codes
 * @param {number} flag
 * @param {string} logisticsProvidersCode
 * @description 更新物流公司
 */
export function updateLogistics(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batch/updateLogistics',
    method: 'post',
    data
  })
}

/**
 *
 * @description 修改终结数量
 */
export function updateOrderDetailEndNumber(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batch/updateOrderDetailEndNumber',
    method: 'post',
    data
  })
}

/**
 *
 * @description 自动让利 让前端展示 不落库
 */
export function findShareDetailMoney(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/findShareDetailMoney',
    method: 'post',
    data
  })
}

/**
 *
 * @description 批量替换
 */
export function batchUpdateDetail(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batch/batchUpdateDetail',
    method: 'post',
    data
  })
}

/**
 *
 * @description 保存明细
 */
export function saveOrderDetailSku(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/saveOrderDetailSku',
    method: 'post',
    data
  })
}

/**
 *
 * @description 新增明细
 */
export function insertSku(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batch/insertSku',
    method: 'post',
    data
  })
}

/**
 * @description 获取被替换的下拉框
 */
export function getReplaceSkuList(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/getReplaceSkuList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 模糊查询商品code的下拉
 * @param {{code?:string,supplierCode?:string}} params
 */
export function getAncillaryCode(params) {
  return service['api-web']({
    url: '/public/product/getAncillaryCode',
    method: 'get',
    params
  })
}

/**
 * 店铺模糊查询下拉框（下拉公用）
 */
export function getStorePullOpts(params) {
  return service['api-web']({
    url: '/public/data/getStorePull',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *
 * @description 待拆单拆单明细保存
 */
export function separateSave(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/save',
    method: 'post',
    data,
    tipTypes: 'none'
  })
}
/**
 *
 * @description 根据货主，商品，仓库，批次，库位远程搜索批次号信息
 */
export function getAvailaNumber(data) {
  return service['api-scm']({
    url: '/public/inventory/getAvailableNumber',
    method: 'post',
    data
  })
}
/**
 * 根据商品编码获取所有库存信息
 */
export function getInventoryDetailByProductCode(params) {
  return service['api-scm']({
    url: '/public/inventory/getInventoryDetailByProductCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 校验订单是否可以部分拆单
 */
export function judgeOrderWhetherOrNotToOpenOrder(params) {
  return service['api-scm']({
    url: '/public/order/judgeOrderWhetherOrNotToOpenOrder',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 校验订单是否可以部分拆单
 */
export function judgeOrdersWhetherOrNotToOpenOrder(data) {
  return service['api-scm']({
    url: '/public/order/judgeOrdersWhetherOrNotToOpenOrder',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 更新物流公司前置校验
 */
export function updateLogisticsPreCheck(data) {
  return service['api-scm']({
    url: '/public/order/updateLogisticsPreCheck',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}
/**
 * 更新物流公司前置校验
 */
export function getWarehouse(params) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/get/getWarehouse',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * checkInventoryNumberIsEnough - task/4542
 */
export function checkInventoryNumberIsEnough(params) {
  return service['api-scm']({
    url: '/public/inventory/checkInventoryNumberIsEnough',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 查询sku在指定仓库下的总库存量和剩余总可用量
 */
export function queryBatchSkuInventoryByWarehouse(params) {
  return service['api-scm']({
    url: 'public/inventory/queryBatchSkuInventoryByWarehouse',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @description 更新收货信息
 */
export function updateReceivingInfo(data = {}) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batchUpdateAddressOrPhone',
    method: 'post',
    data
  })
}

/**
 *
 * @description 切换为仓库直发，自动带出发货仓
 */
export function changeWarehouse(data) {
  return service['api-scm']({
    url: '/public/inventory/changeWarehouse',
    method: 'post',
    data
  })
}

/*
 * 批量修改发货方式
 */
export function batchUpdateModeDesPatch(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batchUpdateModeDesPatch',
    method: 'post',
    data
  })
}

/**
 * 批量修改发货仓
 */
export function batchUpdateWarehouse(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batchUpdateWarehouse',
    method: 'post',
    data
  })
}

/**
 * 批量修改附加信息
 */
export function batchUpdateSubjoinHandoverRemark(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batch/updateSubjoinHandoverRemark',
    method: 'post',
    data
  })
}

/**
 * 批量修改附加信息-明细
 */
export function batchUpdateSubjoinHandoverRemarkDetailId(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batch/updateSubjoinHandoverRemarkByDetailId',
    method: 'post',
    data
  })
}

/**
 * 批量修改采购备注
 */
export function batchUpdatePurchaseRemark(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batch/updatePurchaseRemark',
    method: 'post',
    data
  })
}

/**
 * 批量修改采购备注-明细
 */
export function batchUpdatePurchaseRemarkDetailId(data) {
  return service['api-scm']({
    url: '/scm/order/orderSeparate/batch/updatePurchaseRemarkByDetailId',
    method: 'post',
    data
  })
}
