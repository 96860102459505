const Once = (function () {
  let count = 0

  function Once() {}

  Once.prototype.cancel = function () {
    count--
  }

  Once.prototype.handle = function (cb) {
    try {
      if (count <= 0) {
        count++
        typeof cb === 'function' && cb()
      }
    } catch (err) {
      console.error(err)
    }
  }

  return Once
})()

export default Once
