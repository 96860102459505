<template>
  <vxe-button
    v-if="text"
    class="vxe-button-edit-render"
    :content="text"
    v-bind="$vxeBtn.props"
    v-on="$vxeBtn.events"
    @click="onClick"
  />
  <span v-else class="btn-placeholder" @click="onError">🔘</span>
</template>
<script>
import { isArray, isString } from '@/utils/validate'
import { get as getCookie } from '@/utils/cookies'
export default {
  name: 'VxeEditButton',
  props: {
    content: {
      type: [Array, String],
      default: ''
    },
    scope: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    getSize() {
      return this.$store.getters.size || getCookie('size') || 'mini'
    },
    /**
     * @see https://xuliangzhan_admin.gitee.io/vxe-table/#/button/api
     * 暂不支持“下拉按钮”相关
     */
    $vxeBtn() {
      // eslint-disable-next-line no-unused-vars
      const { click, ...rest } = this.$listeners
      return {
        click,
        events: rest,
        props: {
          size: this.getSize,
          ...this.$attrs['vxe-btn-props']
        }
      }
    },
    editing() {
      try {
        return this.scope.$table
          ? this.scope.$table.isActiveByRow(this.scope.row)
          : false
      } catch (error) {
        console.error(error)
      }
      return false
    },
    text() {
      if (isArray(this.content)) {
        const txt = this.content[this.editing * 1]
        return isString(txt) ? txt : ''
      } else if (isString(this.content)) {
        return this.content
      }
      return ''
    }
  },
  methods: {
    onError() {
      console.error('"EditBtn自定义渲染器":表格行内编辑按钮信息丢失')
      this.$message.error('抱歉,按钮功能失效')
    },
    onClick() {
      if (typeof this.$vxeBtn.click === 'function') {
        this.$vxeBtn.click({ ...this.scope, isEditing: this.editing })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-placeholder {
  cursor: pointer;
}
</style>
