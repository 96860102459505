/**
 * @module api-scm:procureInvoice
 */

import service from '@/service'

/**
 * @typedef {object}  Params
 * @property {Array<string>}  invoiceTime 开票日期
 * @property {array} purchaseTime 单据日期
 * @property {string} invoiceTitle 发票抬头
 * @property {string} supplierName 供应商
 *
 * @param {Params} params - 查询采购开票列表请求参数
 * @returns {Promise<any>}
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInvoice/getList',
    method: 'get',
    params
  })
}
