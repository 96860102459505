/**
 * @module api-web:mediaUpload
 */
import service from '@/service'

/**
 *  列表查询
 */
export function getList(params) {
  return service['api-web']({
    url: '/designApartment/mediaUpload/getList',
    method: 'get',
    params
  })
}
/**
 * 视频提交
 * @param {{ids:Array<string>}} id
 */
export function submit(data) {
  return service['api-web']({
    url: '/designApartment/mediaUpload/submit',
    method: 'put',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {Array<number>} ids
 * @description 视频删除
 */
export function remove(data) {
  return service['api-web']({
    url: '/designApartment/mediaUpload/delete',
    method: 'post',
    data
  })
}
/**
 * 视频   新增
 */
export function create(data) {
  return service['api-web']({
    url: '/designApartment/mediaUpload/save',
    method: 'post',
    data
  })
}
/**
 * 视频   修改
 */
export function update(data) {
  return service['api-web']({
    url: '/designApartment/mediaUpload/update',
    method: 'post',
    data
  })
}
/**
 * 视频   详情查询
 * @param {string} id
 */
export function info(id) {
  return service['api-web']({
    url: '/designApartment/mediaUpload/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 视频   撤销
 * @param {string} id
 */
export function withdraw(id) {
  return service['api-web']({
    url: '/designApartment/mediaUpload/withdrawClaim',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    url: '/public/activiti/mediaUpload/rejectAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
