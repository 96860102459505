import service from '@/service'
/**
 * @param {Object} params
 * @description 根据物流单号、物流公司编码获取物流状态和轨迹信息
 * logisticsCode
 * shipperCode
 */
export function getLogisticQueryInfo(params) {
  return service['api-external']({
    url: '/public/kdniao/getLogisticQueryInfo',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
