import upperFirstStr from 'lodash/upperFirst'
import camelCaseStr from 'lodash/camelCase'
import { isArray, isString } from './validate'
/**
 * @module utils/string
 */

/**
 * @param {string} str
 * @returns {String}
 * @description Upper case first char
 */
export function uppercaseFirst(str) {
  try {
    // const [first, ...rest] = str
    // return first.toUpperCase() + rest.join('')
    // return str.charAt(0).toUpperCase() + str.slice(1)
    return upperFirstStr(str)
  } catch (error) {
    console.error(error)
  }
  return str
}

/**
 *
 * @param {string} str
 * @returns {string}
 * camelCase('Foo Bar'); // => 'fooBar'
 * camelCase('--foo-bar--');// => 'fooBar'
 * camelCase('__FOO_BAR__');// => 'fooBar'
 */
export function camelCase(str) {
  try {
    const s = camelCaseStr(str)
    return s
  } catch (error) {
    console.error(error)
  }
  return str
}
/**
 * @param {string} str input string
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xdc00 && code <= 0xdfff) i--
  }
  return s
}

/**
 * create unique string
 * @returns {string} unique string
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * @param {string} str
 * @param {number} len
 * @returns {array} chunk array
 */
export function chunkString(str, len) {
  var _ret
  if (str.length < 1) {
    return []
  }
  if (typeof len === 'number' && len > 0) {
    var _size = Math.ceil(str.length / len)
    var _offset = 0
    _ret = new Array(_size)
    for (var _i = 0; _i < _size; _i++) {
      _ret[_i] = str.substring(_offset, (_offset = _offset + len))
    }
  } else if (typeof len === 'object' && len.length) {
    var n = 0
    var l = str.length
    var chunk
    var that = str
    _ret = []
    do {
      len.forEach(function (o) {
        chunk = that.substring(n, n + o)
        if (chunk !== '') {
          _ret.push(chunk)
          n += chunk.length
        }
      })
      if (n === 0) {
        return undefined // prevent an endless loop when len = [0]
      }
    } while (n < l)
  }
  return _ret
}
/**
 *
 * @param {string} s
 * @returns {array}
 */
export function str2arr(s, separator = ',') {
  return isArray(s) ? s : s && isString(s) ? s.split(separator) : []
}

/**
 * @param { string } string - 被提取的文本
 * @returns {array | string}
 * @description 从文本中提取hex颜色代码
 */
export function getColorCode(string) {
  const result = string.match(/#[0-9A-Fa-f]{3,6}/g)

  return result?.length === 1 ? result[0] : result
}

export function handleStringSplit(str, separator = ',') {
  if (typeof str !== 'string' || str === '') return []

  return str.split(separator)
}
