/**
 * @module api-scm:purchasePriceList
 */

import service from '@/service'

/**
 * @param {{
 * productCode:string,
 * supplier:string}} params
 * @description  查询采购价目表列表
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/getList',
    method: 'get',
    params
  })
}

/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/info',
    method: 'get',
    params: { id }
  })
}
/**
 *
 * @param {{string}} id
 * @returns
 * @description 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/purchasePrice/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/update',
    method: 'put',
    data
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 采购价目表明细新增
 * @param {object} data
 * @returns {object}
 */
export function createDetail(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/save/saveDetail',
    method: 'post',
    data
  })
}
/**
 * 修改
 * @param {object} data
 * @returns {object}
 */
export function updateDetail(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/update/updateDetail',
    method: 'put',
    data
  })
}
/**
 * @param {{ids:string}} params
 * @description 明细删除
 */
export function removeDetail(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/update/deleteDetail',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 下推
 * @param {object} data
 */
export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/pushDown',
    method: 'put',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交审核
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 审核驳回
 * @param {object} data
 */
export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/purchasePrice/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 价目表批量导出
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchasePrice/exportPurchasePrice',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false // 文件流是否通过浏览器下载
  })
}

/**
 * 根据采购调价函名称模糊搜索
 * @param {number} changeName
 * @returns {any}
 */
export function getChangePriceBillByByChangeName(changeName) {
  return service['api-scm']({
    url: '/public/purchase/getChangePriceBillByByChangeName',
    method: 'get',
    params: { changeName },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据采购价目表单据编码模糊查询
 * @param {number} priceCode
 * @returns {any}
 */
export function getPurchasePriceByPriceCode(priceCode) {
  return service['api-scm']({
    url: '/public/purchase/getPurchasePriceByPriceCode',
    method: 'get',
    params: { priceCode },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
