<template>
  <span>
    <el-popover
      :placement="placement"
      :width="width"
      :trigger="trigger"
      v-bind="proxyProps"
      v-on="$listeners"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="proxyProps?.contentHTML" v-html="proxyProps.contentHTML" />
      <span slot="reference">
        <i class="el-icon-question" />
      </span>
    </el-popover>
    {{ proxyProps?.label ?? 'label' }}
  </span>
</template>

<script>
export default {
  name: 'ElFormLabelPopover',
  props: {
    width: {
      type: [String, Number],
      default: '300'
    },
    trigger: {
      type: String,
      default: 'hover',
      valator: (v) => ['click', 'hover', 'focus', 'manual'].includes(v)
    },
    placement: {
      type: String,
      default: 'top-start'
    }
  },
  data() {
    return {}
  },
  computed: {
    /**
     *  非虚拟DOM节点 使用$attrs属性， 虚拟DOM节点使用 this.$vnode
     */
    proxyProps() {
      const props =
        Object.values(this.$attrs).filter((v) => typeof v !== 'undefined')
          .length > 0
          ? this.$attrs
          : this.$vnode.data.props
      return props
    }
  },
  created() {}
}
</script>

<style></style>
