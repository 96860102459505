<template>
  <AsrAutoComplete
    ref="autocompleteRef"
    v-model="proxyValue"
    placeholder=" "
    v-bind="proxyProps"
    v-on="proxyEvents"
    @select="onSelect"
    @change="onChange"
    @clear="onClear"
  />
</template>
<script>
export default {
  name: 'ElAutoComplete',
  props: {
    scope: {
      type: Object,
      default: () => ({
        row: {},
        column: {}
      })
    }
  },
  data() {
    return {
      row: {},
      column: {}
    }
  },
  computed: {
    proxyEvents() {
      // eslint-disable-next-line no-unused-vars
      const { change, select, ...rest } = this.$listeners
      return rest
    },
    proxyProps() {
      const props = this.$attrs['edit-render-props']?.props ?? {}
      // eslint-disable-next-line no-unused-vars
      const { linkage, ...rest } = props
      return rest
    },
    linkage() {
      const props = this.$attrs['edit-render-props']?.props ?? {}
      // eslint-disable-next-line no-unused-vars
      const { linkage = null, ...rest } = props
      return linkage
    },
    rowPairKey() {
      return this.linkage?.row ?? ''
    },
    selectedPairKey() {
      return this.linkage?.selected ?? ''
    },
    proxyValue: {
      set(val) {
        this.row[this.column.property] = val
      },
      get() {
        return this.scope.row[this.scope.column.property]
      }
    }
  },
  created() {
    Object.assign(this, this.scope)
  },
  methods: {
    onSelect(e) {
      const { select } = this.$listeners
      if (this.rowPairKey && this.selectedPairKey) {
        this.row[this.rowPairKey] = e?.[this.selectedPairKey] ?? ''
      }
      typeof select === 'function' ? select(this.scope, e) : null
    },
    onChange(e) {
      const { change } = this.$listeners
      if (this.rowPairKey) {
        this.row[this.rowPairKey] = ''
      }
      typeof change === 'function' ? change(this.scope, e) : null
    },
    onClear() {
      // el-autocomplete bug，点击清空后，再输入，下拉项没有出现
      const instance = this.$refs.autocompleteRef.$children[0]
      instance.activated = true
    }
  }
}
</script>
