/**
 * @module custom-renderers:ElPicker
 * @path @/src/plugins/vxe-table/
 */
import VXETable from 'vxe-table'
import ElPick from './ElPick'
import ElPickView from './ElPickView'

// 下拉：el-select 基础上扩展， options 支持异步和计算属性
VXETable.renderer.add('ElPicker', {
  renderEdit: (...args) => ElPickerRender(...args, 'edit'),
  renderCell: (...args) => {
    return args[1]?.displayAsEditingMode
      ? ElPickerRender(...args, 'edit')
      : ElPickViewRender(...args, 'cell')
  }
})

function ElPickerRender(h, renderOpts, params = {}, mode = 'edit') {
  try {
    const { events = {}, props = {}, options = [], ...rest } = renderOpts
    return [
      <ElPick
        mode={mode}
        scope={params}
        on={events}
        edit-render-props={rest}
        el-select-props={{ ...props, options }}
      ></ElPick>
    ]
  } catch (error) {
    console.error(error)
  }
  return [<ElPick />]
}

function ElPickViewRender(h, renderOpts, params = {}, mode = 'cell') {
  try {
    const { events = {}, props = {}, options = [], ...rest } = renderOpts
    return [
      <ElPickView
        mode={mode}
        scope={params}
        on={events}
        edit-render-props={rest}
        el-select-props={{ ...props, options }}
      ></ElPickView>
    ]
  } catch (error) {
    console.error(error)
  }
  return [<ElPickView />]
}
