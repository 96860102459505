/**
 * ⚠️ just for src/demos.
 * @file api for demos/article,table,zip,tab,excel
 */
import service from '@/service'

export function fetchList(query) {
  return service['demos']({
    url: '/article/list',
    method: 'get',
    params: query
  })
}

export function fetchArticle(id) {
  return service['demos']({
    url: '/article/detail',
    method: 'get',
    params: { id }
  })
}

export function fetchPv(pv) {
  return service['demos']({
    url: '/article/pv',
    method: 'get',
    params: { pv }
  })
}

export function createArticle(data) {
  return service['demos']({
    url: '/article/create',
    method: 'post',
    data
  })
}

export function updateArticle(data) {
  return service['demos']({
    url: '/article/update',
    method: 'post',
    data
  })
}
