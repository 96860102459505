import Vue from 'vue'
import FilterContent from './FilterContent'
import VXETable from 'vxe-table'

Vue.component(FilterContent.name, FilterContent)
VXETable.renderer.add('FilterContent', {
  // 不显示底部按钮，使用自定义的按钮
  isFooter: false,
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return <FilterContent params={params}></FilterContent>
  },
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = { vals: [], sVal: '' }
    })
  },
  // 筛选数据方法
  filterMethod({ option, row, column }) {
    const { vals } = option.data
    const cellValue = row[column.field]
    return vals.includes(cellValue)
  }
})
