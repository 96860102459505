/**
 * @module api-scm:sellInOutWarehouse/sellAfterSaleStockIn
 */
import service from '@/service'

/**
 * 售后退还入库单   列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * orderoutCode:string,
 * productCode:number,
 * productName:string,
 * date:Array<string>
 * }} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/get/getList',
    method: 'get',
    params
  })
}

/**
 *   树形表格下拉
 * @param {string} code
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/get/getListDown',
    method: 'get',
    params: { code: code },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 新增物流信息
 * @typedef {Object} SaveLogisticsInfo
 * @property {number=} id
 * @property {string} logisticsCompany 物流公司
 * @property {number} logisticsCost 物流费用
 * @property {number} logisticsCostUndertake 运费费用承担方 0供方1需方
 * @property {string} logisticsNumber 物流单号
 * @property {string} logisticsRemark 物流备注
 * @property {string} logisticsStatus 物流状态
 * @property {string} orderCode 订单编号
 * @property {string} shipmentsTime 发货时间
 * @property {number} undertakeWay   运输方式
 *
 * @param {{logistics:Array<SaveLogisticsInfo>}} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/saveLogistics',
    method: 'post',
    data
  })
}

/**
 * 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/update/update',
    method: 'post',
    data
  })
}

/**
 * 当前行详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 物流详情
 * @param {number} id
 * @returns {any}
 */
export function infoLogistics(id) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/get/infoLogistics',
    method: 'get',
    params: { id }
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 审核驳回
 * @param {object} data
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseOrderIn/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function qualityList(params) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/update/getQualityList',
    method: 'get',
    params
  })
}

export function saveQuality(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/update/saveQuality',
    method: 'post',
    data
  })
}

export function updateQuality(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/update/updateQuality',
    method: 'post',
    data
  })
}

/**
 * @param {number}  - ids
 */
export function removeQuality(id) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/update/deleteQuality',
    method: 'delete',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function qualityInfo(id) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/update/infoQuality',
    method: 'get',
    params: { id }
  })
}

export function saveQualityInfo(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/update/updateQualityDetail',
    method: 'put',
    data
  })
}

/**
 * @description 导出数据
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/warehouseOrderIn/export',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description 手动对接
 */
export function manual(data) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/manualDock',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {array<string>} ids
 * @description 打印
 */
export function printBatch(ids) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/warehouseOrderInPrint',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 管易退货单查询
 */
export function getGuanYiTradeReturn(params) {
  return service['api-scm']({
    url: '/scm/sellWarehouse/warehouseOrderIn/getGuanYiTradeReturn',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
