import { isPlainObject } from '@/utils/validate'
export default {
  methods: {
    $_tree_filterTreeNode(value, data, node) {
      try {
        if (!value) return true
        const selfMatch = data.label.indexOf(value) !== -1
        const parentMatch =
          typeof node.parent === 'object' &&
          node.parent &&
          isPlainObject(node.parent.data)
            ? this.$_tree_filterTreeNode(value, node.parent.data, node.parent)
            : false

        return selfMatch || parentMatch
      } catch (error) {
        console.error(error)
      }
      return false
    }
  }
}
