<template>
  <div>
    <el-tooltip
      :content="`${content}: ${valueFormatter(scope.row[fields]) || '⏤'}`"
      placement="bottom"
      effect="light"
    >
      <div class="triangle-topright" />
    </el-tooltip>

    <span>{{ valueFormatter(row[column.property]) }}</span>
  </div>
</template>
<script>
import { isNumeric } from '@/utils/validate'

export default {
  name: 'VxeToolTipSpan',
  props: {
    content: {
      type: String,
      default: ''
    },
    fields: {
      type: String,
      default: ''
    },
    valueType: {
      type: String,
      default: ''
    },
    valueDigits: {
      type: Number,
      default: 0
    },
    scope: {
      type: Object,
      default: () => ({
        row: {}, // 宿主表格行
        column: {} // 宿主表格列
      })
    }
  },
  data() {
    return {
      row: {}, // 宿主表格行
      column: {} // 宿主表格列
    }
  },

  created() {
    Object.assign(this, this.scope)
  },
  methods: {
    valueFormatter(value) {
      if (this.valueType === 'float' && this.valueDigits > 0) {
        if (isNumeric(value)) return (value * 1).toFixed(this.valueDigits)
      }

      return value
    }
  }
}
</script>
<style lang="scss" scoped>
.triangle-topright {
  width: 0;
  height: 0;
  border-top: 8px solid #027aff;
  border-left: 8px solid transparent;
  position: absolute;
  top: 8px;
  right: 15px;
  z-index: 1;
  cursor: pointer;
}
</style>
