<template>
  <el-row
    class="split-layout"
    type="flex"
    :gutter="gutter"
    style="flex-wrap: wrap"
  >
    <el-col
      class="left"
      :xs="hasLeft ? 24 : 0"
      :sm="hasLeft ? leftSpan : 0"
      :md="hasLeft ? leftSpan : 0"
      :lg="hasLeft ? leftSpan : 0"
      :xl="hasLeft ? leftSpan : 0"
    >
      <slot name="left" />
      <slot name="default" />
    </el-col>
    <el-col
      class="right"
      :xs="24"
      :sm="hasLeft ? rightSpan : 24"
      :md="hasLeft ? rightSpan : 24"
      :lg="hasLeft ? rightSpan : 24"
      :xl="hasLeft ? rightSpan : 24"
    >
      <slot name="right" />
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'SplitedLayout',
  props: {
    justify: {
      type: String,
      default: 'start'
    },
    hasLeft: {
      type: Boolean,
      default: true
    },
    gutter: {
      type: Number,
      default: 0
    },
    leftSpan: {
      type: Number,
      default: 6
    },
    rightSpan: {
      type: Number,
      default: 18
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
.split-layout {
  // @include clearfix;

  // > .left,
  // > .right {
  //   float: left;
  // }
  height: 100%;

  :deep(> [class*='el-col-']) {
    height: 100%;
  }
}
</style>
