import axios from 'axios'
import { createConfigs, createInterceptors } from '@/network'

const configs = createConfigs(axios)

const service = (fullUrl, reqConfs) =>
  axios({
    url: fullUrl,
    ...configs,
    ...reqConfs
  })

// create all axios instances as static properties of service
Array.from(
  new Set(
    require
      .context('./', true, /^[^.]+$|\.(?!(js)$)([^.]+$)/)
      .keys()
      .map((dirName) => dirName.split('/')[1])
  )
)
  .filter((dirName) => !!dirName && dirName !== 'index')
  .forEach((dirName) => {
    const apiDirSplited = dirName.split('-')
    const API_ENV = apiDirSplited[+(apiDirSplited.length > 1)]
    service[dirName] = axios.create({
      baseURL:
        process.env.VUE_APP_SERVER_TYPE === 'proxy'
          ? `/proxy-api-${API_ENV}`
          : process.env[`VUE_APP_BASE_API_${API_ENV.toUpperCase()}`],
      ...configs
    })
    service[dirName].interceptors.request.use(...createInterceptors.request())
    service[dirName].interceptors.response.use(...createInterceptors.response())
  })

export default service
