/**
 * @module api-scm:procurementManage/todoPurchaseReplenishmentAssign
 */

import service from '@/service'

/**
 *
 * @param {Params} params - 补货部门待办列表
 * @returns {Promise<any>}
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/purchase/todoPurchaseReplenishmentAssign/getDeptTodeList',
    method: 'get',
    params
  })
}

/**
 * 补货待办-修改接收人
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/purchase/todoPurchaseReplenishmentAssign/update',
    method: 'post',
    data
  })
}

/**
 * @param {{id:(string|number)}} params
 */
export function info(params) {
  return service['api-scm']({
    url: '/scm/purchase/inventorySupplier/info',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 导出
 */
export function exportIntoCenter(data) {
  return service['api-scm']({
    url: '/scm/purchase/todoPurchaseReplenishmentAssign/export',
    method: 'post',
    data
  })
}
