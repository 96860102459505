import service from '@/service'
/**
 *
 * @param {{id:number}} params
 * @description 质检信息tab页打分列表
 */
export function warehouseEntryQualityDetailScore(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/get/warehouseEntryQualityDetailScore',
    method: 'get',
    params
  })
}
/**
 * @param {{id:string,qualityScore:string}} data
 * @description 质检信息tab页修改分数
 */
export function warehouseEntryQualityDetailUpdateScore(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/update/warehouseEntryQualityDetailUpdateScore',
    method: 'post',
    data
  })
}
