export default {
  path: 'article',
  component: () => import('@/views/demos/article'),
  redirect: '/demos/article/list',
  name: 'Article',
  meta: {
    title: '技术文档',
    icon: 'documentation'
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/demos/article/list/index'),
      name: 'ArticleList',
      meta: { title: '开发文档列表' }
    },
    {
      path: 'doc',
      component: () => import('@/views/demos/article/detail/index'),
      name: 'DocList',
      meta: { title: '开发文档详情' }
    },
    {
      path: 'markdown',
      component: () => import('@/views/demos/article/components/Markdown'),
      name: 'Markdown',
      meta: { title: 'Markdown组件' }
    },
    {
      path: 'paratext',
      component: () => import('@/views/demos/article/components/Paratext'),
      name: 'CreateArticle',
      meta: { title: '富文本组件' }
    }
    // {
    //   path: 'edit/:id(\\d+)',
    //   component: () => import('@/views/demos/article/edit'),
    //   name: 'EditArticle',
    //   meta: {
    //     title: 'Edit Article',
    //     noCache: true,
    //     activeMenu: '/article/list'
    //   },
    //   hidden: true
    // }
  ]
}
