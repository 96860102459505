<template>
  <div :class="[{ hidden: hidden }, align, 'pagination-container']">
    <div class="pagination-wrapper">
      <el-pagination
        v-if="reloadDom"
        :small="small"
        :background="background"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        :layout="layout"
        :page-sizes="pageSizes"
        :total="total"
        v-bind="$attrs"
        :hide-on-single-page="false"
        :pager-count="pagerCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
        <div v-if="isSelectData" class="left_sectioned_total">
          已勾选
          <div class="page_total">{{ isSelectData }}</div>
          条
        </div>

        <span key="prefix" class="page-sizes-prefix">每页</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'
import { DEFAULT_TABLE_ROW_LIMIT, DEFAULT_TABLE_ROW_LIMITS } from '@/constants'

export default {
  /**
   * based on el-pagination
   */
  name: 'TurnPage',
  props: {
    align: {
      type: String,
      default: 'end' // left/right/enter/start/end
    },
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: DEFAULT_TABLE_ROW_LIMIT
    },
    pageSizes: {
      type: Array,
      default: () => DEFAULT_TABLE_ROW_LIMITS
    },
    layout: {
      type: String,
      default: 'total,slot,sizes,prev,pager,next,jumper'
    },
    small: {
      type: Boolean,
      default: false
    },
    background: {
      type: Boolean,
      default: false
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    pagerCount: {
      type: Number,
      default: 7 // 大于等于 5 且小于等于 21 的奇数
    },
    isSelectData: {
      type: [String, Number],
      default: 0 // 左边勾选的数量
    }
  },
  data() {
    return {
      reloadDom: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  mounted() {
    this.$halt(400, () => (this.reloadDom = true))
  },
  methods: {
    handleSizeChange(limit) {
      this.$emit('pagination', {
        page: this.currentPage,
        limit,
        paginationChangeWay: 'sizeChange'
      })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(page) {
      this.$emit('pagination', { page, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/mixin.scss';

.pagination-container {
  font-size: 12px !important;
  font-family: PingFangSC-Regular;
  color: #606266;
  font-weight: 400;
  margin-top: 8px;
  height: 28px;
  width: 100%;
  position: relative;
  .pagination-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    @include scrollbar;
  }
}
.pagination-container.right,
.pagination-container.end {
  .pagination-wrapper {
    .el-pagination {
      text-align: right;
    }
  }
}

.pagination-container.center {
  text-align: center;
  .pagination-wrapper {
    display: inline-block;
  }
}

.pagination-container.hidden {
  display: none;
}
:deep(.el-pagination) {
  position: relative;
  font-weight: normal;
  padding: 0;
  color: #606266;
  font-size: 12px;
  font-family: PingFangSC-Regular;

  .btn-prev,
  .btn-next,
  .el-pager li {
    background-color: transparent;
  }
  .el-pager li {
    margin: 0;
  }
  .el-pager li:not(.disabled).active {
    background-color: transparent;
    color: #1890ff;
  }
  .el-input--mini .el-input__inner {
    height: 24px;
    line-height: 24px;
  }
}

:deep(.el-pagination__total) {
  font-size: 12px !important;
  font-family: PingFangSC-Regular;
}

:deep(.el-pager li) {
  font-size: 12px !important;
  font-family: PingFangSC-Regular;
}

:deep(.el-pagination__sizes .el-input .el-input__inner) {
  font-size: 12px !important;
  font-family: PingFangSC-Regular;
}

.left_sectioned_total {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
  display: inline-block;
  margin-right: 10px;
  font-size: 12px !important;
  font-family: PingFangSC-Regular;
  color: #606266;
  font-weight: 400;
}

.page_total {
  color: #027aff;
  display: inline-block;
  font-size: 12px !important;
}
</style>
