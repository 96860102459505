import { info } from '@/service/api-scm/procurementManage/purchasePriceList'

const state = () => ({
  data: []
})

const mutations = {
  CHANGE__DATA: (state, data) => {
    console.log(data, 'xxx1')
    const { price, unitPrice, taxRate, ...rest } = data[0]
    // 左侧为采购调价函的数据，右侧为采购价目表的数据
    state.data = [
      {
        ...rest,
        beforePrice: price,
        beforeUnitPrice: unitPrice,
        beforeRateCode: taxRate
      }
    ]
  },
  CLEAR__DATA: (state) => {
    state.data = []
  }
}

const actions = {
  async getPurchasePriceDetailInfoVOS({ commit }, id) {
    const {
      data: { purchasePriceDetailInfoVOS }
    } = await info(id)

    commit('CHANGE__DATA', purchasePriceDetailInfoVOS)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
