/**
 * @module api-web:filter-opts/warehouse
 * @description common apis : dropdown select options - product
 */
import service from '@/service'

/**
 * 库位下拉框
 */
export function getInventoryCodeList(params) {
  return service['api-web']({
    url: '/public/warehouse/getInventoryCodeList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 出入库类型
 */
export function getBoundTypeCodeOpts(params) {
  return service['api-web']({
    url: '/public/warehouse/getOutboundType',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 入库类型
 */
export function getInBoundTypeCodeOpts(params) {
  return service['api-web']({
    url: '/public/warehouse/getOutboundType',
    method: 'get',
    params: { ...params, flag: 0 },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 出库类型
 */
export function getOutBoundTypeCodeOpts(params) {
  return service['api-web']({
    url: '/public/warehouse/getOutboundType',
    method: 'get',
    params: { ...params, flag: 1 },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 根据仓库编码获取库位代码
 * @param {*} params
 * @returns
 */
export function getRecommendInventoryCodeOpts(params) {
  return service['api-scm']({
    url: '/public/inventory/getRecommendInventoryCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
