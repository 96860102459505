/**
 * @module api-scm:warehouseCheck
 */
import service from '@/service'

/**
 * @typedef {Object} warehouseCheckDetailCorrelationDTO
 * @property {number} detailId - 关联盘点明细id
 * @property {string} inventoryCode - 批次号
 * @property {number} page - 页码
 * @property {number} limit - 条数
 * @property {string} sort -
 *
 * @param {warehouseCheckDetailCorrelationDTO}
 */

export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/update/getCorrelationList',
    method: 'get',
    params
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/update/update',
    method: 'post',
    data
  })
}

/**
 *
 * @param {{Array}} ids
 * @description  盘点明细批次号弹窗 delete
 */
export function remove(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/update/deleteCorrelation',
    method: 'delete',
    data
  })
}

/**
 *
 * @param {{obejct}} data
 * @description  盘点明细批次号弹窗  add
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/update/saveCorrelation',
    method: 'post',
    data
  })
}
