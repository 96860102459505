<template>
  <div>
    <el-upload
      ref="uploadRef"
      v-loading="loading"
      action="#"
      list-type="listType"
      :auto-upload="false"
      :multiple="multiple"
      v-bind="$attrs"
      :show-file-list="true"
      :on-change="handleChange"
      :file-list="proxyFileList"
      class="el-upload-main"
      :disabled="disabled"
    >
      <i class="el-icon-plus" />
      <div slot="file" slot-scope="{ file }" class="el-upload-list-item">
        <img class="el-upload-list-item-img" :src="file.url" alt="" />
        <span class="el-upload-list-item-actions">
          <!-- 预览按钮 -->
          <span
            class="el-upload-list-item-preview el-upload-icon"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in" />
          </span>
          <!-- 删除按钮 -->
          <span
            v-show="!disabled"
            class="el-upload-list-item-delete el-upload-icon"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete" />
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" width="40%">
      <div class="dialog-preview">文件预览</div>
      <img width="100%" :src="dialogImageUrl" alt="" />
      <div class="dialog-download">
        <el-button size="mini" type="primary" @click="handleDownload">
          下载
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uniqueArr } from '@/utils/array'
export default {
  name: 'BaseUpload',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    action: {
      type: String,
      required: true
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    api: {
      type: Function,
      required: true
    },
    fileKey: {
      type: String,
      required: true
    },
    fileSize: {
      type: Number,
      default: 5
    },
    fileWidth: {
      type: Number,
      default: 0
    },
    fileHeight: {
      type: Number,
      default: 0
    },
    params: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 5
    },
    fileFormat: {
      type: Array,
      default: () => ['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG']
    }
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      url: '',
      // 从文件中选择的文件数量
      uploadNum: 0,
      // 记录满足上传条件的文件数量
      fileCount: 0,
      // 上传的文件存在多个错误时，控制只展示一次错误提示
      showTip: true,
      // 上传的文件中是否存在错误的文件
      isErrorFile: false,
      // 记录此时正在上传第几个文件
      uploadCount: 0,
      // 记录满足条件的文件
      selectFileList: [],
      // 上传时的loading
      loading: false
    }
  },
  computed: {
    proxyFileList: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    async handleChange(file) {
      this.showTip = true
      const _URL = window.URL || window.webkitURL
      /**
       * 获取从文件中选择的图片的数量；若想更改element-ui中el-upload的源码来获取在文件中选择的数量，
       * 则需要在lib文件中element-ui.common.js文件中更改，才能生效！！！
       */
      var upload_img = this.$refs.uploadRef
      const uploadRef = this.$refs.uploadRef?.$children[1]
      if (upload_img) {
        var upload = upload_img.$el.getElementsByTagName('input')
        if (
          upload &&
          upload.length > 0 &&
          upload[0].files &&
          upload[0].files.length > 0
        ) {
          this.uploadNum = upload[0].files.length
          console.log(this.uploadNum)
        }
      }

      if (this.uploadNum + this.proxyFileList.length > this.limit) {
        // 设置一个标志让它只提示一回
        if (this.showTip) {
          this.$confirm(
            `最多上传${this.limit}张${this.fileName}，请重新选择！`,
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
          this.showTip = false
        }
        // uploadRef.files 是uploadList中的数据，它是决定上传的图片的因素之一
        uploadRef.files.pop()
        return false
      }

      this.uploadCount++
      const { size, name } = file
      const nameSplited = name.split('.')
      const fileType = nameSplited[nameSplited.length - 1]
      if (!this.fileFormat.includes(fileType)) {
        this.$message.error(
          `文件格式仅支持 ${uniqueArr(
            this.fileFormat.map((v) => v.toLowerCase().trim())
          ).join('、')}`
        )
        uploadRef.files.pop()
        this.isErrorFile = true
        this.clearInvalidFile(uploadRef)
        return false
      }

      if (!name.includes(this.fileName)) {
        this.$confirm(`图片命名需要包含${this.fileName}，请检查！`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        uploadRef.files.pop()
        this.isErrorFile = true
        this.clearInvalidFile(uploadRef)
        return false
      }

      const limitSize = size / 1024 / 1024
      if (limitSize > this.fileSize) {
        this.$message.warning(`图片大小不能超过${this.fileSize},请重新选择！`)
        uploadRef.files.pop()
        this.isErrorFile = true
        this.clearInvalidFile(uploadRef)
        return false
      }
      /**
       * 这段代码必须在getImgInfo()异步任务之前；
       * 由于el-upload中on-change是'同时'进行，
       * 会被异步任务打断，导致最终得到的结果会有偏差。
       */
      const fileUrl = _URL.createObjectURL(file.raw)
      this.selectFileList.push({
        name: file.name,
        url: fileUrl,
        uid: file.uid,
        File: file.raw
      })
      /**
       * @description 对图片尺寸限制的判断
       */
      const imageSize = await this.getImgInfo(_URL, file)
      // 针对图片尺寸有要求的处理
      if (this.fileWidth || this.fileHeight) {
        // 宽高都有限制时的处理
        if (this.fileWidth && this.fileHeight) {
          if (
            this.fileWidth !== imageSize.width ||
            this.fileHeight !== imageSize.height
          ) {
            // uploadRef.files.pop()
            this.$message.warning(
              `图片尺寸只能是${this.fileWidth}*${this.fileHeight},请重新选择！`
            )
            this.isErrorFile = true
            this.clearInvalidFile(uploadRef)
            return false
          }
        }
        // 宽度限制的处理
        if (this.fileWidth) {
          if (this.fileWidth !== imageSize.width) {
            // uploadRef.files.pop()
            this.$message.warning(
              `图片尺寸宽度只能为${this.fileWidth},请重新选择！`
            )
            this.isErrorFile = true
            this.clearInvalidFile(uploadRef)
            return false
          }
        }
        // 高度限制的处理
        if (this.fileHeight) {
          if (this.fileHeight !== imageSize.height) {
            uploadRef.files.pop()
            this.$message.warning(
              `图片尺寸高度只能为${this.fileHeight},请重新选择！`
            )
            this.isErrorFile = true
            this.clearInvalidFile(uploadRef)
            return false
          }
        }
      }
      this.fileCount++
      this.clearInvalidFile(uploadRef)

      if (this.uploadNum === this.fileCount) {
        this.loading = true
        this.fileCount = 0
        this.uploadCount = 0
        this.isErrorFile = false
        const endIndex = this.proxyFileList?.length ?? 0
        upload_img.uploadFiles = upload_img.uploadFiles.slice(0, endIndex)
        await this.upLoadFile()
        this.selectFileList = []
      }
      this.loading = false
    },
    /**
     * 去除uploadList中无效的文件
     */
    clearInvalidFile(uploadRef) {
      if (this.uploadCount === this.uploadNum) {
        if (this.isErrorFile) {
          this.selectFileList.forEach((item) => {
            const index = uploadRef.files.findIndex(
              (res) => res?.uid === item?.uid
            )
            if (index >= 0) {
              uploadRef.files.splice(index, 1)
            }
          })
          this.isErrorFile = false
          this.uploadCount = 0
          this.fileCount = 0
          this.selectFileList = []
        }
      }
    },
    /**
     * 获取图片信息
     */
    getImgInfo(_URL, file) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = _URL.createObjectURL(file.raw)
        img.onload = function () {
          const imgSize = {
            width: 0,
            height: 0
          }
          imgSize.width = img.width
          imgSize.height = img.height
          resolve(imgSize)
        }
      })
    },
    /**
     * @description 手动上传
     */
    async upLoadFile() {
      // 需要上传的文件列表
      const uploadList = []
      this.selectFileList.forEach((item) => {
        if (item?.File) {
          uploadList.push(item.File)
        }
      })
      // 已上传过的文件列表
      const uploadedList = this.proxyFileList.filter((item) => {
        return !item.File
      })
      const params = {
        ...this.params,
        [this.fileKey]: uploadList
      }
      this.$emit('update:params', params)
      const res = await this.api(params)
      if (res.code === 200) {
        const fileArr = res.data.map((item) => {
          return {
            name: item.fileName,
            url: item.url
          }
        })
        this.proxyFileList = uploadedList.concat(fileArr)
        console.log('上传成功', this.proxyFileList)
      } else {
        console.log('上传失败')
      }
    },
    handleRemove(file) {
      const index = this.proxyFileList.findIndex(
        (item) => item.uid === file.uid
      )
      if (index >= 0) {
        this.proxyFileList.splice(index, 1)
      }
    },
    handlePictureCardPreview(file) {
      console.log('file', file)
      this.dialogVisible = true
      this.dialogImageUrl = file.url
    },
    handleDownload() {
      console.log(this.dialogImageUrl)
      window.open(this.dialogImageUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-upload-main {
  display: flex;
  justify-content: flex-start;
}
.el-icon-plus {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border: 1px dashed #13c3c7;
  border-radius: 6px;
}
.el-upload-list-item {
  width: 100px;
  height: 100px;
  position: relative;
}
:deep(.el-upload-list--listType) {
  display: flex;
  justify-content: flex-start;
}
:deep(.el-upload-list__item:first-child) {
  margin-top: 0;
}
:deep(.el-upload-list__item) {
  margin: 0 0 5px 5px;
  width: 100px;
  border-radius: 6px;
  transition: none !important;
}
.el-upload-list-item-img {
  border-radius: 6px;
  width: 100px;
  height: 100px;
}
.el-upload-list-item-actions {
  width: 100%;
  height: 100%;
  cursor: default;
  text-align: center;
  font-size: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 6px;
}
.el-upload-icon {
  width: 40%;
}

.el-upload-list-item-actions:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  color: #ddd;
}

.dialog-preview {
  width: 100%;
  font-size: 18px;
  color: #303133;
  text-align: center;
}
.dialog-download {
  width: 100%;
  text-align: center;
}

:deep(.el-dialog__body) {
  padding-top: 0;
}
</style>
