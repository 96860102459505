/**
 * @module api-web:financialAffairs/generalLedger/data/subject
 */
import service from '@/service'

/**
 * 查询 财务-总账-资料-科目
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountSubjects/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 总账资料-科目类别下拉
 * @param {object} params
 */
export function getSubjectsCategory(params) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountSubjects/getSubjectsCategory',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 详情
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountSubjects/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountSubjects/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountSubjects/update',
    method: 'put',
    data
  })
}
/**
 *  列表树形下拉
 * @param {{id}}
 */
// export function getListDown(id) {
//   return service['api-web']({
//     url: '/fin/gl/data/finAccountSubjects/getListDown',
//     method: 'get',
//     params: { id },
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }
