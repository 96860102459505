/**
 * @module utils/halt
 */
import Vue from 'vue'
import { isInteger } from '@/utils/validate'
/**
 * wait(include nextTick and setTimeout)
 * @param {number=} [ms=-1] 毫秒
 * @param {(function|null)=} [cb=null] callback before fullfilled/rejected
 * @returns {Promise<boolean>}
 */
export async function halt(ms = -1, cb = null) {
  try {
    await Vue.nextTick()
    if (isInteger(ms) && ms >= 0) {
      await new Promise((resolve) => setTimeout(resolve, ms))
    }
    if (typeof cb === 'function') {
      const foo = cb()
      if (foo instanceof Promise) {
        let res
        await foo
          .then((result) => (res = result))
          .catch((err) => console.error(err))
        return res
      } else {
        return foo
      }
    }
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export default halt
