import service from '@/service'
/**
 * @param {array<string>} params
 * @description 批量获取面单HTML模板
 */
export function getPrintHtml(params) {
  return service['api-external']({
    url: 'public/kdniao/getPrintHtml',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
