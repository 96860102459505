/**
 * @module api-web:summary
 */
import service from '@/service'

/**
 * 查询 财务-应收款汇总
 * @param {object} params
 */
export function getArSummaryList(params) {
  return service['api-web']({
    url: '/fin/ar/finSummary/getReceivablesList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 查询 财务-应收款汇总导出
 * @param {object} params
 */
export function exportData(params) {
  return service['api-web']({
    url: '/fin/ar/finSummary/receivablesExport',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
