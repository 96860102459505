<template>
  <!-- 编辑模式 renderEdit -->
  <div>
    <!-- 单选多选 切换，value 清除不了，此处使用两个不同 key 的 select 来解决 -->
    <el-select
      v-if="multiple"
      :key="`el-select-multiple-${Date.now()}-${Math.random()}`"
      ref="el-picker-select-multiple"
      v-model="proxyValue"
      v-loading="loading"
      class="el-picker-select-edit-render __multiple"
      v-bind="$elSelect.props"
      :multiple="true"
      :placeholder="placeholder"
      v-on="$elSelect.events"
      @change="onChange"
      @focus="onFocus"
    >
      <el-option
        v-for="item in optionsFiltered"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        v-bind="item"
      />
    </el-select>
    <el-select
      v-else
      :key="`el-select-single-${Date.now()}-${Math.random()}`"
      ref="el-picker-select-select-single"
      v-model="proxyValue"
      v-loading="loading"
      class="el-picker-select-edit-render __single"
      v-bind="$elSelect.props"
      :multiple="false"
      :placeholder="placeholder"
      v-on="$elSelect.events"
      @change="onChange"
      @focus="onFocus"
    >
      <el-option
        v-for="item in optionsFiltered"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        v-bind="item"
      />
    </el-select>
  </div>
</template>
<script>
import { isArray, isPlainObject, isString } from '@/utils/validate'
import { BIET_RENDER_INJECT_NAMESPACE } from '@/constants'

export default {
  /**
   * 基于 el-select
   * @see https://element.eleme.io/#/zh-CN/component/select
   */
  name: 'ElPick',
  props: {
    mode: {
      type: String,
      default: 'edit',
      validator: (mode) => ['edit', 'cell'].includes(mode)
    },
    scope: {
      type: Object,
      default: () => ({
        row: {},
        column: {}
      })
    }
  },
  data() {
    return {
      // initValue: null,
      row: {}, // 宿主表格行
      column: {}, // 宿主表格列,
      loading: false,
      options: [],
      isInit: true // 点编辑按钮（第一次触发获取批次号的接口），批次号不赋默认值
    }
  },
  computed: {
    $editRender() {
      return this.$attrs['edit-render-props']
    },
    $elSelect() {
      const { multiple, options, ...restProps } = this.$attrs['el-select-props']
      const { change, focus, ...restEvents } = this.$listeners
      return {
        options,
        multiple,
        change,
        focus,
        events: restEvents,
        props: restProps
      }
    },
    multiple() {
      // 普通配置
      let columnMulti = this.$elSelect.multiple
      columnMulti = typeof columnMulti === 'boolean' ? columnMulti : false

      /**
       * @description
       * 从 __BIET_ROW_EXTERNAL__ 上读取
       * __BIET_ROW_EXTERNAL__[this.column.property].multiple
       */
      const cellMulti = this.$biet.getRowExt(
        this.row,
        `${this.column.property}.multiple`
      )

      return typeof cellMulti === 'boolean' ? cellMulti : columnMulti
    },
    /**
     * remove option that "disabled === true"
     */
    optionsFiltered() {
      // return this.options.length > 0
      //   ? this.options.filter((v) => !v.disabled || v.value === this.proxyValue)
      //   : this.options

      // const _options =
      //   this.options.length > 0
      //     ? this.options.filter(
      //         (v) => !v.disabled || v.value === this.initValue
      //       )
      //     : this.options
      // if (_options.length !== this.options.length) {
      //   console.warn('【ElPick】过滤掉了 disabled:true 的选项')
      // }
      // return _options
      return this.options
    },
    /**
     * @returns {string}
     */
    placeholder() {
      try {
        if (this.loading) {
          return this.$elSelect.props.loadingText || '加载中'
        } else {
          const value = this.row[this.column.property]
          if (
            value === '' ||
            value === null ||
            (isArray(value) && !value.length)
          ) {
            return this.$elSelect.props.placeholder || '请选择'
          } else return ''
        }
      } catch (error) {
        console.error('【ElPick】:', error)
      }
      return '请选择'
    },
    optionValueKey() {
      return this.$elSelect.props?.valueKey || 'value'
    },
    /**
     * 多选数据支持 逗号分割字符串或者数组
     */
    proxyValue: {
      set(val) {
        this.setValue(val)
      },
      get() {
        return this.getValue()
      }
    },
    /**
     * @description 处理options为字符串
     */
    proxyTarget() {
      if (this.$editRender?.watch) {
        const { target } = this.$editRender.watch
        return this.row?.[target]
      } else {
        return ''
      }
    },
    proxyOptions() {
      return this.$elSelect.options
    }
  },
  watch: {
    'scope.row': {
      immediate: false,
      deep: true,
      handler(row) {
        this.row = row
      }
    },
    'scope.column': {
      immediate: false,
      deep: true,
      handler(column) {
        this.column = column
      }
    },

    proxyOptions: {
      immediate: true,
      deep: false,
      handler: 'updateOptions'
    },
    /**
     * @description 针对监听存在联动的异步逻辑事件
     */
    proxyTarget: {
      immediate: true,
      handler: 'linkHandler'
    }
  },
  created() {
    Object.assign(this, this.scope)
  },
  methods: {
    updateOptions(newOptions) {
      this.initOptions(isString(newOptions) ? [] : newOptions)
    },
    /**
     * optionItem: 联动下拉首项对指定字段赋值；
     * specialFields: 特殊字段的赋值处理；
     */
    async linkHandler() {
      if (isString(this.proxyOptions)) {
        if (this.$editRender?.watch) {
          const { optionsData, specialFields } = this.$editRender.watch
          // 监听的目标有值时
          if (this.proxyTarget) {
            const options = await optionsData(this.scope)
            if (isArray(options) && options.length > 0) {
              await this.initOptions(options)
              // 联动的字段自动填充options的第一项值
              if (
                isArray(this.$editRender?.optionItem) &&
                this.$editRender.optionItem.length > 0
              ) {
                const validOption =
                  options?.filter((item) => !item.disabled) ?? []

                this.$editRender.optionItem.forEach((item) => {
                  this.row[item] =
                    isString(this.row?.[item]) && this.row[item].trim() === ''
                      ? validOption[0]?.[item]
                      : this.row?.[item] ?? validOption[0]?.[item]
                })
              }
              if (specialFields) {
                if (isArray(specialFields)) {
                  specialFields.forEach((item) => {
                    this.row[item.field] ??= item.method(options)
                  })
                } else console.error('"specialFields" must be an array type')
              }
            } else this.clearNullInfo()
          } else this.clearNullInfo()
        }
      }
      this.isInit = false
    },
    clearNullInfo() {
      this.initOptions([])
      // 监听的目标无值时，并设置了optionItem属性时
      if (!this.isInit) {
        if (isArray(this.$editRender?.optionItem)) {
          this.$editRender.optionItem.forEach((item) => {
            this.row[item] = typeof this.row[item] === 'number' ? 0 : ''
          })
        } else {
          this.row[this.column.property] =
            typeof this.row[this.column.property] === 'number' ? 0 : ''
        }
        if (isArray(this.$editRender?.watch?.specialFields)) {
          this.$editRender.watch.specialFields.forEach((item) => {
            this.row[item.field] = ''
          })
        }
      }
    },
    setValue(val) {
      // set value
      const _val = this.multiple === true && isArray(val) ? val.join(',') : val
      this.row[this.column.property] = _val
      // console.log(`【ElPick】value setter (${this.column.property})`, val, _val)

      // set name
      const inject = this.$editRender[BIET_RENDER_INJECT_NAMESPACE]
      const hasInject = isPlainObject(inject)
      if (hasInject) {
        const shim = inject?.shim
        const hasShim = isPlainObject(shim)
        if (hasShim) {
          if (shim?.field && isString(shim.field)) {
            let cellValues = []
            if (typeof _val === 'number') cellValues = [_val]
            else if (isString(_val) && _val.trim()) {
              cellValues = _val
                .split(',')
                .filter(
                  (v) => typeof v === 'number' || !!(isString(v) && v.trim())
                )
            } else if (isArray(_val)) {
              cellValues = _val.filter(
                (v) => typeof v === 'number' || !!(isString(v) && v.trim())
              )
            }
            if (cellValues.length === 1) {
              this.row[shim.field] = this.findLabel(cellValues[0])
            } else {
              this.row[shim.field] = cellValues
                .map((v) => this.findLabel(v))
                .filter((v) => !!v)
                .join()
            }
          }
        }
      }
    },
    getValue() {
      if (this.loading) return this.multiple === true ? [] : null

      const val = this.row[this.column.property]
      const _val =
        this.multiple === true
          ? (isArray(val)
              ? val
              : isString(val)
              ? val.includes(',')
                ? val.split(',')
                : [val]
              : []
            ).filter((v) => typeof v === 'number' || (isString(v) && v.trim()))
          : val

      // console.log(`【ElPick】value getter (${this.column.property})`, val, _val)
      return _val
    },
    setOptions(options) {
      if (isArray(options)) {
        this.options = options
      } else console.error('【ElPick】: illeagl "options"', options)

      if (
        this.$refs['select'] &&
        typeof this.$refs['select'].blur === 'function'
      ) {
        this.$refs['select'].blur()
      }
    },
    setOptionsByPromise(options) {
      options
        .then(this.setOptions)
        .catch((error) => console.error('【ElPick】:', error))
    },
    async initOptions(options) {
      this.loading = true
      try {
        if (options instanceof Promise) {
          await this.setOptionsByPromise(options)
        } else if (typeof options === 'function') {
          const res = options()
          if (res instanceof Promise) {
            await this.setOptionsByPromise(res)
          } else this.setOptions(res)
        } else this.setOptions(options)
      } catch (error) {
        this.setOptions([])
        console.error(error)
      }
      this.loading = false
    },
    onChange(value) {
      const { change } = this.$elSelect

      const currSelectedItem = this.multiple
        ? this.options.filter(
            (item) =>
              isPlainObject(item) &&
              isArray(value) &&
              value.includes(item[this.optionValueKey])
          )
        : this.options.find(
            (item) => isPlainObject(item) && value === item[this.optionValueKey]
          ) || null

      if (this.options.length > 0) {
        if (
          (this.multiple &&
            isArray(value) &&
            value.length > 0 &&
            currSelectedItem.length === 0) ||
          (!this.multiple && value && !currSelectedItem)
        ) {
          console.error('【ElPick】: please check props:valueKey')
        }
      }
      typeof change === 'function'
        ? change(
            this.scope,
            value,
            currSelectedItem || (this.multiple ? [] : null)
          )
        : null
    },
    onFocus($evt) {
      const { focus } = this.$elSelect
      typeof focus === 'function' ? focus(this.scope, $evt) : null
    },
    findLabel(value) {
      const _v = isString(value) ? value.trim() : value
      const label =
        this.options.find((item) => item.value === _v)?.label.trim() || ''
      return label
    }
  }
}
</script>
<style lang="scss" scoped></style>
