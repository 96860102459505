export default {
  /**
   * *********
   * 普通按钮 *
   * *********
   */
  save: {
    attrs: {
      type: 'primary'
    },
    iconName: 'i-add',
    evtName: '新增'
  },
  delete: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-delete',
    evtName: '删除'
  },
  submit: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-submit',
    evtName: '提交'
  },
  withdraw: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-cancel',
    evtName: '结束流程'
  },
  pushDown: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-push-down',
    evtName: '下推'
  },
  reverseAudit: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-rollback',
    evtName: '反审核'
  },
  batchReverseAudit: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-rollback',
    evtName: '批量反审核'
  },
  completedAudit: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-approval',
    evtName: '审核'
  },
  checkout: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-gz',
    evtName: '过账'
  },
  reverseCheckout: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-fgz',
    evtName: '反过账'
  },
  postCheckout: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-jz',
    evtName: '结账'
  },
  reversePostCheckout: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-fjz',
    evtName: '反结账'
  },
  invalid: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-invalid',
    evtName: '作废'
  },
  filter: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-filter',
    evtName: '过滤'
  },
  lock: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-lock',
    evtName: '锁定'
  },
  unlock: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-unlock',
    evtName: '解锁'
  },
  duplicatePurchaseOrder: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-copy',
    evtName: '复制'
  },
  jdInvoicePrinting: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-shipment',
    evtName: '配送清单打印'
  },
  procurementOrderShipInvoicePrint: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-shipment',
    evtName: '发货单打印'
  },
  writeOffVoucher: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-operate',
    evtName: '凭证冲销'
  },
  printVoucher: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-pzdy',
    evtName: '打印'
  },
  callOff: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-cancel',
    evtName: '撤销'
  },
  prev: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-up',
    evtName: '前一'
  },
  next: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-down',
    evtName: '后一'
  },

  /**
   **************
   * 下拉：父按钮 *
   **************
   */
  businessOperation: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-operate',
    label: '业务操作'
  },
  associationQuery: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-zoom-out',
    label: '关联查询'
  },
  options: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-selection',
    label: '选项'
  },
  vouchers: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-contract',
    label: '凭证'
  },
  orderBlock: {
    attrs: {
      class: 'common-btn'
    },
    iconName: '',
    label: '订单拦截'
  },
  receiptOrder: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-ticket',
    label: '收款单'
  },
  paymentApplacationOrder: {
    attrs: {
      class: 'common-btn'
    },
    iconName: 'i-ticket',
    label: '付款申请单'
  },
  /**
   **************
   * 下拉：子按钮 *
   **************
   */
  turnOffFin: {
    evtName: '关闭财务收付单'
  },
  reverseCloseFin: {
    evtName: '反关闭财务收付单'
  },
  cancellation: {
    evtName: '作废'
  },
  downloadExcel: {
    evtName: '导出合同'
  },
  downloadPdf: {
    evtName: '导出合同'
  },
  downloadExcelQuotation: {
    evtName: '导出报价单'
  },
  uploadVoucher: {
    evtName: '上传收款凭证'
  },
  import: {
    evtName: '导入'
  },
  export: {
    evtName: '导出'
  },
  exportData: {
    evtName: '导出'
  },

  inventoryQuery: {
    evtName: '库存查询'
  },
  enable: {
    evtName: '启用'
  },
  disable: {
    evtName: '禁用'
  },
  workProcess: {
    evtName: '工作流程'
  },
  exportQuotation: {
    evtName: '导出报价单'
  },
  exportContract: {
    evtName: '导出合同'
  },
  reconciliation: {
    evtName: '对账'
  },
  confirmBlock: {
    evtName: '拦截订单'
  },
  cancelBlock: {
    evtName: '取消拦截'
  },
  close: {
    evtName: '关闭'
  },
  rollbackClose: {
    evtName: '反关闭'
  },
  fullClose: {
    evtName: '整单关闭'
  },
  generateVouchers: {
    evtName: '生成凭证'
  },
  viewBizVouchers: {
    evtName: '查看业务凭证'
  },
  viewGlVouchers: {
    evtName: '查看总账凭证'
  }
}
