<template>
  <el-drawer
    class="base-drawer"
    :visible.sync="proxyVisible"
    :title="title"
    :size="size"
    v-bind="$attrs"
    :wrapper-closable="wrapperClosable"
    :close-on-press-escape="closeOnPressEscape"
    v-on="$listeners"
  >
    <slot />
    <div class="buttons">
      <el-button type="primary" class="confirm-btn" @click="onConfirm">
        {{ confirmBtn }}
      </el-button>
      <el-button class="cancel-btn" @click="onCancel">
        {{ cancelBtn }}
      </el-button>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'BaseDrawer',
  components: {},
  props: {
    size: {
      type: String,
      default: '600px'
    },
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    wrapperClosable: {
      type: Boolean,
      default: false
    },
    closeOnPressEscape: {
      type: Boolean,
      default: false
    },
    confirmBtn: {
      type: String,
      default: '确 定'
    },
    cancelBtn: {
      type: String,
      default: '取 消'
    }
  },
  computed: {
    proxyVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onConfirm(e) {
      this.$emit('confirm', e)
    },
    onCancel(e) {
      this.$emit('cancel', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.ag-el-form-drawer {
  transition: width 300ms;
}
:deep(.el-drawer__body) {
  overflow: auto;
}
:deep(.el-drawer__header) {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
:deep(.el-drawer__container ::-webkit-scrollbar) {
  display: none;
}
.buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 60px;
  line-height: 60px;
  padding-right: 25px;
  text-align: right;
  border-top: 4px solid #eeeeee;
}
</style>
