import { isArray, isString } from '@/utils/validate'

/**
 * select+input pair for display name input when view mode
 * @param {object} item - agel item
 * @returns {Array<object>} - agel items
 * @description not need update name input's value, only get and show value from info (api)
 */
export function agelSelectNamePair(
  item,
  payloads = { readonly: false, disabled: true }
) {
  const {
    form = '', // 字段所在表单变量名，无默认,
    name = '', // 自定义配置 input's prop, 默认 select's prop+'Name'
    component, // eslint-disable-line no-unused-vars
    prop,
    label,
    display,
    disabled = false,
    required = false,
    on = {},
    selectAutoInitOptions = false,
    ...rest
  } = item

  const _disabled = (data) =>
    typeof disabled === 'function'
      ? disabled(data)
      : typeof disabled === 'boolean'
      ? disabled
      : false

  const selectItemDisplay =
    typeof display === 'function'
      ? (data) => !!display(data) && !_disabled(data)
      : typeof display === 'undefined'
      ? (data) => !_disabled(data)
      : (data) => !!display && !_disabled(data)

  const inputItemShow = (data) => !selectItemDisplay(data)
  /**
   * input name item 的 【required】 须与 【show】 同频
   */
  const inputItemRequired =
    typeof required === 'function'
      ? (data) => !!required(data) && !!inputItemShow(data)
      : (data) => !!required && inputItemShow(data)

  const nameProp = isString(name) && name.trim() ? name : `${prop}Name`

  const { change, ...restEvts } = on
  const selectItem = {
    component: 'el-select',
    label,
    prop,
    display: selectItemDisplay,
    required,
    on: {
      ...restEvts,
      change: (e) => {
        if (e) {
          if (isString(form) && form.trim()) {
            if (typeof this[form]?.getRef === 'function') {
              const name = this[form]?.getRef(prop)?.getValueOption(e)?.label
              if (isString(name) && name.trim()) {
                this[form].data[nameProp] = name
                console.log(
                  `【agelSelectNamePair】update ${form}'s "${nameProp}:${name}"`
                )
              } else {
                console.error(
                  `【agelSelectNamePair】can't find "${nameProp}"'s value in current item's options`,
                  form,
                  prop,
                  this?.[form]?.getItem(prop)
                )
              }
            } else {
              console.error(
                `【agelSelectNamePair】can't find "${form}" and set "${nameProp}"'s value`,
                form,
                prop
              )
            }
          } else {
            console.log(
              `【agelSelectNamePair】not need update form's "${nameProp}"`
            )
          }
        }

        typeof change === 'function' && change(e)
      }
    },
    ...rest
  }

  const inputItemAsName = {
    component: 'el-input',
    label,
    prop: nameProp,
    required: inputItemRequired,
    show: inputItemShow,
    display: true,
    placeholder: ' '
  }

  // name 字段样式 readonly or disable
  if ((payloads?.readonly ?? false) === true) {
    inputItemAsName.readonly = true
  } else inputItemAsName.disabled = true

  // 初始化自动设置远程搜索的下拉维当前 value/name 对
  if (
    selectAutoInitOptions === true &&
    typeof selectItem?.remoteMethod === 'function'
  ) {
    if (!isArray(selectItem?.options)) selectItem.options = []

    if (isString(form) && form.trim()) {
      const unwatch = this.$watch(
        `${form}.data.${prop}`,
        (next, prev) => {
          unwatch()
          let nextHasValue = false
          if (isString(next) && next.trim() !== '') nextHasValue = true
          else if (isArray(next) && next.length > 0) nextHasValue = true
          else if (typeof next === 'number') nextHasValue = true
          if (nextHasValue === true) {
            if (typeof this?.[form]?.getItem === 'function') {
              const currOptions = this[form].getItem(prop)?.options

              if (!isArray(currOptions) || currOptions.length === 0) {
                if (item?.multiple === true) {
                  // TODO:
                  // selectItem.options = next.split(',').map((v) => ({
                  //   value: v,
                  //   label: this[form].getRef(prop).getValueOption(v)?.label
                  // }))
                  console.error(
                    '【agelSelectNamePair】set init option not support [multiple:true]'
                  )
                } else {
                  this[form].getItem(prop).options = [
                    {
                      value: next,
                      label: this[form].data[nameProp]
                    }
                  ]
                  console.log(
                    `【agelSelectNamePair】set init option via selectAutoInitOptions:${selectAutoInitOptions}`
                  )
                }
              } else {
                console.log(
                  `【agelSelectNamePair】dont't init options, current options:`,
                  currOptions
                )
              }
            } else {
              console.error(`【agelSelectNamePair can't find form via`, form)
            }
          }
        },
        { immediate: false }
      )
    } else {
      console.error(
        `【agelSelectNamePair】selectAutoInitOptions need config "form" porp`
      )
    }
  }

  return [selectItem, inputItemAsName]
}

export default agelSelectNamePair
