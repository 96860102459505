/**
 * @module utils/poll
 */
import { isArray, isInteger, isPlainObject } from '@/utils/validate'
import { isHTMLElement } from '@/utils/dom'

/**
 * @param {(Record<(string|number),any>|Function)} target 轮询目标,可以是函数来支持动态
 * @param {Array<(string|number)>} nested  nested property of target
 * @param {number} timeout 超时
 * @param {any=} expect 期望轮询的结果, 不支持 undefined
 * @returns target founed
 */
export function poll({ target, nested = [], timeout, expect }) {
  return new Promise((resolve) => {
    try {
      const _keys = isArray(nested) ? nested : []

      const _target = _keys.length
        ? _keys.reduce(
            (prev, curr) => prev[curr],
            typeof target === 'function' ? target() : target
          )
        : typeof target === 'function'
        ? target()
        : target
      // console.groupCollapsed('Polling:', ['target', ...nested].join('.'))
      // console.log('Start:', _target, typeof _target)
      // const start = Date.now()
      if (typeof expect !== 'undefined') {
        if (_target === expect) {
          // window.cancelAnimationFrame(_pollid)
          resolve(_target)
          // console.log('Stop: found "object" || "array" || "HTMLElement"')
          // console.log('End:', _target, typeof _target)
          // const end = Date.now()
          // console.log('Spent:', end - start + 'ms')
        }
      } else {
        if (typeof _target !== 'undefined') {
          if (typeof _target === 'object') {
            if (
              (isPlainObject(_target) && Object.keys(_target).length > 0) ||
              (isArray(_target) && _target.length > 0) ||
              isHTMLElement(_target)
            ) {
              resolve(_target)
              // console.log("Don't start poll, existed [1]")
              // console.log('End:', _target, typeof _target)
              // const end = Date.now()
              // console.log('Spent:', end - start + 'ms')
              // console.groupEnd()
              return
            }
          } else {
            resolve(_target)
            // console.log("Don't start poll, existed [2]")
            // console.log('End:', _target, typeof _target)
            // const end = Date.now()
            // console.log('Spent:', end - start + 'ms')
            // console.groupEnd()
            return
          }
        }
      }

      let _pollid = 0
      let _timeoutid = 0
      const _timeout =
        typeof timeout === 'number' && isInteger(timeout) ? timeout : 10000

      const _polling = () => {
        const _target = _keys.length
          ? _keys.reduce(
              (prev, curr) => prev[curr],
              typeof target === 'function' ? target() : target
            )
          : typeof target === 'function'
          ? target()
          : target
        // console.log('polling:', _target, typeof _target)
        if (typeof expect !== 'undefined') {
          if (_target === expect) {
            window.cancelAnimationFrame(_pollid)
            resolve(_target)
            clearTimeout(_timeoutid)
            // console.log('Stop: found "object" || "array" || "HTMLElement"')
            // console.log('End:', _target, typeof _target)
            // const end = Date.now()
            // console.log('Spent:', end - start + 'ms')
            // console.groupEnd()
            return
          } else {
            _pollid = window.requestAnimationFrame(_polling)
          }
        } else {
          if (typeof _target !== 'undefined') {
            if (typeof _target === 'object') {
              if (
                (isPlainObject(_target) && Object.keys(_target).length > 0) ||
                (isArray(_target) && _target.length > 0) ||
                isHTMLElement(_target)
              ) {
                window.cancelAnimationFrame(_pollid)
                resolve(_target)
                clearTimeout(_timeoutid)
                // console.log('Stop: found "object" || "array" || "HTMLElement"')
                // console.log('End:', _target, typeof _target)
                // const end = Date.now()
                // console.log('Spent:', end - start + 'ms')
                // console.groupEnd()
                return
              } else {
                _pollid = window.requestAnimationFrame(_polling)
              }
            } else {
              window.cancelAnimationFrame(_pollid)
              resolve(_target)
              clearTimeout(_timeoutid)
              // console.log('Stop: found', typeof _target)
              // console.log('End:', _target, typeof _target)
              // const end = Date.now()
              // console.log('Spent:', end - start + 'ms')
              // console.groupEnd()
              return
            }
          } else {
            _pollid = window.requestAnimationFrame(_polling)
          }
        }
      }
      _timeoutid = setTimeout(() => {
        window.cancelAnimationFrame(_pollid)
        resolve(null)
        console.warn(
          'Stop Poll ',
          'target:',
          target,
          'nested:',
          nested,
          'timeout:',
          _timeout + 'ms'
        )
        // console.groupEnd()
        return
      }, _timeout)

      _polling()
    } catch (error) {
      console.error(error)
      resolve(null)
      // console.groupEnd()
      return
    }
  })
}

export default poll
