/**
 * @module api-scm:taskCenter/orderFulfillmentTask/index.vue
 * @description 任务管理中心
 */
import service from '@/service'

/**
 * 查询 列表
 * @param {{limit:number,page:number}} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/orderFulfillmentTask/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  订单履约数据导出
 * @param {{endDate: string, startDate: string}} params
 */

export function exportData(params) {
  return service['api-scm']({
    url: '/orderFulfillmentTask/getJdOrder',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
