/**
 * @module api-web:financialAffairs/generalLedger/voucherManagement/postVoucher
 */
import service from '@/service'

/**
 * 查询 财务-总账-凭证管理-凭证过账
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucherCheckout/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情 财务-总账-凭证管理-凭证过账
 * @param {object} data
 */
export function checkout(data) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucherCheckout/checkout',
    method: 'post',
    data
  })
}

/**
 * 创建 财务-总账-凭证管理-凭证过账
 * @param {object} data
 */
export function reverseCheckout(data) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucherCheckout/reverseCheckout',
    method: 'post',
    data
  })
}
