// eslint-disable-next-line spaced-comment
/// <reference path="../../permission/typedef.js" />

import Vue from 'vue'

import { localRoutes } from '@/router'
import generateRolePermissions from '@/permission'
import { isArray } from '@/utils/validate'
import { setPm, getPm } from '@/storage/session/permission'
import { getRoleMenu, setRoleMenu } from '@/utils/auth/user'
/**
 * @returns 角色权限状态
 */
const state = () => ({
  list: [], // 当前角色所有的权限列表
  idIndex: {}, // 当前角色所有的权限的 ID 索引集
  nameIndex: {}, // 当前角色所有权限的 Name 索引集
  menus: [], // 当前角色的所有菜单 (本地固定路由 localRoutes + 本地条件判断添加的路由 createdRoutes + 远程鉴权动态获取路由 remoteRoutes)
  addMenus: [], // 当前角色从服务端远程鉴权获取的菜单（本地条件判断添加的路由 createdRoutes + remoteRoutes）
  /**
   * @type {ResourcePidIndexes}
   */
  resources: {
    navs: {}, // PID 索引：当前角色资源权限之树形导航（如某些页面的tab route、tree navs等）
    btns: {} // PID 索引：当前角色资源权限之按钮操作（如新增、删除按钮、表格操作列等）
  }
})

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list
  },
  INCREASE_ITEM: (state, item) => {
    state.list.push(item)
  },
  SET_ID_INDEX: (state, idIndex) => {
    state.idIndex = idIndex
  },
  SET_NAME_INDEX: (state, nameIndex) => {
    state.nameIndex = nameIndex
  },
  SET_MENUS: (state, dynamicRoutes) => {
    state.addMenus = dynamicRoutes
    state.menus = localRoutes.concat(dynamicRoutes)
  },
  SET_RESOURCES: (state, resources) => {
    Object.keys(resources).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(state.resources, key)) {
        state.resources[key] = resources[key]
      } else {
        console.error(
          'Losed key:' + key + ' for "SET_RESOURCES" that on state.resources'
        )
      }
    })
  }
}

const actions = {
  /**
   * @returns {{menus:Array<import('vue-router').RouteConfig>}}
   */
  async init({ commit }) {
    try {
      const data = await getRoleMenu()

      setRoleMenu(data)

      const { idIndex, nameIndex, menus, resources } =
        await generateRolePermissions(data, 'create')
      commit('SET_MENUS', menus)
      commit('SET_ID_INDEX', idIndex)
      commit('SET_NAME_INDEX', nameIndex)
      commit('SET_RESOURCES', resources)
      return { menus }
    } catch (error) {
      console.error(error)
      return {}
    }
  },
  /**
   * @returns {{menus:Array<import('vue-router').RouteConfig>}}
   */
  async add({ commit }, permissions) {
    try {
      const { idIndex, nameIndex, menus, resources } =
        await generateRolePermissions(permissions, 'append')
      commit('SET_MENUS', menus)
      commit('SET_ID_INDEX', idIndex)
      commit('SET_NAME_INDEX', nameIndex)
      commit('SET_RESOURCES', resources)
      return { menus }
    } catch (error) {
      console.error(error)
    }
    return {}
  },
  /**
   * setSource
   * 初始化权限源
   */
  async setSource({ commit, dispatch, rootGetters }, list) {
    commit('SET_LIST', list)

    // 根据用户 ID 在 session 中查找临时动态添加的权限，同步到源 state.list
    const tempPm = getPm(rootGetters.id)
    if (isArray(tempPm) && tempPm.length) {
      await dispatch('increaseSource', tempPm)
    }
  },
  /**
   * increaseSource
   * - 初始化时， 从 session 中同步追加权限到源
   * - 运行时，   动态追加权限到源并同步到 session
   */
  increaseSource({ commit, state, rootGetters }, items) {
    const currStoreSources = state.list.map((v) => v.id)
    const notExistInStore = items.filter(
      (item) => !currStoreSources.includes(item.id)
    )
    notExistInStore.length
      ? commit('SET_LIST', [...state.list, ...notExistInStore])
      : null

    // 动态添加的权限，同步到 session 中，保证刷新正常
    Vue.nextTick(() => void setPm(rootGetters.id, items))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
