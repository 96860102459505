/**
 * @module utils/auth/name
 */
import { get, set, remove } from '@/storage'
const STORAGE_KEY = 'ASR-SCM-SYS-AUTH-NAME'

export function getName() {
  return get('session', STORAGE_KEY)
}

export function setName(name) {
  set('session', STORAGE_KEY, name)
}

export function removeName() {
  remove('session', STORAGE_KEY)
}
