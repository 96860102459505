import { optionsCommonDisabler, optionsCommonFilter } from '@/utils/options'
import { isPlainObject, isString } from '@/utils/validate'
import { isDetailPage } from '@/utils/page-type'

import * as webAutoSelectRemoteApi from '@/service/api-web/auto-complete'
import * as scmAutoSelectRemoteApi from '@/service/api-scm/auto-complete'
// import * as externalAutoSelectRemoteApi from '@/service/api-external/auto-complete'
// import * as activitiAutoSelectRemoteApi from '@/service/api-activiti/auto-complete'

const autoSelectRemoteApis = {
  web: webAutoSelectRemoteApi,
  scm: scmAutoSelectRemoteApi
  // external: externalAutoSelectRemoteApi
  // activiti: activitiAutoSelectRemoteApi
}
/**
 * @param {string} query 远程查询筛选内容
 * @param {boolean} [useForAgel=false] 是否代理 agle form 值得突变，一般 在 agle-form item 直接配置时使用的时候使用，需要 agel-form 的信息支持
 * @param {string=} [refName=''] 对应 agel-form ref name， useForAgel 为 true 使用
 * @param {string} [formName=''] 对应 agel-form model name， useForAgel 为 true 使用
 * @param {string} prop 对应 agel-form items 项中的 prop 字段名， useForAgel 为 true 使用
 * @param {('web'|'scm'|'activiti'|'external')} service  服务名
 * @param {string} api 请求方法名
 * @param {string} key 请求 param 字段
 * @param {(object|function)=} payload 额外的请求参数 可选值
 * @param {object=} [mapper={value:'id',label:'name'}]  映射返回数据的 value
 * @param {(1|2|3)} [filterType=1] 过滤类型
 * @param {(1|2|3)} [disableType=1] 禁用类型
 * @see optionsCommonFilter
 * @see optionsCommonDisabler
 */
export const autoSelectRemoteFunc = async function ({
  query,
  useForAgel = false,
  refName = '',
  formName = '',
  prop = '',
  service,
  api,
  key,
  mapper = { value: 'id', label: 'name' },
  payload = {},
  disableType = 1,
  filterType = 1
}) {
  let options = []

  let vm = null
  let item = null

  try {
    console.groupCollapsed('【autoSelectRemoteFunc】')

    if (!query) return options
    console.log('query:', query)

    if (!['web', 'scm', 'activiti', 'external'].includes(service)) {
      console.error('【autoSelectRemoteFunc】illegal "service"', service)
      return options
    }
    console.log('service:', service)

    if (!isString(api) || (isString(api) && !api.trim())) {
      console.error('【autoSelectRemoteFunc】illegal "api"', api)
      return options
    }
    console.log('api:', api)
    const reqFunc = autoSelectRemoteApis[service][api]
    if (typeof reqFunc !== 'function') {
      console.error(
        '【autoSelectRemoteFunc】losed "reqFunc"',
        autoSelectRemoteApis,
        service,
        api
      )
      return options
    }
    /**
     * 请求参数名
     */
    if (!isString(key) || (isString(key) && !key.trim())) {
      console.error('【autoSelectRemoteFunc】illegal param "key":', key)
      return options
    }
    console.log('key:', key)
    /**
     * 请求额外参数
     */
    let _payload = isPlainObject(payload)
      ? { ...payload }
      : typeof payload === 'function'
      ? payload()
      : {}
    _payload = isPlainObject(_payload) ? _payload : {}
    console.log('payload:', _payload)

    console.log('useForAgel:', useForAgel)
    if (useForAgel === true) {
      if (!refName) {
        console.warn('【autoSelectRemoteFunc】useForAgel without "refName"')
      }
      if (!isString(refName)) {
        console.error(
          '【autoSelectRemoteFunc】useForAgel with illegal "refName":',
          refName
        )
        return options
      }
      console.log('refName:', refName)

      if (!formName) {
        console.error('【autoSelectRemoteFunc】useForAgel without "formName"')
        return options
      }
      if (!isString(formName) || (isString(formName) && !formName.trim())) {
        console.error(
          '【autoSelectRemoteFunc】useForAgel with illegal "formName":',
          formName
        )
        return options
      }
      console.log('formName:', formName)
      if (!prop) {
        console.error('【autoSelectRemoteFunc】useForAgel without "prop"')
        return options
      }

      if (!isString(prop) || (isString(prop) && !prop.trim())) {
        console.error(
          '【autoSelectRemoteFunc】useForAgel with illegal "prop":',
          prop
        )
        return options
      }
      console.log('prop:', prop)
      vm = refName
        ? this?.$refs?.[refName]?.[formName]?.getRef(prop)
        : this?.[formName]?.getRef(prop)
      if (!vm) {
        console.error(
          '【autoSelectRemoteFunc】useForAgel losed "vm"',
          refName,
          formName,
          prop
        )
        return options
      }
      if (vm) vm.optionsLoading = true
      console.log('vm:', vm)
      item = refName
        ? this?.$refs?.[refName]?.[formName]?.getItem(prop)
        : this?.[formName]?.getItem(prop)
      if (!item) {
        console.error(
          '【autoSelectRemoteFunc】useForAgel losed "item"',
          refName,
          formName,
          prop
        )
        return options
      }
      console.log('item:', item)
    }
    const { data } = await autoSelectRemoteApis[service][api]({
      [key]: query,
      ..._payload
    })
    console.log('data:', data)
    console.log('mapper:', mapper)
    console.log('disableType:', disableType)
    console.log('filterType:', filterType)
    const _data =
      data && Array.isArray(data) && data.length > 0
        ? data.map((item) => ({
            ...item,
            value: item[mapper?.value || 'id'],
            label: item[mapper?.label || 'name']
          }))
        : []
    options = isDetailPage(this.$route.name)
      ? optionsCommonDisabler(_data, disableType ?? 1)
      : optionsCommonFilter(_data, filterType ?? 1)
    console.log(
      `最终结果(按需${isDetailPage ? '禁用' : '隐藏'}一些选项后):`,
      options
    )
    if (item) item.options = options
    return options
  } catch (error) {
    console.error(error)
  } finally {
    console.groupEnd()
    if (vm?.optionsLoading === true) vm.optionsLoading = false
  }
}
export default autoSelectRemoteFunc
