/**
 * @module api-web:sysMenu
 */
import service from '@/service'
// import { createUniqueString } from '@/utils/string'

/**
 * 查询
 * @param {{dictType:string,limit:number,page:number,dictName:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/sys/sysMenu/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 增加
 * @param {{sysDictAddDTO:object}} data
 */
export function add(data) {
  return service['api-web']({
    url: '/sys/sysMenu/save',
    method: 'post',
    data
  })
}

/**
 * @param {{jobId:number,jobName:string}} data
 * @description 修改
 */
export function update(data) {
  return service['api-web']({
    url: '/sys/sysMenu/update',
    method: 'put',
    data
  })
}
/* 删除 */
export function remove(params) {
  return service['api-web']({
    url: '/sys/sysMenu/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
