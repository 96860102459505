var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-bar-sticky-container",style:({ height: _vm.calcHeight, zIndex: _vm.zIndex })},[_c('div',{class:[_vm.className, 'title-bar-sticky-wrapper'],style:({
      top: _vm.isSticky ? _vm.stickyTop + 'px' : '',
      zIndex: _vm.zIndex,
      position: _vm.position,
      width: _vm.width,
      height: _vm.calcHeight
    })},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }