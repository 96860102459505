import service from '@/service'

/**
 * 批改日志列表查询
 * @param {params} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/data/markingLog/getMarkingLogList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
