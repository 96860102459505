<template>
  <div class="filter-query-form-buttons">
    <slot name="prepend" />
    <el-button
      type="primary"
      :loading="queryButtonLoading"
      @click="$emit('query', $event)"
    >
      {{ queryButtonText }}
    </el-button>
    <el-button :loading="resetButtonLoading" @click="$emit('reset', $event)">
      {{ resetButtonText }}
    </el-button>
    <slot name="append" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FilterQueryFormButtons',
  props: {
    queryButtonText: {
      type: String,
      default: 'query' // 为了凸显按钮权限文案与本地文案的区别，默认值使用 'query'
    },
    resetButtonText: {
      type: String,
      default: '重置'
    },
    queryButtonLoading: {
      type: Boolean,
      default: false
    },
    resetButtonLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped></style>
