/**
 * @module api-web:financialAffairs/accountPayable/receiptManualOn
 */
import service from '@/service'
// 应付付款过滤-应付过滤
export function getPayablePaymentFilterList(params) {
  return service['api-web']({
    url: '/fin/ap/finPayableManualVerification/public/getPayablePaymentFilterList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// 应付付款过滤-付款过滤
export function getReceiptFilterList(params) {
  return service['api-web']({
    url: '/fin/ap/finPayableManualVerification/public/getPaymentFilterList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// // 应收收款过滤-收款单过滤
// export function getReceiptOrderList(params) {
//   return service['api-web']({
//     url: '/fin/ar/finReceivableManualVerification/public/getReceiptOrderList',
//     method: 'get',
//     params,
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }
// 手动匹配核销
export function chargeOff(data) {
  return service['api-web']({
    url: '/fin/ap/finPaymentVerificationSheet/save',
    method: 'post',
    data
  })
}
