/**
 * @module api-web:financialAffairs/generalLedger/data/accountingCalendar
 */
import service from '@/service'

/**
 * 查询 财务-总账-资料-会计日历
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountCalendar/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 详情
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountCalendar/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 新增
 * @param {object} data
 * @returns {object}
 */
export function createDetail(data) {
  return service['api-web']({
    url: '',
    method: 'post',
    data
  })
}
/**
 * 修改
 * @param {object} data
 * @returns {object}
 */
export function updateDetail(data) {
  return service['api-scm']({
    url: '',
    method: 'put',
    data
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function removeDetail(params) {
  return service['api-web']({
    url: '',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
