/**
 * @module api-scm:logisticsBasicInformation
 */
import service from '@/service'

/**
 * @typedef {Object} logisticsBasicInformation -- 物流基础信息列表
 * @property {number=} [limit=1] -
 * @property {number} [page=10] - 页码
 * @property {string} sort -
 * @property {string} companyName - 物流公司
 * @property {number} isMonthly - 预付
 * @property {number} businessState - 业务状态
 * @property {string} purchaseCode - 单据编号
 * @property {string} logisticsOrder - 物流单号
 * @property {string} paymentTimeStart - 下单时间起始
 * @property {string} paymentTimeEnd - 下单时间结束
 *
 * @param {logisticsBasicInformation}
 */

export function getList(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/logisticsBasicInformation/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/logisticsBasicInformation/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(number|string)} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 作废
 * @param {(string|number)} id
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下推
 */
export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/pushDown',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  对账
 * @param {string} ids
 */
export function reconciliation(ids) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/reconciliation',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsBasicInformation/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *根据物流单号与关联单号查询是否已存在关联(true-存在，false-不存在)
 * @param {Obejct} params
 * @returns {Boolean}
 */
export function compareByLogisticAndAssociateCode(params) {
  return service['api-scm']({
    url: '/public/logistics/compareByLogisticAndAssociateCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *根据物流公司编码以及结算组织id获取物流商关联使用组织的结算方式
 * @param {Obejct} params
 * @returns {Obejct}
 */
export function getSettlementMethod(params) {
  return service['api-scm']({
    url: '/public/logistics/getSettlementMethod',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
