// eslint-disable-next-line spaced-comment
/// <reference path="../../typedef.js" />
/**
 * @module permission/menus/routes/configs
 */
import {
  hidden,
  alwaysShow,
  noTag,
  noCache,
  noBreadcrumb,
  affix,
  fixedHeight,
  disabled
} from '@/router/config'
import { isString } from '@/utils/validate'
import { recursiveParents } from '@/permission/utils'
import { validPath } from '../../utils'
/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {string} route.meta.namespace for resource permission control
 */
function generateNamespace(node, indexTable) {
  let namespace = ''
  try {
    // namespace =
    //   indexTable.idIndex[
    //     typeof node.pid === 'number' && node.pid >= 0 ? node.pid : node.id
    //   ].pmUrl
    const { pmType, pmUrl } = node
    const sliced = pmUrl
      .replace('api-', '') // remove 'api-' of api-web,api-activiti,api-scm,...
      .replace(/\{.*?\}\s?/g, '') // remove like {id},...
      .split('/')
      // .slice(2)
      .filter((v) => !!v && v !== '**') // remove '/' and '**'
    namespace = sliced.slice(0, pmType > 2 ? 2 - pmType : sliced.length) // if resource, remove self, just keep parent
    // console.log(namespace)
    if (namespace.length) {
      const [serviceName, ...rest] = namespace
      if (namespace.length === 1) namespace = serviceName
      else namespace = `${serviceName}:${rest.join('.')}` // {{服务名}}:{{pmUrl.join('.')}}
    }
  } catch (error) {
    console.error(error)
  }
  return namespace
}
/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {number} route.meta.idspace for resource permission control
 */
function generateIdspace(node, indexTable) {
  let idspace
  try {
    // idspace = typeof node.pid === 'number' && node.pid >= 0 ? node.pid : node.id
    const { pmType, id } = node
    const recursiveResult = recursiveParents(indexTable, node, [id], 'id')
    idspace =
      recursiveResult
        .slice(0, pmType > 2 ? 2 - pmType : recursiveResult.length)
        .slice(-1)[0] || id
  } catch (error) {
    console.error(error)
  }
  return idspace
}

/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {string} route.meta.menuspace for nested navs control
 */
function generateMenuspace(node, indexTable) {
  let menuspace = ''
  if (node.isNestedNav) {
    const recursiveResult = recursiveParents(
      indexTable,
      node,
      [],
      'id',
      (item) => !item.isNestedNav
    )
    menuspace = recursiveResult.slice(-1)[0]
  }
  return menuspace
}

/**
 * @typedef {Object} ProcessOptions
 * @property {string=} origin 原字符串
 * @property {string=} before 前置拼接的字符串
 * @property {string=} after 后置拼接的字符串
 * @property {{from:string,to:string}=} replace 替换 from 字符串 到 to 字符串
 *
 * @param {ProcessOptions} processOptions
 * @returns {string}
 */
function processString(processOptions) {
  if (!isString(processOptions.origin)) {
    console.error('processString: please check "orgin"')
    return processOptions.origin
  }

  try {
    const {
      origin = '',
      before = '',
      after = '',
      replace = { from: '', to: '' }
    } = processOptions
    return `${before}${origin.replace(replace.from, replace.to)}${after}`
  } catch (error) {
    console.error(error)
  }
}
/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {string} 路由 title of meta
 *
 * @description
 * - 当 "getList 查询" 资源作为菜单，则改为父级+列表; 如：用户管理下的查询作为菜单时，查询重命名为“用户列表”
 * - 当 "info 详情" 资源作为菜单，则改为父级+详情; 如：用户管理下的详情作为hidden菜单时，查询重命名为“用户详情”
 */
function generateTitle(node, indexTable) {
  let title
  try {
    const { pmType, path, pid, label } = node
    switch (true) {
      case pmType > 2 && validPath(path): {
        switch (label) {
          case '查询':
          case '列表':
          case '新增':
          case '上传':
          case '修改':
          case '编辑':
          case '详情': {
            if (node.isNestedNav) {
              const recursiveResult = recursiveParents(
                indexTable,
                node,
                [label],
                'label'
              )
              const rmFirstAndLast = recursiveResult.slice(1, -1)
              title = rmFirstAndLast
                .slice(rmFirstAndLast.length - 2)
                .join(' - ')
            } else {
              const parentLabel = indexTable.idIndex[pid].label
              if (isString(parentLabel) && parentLabel) {
                if (
                  parentLabel.includes('管理') ||
                  parentLabel.includes('列表')
                ) {
                  switch (label) {
                    case '查询': {
                      title = processString({
                        origin: parentLabel,
                        replace: { from: '管理', to: '列表' }
                      })
                      break
                    }
                    case '列表': {
                      title = processString({
                        origin: processString({
                          origin: parentLabel,
                          after: label
                        }),
                        replace: { from: '管理', to: '' }
                      })
                      break
                    }
                    case '详情': {
                      title = processString({
                        origin: processString({
                          origin: parentLabel,
                          replace: { from: '管理', to: label }
                        }),
                        replace: { from: '列表', to: label }
                      })
                      break
                    }
                    case '编辑':
                    case '更新':
                    case '修改': {
                      title = processString({
                        origin: processString({
                          origin: processString({
                            origin: parentLabel,
                            replace: { from: '管理', to: '' }
                          }),
                          replace: { from: '列表', to: '' }
                        }),
                        before: '编辑'
                      })
                      break
                    }
                    case '新增':
                    case '上传': {
                      title = processString({
                        origin: processString({
                          origin: processString({
                            origin: parentLabel,
                            replace: { from: '管理', to: '' }
                          }),
                          replace: { from: '列表', to: '' }
                        }),
                        before: '创建'
                      })
                      break
                    }
                  }
                } else {
                  switch (label) {
                    case '查询': {
                      title = processString({
                        origin: parentLabel,
                        after: '列表'
                      })
                      break
                    }
                    case '列表': {
                      title = processString({
                        origin: parentLabel,
                        after: label
                      })
                      break
                    }
                    case '详情': {
                      title = processString({
                        origin: parentLabel,
                        after: label
                      })
                      break
                    }
                    case '编辑':
                    case '更新':
                    case '修改': {
                      title = processString({
                        origin: parentLabel,
                        before: '编辑'
                      })
                      break
                    }
                    case '新增':
                    case '上传': {
                      title = processString({
                        origin: parentLabel,
                        before: '创建'
                      })
                      break
                    }
                  }
                }
              }
            }
            break
          }
          // case 'xxx': {
          //   // ...
          //   break
          // }
          default:
            title = label
            break
        }
        break
      }
      // case xxx : {
      // // ...
      //   break
      // }
      default:
        title = label
        break
    }
  } catch (error) {
    console.error(error)
  }
  return title
}

/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {string} route.meta.menuspace for nested navs control
 */
function generateActiveMenu(node, indexTable) {
  let recursiveResult = ''
  try {
    const { isNestedNav, path } = node
    if (isNestedNav) {
      recursiveResult = recursiveParents(indexTable, node, [])
        .filter((v) => v.pmType <= 3 && v.pmType >= 0 && !v.isNestedNav)
        .map((v) => v.path)
        .join('/')
      return `/${recursiveResult}`
    } else {
      if (
        path === 'detail/:id' ||
        path === 'liaison/:id' ||
        path === 'processDesign/:id' ||
        path === 'create'
      ) {
        recursiveResult = recursiveParents(
          indexTable,
          node,
          ['list/:id'],
          'path'
        )
          .filter((v) => v !== '/')
          .join('/')
      }
    }
  } catch (error) {
    console.error(error)
  }

  return `/${recursiveResult}`
}
/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @param {(string|null)} recursivePath
 * @returns {string} 路由配置参数, see "vue-element-admin"
 */
export function generateConfigs(node, indexTable, recursivePath) {
  try {
    const {
      icon,
      id,
      pmType,
      path,
      isNestedNav,
      pid,
      affix: nodeAffix = undefined,
      hidden: nodeHidden = undefined,
      breadcrumb: nodeBreadcrumb = undefined,
      noTag: nodeNoTag = undefined,
      onCache: nodeNoCache = undefined
    } = node
    const label = generateTitle(node, indexTable)
    const configs = {
      id,
      label,
      hidden:
        typeof nodeHidden === 'boolean' ? nodeHidden : hidden.includes(id),
      alwaysShow: alwaysShow.includes(id)
    }
    const meta = {
      id,
      pid,
      icon,
      title: label,
      depth: pmType,
      fullpath: `/${recursivePath || path}`,
      idspace: generateIdspace(node, indexTable),
      namespace: generateNamespace(node, indexTable),
      menuspace: generateMenuspace(node, indexTable),
      affix: typeof nodeAffix === 'boolean' ? nodeAffix : affix.includes(id),
      noTag: typeof nodeNoTag === 'boolean' ? nodeNoTag : noTag.includes(id),
      noCache:
        typeof nodeNoCache === 'boolean' ? nodeNoCache : noCache.includes(id),
      activeMenu: generateActiveMenu(node, indexTable),
      breadcrumb:
        typeof nodeBreadcrumb === 'boolean'
          ? nodeBreadcrumb
          : (path !== 'list/:id' &&
              path !== 'create' &&
              path !== 'detail/:id' &&
              path !== 'liaison/:id' &&
              path !== 'processDesign/:id') ||
            noBreadcrumb.includes(id),
      fixedHeight: fixedHeight.includes(id) ? true : undefined,
      disabled: disabled.includes(id),
      isNestedNav
    }
    return { ...configs, meta }
  } catch (error) {
    console.error(error)
    return {
      hidden: true
    }
  }
}

export default generateConfigs
