/**
 * @module api-web:log
 */
import service from '@/service'

/**
 * 查询系统日志
 * @param {{keywords:string,limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/sys/sysLog/getList',
    method: 'get',
    params
  })
}
