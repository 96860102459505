/**
 * @module api-web:financialAffairs/generalLedger/data/constItem
 */
import service from '@/service'

/**
 * 查询 财务-总账-资料-费用项目
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/data/finCostItem/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情 财务-总账-资料-费用项目
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/gl/data/finCostItem/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 创建 财务-总账-资料-费用项目
 * @param {object} data
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/gl/data/finCostItem/save',
    method: 'post',
    data
  })
}

/**
 * 修改 财务-总账-资料-费用项目
 * @param {Department} data
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/gl/data/finCostItem/update',
    method: 'put',
    data
  })
}

/**
 * 删除 财务-总账-资料-费用项目
 * @param {{ids:Array<number>}} params - id
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/gl/data/finCostItem/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 关联sku 财务-总账-资料-费用项目
 * @param {{id:(string|number),skuCode:string,usePurchase:(0|1),useSales:(0|1)}} data - UpdateCostItemDTO
 *
 * - usePurchase	采购支出是否使用 1是 0否 default:1
 * - useSales	    销售收入是否使用 1是 0否 default:1
 */
export function updateCostItemLinkSku(data) {
  return service['api-web']({
    url: '/fin/gl/data/finCostItem/updateCostItemShow',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}
