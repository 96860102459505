/**
 * @module api-scm:inventoryCheckout
 */
import service from '@/service'

/**
 *
 * @param {object} params
 * @returns
 * @description 查询 仓库-库存关账
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryCheckout/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {object} data
 * @returns
 * @description 反结账 仓库-库存关账
 */
export function reverseCheckout(data) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryCheckout/reverseCheckout',
    method: 'post',
    data
  })
}

/**
 *
 * @param {object} data
 * @returns
 * @description 结账 仓库-库存关账
 */
export function checkout(data) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryCheckout/checkout',
    method: 'post',
    data
  })
}
