/**
 * @module api-web:financialAffairs/accountPayable/finCashierClosing
 * @description 财务管理/应付/出纳结账
 */
import service from '@/service'

/**
 * @description 查询 列表
 */
export function getList() {
  return service['api-web']({
    url: '/fin/ap/finCashierClosing/findCashierClosingList',
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 关账
 */
export function closing(data) {
  return service['api-web']({
    url: '/fin/ap/finCashierClosing/closing',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 反关账
 */
export function contraryClosing(data) {
  return service['api-web']({
    url: '/fin/ap/finCashierClosing/contraryClosing',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
