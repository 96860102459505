import store from '@/store'
import { getDict } from '@/utils/request'
import { isString, isArray } from '@/utils/validate'
import { logErr } from './utils'
import { getItem, getItemSync } from './item'
import { getLabel, getLabelSync, getAllLabel, getAllLabelSync } from './label'
import { getValue, getValueSync, getAllValue, getAllValueSync } from './value'
/**
 * @typedef {import('../request').DictItem} DictItem
 * @typedef {Array<(DictItem|undefined)>} Dict
 *
 * @typedef {import('../request').GetDictPayload} GetDictPayload
 */
/**
 * get dict array async, if local missed, get via action
 * @param {(string|Array<string>)} from - e.g: 'sys.shoppingStatus' or [ 'sys.shoppingStatus',  'sys.processNodeStatus']
 * @param {GetDictPayload} payload @see /src/store/dict
 * @returns {Promise<(Dict|Array<Dict>|Boolean)>}
 * @description 初始化获取字典项列表
 */
export async function dict(from, payload = {}) {
  try {
    let _from = null
    if (!isArray(from)) {
      if (isString(from)) {
        _from = [from]
      } else {
        logErr(['illegal "from" for get dictionary', from])
        return false
      }
    }
    return await store.dispatch(
      'dict/get',
      (_from || from).map((f) => {
        const query = extractQueryParams(f)
        const [category, name] = f.split('?')[0].split('.')
        return { category, name, query, ...payload }
      })
    )
  } catch (error) {
    logErr([error, arguments])
    return false
  }
}
/**
 * @param {*} from
 * @param {*} startIndex
 * @param {*} endIndex
 * @param {*} payload
 * @returns
 * 字典切片
 */
async function getSub(from, startIndex, endIndex, payload = {}) {
  return (await dict(from, payload)).slice(startIndex, endIndex)
}
/**
 * 字典为了兼容大量历史接口，get 和 getSync 对 value 字段进行类型转换，将数字字符串转成数字
 * @description 此 getNTC 不转换 数据类型
 */
async function getNTC(from, payload = {}) {
  return await dict(from, { ...payload, tc: false })
}
async function getSubNTC(from, startIndex, endIndex, payload = {}) {
  return (await getNTC(from, payload)).slice(startIndex, endIndex)
}

/**
 * get dict array sync via getters
 * @param {(string|Array<string>)} from - e.g: 'sys.shoppingStatus' or [ 'sys.shoppingStatus',  'sys.processNodeStatus']
 * @returns {(Dict|Array<Dict>|Boolean)}
 * @description 获取字典同步， 须初始化字典项列表
 */
export function getSync(from) {
  try {
    let _from = null
    if (!isArray(from)) {
      if (isString(from)) {
        _from = [from]
      } else {
        logErr(['illegal "from" for get dictionary (sync)', from])
        return false
      }
    }
    const result = (_from || from).map((f) => {
      const [category, name] = f.split('.')
      const dict = store.state.dict[category]?.[name]
      if (!isArray(dict)) {
        store.commit('dict/SET_DICT', { category, key: name, value: [] })
      }
      return store.state.dict[category][name]
    })

    return result.length === 1 ? result[0] : result
  } catch (error) {
    logErr([error, from])
    return false
  }
}

function extractQueryParams(dictPath) {
  const queryParams = {}

  // Extract the query string from the URL
  const queryString = dictPath.split('?')[1]

  // Check if there are query parameters
  if (queryString) {
    // Split the query string into an array of key-value pairs
    const paramPairs = queryString.split('&')

    // Iterate over each key-value pair
    for (const pair of paramPairs) {
      // Split the pair into key and value
      const [key, value] = pair.split('=')

      // Decode the key and value to handle URL encoding
      const decodedKey = decodeURIComponent(key)
      const decodedValue = decodeURIComponent(value)

      // Store the key-value pair in the queryParams object
      queryParams[decodedKey] = decodedValue
    }
  }

  return queryParams
}

dict.request = getDict // only the network request for get dict array

dict.get = dict // get dict array async, if local missed, get via action
dict.getSync = getSync // get dict array sync via state
dict.getNTC = getNTC // get dict array, not type conversion
dict.getSub = getSub // get part of dict array
dict.getSubNTC = getSubNTC // get part of dict array, not type conversion

dict.getItem = getItem // get item by label or value async
dict.getItemSync = getItemSync // get value sync via store/getters

dict.getValue = getValue // get value async, if local missed, get via action
dict.getValueSync = getValueSync // get value sync via getters
dict.getAllValue = getAllValue // get all values async, if local missed, init dict array via action
dict.getAllValueSync = getAllValueSync // get all values sync via getters

dict.getLabel = getLabel // get label async, if local missed, get via action
dict.getLabelSync = getLabelSync // get label sync via getters
dict.cfmt = (...args) =>
  getLabelSync(...args) || (args.at(-1) === undefined ? '⏤' : args.at(-1)) // cell format， 表格单元格 label 映射专属, 多一个 - 占位符， - 为默认占位符，最后一个参数为自定义占位符
dict.getAllLabel = getAllLabel // get all labels async, if local missed, init dict array via action
dict.getAllLabelSync = getAllLabelSync // get all labels sync via getters
dict.format = (value, source) => {
  if (!source || !Array.isArray(source)) return value

  return source.find((item) => item.value === value)?.label ?? '⏤'
}

export default dict
