<template>
  <el-tabs v-model="activeName" class="my-el-tabs" type="card">
    <el-tab-pane v-if="showFormTab === true" :label="tabs.form" name="first">
      <BlueTitleText v-if="showFormTitle" :title-name="titles.form" />
      <agel-form
        v-model="model.form"
        :attach="{ items: formItems }"
        class="agel-input-audit-reset"
      />
    </el-tab-pane>
    <el-tab-pane
      v-if="showTableTab && !showTableAlone"
      :label="tabs.table"
      name="second"
      :lazy="showFormTab === true"
    >
      <BlueTitleText v-if="showTableTitle" :title-name="titles.table" />
      <base-inline-edit-table
        ref="backlog-audit-process-node-table"
        drag-column-key="BacklogAuditTabFullTabs"
        table-key="backlog-audit-process-node-table"
        :crud-local="['read']"
        :has-tool-bar="false"
        :disable-operate="true"
        :table-header="tableHeader"
        :table-loading.sync="model.table.loading"
        :value="model.table.data"
        cell-placeholder="⏤"
        external-class="backlog-audit-tab-biet"
      >
        <template #stateDefault="{ row }">
          <LabelPickerColorCircleMark
            dict="sys.processNodeStatus"
            :value="row.state?.value"
            :color="{
              1: 'danger', // 驳回
              0: 'warning', // 当前
              '-1': 'success' // 完成
            }"
          />
        </template>
        <template #durationDefault="{ row }">
          <span>{{ processDuration(row) }}</span>
        </template>
      </base-inline-edit-table>
    </el-tab-pane>
    <el-tab-pane
      v-if="showDiagramTab"
      :label="tabs.diagram"
      name="third"
      :lazy="showFormTab === true || showTableTab === true"
    >
      <BlueTitleText v-if="showDiagramTitle" :title-name="titles.diagram" />

      <ProcessDesign
        v-bind="model.diagram"
        :instance-id="model.diagram.instanceId"
        :document-code="documentCode"
        :process-submitter="processSubmitter"
        :dispaly="activeName === 'third'"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import mixin from './mixin'
import BlueTitleText from '@/components/BlueTitleText'
import LabelPickerColorCircleMark from '@/components/LabelPickerColorCircleMark'
import ProcessDesign from '@/components/ProcessDesign'
export default {
  /**
   * @description 通用审核处理
   * TAB1 表单：
   *    - radio: 重新提交/审核通过/驳回
   *    - textarea: 备注描述
   * TAB2 表格：流程流传历史记录
   * TAB3 流程图： 流程实例
   */
  name: 'BacklogAuditTabFullTabs',
  components: {
    BlueTitleText,
    LabelPickerColorCircleMark,
    ProcessDesign
  },
  mixins: [mixin],
  props: {
    // 将处理环节拆出来，单独显示, 见需求2642
    showTableAlone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  computed: {
    showFormTab() {
      return this.layout.split('|').includes('form')
    },
    showTableTab() {
      return this.layout.split('|').includes('table')
    },
    showDiagramTab() {
      return this.layout.split('|').includes('diagram')
    }
  },
  watch: {
    showFormTab: {
      immediate: true,
      handler: 'setActiveName'
    },
    activeName: {
      immediate: true,
      handler: 'onTabChange'
    }
  },
  methods: {
    setActiveName() {
      this.activeName =
        this.showFormTab === true
          ? 'first'
          : this.showTableTab === true
          ? 'second'
          : this.showDiagramTab === true
          ? 'third'
          : ''
    },
    onTabChange(activeName) {
      this.model.diagram.instanceId = ''

      switch (activeName) {
        case 'first': {
          console.log('show process handle form')
          break
        }
        case 'second': {
          this.generateProcessHistoryTable()
          break
        }
        case 'third': {
          console.log('show process instance diagram')
          if (this.processId) {
            this.model.diagram.instanceId = this.processId
          }
          break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
