/**
 * @module api-scm:auto-complete
 */
import service from '@/service'

/**
 * @param {*} params
 * @description 价目表
 */
export function getPurchasePriceList(params) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/selectPurchasePrice',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {*} params
 * @description 采购调价函详情明细填写商品时带出相关信息
 */
export function getChangePriceBillBeforDetail(params) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/getBeforDetail',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 获取此采购订单下的所有商品
export function getPurchaseProduct(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseApplyReturn/get/getPurchaseProduct',
    method: 'get',
    params
  })
}

/**
 * 远程搜索订单 销售单号
 */
export function getOrderInfoByCode(params) {
  return service['api-scm']({
    url: '/public/order/getOrderInfoByCode',
    method: 'get',
    params
  })
}

/**
 * 根据销售单号或平台编码，获取对应关联
 */
export function getOrderOrPlatformCode(params) {
  return service['api-scm']({
    url: '/public/order/getOrderOrPlatformCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 物流商模糊查询
 */
export function getLogisticsInfoPullList(params) {
  return service['api-scm']({
    url: '/public/logistics/getLogisticsInfoPullList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 远程搜索库位代码
 */
export function getInventoryList(params) {
  return service['api-scm']({
    url: '/public/inventory/getAllInventoryCodeByProductAndWarehouseCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *   远程搜索批次号
 */
export function getBeatchList(params) {
  return service['api-scm']({
    url: '/public/inventory/getAllInventoryProductInfoByDetailId',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {*} params
 * @returns 根据调出仓库、调出货主、商品编码、库位代码、批次号获取 库存数量以及可配数
 * @see  supplyChain/warehouse/warehouseAllot/inline-edit-tables/index.js
 */
export function getAllInventoryProductInfoByBatchCode(params) {
  return service['api-scm']({
    url: '/public/inventory/getAllInventoryProductInfoByBatchCode',
    method: 'get',
    params
  })
}

/**
 * 物流查询
 */
export function getLogisticsInfo(params) {
  return service['api-scm']({
    url: '/public/logistics/getLogisticsInfo',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 订单单号远程搜索
 */
export function getOrderByCode(params) {
  return service['api-scm']({
    url: '/public/order/getOrderByCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// /**
//  *
//  * @param {number} pageNum -条数
//  * @param {number} pageSize -页码
//  * @param {string} warehouseCode -仓库
//  * @returns {Promise<Array>}
//  * @description  根据仓库、查询指定仓库下的商品(sku)
//  */
// export function selectWarehouseSubProduct(params) {
//   return service['api-scm']({
//     url: '/scm/warehouse/warehouseLock/selectWarehouseSubProduct',
//     method: 'get',
//     params
//   })
// }
