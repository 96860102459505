/**
 * @module api-web:businessTodo/interiorLiaison
 * @description 首页/联络单待办
 * @deprecated 产品需求废弃了联络单待办
 * @see #897 禅道需求 废弃联络单待办
 */
import service from '@/service'

/**
 *  联络单待办 拾取任务
 * @param {string} id
 * @deprecated 后端处理拾取，前端不再需要调用拾取接口
 */
export function claim(id) {
  return service['api-web']({
    url: '/index/public/interiorLiaison/pickup',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 联络单待办 保存
 * @param {object} data
 */
export function saveLiaisonRecord(data) {
  return service['api-web']({
    url: '/index/public/interiorLiaison/saveLiaisonRecord',
    method: 'post',
    data
  })
}
/**
 * 联络单待办 详情
 * @param {string} id
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/index/public/interiorLiaison/info',
    method: 'get',
    params: { id }
  })
}
