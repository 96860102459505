<template>
  <component :is="component" v-bind="linkProps(to)" v-on="linkEvents(to)">
    <slot />
  </component>
</template>

<script>
import { isExternal } from '@/utils/validate'
import { uniqueNamedRouteID } from '@/utils/router'
import { EventBus } from '@/eventBus'

export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.to)
    },
    isNamedRoute() {
      try {
        const paths = this.to?.split('/') || []
        return !!paths.slice(-1).pop()?.startsWith(':')
      } catch (error) {
        return false
      }
    },
    component() {
      if (this.isExternal) {
        return 'a'
      } else {
        return 'span'
      }
    }
  },
  methods: {
    linkProps(to) {
      if (this.isNamedRoute) {
        return {}
      }

      if (this.isExternal) {
        return {
          href: to,
          target: '_blank',
          rel: 'noopener'
        }
      }

      return { to }
    },
    linkEvents(to) {
      if (this.isExternal) {
        return {}
      } else {
        return {
          click: this.namedRoutting.bind(this, to)
        }
      }
    },
    /**
     * @param {string} routePath - this.to
     * @returns {any}
     */
    namedRoutting(routePath) {
      const path = this.isNamedRoute
        ? `${routePath.slice(
            0,
            routePath.lastIndexOf('/')
          )}/${uniqueNamedRouteID()}`
        : routePath

      this.$openLink({ path })

      EventBus.$emit('click-link')
    }
  }
}
</script>
