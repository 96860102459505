import { clearQuery } from '@/storage/session/listQuery'

clearQuery({ force: false })
export default () => {
  window.addEventListener(
    'beforeunload',
    (e) => {
      clearQuery({ force: false })
    },
    {
      capture: true
    }
  )
}
