import service from '@/service'

export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseInventory/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function exportData(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseInventory/export',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
