/**
 * @module api-web:hr/workForce/workForcePurchase
 */

import service from '@/service'

/**
 * @property {string} keywords -
 * @property {string} sort -
 * @property {number} limit - 条数,示例值(10)
 * @property {number} page - 页码,示例值(1)
 * @property {string} purchaseUser - 采购员
 * @property {number} effectiveStatus -	 生效状态 0-未生效 1-已生效
 * @description 采购排班表列表
 */
export function getList(params) {
  return service['api-web']({
    url: '/hr/purchase/purchaseSchedule/getList',
    method: 'get',
    params
  })
}

/**
 *
 * @param {object} data
 * @description 采购排班表新增
 */
export function create(data) {
  return service['api-web']({
    url: '/hr/purchase/purchaseSchedule/save',
    method: 'post',
    data
  })
}

/**
 *
 * @param {object} data
 * @description 采购排班表修改
 */
export function update(data) {
  return service['api-web']({
    url: '/hr/purchase/purchaseSchedule/update',
    method: 'put',
    data
  })
}

/**
 *
 * @param {string} id - row id
 * @description 采购排班表详情
 */
export function info(id) {
  return service['api-web']({
    url: '/hr/purchase/purchaseSchedule/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {Array<string>} ids -row id
 * @description 采购排班表删除
 */
export function remove(ids) {
  return service['api-web']({
    url: '/hr/purchase/purchaseSchedule/delete',
    method: 'get',
    data: { ids }
  })
}

/**
 *
 * @param {Array<string>} data -row id
 * @description 采购排班（失效）
 */
export function valid(data) {
  return service['api-web']({
    url: '/hr/purchase/purchaseSchedule/valid',
    method: 'put',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {Array<string>} data -row id
 * @description 采购排班（生效）
 */
export function invalid(data) {
  return service['api-web']({
    url: '/hr/purchase/purchaseSchedule/invalid',
    method: 'put',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
