/**
 * @module utils/cookies
 */
import Cookies from 'js-cookie'

import { isArray, isString } from './validate'
/**
 * @returns {string} cookies: get
 */
export function get(name) {
  try {
    return Cookies.get(name) || ''
  } catch (error) {
    console.error('【Cookies】get error catched: ' + error)
    return ''
  }
}
/**
 * @returns {void}
 * @description cookies: set
 */
export function set(name, value) {
  try {
    Cookies.set(name, value)
  } catch (error) {
    console.error('【Cookies】set error catched: ' + error)
  }
}
/**
 * @param {(string|Array<string>)} name
 * @returns {void}
 * @description cookies: remove
 */
export function remove(name) {
  try {
    if (isArray(name)) {
      name.forEach((k) => {
        isString(k)
          ? Cookies.remove(k)
          : console.error('【Cookies】Illegel "name" for "remove"[1]')
      })
    } else if (isString(name)) {
      Cookies.remove(name)
    } else {
      console.error('【Cookies】Illegel "name" for "remove"[2]')
    }
  } catch (error) {
    console.error('【Cookies】remove error catched: ' + error)
  }
}

export default {
  set,
  get,
  remove
}
