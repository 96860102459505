/**
 * @module api-scm:logisticsInquirySheet
 */
import service from '@/service'

/**
 * @typedef {Object} logisticsInquirySheet -- 物流询价单
 * @property {number=} [limit=1] -
 * @property {number} [page=10] - 页码
 * @property {string} sort -
 * @property {string} purchaseState - 单据状态
 * @property {string} logisticsCompany - 公司名称
 * @property {string} createTimeEnd - 结束时间
 * @property {string} createTimeStart - 开始时间
 *
 * @param {logisticsInquirySheet}
 */

export function getList(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsInquirySheet/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsInquirySheet/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsInquirySheet/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsInquirySheet/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsInquirySheet/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{code:string}}
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsInquirySheet/get/getListDown',
    method: 'get',
    params: { code },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(number|string)} id
 * @description 去下单
 */
export function toOrder(data) {
  return service['api-scm']({
    url: '/scm/logistics/logisticsInquirySheet/toOrder',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
