<template>
  <el-select
    v-model="proxyValue"
    v-bind="$attrs"
    :clearable="clearable"
    v-on="proxyEvents"
  >
    <el-option
      v-for="(item, idx) in options"
      :key="item?.[valueKey] ?? item?.id ?? idx"
      :value="item?.[valueKey] ?? item?.id ?? idx"
      :label="item?.[labelKey] ?? item?.name ?? 'Unknown Label'"
    />
  </el-select>
</template>
<script>
export default {
  name: 'BaseSelect',
  model: {
    event: 'input'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, String, Array],
      default: null
    },
    clearable: {
      type: Boolean,
      default: true
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    valueKey: {
      type: String,
      default: 'value'
    }
  },
  data() {
    return {}
  },
  computed: {
    isMultiple() {
      return typeof this.$attrs?.multiple !== 'undefined'
    },
    proxyValue: {
      set(v) {
        this.$emit('input', v)
      },
      get() {
        return this.value
      }
    },
    proxyEvents() {
      // eslint-disable-next-line no-unused-vars
      const { change, ...rest } = this.$listeners
      return {
        change: function (e) {
          const selection = this.isMultiple
            ? e?.map((_v) =>
                this.options.find((o) => o?.[this.valueKey] === _v)
              ) ?? []
            : this.options.find((o) => o?.[this.valueKey] === e) ?? null

          // typeof change === 'function' && change.apply(this, [e, selection])
          this.$emit('change', [e, selection])
        }.bind(this),
        ...rest
      }
    }
  }
}
</script>

<style></style>
