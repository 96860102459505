/**
 * @module api-web:financialAffairs/accountReceivale/report
 */
import service from '@/service'

/**
 * 查询 财务-预收款报表
 */
export function getPrecollectedList(params) {
  return service['api-web']({
    url: '/fin/ar/finSummary/getPrecollectedList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
