/**
 * @module api-web:customer/group
 */
import service from '@/service'
/**
 * 客户分组 增/改
 * @typedef {Object} clientGroupsDTO
 * @property {string=} groupName - 分组名
 * @property {number=} parentId - 父id
 * @property {number=} id - 分组列表主键
 */

/**
 * 新增客户分组
 * @param {clientGroupsDTO} data
 */
export function save(data) {
  return service['api-web']({
    url: '/data/clientGroups/save',
    method: 'post',
    data
  })
}

/**
 * 修改客户分组
 * @param {clientGroupsDTO} data
 */
export function update(data) {
  return service['api-web']({
    url: '/data/clientGroups/update',
    method: 'post',
    data
  })
}

/**
 * @description 删除分组
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/clientGroups/deleteClientGroup',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
