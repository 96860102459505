<template>
  <div class="wrap">
    <span :class="getColorClassName(value)" class="circle" />
    <LabelPicker :value="value" :source="finalSource" />
  </div>
</template>

<script>
import LabelPicker from '@/components/LabelPicker'
import { isArray, isNumeric, isPlainObject } from '@/utils/validate'

/**
 * @description
 *
 * default
 * 单据状态/审核状态
 * { value: 1, label: '创建', color: '' },
 * { value: 2, label: '审核中', color: '' },
 * { value: 3, label: '已审核', color: 'success' },
 * { value: 4, label: '重新审核', color: '' }
 *
 * support
 * 1. 客服工单
 * { label: '创建中', value: 1, color: 'info' },
 * { label: '处理中', value: 2, color: 'warning' },
 * { label: '已完成', value: 3, color: 'success' },
 * { label: '已超时', value: 4, color: 'danger' }
 *
 * 2. 流程节点状态
 * { label: '驳回', value: 1, color: 'danger' },
 * { label: '当前', value: 0, color: 'warning' },
 * { label: '完成', value: -1, color: 'success' }
 *
 * 3. ...
 */

export default {
  name: 'LabelPickerColorCircleMark',
  components: {
    LabelPicker
  },
  props: {
    value: {
      type: [Number, String, null],
      default: null
    },
    /**
     * 外部传入字典路径
     * @default 默认为单据审核状态的字典
     */
    dict: {
      type: String,
      default: 'biz.documentStatus'
    },
    /**
     * 外部直接传入列表数据
     */
    source: {
      type: Array,
      default: () => []
    },
    /**
     * 外部传入调色盘
     * @description 数组则根据索引选色，对象则根据 value 为 key color 为值映射
     * @default 默认为单据审核状态的调色盘
     */
    color: {
      type: [Array, Object],
      default: () => ['', '', '', 'success', '']
    }
  },
  computed: {
    finalSource() {
      return this.async_dict || []
    }
  },
  asyncComputed: {
    async_dict() {
      if (this.source?.length > 0) {
        return this.source
      } else {
        return this.$dict.get(this.dict)
      }
    }
  },
  methods: {
    /**
     * @param {(nubmer|string|null)}
     * @returns {('info'|'warning'|'success'|'danger'|'')}
     */
    getColorClassName(value) {
      let className = ''
      try {
        switch (true) {
          case isPlainObject(this.color):
            className = this.color[`${value}`] || this.color[value] || ''
            break
          case isArray(this.color):
            className =
              typeof value === 'number'
                ? this.color[value]
                : isNumeric(value)
                ? this.color[value * 1]
                : ''
            break
        }
      } catch (error) {
        console.error(error)
      }
      return className || 'hide'
    }
  }
}
</script>

<style lang="scss" scoped>
.circle {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 5px;
  border-radius: 50%;
  vertical-align: middle;
}

.circle.hide {
  display: none;
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
}

.info {
  background-color: gray;
}

.warning {
  background-color: #fcd217;
}

.success {
  background-color: #0ddd45;
}

.danger {
  background-color: #ed0d0d;
}
</style>
