import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import guard from './guard' // permission control
Vue.use(Router)

import layout from '@/layout' // layout
import demoRoute from './demos' // just load when env:development
import { obj2param } from '@/utils'
// import { Message } from 'element-ui'

const originalReplace = Router.prototype.replace
const originalPush = Router.prototype.push

const upgradeReplace = async function replace(...args) {
  const { 0: location } = args
  // 为了打印 404 的页面, 导航守卫无法获取 404 的来源
  await store.dispatch('setNextRouteRecord', location)

  // 是否无痕访问页面
  await store.dispatch('setPrevRouteIncognito', true)

  try {
    return await originalReplace.apply(this, args)
  } catch (e) {
    console.warn('router replace:', e)
  } // eslint-disable-line no-empty
}
const upgradePush = async function push(...args) {
  const { 0: location } = args
  if (location.replace === true) {
    return await upgradeReplace.apply(this, args)
  }

  // 为了打印 404 的页面, 导航守卫无法获取 404 的来源
  await store.dispatch('setNextRouteRecord', location)
  // 是否无痕访问页面
  await store.dispatch('setPrevRouteIncognito', false)

  try {
    return await originalPush.apply(this, args)
  } catch (e) {
    console.warn('router push:', e)
  } // eslint-disable-line no-empty
}
Router.prototype.replace = upgradeReplace
Router.prototype.push = upgradePush

/* use this.$router.blank({path:xxx,query:{}}) */
Router.prototype.blank = function ({ path = '', query = {} }) {
  const link = document.createElement('a')
  link.href = `${path}?${obj2param(query)}`
  // const root = window.location.protocol + '//' + window.location.host
  // link.href = `${root}${path}`
  link.target = '_blank'
  link.rel = 'noopener noreferrer' // IMPORTANT to add this
  link.click()
}

/**
 * 本地动态创建路由
 */
export { createRoutesConfigs, createRoutesHandlers } from './create/'

/**
 * localRoutes 本地固定路由
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const localRoutes = [
  {
    path: '/login',
    namne: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index'),
    hidden: true,
    noCache: true,
    noTag: true
  },
  {
    path: '/auth-redirect',
    name: 'AuthRedirect',
    component: () =>
      import(
        /* webpackChunkName: "auth-redirect" */ '@/views/login/auth-redirect'
      ),
    hidden: true,
    noCache: true,
    noTag: true
  },
  {
    path: '/redirect',
    component: layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () =>
          import(/* webpackChunkName: "redirect" */ '@/views/redirect/index')
      }
    ],
    hidden: true,
    noCache: true,
    noTag: true
  },
  {
    path: '/preview-files',
    namne: 'PreviewFiles',
    component: () =>
      import(/* webpackChunkName: "preview-files" */ '@/views/previewFiles'),
    hidden: true,
    noCache: true,
    noTag: true
  },
  {
    path: '/401',
    name: 'Error401',
    component: () =>
      import(
        /* webpackChunkName: "no-authorization-401" */ '@/views/error/401'
      ),
    hidden: true,
    noCache: true,
    noTag: true
  },
  {
    path: '/404',
    name: 'Error404',
    component: () =>
      import(/* webpackChunkName: "page-not-found-404" */ '@/views/error/404'),
    hidden: true,
    noCache: true,
    noTag: true
  },
  {
    path: '/print',
    name: 'Print',
    component: () => import(/* webpackChunkName: "print" */ '@/views/print'),
    hidden: true,
    noCache: true,
    noTag: true
  }
  // {
  //   path: '/print-html',
  //   name: 'PrintHtml',
  //   component: () =>
  //     import(/* webpackChunkName: "print-html-string" */ '@/views/printHtml'),
  //   hidden: true,
  //   noCache: true,
  //   noTag: true
  // },
  // {
  //   path: '/pdf',
  //   name: 'pdf',
  //   component: () => import(/* webpackChunkName: "print-pdf" */ '@/views/pdf'),
  //   // hidden: true,
  //   noCache: true,
  //   noTag: true
  // },
  // {
  //   path: '/lodop',
  //   name: 'Lodop',
  //   component: () =>
  //     import(/* webpackChunkName: "print-lodop" */ '@/views/lodop'),
  //   hidden: true,
  //   noCache: true,
  //   noTag: true
  // }
  // {
  //   path: '/supplyChain/liaison',
  //   name: 'Liaison',
  //   component: () => import('@/views/supplyChain/liaison'),
  //   // hidden: true,
  //   noCache: true,
  //   noTag: true,
  //   children: [
  //     {
  //       path: '/supplyChain/liaison/detail/:id',
  //       name: 'messagePage',
  //       component: () => import('@/views/supplyChain/liaison/detail')
  //     }
  //   ]
  // }
]

/**
 * tailRoutes, push end
 * the routes that need to be dynamically loaded based on user roles and VUE_APP_ENV
 */
export const tailRoutes =
  process.env.NODE_ENV === 'development' && process.env.VUE_APP_ENV === 'dev'
    ? [
        /**
         * 现成示例集合（开发环境专属）
         */
        demoRoute,
        /**
         * 404 route page must be placed at the end !!!
         */
        {
          path: '*',
          redirect: '/404',
          hidden: true,
          noCache: true,
          noTag: true
        }
      ]
    : [
        /**
         * 404 route page must be placed at the end !!!
         */
        {
          path: '*',
          redirect: '/404',
          hidden: true,
          noCache: true,
          noTag: true
        }
      ]

const createRouter = () =>
  guard(
    new Router({
      /**
       * history mode require service support
       * @see https://v3.router.vuejs.org/zh/guide/essentials/history-mode.html
       *
       * hotfix only support hash, because of limited by the server environment built by operation and maintenance
       */
      mode: process.env.VUE_APP_ENV === 'hotfix' ? 'hash' : 'history',
      scrollBehavior: () => ({ y: 0 }),
      routes: localRoutes
    })
  )

const router = createRouter()

/**
 * @see https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
 * createRouter and reset router.matche
 */
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

// router.onError((error) => {
//   const pattern = /Loading chunk (\d)+ failed/g
//   const cssPattern = /Loading CSS chunk (\d)+ failed/g
//   const isChunkLoadFailed =
//     error.message.match(pattern) || error.message.match(cssPattern)
//   if (isChunkLoadFailed) {
// Message.error({ message: '抱歉，静态资源加载失败', showClose: true })
//     location.reload()
//   }
// })

export default router
