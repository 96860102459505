// eslint-disable-next-line spaced-comment
/// <reference path="../../typedef.js" />

import { unflattenAsync } from '@/utils/tree'
import { generateRoutes } from '@/permission/menus/routes'

/**
 * @module permission/resources/categoary/nested-navs
 */

/**
 * 生成内嵌菜单
 * @param {RolePermissionFlat}
 * @param {PermissionIndexTable} indexTable
 * @returns {Array<import('vue-router').Route>} 路由列表
 */
export async function createNestedNavs(list, indexTable) {
  const tree = await unflattenAsync(list)
  return generateRoutes(tree, indexTable)
}
