/**
 * @module api-web:productBigType
 * @description api for data/basic/productBigType
 */
import service from '@/service'

/**
 * 表查询
 * @param {{keywords:string,limit:number,page:number,sort:string,documentStatus:number}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/data/basic/productBigType/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  详情
 */
export function info(id) {
  return service['api-web']({
    url: '/data/basic/productBigType/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/productBigType/info',
    method: 'get',
    params: { id }
  })
}

/**
 * @param {object}
 * @returns 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/data/basic/productBigType/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 */
export function save(data) {
  return service['api-web']({
    url: '/data/basic/productBigType/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 */
export function update(data) {
  return service['api-web']({
    url: '/data/basic/productBigType/update',
    method: 'post',
    data
  })
}
/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: '/data/basic/productBigType/reject',
    url: '/public/activiti/productBigType/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(string|number)} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/data/basic/productBigType/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(string|number)} ids
 * @description 审核完成
 */
export function completedAudit(ids) {
  return service['api-web']({
    url: '/data/basic/productBigType/completedAudit',
    method: 'post',
    data: { id: ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:string}} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/data/basic/productBigType/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 禁用
 * @param {(string|number)} id - id
 */
export function disable(data) {
  return service['api-web']({
    url: '/data/basic/productBigType/disable',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 启用
 * @param {(string|number)} id - id
 */
export function enable(data) {
  return service['api-web']({
    url: '/data/basic/productBigType/enable',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 删除
 * @param {(string|number)} ids - ids
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/basic/productBigType/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 手动对接
 * @param {{ids:string}} ids
 */
export function docking(ids) {
  return service['api-web']({
    url: '/data/basic/productBigType/docking',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据id-编码或名称查询系统是否已存在
 */
export function getCountByCodeOrName(params) {
  return service['api-web']({
    url: '/data/basic/productBigType/get/getCountByCodeOrName',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 查询所有物料大类
 */
export function getListForProductList(params) {
  return service['api-web']({
    url: '/public/data/getProductBigTypeList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
