// import Vue from 'vue'
import { consoleTheme } from '@/settings'
import { CANCEL_REQ_WHITE_LIST } from '@/constants'
import { isArray, isString } from '@/utils/validate'
/**
 * @typedef {string} RequestPoolItemPayload
 * @description json stringify request infos
 * ```js
 * {
 *    auth: config.headers.Authorization || '',
 *    data: config.data || '',
 *    params: config.params || ''
 * }
 * ```
 * @typedef {Object} RequestPoolItem
 * @property {string} id - config.id,
 * @property {string} name -config.url,
 * @property {RequestPoolItemPayload} payload - json stringify request infos (auth,rid,uid,data,params)
 * @property {Function} cancel - aixos cancel executor
 * @property {boolean} [stash=false] - if true, will push into "stash" after remove from "stage"
 * @property {number} [repeatimes=0] - count of request repeat times
 *
 * @typedef {Array<RequestPoolItem>} RequestPoolList
 *
 * @typedef {Object} RequestPool
 * @property {RequestPoolList} stage
 * @property {RequestPoolList} stash
 *
 * @returns {RequestPool}
 */
const state = () => ({
  /**
   * @type {RequestPoolList}
   * @description 当前去重后的请求列表
   */
  stage: [],
  /**
   * @type {RequestPoolList}
   * @description 暂存的出现重复的唯一请求列表
   */
  stash: []
})

const getters = {
  /**
   * @param {*} state
   * @returns {Record<string,RequestPoolItem>}
   */
  stashIdx: (state) => {
    const idx = {}
    try {
      if (!isArray(state.stash) || !state.stash.length) {
        return idx
      }
      state.stash.forEach((request) => {
        idx[request.id] = request
      })
    } catch (error) {
      console.error(error)
    }
    return idx
  }
}
const mutations = {
  /**
   * 将请求添加到 state.stage
   * @param {*} state
   * @param {*} request
   */
  ADD(state, request) {
    state.stage.push(request)
  },
  /**
   * 将请求添加到 state.stash
   * @param {*} state
   * @param {*} request
   */
  TEMP_ADD(state, request) {
    state.stash.push(request)
  },
  /**
   * 通过指定 stage or stash 请求存储项索引，删除对应请求存储项
   * @param {*} state
   * @param {{pool:('stage'|'stash'),index:number}} param1
   */
  REMOVE_BY_INDEX(state, { pool, index }) {
    try {
      index > -1 && typeof state[pool][index] !== 'undefined'
        ? state[pool].splice(index, 1)
        : console.error('request pool remove error[1]')
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * 通过指定 stage or stash 请求存储项ID，删除对应请求存储项
   * @param {*} state
   * @param {{pool:('stage'|'stash'),id:string}} param1
   */
  REMOVE_BY_ID(state, { pool, id }) {
    try {
      if (!state[pool].length) {
        return void console.error('request pool remove error[2]')
      }
      if (typeof id === 'undefined') {
        return void console.error('request pool remove error[3]')
      }
      const index = state[pool].findIndex((req) => req.id === id)
      if (index !== -1) state[pool].splice(index, 1)
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * 通过指定 stage or stash 请求存储项ID，更新对应请求存储项的属性
   * @param {*} state
   * @param {{pool:('stage'|'stash'),id:string,key:(Array<string>|string),val:(Array<any>|any)}} param1
   */
  PATCH_BY_ID(state, { pool, id, key, val }) {
    try {
      if (!state[pool].length) {
        return void console.error('request pool patch error[1]')
      }
      if (typeof id === 'undefined') {
        return void console.error('request pool patch error[2]')
      }
      const index = state[pool].findIndex((req) => req.id === id)
      if (index !== -1) {
        if (isString(key)) {
          state[pool][index][key] = val
        } else if (isArray(key) && isArray(val) && key.length === val.length) {
          for (let i = 0; i < key.length; i++) {
            state[pool][index][key[i]] = val[i]
          }
        } else {
          console.error('"PATCH_BY_ID": please check "key" or "val"')
        }
      }
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * 通过指定 stage or stash 请求存储项ID，更新覆盖整个对应请求存储项
   * @param {*} state
   * @param {{pool:('stage'|'stash'),id:string,request:RequestPoolItem}} param1
   * @returns
   */
  PUT_BY_ID(state, { pool, id, request }) {
    try {
      if (!state[pool].length) {
        return void console.error('request pool put error[1]')
      }
      if (typeof id === 'undefined') {
        return void console.error('request pool put error[2]')
      }
      const index = state[pool].findIndex((req) => req.id === id)
      if (index !== -1) state[pool][index] = request
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * 取消对应请求
   * @param {*} state
   * @param {{request:RequestPoolItem,forword:RequestPoolItem}} param1
   * @returns
   */
  CANCEL(state, { request, forword }) {
    try {
      if (typeof request?.cancel === 'function') {
        const by = forword && forword?.id ? forword.id : 'router-guard'
        if (!CANCEL_REQ_WHITE_LIST.includes(request?.name)) {
          request.cancel(`cancel:${request?.id} by:${by}`)
          const { VUE_APP_ENV, VUE_APP_TRANSPORT_SECURITY } = process.env
          if (
            (VUE_APP_ENV === 'dev' || VUE_APP_ENV === 'test') &&
            VUE_APP_TRANSPORT_SECURITY === 'true'
          ) {
            console.groupCollapsed(
              `%c cancel: ${request.name} [by:${by}]`,
              `background: ${consoleTheme.bgColor.transparent}; color: ${consoleTheme.txtColor.red}; padding: 0 10px; border-radius: 3px; font-size: 10px;`
            )
            const { id, name, payload, ...rest } = request
            console.log({ id, name, ...rest, ...(JSON.parse(payload) || {}) })
            console.log('stage:', JSON.parse(JSON.stringify(state.stage)))
            console.log('stash:', JSON.parse(JSON.stringify(state.stash)))
            console.groupEnd()
          }
        } else console.log(`Whitelist contains ${request.name}, do not cancel`)
      }
    } catch (error) {
      console.error(error)
    }
  }
}
const actions = {
  /**
   * 添加到请求池的 stage 中, 如果已存在则更新对应请求的部分字段
   * @param {*} param0
   * @param {{status:boolean,message:string,data:RequestPoolItem}} param1
   */
  async add({ state, commit }, request) {
    try {
      if (!state.stage.length) {
        commit('ADD', request)
        return { status: true }
      }

      const index = state.stage.findIndex(
        (v) => v.name === request.name && v.payload === request.payload
      )
      if (index > -1) {
        const forword = state.stage[index]
        /**
         * 重复请求添加，则更新信息
         * 1. stash 设置为 true 后 => remove 的时候，会在 stage 中移除后被放入 state.stash
         * 2. repeatimes => 统计并发的同一请求重复次数，用于后续所有请求结束后清除 state.stash 的请求
         */
        commit('PATCH_BY_ID', {
          pool: 'stage',
          id: forword.id,
          key: ['stash', 'repeatimes'],
          val: [true, forword.repeatimes + 1]
        })
        commit('CANCEL', { request, forword }) // 方案：前浪留，后浪“死”
        return {
          status: false,
          message: 'has same forword request being executed',
          data: forword
        }
      }
      commit('ADD', request)
      return { status: true }
    } catch (error) {
      console.error(error)
      return { status: false, message: 'catched exception error ', data: error }
    }
  },
  /**
   * 添加到请求池的 stash 中
   * @param {*} param0
   * @param {RequestPoolItem} param1
   */
  async temp({ state, commit }, request) {
    try {
      /**
       * stash 为 true, 说明并发了，存入 stash 等待第一次 详情请求的 response
       */
      if (request.stash === true) {
        if (!state.stash.length) {
          commit('TEMP_ADD', request)
          return true
        }

        const index = state.stash.findIndex(
          (v) => v.name === request.name && v.payload === request.payload
        )
        if (index === -1) {
          commit('TEMP_ADD', request)
          return true
        }
      } /*  else console.info('The "request.stash" not equal true', request) */
    } catch (error) {
      console.error(error)
    }
    return false
  },
  /**
   * 请求池中获取请求
   * @param {*} param0
   * @param {*} param1
   * @returns {RequestPoolItem}
   */
  get({ state }, { pool, id }) {
    try {
      if (!state[pool].length) return
      if (typeof id === 'undefined') return
      const index = state[pool].findIndex((request) => request.id === id)
      if (index !== -1) {
        return state[pool][index]
      }
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * 请求池中更新请求的部分属性
   */
  async patch({ dispatch, commit }, { pool, id, key, val }) {
    commit('PATCH_BY_ID', {
      pool,
      id,
      key,
      val
    })
    return await dispatch('get', { pool, id })
  },
  /**
   * 请求池中移除请求
   */
  async remove({ state, dispatch, commit }, { pool, id }) {
    try {
      if (!state[pool].length) return null
      if (typeof id === 'undefined') return null
      const index = state[pool].findIndex((request) => request.id === id)
      if (index !== -1) {
        const target = state[pool][index]
        commit('REMOVE_BY_INDEX', { pool, index })
        return target
      }
    } catch (error) {
      console.error(error)
    }
    return null
  },
  /**
   * @deprecated 过于暴力，暂时废弃
   * 取消所有请求,同时清空请求池中所有存储区
   * @returns {Promise<('empty'|'success'|'error')>}
   */
  async cancelAll({ state, dispatch, commit }) {
    const pools = ['stage', 'stash']
    try {
      if (pools.every((pool) => state[pool].length === 0)) return 'emtpy'

      await Promise.all(
        pools.map(
          (pool) =>
            new Promise((resolve) => {
              try {
                state[pool].forEach((request) => commit('CANCEL', { request }))
                state[pool] = []
              } catch (e) {
                console.error(e)
              } finally {
                resolve()
              }
            })
        )
      )
      return 'success'
    } catch (error) {
      console.error(error)
      return 'error'
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
