/**
 * @module api-scm:warehouseOut
 */
import service from '@/service'

/**
 * @typedef {Object} WarehouseOut 其他出库单列表
 * @property {number=} [limit=1] -
 * @property {number} page - 页码
 * @property {string} sort -
 * @property {number} deliveryWarehouseCode - 发货仓库
 * @property {string} productCode - 商码编号
 * @property {string} productName - 商品名称
 * @property {string} warehouseOutTimeEnd - 出库时间结束
 * @property {string} warehouseOutTimeStart - 出库时间起始
 *
 * @param {WarehouseOut}
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/get/getList',
    method: 'post',
    data
  })
}

/**
 * 下拉
 * @param {{code:string}} code
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/get/getListDown',
    method: 'get',
    params: { code },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/info',
    // url: '/scm/warehouse/warehouseOut/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehouseOut/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{number:id}}
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseOut/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 作废
 * @param {{id:number}} id
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/pushDown',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出其它出库单
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/warehouseOutData/export',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false // 文件流是否通过浏览器下载
  })
}

/**
 * @description 拣货单打印
 */
export function printPicklist(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/otherWarehouseOutPrinting',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 手动对接
 * @param {string} ids
 */
export function manualDockingOfKingdee(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseOut/manualDockingWarehouseToKingDee',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 根据货主，库位代码，批次号，商品，仓库查询商品可用量
 */
export function getAvailableNumber(data) {
  return service['api-scm']({
    url: '/public/inventory/getAvailableNumber',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}
/**
 *
 * @param {*} params
 * @returns 根据调出仓库、调出货主、商品编码、库位代码、批次号获取 库存数量以及可配数
 * @see  supplyChain/warehouse/warehouseAllot/inline-edit-tables/index.js
 */
export function getAllInventoryProductInfoByBatchCode(params) {
  return service['api-scm']({
    url: '/public/inventory/getAllInventoryProductInfoByBatchCode',
    method: 'get',
    params
  })
}
/**
 * @description 根据货主，库位代码，商品查询批次号
 */
export function getBatchCode(data) {
  return service['api-scm']({
    url: '/public/inventory/getBatchCode',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}
