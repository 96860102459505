/**
 * @module api-web:businessTodo/productionOrder/list
 * @description 首页/生产订单待办 列表
 */
import service from '@/service'
/**
 * 我的待办列表
 * @param {*} params
 */
export function getMyTodoList(params) {
  return service['api-web']({
    url: '/index/public/todoProductionOrder/get/getList',
    method: 'get',
    params
  })
}

/**
 * 我的已完成列表
 * @param {*} params
 */
export function getMyDoneList(params) {
  return service['api-web']({
    url: '/index/public/todoProductionOrder/get/getDoneList',
    method: 'get',
    params
  })
}
/**
 * 部门待办列表
 * @param {*} params
 */
export function getDeptTodoList(params) {
  return service['api-web']({
    url: '/index/public/todoProductionOrder/get/getDeptList',
    method: 'get',
    params
  })
}

/**
 * 部门已完成列表
 * @param {*} params
 */
export function getDeptDoneList(params) {
  return service['api-web']({
    url: '/index/public/todoProductionOrder/get/getDeptDoneList',
    method: 'get',
    params
  })
}

export default {
  getMyTodoList,
  getMyDoneList,
  getDeptTodoList,
  getDeptDoneList
}
