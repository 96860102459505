import store from '@/store'

import { Message } from 'element-ui'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import halt from '@/utils/halt' // sleep with nexttick
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist
/**
 * @param {*} to
 * @param {*} from
 * @param {*} next
 * @param {string} erroMsg - for message.error
 * @param {Array<any>} errorLogs - for console.error
 */
const errorHandler = async (to, from, next, erroMsg, errorLogs) => {
  await store.dispatch('user/logout', { request: false })
  erroMsg && Message.error({ message: erroMsg, showClose: true })
  next('/login')
  NProgress.done()
  console.error.apply(null, errorLogs)
}

export default (router) => {
  router.beforeEach(async (to, from, next) => {
    // 取消过于暴力，不符合某些场景，先注释
    // await store.dispatch('requestPool/cancelAll')

    // start progress bar
    NProgress.start()
    await halt(16)

    store.state.disablePrevRoute
      ? halt(0, () => store.dispatch('setPrevRouteIncognito', false))
      : store.dispatch('setPrevRouteRecord', from)

    // set page title
    document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    const hasToken = getToken()

    if (hasToken) {
      const isRedirectingToHome = !to.query[btoa('notRedirectingToHome')]
      if (to.path === '/login' && isRedirectingToHome) {
        // if is logged in, redirect to the home page
        next({ path: '/' })
        NProgress.done()
      } else {
        const hasUserInfo =
          store.getters.roles &&
          store.getters.roles.length > 0 &&
          store.getters.id &&
          store.getters.name
        if (hasUserInfo) {
          next()
        } else {
          try {
            // set user info
            // eslint-disable-next-line no-unused-vars
            const result = await store.dispatch('user/init')
            if (result.status !== 'success') {
              return void errorHandler(to, from, next, result.msg, result.log)
            }
            // generate accessible routes map based on roles
            const { menus } = await store.dispatch('permission/init')
            // dynamically add accessible routes

            router.addRoutes(menus)
            // hack method to ensure that addRoutes is complete
            // set the replace: true, so the navigation will not leave a history record
            next({ ...to, replace: true })
            NProgress.done()
          } catch (error) {
            // remove token and go to login page to re-login
            errorHandler(to, from, next, '程序异常：初始化用户和路由信息失败', [
              'Catched error "beforeEach"',
              error
            ])
          }
        }
      }
    } else {
      /* has no token*/
      if (whiteList.indexOf(to.path) !== -1) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next(`/login?redirect=${to.fullPath || to.path}`)
        NProgress.done()
      }
    }
  })

  router.afterEach((to, from) => {
    try {
      // remove nested <router-view>
      handleKeepAlive(to)

      // ...
    } catch (error) {
      console.error(error)
    } finally {
      // finish progress bar
      NProgress.done()
    }
  })

  return router
}
/**
 * 递归处理删除 嵌套的 <router-view>，
 * 让组件保持在第一层 <router-view> 之下
 * @param to
 */
function handleKeepAlive(to) {
  if (to.matched && to.matched.length > 2) {
    for (let i = 0; i < to.matched.length; i++) {
      const element = to.matched[i]
      // 因为 import() 异步懒加载,第一次获取不到 element.components.default.name
      // 所以不能再 beforeEach 做, 不然第一次访问的界面不缓存第二次才会缓存
      // afterEach 可以获取到 element.components.default.name
      // console.log(element.components.default)
      const componentName = element?.components?.default?.name
      if (
        componentName &&
        componentName.toLowerCase() !== 'layout' &&
        typeof element?.redirect !== 'undefined'
      ) {
        to.matched.splice(i, 1)
        handleKeepAlive(to)
      }
    }
  }
}
