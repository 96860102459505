import qs from 'qs'
import { obj2form, safeJsonParse } from '@/utils'

const DEFAULT_TIMEOUT = 15 * 1000

const transformRequest = (data, headers) => {
  const REQ_CONTENT_TYPE = headers['Content-Type'] || ''
  let payload
  switch (true) {
    case REQ_CONTENT_TYPE.includes('multipart/form-data'):
      payload = obj2form(data)
      break
    case REQ_CONTENT_TYPE.includes('application/json'):
      payload = JSON.stringify(data)
      break
    default:
      payload = qs.stringify(data)
      break
  }
  return payload
}
const transformResponse = (data = {}) => {
  if (
    (typeof data === 'object' && data instanceof Blob) ||
    (typeof data === 'object' && !data)
  ) {
    return data
  }

  // eslint-disable-next-line no-unused-vars
  const [e, _data] = safeJsonParse(data)
  return _data
}

export default (axios) => ({
  /**
   * send cookies when cross-domain requests
   */
  // withCredentials: true,
  timeout: DEFAULT_TIMEOUT,
  /**
   * transform the data or headers
   */
  transformRequest: [transformRequest],
  /**
   * `transformResponse.data` allows changes to the response.data data to be made before
   */
  transformResponse: [...axios.defaults.transformResponse, transformResponse]
})
