/**
 * @module api-web:sysDict
 */
import service from '@/service'

/**
 * 查询字典列表
 * @param {{dictType:string,limit:number,page:number,dictName:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/sys/sysDict/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增字典
 */
export function add(data) {
  return service['api-web']({
    url: '/sys/sysDict/save',
    method: 'post',
    data
  })
}

/**
 * 修改字典
 */
export function update(data) {
  return service['api-web']({
    url: '/sys/sysDict/update',
    method: 'put',
    data
  })
}
/**
 * 删除字典
 */
export function remove(params) {
  return service['api-web']({
    url: '/sys/sysDict/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 查询字典详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/sys/sysDict/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 增加字典项
 */
export function addDictItem(data) {
  return service['api-web']({
    url: '/sys/sysDictItem/save',
    method: 'post',
    data
  })
}
/**
 * @param {{jobId:number,jobName:string}} data
 * @description 修改字典项
 */
export function updateDictItem(data) {
  return service['api-web']({
    url: '/sys/sysDictItem/update',
    method: 'put',
    data
  })
}
/* 删除字典项 */
export function removeDictItem(params) {
  return service['api-web']({
    url: '/sys/sysDictItem/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @deprecated 已废弃 ❌
 *
 * 根据字典类型和字典名查询字典项
 * @param {string} dictPath dictType + dictName
 * @example '/businessDict/${dictName}' 业务类
 * @example '/sysDict/${dictName}' 系统类
 */
export function getDict(dictPath) {
  return service['api-web']({
    url: `/public/sys/${dictPath}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 查询字典项
 * @param {{dictName:string,type?:(0|1),parentValue?:(number|string|null)}} 字典名 namme 必填 ; 字典类型 type 可选: 0业务 1系统 关联的父字典的字典项 parentValue 可选
 */
export function fetchDict({ dictName = '', type = null, ...rest }) {
  return service['api-web']({
    url: `/public/sys/${dictName}`,
    method: 'get',
    params: type === null ? rest : { type, ...rest },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
