/**
 * @typedef {Object} Config
 * @property {string} methodName
 * @property {string} apiName
 * @property {boolean} defaultCallback
 * @property {('number'|'string')} idType
 *
 * @typedef {Record<string,Config>} Mapper
 * @description 字段均可被 buttonGroup 'item config 覆盖
 */

/**
 * @type {Mapper}
 * @description button 映射器
 */
export const MAPPER = {
  /**
   * @description 列表新增
   * 路由到新增页
   */
  新增: {
    methodName: 'create',
    defaultCallback: false /* 路由跳转了，不需要执行 callback */
  },
  /**
   * @description 列表批量删除
   * 使用 list crud mixin handler
   */
  删除: {
    methodName: 'delete',
    defaultCallback: false /* callback 在 list crud mixin 中处理了 */
  },
  导出: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  /**
   * @see pushDown.js
   */
  下推: {
    methodName: 'pushDown',
    defaultCallback: false
  },
  复制: {
    methodName: 'duplicatePurchaseOrder',
    defaultCallback: true
  },
  配送清单打印: {
    methodName: 'jdInvoicePrinting',
    defaultCallback: true
  },
  发货单打印: {
    methodName: 'procurementOrderShipInvoicePrint',
    defaultCallback: true
  },
  /**
   * @see getCostInfomation.js
   */
  获取成本信息: {
    methodName: 'getCostInformation',
    defaultCallback: true
  },
  /**
   * @description 提交以下几种情况：
   * 1. 列表批量提交到审核流
   * 2. 普通列表(非待办列表) 进入详情，提交的审核流
   * 3. 普通列表(非待办列表) 进入详情，重新提交的审核流
   * 4. 待办列表进入详情，提交的审核流
   * 5. 待办列表进入详情，重新提交的审核流
   * 6. 待办列表进入详情，审核通过提交到审核流下一个节点
   * 7. 待办列表进入详情，提交驳回
   */
  提交: {
    methodName: 'submit',
    defaultCallback: true
  },
  /**
   * 使用通用处理器
   * api + id
   */
  结束流程: {
    methodName: 'commonHandler',
    apiName: 'withdraw',
    idType: 'string',
    defaultCallback: true
  },
  /**
   * 使用通用处理器
   * api + id
   */
  撤销: {
    methodName: 'withdraw',
    apiName: 'withdraw',
    idType: 'string',
    defaultCallback: false
  },
  /**
   * 使用通用处理器
   * api + id
   */
  反审核: {
    methodName: 'commonHandler',
    apiName: 'rollback',
    idType: 'number',
    defaultCallback: true
  },
  /**
   * 使用通用处理器
   * api + id
   */
  批量反审核: {
    methodName: 'commonHandler',
    apiName: 'batchReverseAudit',
    defaultCallback: true
  },
  /**
   * 使用通用处理器
   * api + id
   */
  审核: {
    methodName: 'commonHandler',
    apiName: 'audit',
    idType: 'number',
    defaultCallback: true
  },
  /**
   * 使用通用处理器
   * api + 动态参数(外部 buttonGroup 配置)
   */
  反核销: {
    methodName: 'commonHandler',
    apiName: 'reverseWriteOff',
    idType: 'number',
    defaultCallback: true
  },
  /**
   * 使用通用处理器
   * api + id
   */
  对账: {
    methodName: 'commonHandler',
    apiName: 'reconciliation',
    idType: 'string',
    defaultCallback: true
  },
  确认收款: {
    methodName: 'commonHandler',
    apiName: 'receiptConfirmation',
    idType: 'string',
    defaultCallback: true
  },
  凭证冲销: {
    methodName: 'commonHandler',
    apiName: 'writeOffVoucher',
    idType: 'number',
    defaultCallback: true
  }
}
