import {
  EDIT_PAGE_ROUTE_NAME_ENDS,
  CREATE_PAGE_ROUTE_NAME_ENDS,
  DETAIL_PAGE_ROUTE_NAME_ENDS,
  LIST_PAGE_ROUTE_NAME_ENDS
} from '@/constants'
import { getRouteName } from './router'
/**
 * @description ⚠️ 不包含/不支持 非“三件套”目录结构的模块，如系统管理下的部门管理、权限管理等
 */

/**
 * @param {string} routeName - this.$route.name
 * @param {*} route - this.$route
 * @returns {boolean} 是否是 「列表页」
 */
export const isListPage = (routeName = '', $route = {}) => {
  return LIST_PAGE_ROUTE_NAME_ENDS.some((end) => routeName.endsWith(end))
}
/**
 * @param {string} routeName - this.$route.name
 * @param {*} route - this.$route
 * @returns {boolean} 是否是"可编辑页"：包括了 「创建页」和「详情页」(编辑/查看/流程设计等)
 */
export const isEditPage = (routeName = '', $route = {}) => {
  return EDIT_PAGE_ROUTE_NAME_ENDS.some((end) => routeName.endsWith(end))
}
/**
 * @param {string} routeName - this.$route.name
 * @param {*} route - this.$route
 * @returns {boolean} 是否是「创建页」
 */
export const isCreatePage = (routeName = '', $route = {}) => {
  return (
    CREATE_PAGE_ROUTE_NAME_ENDS.some((end) => routeName.endsWith(end)) ||
    $route?.query?.mode === 'create'
  )
}
/**
 * @param {string} routeName - this.$route.name
 * @param {*} route - this.$route
 * @returns {boolean} 是否是「详情页」：(编辑/查看/流程设计等)
 */
export const isDetailPage = (routeName = '', $route = {}) => {
  return DETAIL_PAGE_ROUTE_NAME_ENDS.some((end) => routeName.endsWith(end))
}

/**
 * @param {string} $route - this.$route
 * @returns {('list'|'create'|'detail'|'')} page type
 */
export const currentPageType = ($route = {}) => {
  const routeName = getRouteName($route)
  if (!routeName) return ''

  switch (true) {
    case isListPage(routeName, $route):
      return 'list'
    case isCreatePage(routeName, $route):
      return 'create'
    case isDetailPage(routeName, $route):
      return 'detail'
    default:
      console.error(`【currentPageType】unknown page type:${routeName}`, $route)
      return ''
  }
}

export default {
  currentPageType,
  isListPage,
  isCreatePage,
  isDetailPage,
  isEditPage
}
