/* eslint-disable no-unused-vars */
import { isPlainObject } from '@/utils/validate'
import { getSyncAsyncResult, getSyncAsyncReturns } from '@/utils'
export default {
  /**
   * 查询历史价格
   */
  name: 'getCostInformationMixin',
  data() {
    return {
      getCostInformationVisible: false,
      getCostInfomation: []
    }
  },
  computed: {
    getCostInformationBtn() {
      const btns = this.buttonGroup.filter(
        (item) =>
          item.evtName === '获取成本信息' || item.label === '获取成本信息'
      )
      /**
       * TODO： 设计缺陷，暂只支持一个获取成本信息按钮
       */
      return btns[0] || {}
    },
    hasgetCostInformationBtn() {
      const has = !!(
        isPlainObject(this.getCostInformationBtn) &&
        Object.keys(this.getCostInformationBtn).length > 0
      )
      return has
    },
    getCostInformationExternalHandlers() {
      return this.getCostInformationBtn?.guards || {}
    }
  },
  created() {
    if (this.hasgetCostInformationBtn) {
      console.info('此页面有获取成本信息按钮')
    } else console.info('无获取成本信息按钮')
  },
  methods: {
    // 获取成本信息
    async getCostInformation({ config, evtName, methodName, button }) {
      let selections
      if (this.currentPageType === 'list') {
        selections = this.getSelections()
        if (button.skipValidate !== true) {
          if (selections.length < 1) {
            return void this.$message.error(`请先选择要${evtName}的项`)
          }
        }
      }
      const { code, data } = await this.scope.apiList.getCostInformation(
        this.currentPageType === 'list'
          ? selections.map((v) => v.id).join()
          : this.scope.id
      )
      code === 200 &&
        ((this.getCostInfomation = Array.isArray(data) ? data : []),
        this.currentPageType === 'list'
          ? this.scope.$_list_crud_table_refresh({
              pageReset: true,
              selectionClear: true
            })
          : this.scope.queryDetailById(this.scope.id))
      code !== 200 && (this.getCostInfomation = [])
      let beforegetCostInformationError = null
      let showgetCostInformationDialog = false
      if (
        typeof this.getCostInformationExternalHandlers
          ?.beforegetCostInformation === 'function'
      ) {
        const [err, res] = await getSyncAsyncReturns(
          this.getCostInformationExternalHandlers?.beforegetCostInformation,
          null,
          selections
        )
        beforegetCostInformationError = err
        showgetCostInformationDialog = res
      } else if (
        isPlainObject(
          this.getCostInformationExternalHandlers?.beforegetCostInformation
        ) &&
        typeof this.getCostInformationExternalHandlers.beforegetCostInformation
          ?.handler === 'function'
      ) {
        const [err, res] = await getSyncAsyncReturns(
          this.getCostInformationExternalHandlers?.beforegetCostInformation
            .handler,
          this.getCostInformationExternalHandlers?.beforegetCostInformation
            ?.context || null,
          selections
        )
        beforegetCostInformationError = err
        showgetCostInformationDialog = res
      } else {
        showgetCostInformationDialog = true
      }

      if (beforegetCostInformationError) {
        return void console.error(
          'Error in "beforegetCostInformation"',
          beforegetCostInformationError
        )
      }

      if (typeof showgetCostInformationDialog !== 'boolean') {
        return void console.error(
          'Expected boolean from "beforegetCostInformation", but accepted:',
          showgetCostInformationDialog
        )
      }
      this.getCostInformationVisible = showgetCostInformationDialog
    }
  }
}
