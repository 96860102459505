/**
 * @module api-web:businessTodo/csTickets/handle
 * @description 首页/客服工单待办  处理
 */
import service from '@/service'

/**
 * 客服工单处理记录新增
 * @param {{
 * attachmentUrlList: Array<string>,
 * processResult: string,
 * workOrderCode: string
 * }} data
 */
export function reply(data) {
  return service['api-web']({
    // url:  '/sell/work/serviceWorkOrderProcessRecord/save',
    url: '/public/processRecordSave',
    method: 'post',
    data
  })
}
