<template>
  <div class="filter-query-form-wrapper">
    <agel-form
      ref="form"
      v-model="form"
      :attach="attach"
      @submit.native.prevent
      @keyup.enter.native="$emit('query', $event)"
    >
      <slot />
    </agel-form>

    <!-- <FilterQueryFormButtons
      v-if="hasFilterQueryFormButtons"
      :query-button-text="queryButtonText"
      :reset-button-text="resetButtonText"
      :query-button-loading="queryButtonLoading"
      :reset-button-loading="resetButtonLoading"
      @query="$emit('query', $event)"
      @reset="$emit('reset', $event)"
    /> -->
  </div>
</template>

<script>
import { isString } from '@/utils/validate'
// import FilterQueryFormButtons from '@/components/FilterQueryFormButtons'
export default {
  name: 'FilterQueryForm',
  components: {
    // FilterQueryFormButtons
  },
  props: {
    hasFilterQueryFormButtons: {
      type: Boolean,
      default: true
    },
    /**
     * form model
     */
    value: {
      type: Object,
      required: true
    },
    /**
     * form data
     */
    attach: {
      type: Object,
      default: () => ({ data: {}, itemss: [] })
    },
    buttonText: {
      type: Object,
      default: () => ({
        query: 'query', // 为了凸显按钮权限文案与本地文案的区别，默认值使用 'query'
        reset: '重置'
      }),
      validator: (loading) =>
        Object.values(loading).every(
          (v) => v && isString(v) && v.trim().length > 0
        )
    },
    buttonLoading: {
      type: Object,
      default: () => ({
        query: false,
        reset: false
      }),
      validator: (loading) =>
        Object.values(loading).every((v) => typeof v === 'boolean')
    }
  },
  data() {
    return {}
  },
  computed: {
    queryButtonText() {
      return this.buttonText.query
    },
    resetButtonText() {
      return this.buttonText.reset
    },
    queryButtonLoading() {
      return this.buttonLoading.query
    },
    resetButtonLoading() {
      return this.buttonLoading.reset
    },
    form: {
      set(v) {
        this.$emit('change', v)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
