/**
 * @module utils/aes
 * @see https://cryptojs.gitbook.io/docs/
 */
import CryptoJS from 'crypto-js'
import { isString, isPlainObject, isArray } from '@/utils/validate'

import {
  DEFAULT_IV,
  DEFAULT_MODE,
  DEFAULT_PAD,
  DEFAULT_INPUT,
  DEFAULT_OUTPUT,
  DEFAULT_KV_PARSER,
  DEFAULT_FORMAT
} from './conf'

import { safeJsonParse } from '@/utils'
import { getKey } from '@/utils/auth'

/**
 * @typedef {Object} AesPayload
 * @property {string} key
 * @property {string=} iv
 * @property {string} [mode=ECB] CryptoJS.mode
 * @property {string} [pad=Pkcs] CryptoJS.pad
 * @property {string} [input=Hex] CryptoJS Encoders
 * @property {string} [output=base64] CryptoJS Encoders
 * @property {string} [format=utf8] CryptoJS Encoders
 * @property {boolean} [encode=true] 是否 URI 编码
 * @property {boolean} [decode=true] 是否 URI 解码
 * @see https://cryptojs.gitbook.io/docs/
 */

/**
 * @param {(object|string)} source
 * @param {AesPayload} options
 * @returns {string} return encrypted string, and will return original source when error
 */
const encrypt = (source, options = {}) => {
  try {
    let src
    if (source && isString(source)) {
      src = source
    } else if (isPlainObject(source) || isArray(source)) {
      src = JSON.stringify(source)
    } else {
      console.error('【AES】: Illeagl "source" for encrypt', source)
      return source
    }

    const {
      key = getKey(),
      iv = DEFAULT_IV,
      mode = DEFAULT_MODE,
      pad = DEFAULT_PAD,
      input = DEFAULT_INPUT.encryption,
      output = DEFAULT_OUTPUT.encryption,
      keyParser = DEFAULT_KV_PARSER.key,
      ivParser = DEFAULT_KV_PARSER.iv,
      // format = DEFAULT_FORMAT.encryption,
      encode = true
    } = options

    if (!validOptions('decrypt', { key, input, output, keyParser, ivParser })) {
      return source
    }

    const baseSettings = {
      mode: CryptoJS.mode[mode],
      padding: CryptoJS.pad[pad]
      // format: CryptoJS.format[format]
    }
    // (key && iv) words
    const keyBytes = CryptoJS.enc[keyParser].parse(key)
    const ivBytes = CryptoJS.enc[ivParser].parse(iv)
    // Parse Input
    const plaintext = CryptoJS.enc[input].stringify(
      CryptoJS.enc[input].parse(src)
    )
    // URI Encode
    const encoded = encode ? encodeURIComponent(plaintext) : ''
    // Encryption
    const encrypted = CryptoJS.AES.encrypt(
      encoded || plaintext,
      keyBytes,
      iv
        ? {
            iv: ivBytes,
            ...baseSettings
          }
        : baseSettings
    )
    // Get Encrypted Ciphertext
    const ciphertext = encrypted.ciphertext
    // Encode Output
    const result = CryptoJS.enc[output].stringify(ciphertext)
    // ------------------------------- debugger ---------------------------
    // console.groupCollapsed('【AES】encrypt')
    // console.log('source:', source)
    // console.log('key:', key)
    // console.log('key bytes:', keyBytes)
    // console.log('iv:', iv)
    // console.log('iv bytes:', ivBytes)
    // console.log('mode:', mode)
    // console.log('pad:', pad)
    // console.log('input:', input)
    // console.log('output:', output)
    // encode && console.log('encoded:', encoded)
    // console.log('encrypted:', encrypted)
    // console.log('ciphertext:', ciphertext)
    // result
    //   ? console.log('result:', result)
    //   : console.error('encryption fails, return the original source')
    // console.groupEnd()
    // unencrypt(result) // 调试自解密，解密打印出前端加密的密文
    //  ----------------- debugger -----------------
    return result || source
  } catch (error) {
    console.error('【AES】encrypt: error: ' + error)
    return source
  }
}

/**
 * 解密后端返回密文
 * @param {string} ciphertext
 * @param {AesPayload} options
 * @returns {string} return decrypted string, and will return original ciphertext when error
 */
const decrypt = (ciphertext, options = {}) => {
  try {
    if (!ciphertext || !isString(ciphertext)) {
      console.error(
        '【AES】decrypt: currently only supports string',
        ciphertext
      )
      return ciphertext
    }
    const {
      key = getKey(),
      iv = DEFAULT_IV,
      mode = DEFAULT_MODE,
      pad = DEFAULT_PAD,
      input = DEFAULT_INPUT.decryption,
      output = DEFAULT_OUTPUT.decryption,
      keyParser = DEFAULT_KV_PARSER.key,
      ivParser = DEFAULT_KV_PARSER.iv,
      format = DEFAULT_FORMAT.decryption,
      decode = false
    } = options

    if (!validOptions('encrypt', { key, input, output, keyParser, ivParser })) {
      return ciphertext
    }

    const baseSettings = {
      mode: CryptoJS.mode[mode],
      padding: CryptoJS.pad[pad],
      format: CryptoJS.format[format]
    }
    // (key && iv) words
    const keyBytes = CryptoJS.enc[keyParser].parse(key)
    const ivBytes = CryptoJS.enc[ivParser].parse(iv)
    // Parse Input
    const chipher = CryptoJS.enc[input].stringify(
      CryptoJS.enc[input].parse(ciphertext)
    )
    // Decryption
    const decrypt = CryptoJS.AES.decrypt(
      chipher,
      keyBytes,
      iv
        ? {
            iv: ivBytes,
            ...baseSettings
          }
        : baseSettings
    )
    // Utf-8
    const utf8String = CryptoJS.enc.Utf8.stringify(decrypt) ?? ''
    // URI Decoded
    const decoded = decode ? decodeURIComponent(utf8String) : ''
    // Crypto Decoded
    const outputString = CryptoJS.enc[output].stringify(
      CryptoJS.enc[output].parse(decoded || utf8String)
    )

    // Try Json Parse
    // eslint-disable-next-line no-unused-vars
    const [e, result] = safeJsonParse(outputString)

    //  ----------------- debugger -----------------
    // console.groupCollapsed('【AES】decrypt')
    // console.info('string type:', e ? 'normal' : 'json')
    // console.log('ciphertext:', ciphertext)
    // console.log('cipher:', chipher)
    // console.log('key:', key)
    // console.log('key bytes:', keyBytes)
    // console.log('iv:', iv)
    // console.log('iv bytes:', ivBytes)
    // console.log('mode:', mode)
    // console.log('pad:', pad)
    // console.log('input:', input)
    // console.log('output:', output)
    // console.log('decrypt:', decrypt)
    // console.log('utf-8:', utf8String)
    // decode && console.log('decoded:', decoded)
    // console.log('outputString:', outputString)
    // result
    //   ? console.log('result:', result)
    //   : console.error('decryption fails, return the original ciphertext')
    // console.groupEnd()
    //  ----------------- debugger -----------------

    return result || ciphertext
  } catch (error) {
    console.error('【AES】decrypt: error ' + error)
    return ciphertext
  }
}
/**
 * 自解密前端密文
 * @param {string} ciphertext
 * @param {AesPayload} options
 * @returns {string} return un-ecrypted string, and will return original ciphertext when error
 */
const unencrypt = (ciphertext, options = {}) => {
  try {
    if (!ciphertext || !isString(ciphertext)) {
      console.error(
        '【AES】unecrypt: currently only supports string',
        ciphertext
      )
      return ciphertext
    }
    const {
      key = getKey(),
      iv = DEFAULT_IV,
      mode = DEFAULT_MODE,
      pad = DEFAULT_PAD,
      input = DEFAULT_OUTPUT.encryption,
      output = DEFAULT_INPUT.encryption,
      keyParser = DEFAULT_KV_PARSER.key,
      ivParser = DEFAULT_KV_PARSER.iv,
      // format = DEFAULT_FORMAT.encryption,
      decode = true
    } = options

    if (
      !validOptions('unencrypt', { key, input, output, keyParser, ivParser })
    ) {
      return ciphertext
    }

    const baseSettings = {
      mode: CryptoJS.mode[mode],
      padding: CryptoJS.pad[pad]
      // format: CryptoJS.format[format]
    }

    // (key && iv) words
    const keyBytes = CryptoJS.enc[keyParser].parse(key)
    const ivBytes = CryptoJS.enc[ivParser].parse(iv)
    // Parse Input
    const chipher = CryptoJS.enc[input].stringify(
      CryptoJS.enc[input].parse(ciphertext)
    )
    // Unecryption
    const unencrypted = CryptoJS.AES.decrypt(
      chipher,
      keyBytes,
      iv
        ? {
            iv: ivBytes,
            ...baseSettings
          }
        : baseSettings
    )
    // Utf-8
    const utf8String = unencrypted.toString(CryptoJS.enc.Utf8) ?? ''
    // URI Decoded
    const decoded = decode ? decodeURIComponent(utf8String) : ''

    // Crypto Decoded
    const outputString = CryptoJS.enc[output].stringify(
      CryptoJS.enc[output].parse(decoded || utf8String)
    )
    // Try Json Parse
    // eslint-disable-next-line no-unused-vars
    const [e, result] = safeJsonParse(outputString)
    //  ----------------- debugger -----------------
    // console.groupCollapsed('【AES】unencrypt')
    // console.info('string type:', e ? 'normal' : 'json')
    // console.log('ciphertext:', ciphertext)
    // console.log('cipher:', chipher)
    // console.log('key:', key)
    // console.log('key bytes:', keyBytes)
    // console.log('iv:', iv)
    // console.log('iv bytes:', ivBytes)
    // console.log('mode:', mode)
    // console.log('pad:', pad)
    // console.log('input:', input)
    // console.log('output:', output)
    // console.log('unencrypted:', unencrypted)
    // console.log('utf-8:', utf8String)
    // decode && console.log('decoded:', decoded)
    // console.log('outputString:', outputString)
    // result
    //   ? console.log('result:', result)
    //   : console.error('unencryption fails, return the original ciphertext')
    // console.groupEnd()
    //  ----------------- debugger -----------------

    return result || ciphertext
  } catch (error) {
    console.error('【AES】unencrypt: ' + error)
    return ciphertext
  }
}

export const AES = {
  encrypt,
  unencrypt,
  decrypt
}
export default AES

/**
 * @param {('encrypt'|'decrypt'|'unencrypt')} from
 * @param {*} options
 * @returns {boolean}
 */
function validOptions(from, { key, input, output, keyParser, ivParser }) {
  if (!key) {
    console.error(`【AES】${from}: Losed "key"`)
    return false
  }
  if (!isString(key)) {
    console.error(`【AES】${from}: invalid "key"`, key)
    return false
  }

  if (
    !CryptoJS.enc[input] ||
    typeof CryptoJS.enc[input].stringify !== 'function' ||
    typeof CryptoJS.enc[input].parse !== 'function'
  ) {
    console.error(`【AES】${from}: invalid "input"`, input)
    return false
  }

  if (
    !CryptoJS.enc[output] ||
    typeof CryptoJS.enc[output].stringify !== 'function' ||
    typeof CryptoJS.enc[output].parse !== 'function'
  ) {
    console.error(`【AES】${from}: invalid "output" `, output)
    return false
  }

  if (typeof CryptoJS.enc[keyParser]?.parse !== 'function') {
    console.error(`【AES】${from}: invalid "keyParser" `, keyParser)
    return false
  }

  if (typeof CryptoJS.enc[ivParser]?.parse !== 'function') {
    console.error(`【AES】${from}: invalid "ivParser"`, ivParser)
    return false
  }

  return true
}
