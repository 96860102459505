<template>
  <div :class="{ 'has-logo': showLogo }">
    <Logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        ref="menuRef"
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="true"
        mode="vertical"
      >
        <SidebarItem
          v-for="route in menus"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          @click="onClick"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import { isString } from '@/utils/validate'
import { EventBus } from '@/eventBus'

export default {
  components: { SidebarItem, Logo },
  provide() {
    return {
      generateMenukey: this.generateMenukey
    }
  },
  data() {
    return {
      navHasClicked: false,
      defaultOpen: '/home',
      defaultActiveSubMenu: ''
    }
  },
  computed: {
    ...mapGetters(['menus', 'sidebar']),
    initOpenPath() {
      return this.navHasClicked ? [] : [this.defaultOpen]
    },
    activeMenu() {
      const route = this.$route
      const { path } = route

      return this.getActiveIndex(path)
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },

  created() {
    EventBus.$on('click-link', this.handleClickingLink)
  },

  mounted() {
    const openedMenus = this.$refs.menuRef.openedMenus
    this.defaultActiveSubMenu = openedMenus[openedMenus.length - 1]
  },

  beforeDestroy() {
    EventBus.$off('click-link', this.handleClickingLink)
  },

  methods: {
    generateMenukey(route) {
      // console.log(
      //   route.path,
      //   JSON.parse(JSON.stringify(route)),
      //   JSON.parse(JSON.stringify(route.meta))
      // )
      try {
        if (route.meta && isString(route.meta.title)) {
          return `${route.path}${encodeURIComponent(route.meta.title)}`
        } else {
          return route.path
        }
      } catch (error) {
        console.log(error)
      }
      return route.path
    },
    onClick(item) {
      const splited = item.path.split('/').filter((v) => !!v)
      if (splited.length > 1) {
        const parent = `/${splited[0]}`
        if (parent !== this.defaultOpen) {
          if (!this.navHasClicked) this.navHasClicked = true
        }
      }
    },

    getActiveIndex(path) {
      let _path = path
      if (path.includes('/home/myTasks')) {
        _path = '/home/myTasks'
      } else if (path.includes('/data/basic')) {
        _path = '/data/basic'
      } else if (['/list', '/detail'].some((char) => path.includes(char))) {
        _path = _path.replace(/\/(list|detail).*/, '') // 去掉/list或者/detail及其之后的内容
        _path = _path + '/list/:id'
      }

      return _path
    },

    handleClickingLink() {
      const route = this.$route
      const { path } = route

      this.$refs.menuRef.open(this.defaultActiveSubMenu)

      this.$refs.menuRef.activeIndex = this.getActiveIndex(path)
    }
  }
}
</script>
