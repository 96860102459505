//  polyfill for element.remove
;(function (arr) {
  arr.forEach(function (item) {
    if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode && this.parentNode.removeChild(this)
      }
    })
  })
})(
  [Element.prototype, CharacterData.prototype, DocumentType.prototype].filter(
    Boolean
  )
)

/**
 * @module directive/permission
 * @description 权限控制指令
 */
import store from '@/store'
import { isArray } from '@/utils/validate'
/**
 * 鉴权
 *
 * @description
 *
 * 须传入资源 `id` 或者 `name` 的数组，支持多资源同时控制权限
 * 如果需要根据 资源 `id` 需要额外添加修饰符，如 `v-permission.id="[1]"` 没有修饰符则默认为 `name`
 *
 * 如果需要多个资源控制 UI 层的按钮等元素，可以额外添加修饰符 `some` 或 `every` 来定义 `和` 、`或`
 * 如 `v-permission.some.id="[1,2]"` 没有则默认为 `every`
 *
 * - `every`: 需拥有所有传入的权限
 * - `some`:  拥有其中一个权限即可
 *
 * **另外，推荐优先使用 `name` 的方式**， 也就是没有情况始终不使用 `id` 修饰符
 *
 * @useage
 * ```html
 *  <div v-permission.some="['update','save']"></div>
 *  <div v-permission="['delete','deleteAll']"></div>
 *  <div v-permission="['info']"></div>
 *  <div v-permission.id="[1]"></div>
 *  <div v-permission.id.some="[1,2]"></div>
 *  <div v-permission.fullname="['web:sys.sysDept.save']"></div>
 * ```
 */
function checkPermission(el, binding, vnode) {
  let hasPermission = false
  try {
    const {
      value: resources,
      modifiers: {
        some = false, // 只需要满足至少一个
        // every = true, // 都须满足 default is true
        id = false, // 根据 ID 判断
        name = true, // 根据 name 判断 （来源后端 pmUrl 字段） default is true
        fullname = false // name 是否完整
      }
    } = binding
    const bySome = some
    const byId = id
    const byName = !id || name
    const byFullname = byName && fullname

    if (isArray(resources) && resources.length) {
      const resourcePool = byId
        ? Object.keys(store.getters.pmIdx).map((v) => +v)
        : Object.keys(store.getters.pmNamex)
      if (isArray(resourcePool) && resourcePool.length) {
        const checkingResources =
          byName && !byFullname && !byId
            ? resources.map(
                // vnode.context === this === vm
                (resource) =>
                  `${vnode.context.$route.meta.namespace}.${resource}`
              )
            : byFullname || byId
            ? resources
            : []
        if (checkingResources.length) {
          hasPermission = bySome
            ? checkingResources.some((resource) =>
                resourcePool.includes(resource)
              )
            : checkingResources.every((resource) =>
                resourcePool.includes(resource)
              )
          // console.groupCollapsed('当前鉴权信息(directive):')
          // console.groupCollapsed('current resource pool')
          // console.log(resourcePool)
          // console.groupEnd()
          // console.log('hasPermission:', hasPermission)
          // console.log('checkingResources:', checkingResources)
          // console.groupEnd()
        } else {
          console.error(
            'Please check "modifiers", only support ".id" 、".name" and ".fullname"',
            resources
          )
        }
      } else {
        console.warn('Empty "resourceSet" [directive]: ', resources)
      }
    } else {
      console.error('Permission Control Error [directive]: ', resources)
    }
  } catch (error) {
    console.error(error)
  }

  if (!hasPermission) {
    try {
      el.remove()
    } catch (error) {
      console.error(error)
      try {
        el.parentNode && el.parentNode.removeChild(el)
      } catch (err) {
        el.style.display = 'none'
        console.error(err)
      }
    }
  }
}

export default {
  inserted(el, binding, vnode) {
    checkPermission(el, binding, vnode)
  },
  update(el, binding, vnode) {
    checkPermission(el, binding, vnode)
  }
}
