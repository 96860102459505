<template>
  <div v-if="show">
    <div class="form-part">
      <!-- tabs 嵌套切换显示 -->
      <FullTabs
        v-if="useTabDisplay"
        ref="full-tabs"
        :layout="layout"
        :tabs="tabs"
        :titles="titles"
        :show-handle-form="showHandleForm"
        :default-form-model="defaultFormModel"
        :show-table-alone="showTableAlone"
        :form-items="proxyFormItems"
        :table-header="tableHeader"
        :process-id="processId"
        :document-code="documentCode"
        :process-submitter="processSubmitter"
        :dynamic-form-label="dynamicFormLabel"
      />
      <!-- 单个显示 -->
      <template v-else>
        <div v-if="layout === 'form'" class="the-form">
          <BlueTitleText v-if="showFormTitle" :title-name="titles.form" />
          <agel-form
            v-model="model.form"
            :attach="{ items: proxyFormItems }"
            class="agel-input-audit-reset"
          />
        </div>
        <div v-else-if="layout === 'table'" class="the-table">
          <BlueTitleText v-if="showTableTitle" :title-name="titles.table" />
          <base-inline-edit-table
            ref="backlog-audit-process-node-table"
            table-key="backlog-audit-process-node-table"
            drag-column-key="BacklogAuditTab"
            :crud-local="['read']"
            :has-tool-bar="false"
            :disable-operate="true"
            :table-header="tableHeader"
            :table-loading.sync="model.table.loading"
            :value="model.table.data"
            cell-placeholder="⏤"
            external-class="backlog-audit-biet"
          >
            <template #stateDefault="{ row }">
              <LabelPickerColorCircleMark
                dict="sys.processNodeStatus"
                :value="row.state?.value"
                :color="{
                  1: 'danger', // 驳回
                  0: 'warning', // 当前
                  '-1': 'success' // 完成
                }"
              />
            </template>
            <template #durationDefault="{ row }">
              <span>{{ processDuration(row) }}</span>
            </template>
          </base-inline-edit-table>
        </div>
        <div v-else-if="layout === 'diagram'" class="the-diagram">
          <BlueTitleText v-if="showDiagramTitle" :title-name="titles.diagram" />

          <ProcessDesign
            v-bind="model.diagram"
            :instance-id="processId"
            :document-code="documentCode"
            :process-submitter="processSubmitter"
          />
        </div>
        <div v-else>Not Support Layout</div>
      </template>
    </div>

    <div
      v-if="showTableAlone"
      class="form-part the-table padding-bottom"
      :class="{ 'is-collapse': !isExpand }"
    >
      <BlueTitleText
        :title-name="titles.table"
        show-expand-button
        @toggle="onToggleExpand"
      />

      <base-inline-edit-table
        ref="backlog-audit-process-node-table"
        table-key="backlog-audit-process-node-table"
        drag-column-key="BacklogAuditTab"
        :crud-local="['read']"
        :has-tool-bar="false"
        :disable-operate="true"
        :table-header="tableHeader"
        :table-loading.sync="model.table.loading"
        :value="model.table.data"
        cell-placeholder="⏤"
        external-class="backlog-audit-biet"
      >
        <template #stateDefault="{ row }">
          <LabelPickerColorCircleMark
            dict="sys.processNodeStatus"
            :value="row.state?.value"
            :color="{
              1: 'danger', // 驳回
              0: 'warning', // 当前
              '-1': 'success' // 完成
            }"
          />
        </template>
        <template #durationDefault="{ row }">
          <span>{{ processDuration(row) }}</span>
        </template>
      </base-inline-edit-table>
    </div>
  </div>
</template>

<script>
import FullTabs from './FullTabs'
import BlueTitleText from '@/components/BlueTitleText'
import LabelPickerColorCircleMark from '@/components/LabelPickerColorCircleMark'
import ProcessDesign from '@/components/ProcessDesign'
import mixin from './mixin'

export default {
  name: 'BacklogAuditTab',
  components: {
    FullTabs,
    BlueTitleText,
    LabelPickerColorCircleMark,
    ProcessDesign
  },
  mixins: [mixin],

  props: {
    // 将处理环节拆出来，单独显示, 见需求2642
    showTableAlone: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isExpand: true
    }
  },

  computed: {
    /**
     * @returns {boolean} show the tabs
     */
    useTabDisplay() {
      return this.layout.includes('|')
    },
    /**
     * @returns {{form:object,table:{loading:boolean,data:array},diagram:{type:('instance'|'definition'),mode:('edit'|'view'|'create')}}}
     */
    processHandle() {
      if (this.useTabDisplay) {
        const _model = this.$refs['full-tabs']?.model
        return _model
      } else return this.model
    }
  },
  watch: {
    layout: {
      immediate: true,
      handler(layout) {
        if (layout === 'table') this.generateProcessHistoryTable()
      }
    }
  },

  created() {
    if (this.showTableAlone) {
      this.generateProcessHistoryTable()
    }
  },

  methods: {
    onToggleExpand(isExpand) {
      this.isExpand = isExpand
    }
  }
}
</script>

<style lang="scss" scoped>
.form-part.the-table.padding-bottom {
  padding-bottom: 16px;
}
</style>
