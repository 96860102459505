/**
 * @module api-activiti:home/todo/handle
 * @description 待办审核
 */
import service from '@/service'

/**
 *  流程待办：拾取任务
 * @param {string} taskId
 * @deprecated 后端处理拾取，前端不再需要调用拾取接口
 */
export function claim(taskId) {
  return service['api-activiti']({
    url: '/sys/process/task/claim',
    method: 'put',
    data: { taskId },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *  流程待办：完成任务
 * @param {{processInstanceId:string,taskId:string,remarks:string}} data`
 * @description 如果任务未拾取，先拾取再完成审核
 * @deprecated remarks参数文本中，如果有 % 字符，导致接口报错 500, 弃用此接口，改用批量审核
 * @see completeTasks
 */
export function complete(data) {
  return service['api-activiti']({
    url: '/sys/process/task/complete',
    method: 'put',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *  流程待办：重新提交
 * @param {{processInstanceId:string,taskId:string,remarks:string}} data`
 * @description
 */
export function resubmit(data) {
  return service['api-activiti']({
    url: '/sys/process/task/resubmit',
    method: 'put',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  流程待办：批量完成任务
 * @param {Array<{processInstanceId:string,taskId:string,remarks:string}>} data`
 * @description 如果任务未拾取，先拾取再完成审核
 */
export function completeTasks(data) {
  return service['api-activiti']({
    url: '/sys/process/task/completeTasks',
    method: 'put',
    data
  })
}
/**
 *  流程待办：驳回
 * @param {object} data
 */
export function reject(data) {
  return service['api-activiti']({
    url: '/sys/process/task/reject',
    method: 'put',
    data
  })
}
