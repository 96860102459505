<template>
  <el-tooltip
    :content="proxyContent"
    :disabled="proxyDisabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </el-tooltip>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'AsrTooltip',
  props: {
    content: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minLengthToHide: {
      type: Number,
      default: 11
    },

    // 编辑模式时是否显示tooltip
    previewOnEditMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerContent: '',
      slotInstance: null
    }
  },
  computed: {
    proxyOptions() {
      const { options, selected } = this.slotInstance ?? {}
      return selected ?? options
    },

    proxyContent() {
      const { value } = this.slotInstance ?? {}
      let innerContent = ''

      if (Array.isArray(this.proxyOptions)) {
        innerContent = this.proxyOptions
          .map((item) => item.label ?? item.labelName)
          .join()
      } else {
        innerContent = value ?? ''
      }

      return this.content || innerContent
    },

    proxyDisabled() {
      const { disabled } = this.slotInstance ?? {}
      const isEditMode = !disabled // 当disabled为true时，说明此时处于非编辑状态，需要展示tooltip

      let baseCondition = this.disabled || isEditMode

      if (this.previewOnEditMode) {
        baseCondition = this.disabled
      }

      const hasCollapseTags = this.proxyOptions?.length > 1 // select组件中是否有折叠项
      if (hasCollapseTags) {
        // 有折叠项时，不需要判断内容长度
        return baseCondition
      } else {
        return baseCondition || this.proxyContent.length < this.minLengthToHide
      }
    }
  },

  mounted() {
    const slot = this.$slots.default[0]
    if (!slot) {
      return
    }

    const { tag } = slot

    // 由于slot中的数据是非响应式的，故使用Vue.observable包裹
    if (/agel-select|agel-tree-select/.test(tag)) {
      this.slotInstance = Vue.observable(slot.componentInstance.$children[0])
    } else {
      this.slotInstance = Vue.observable(slot.componentInstance)
    }
  }
}
</script>
