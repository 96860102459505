<template>
  <!-- 非编辑模式 renderCell -->
  <span>{{ pureLabel }}</span>
</template>
<script>
import { isArray, isPlainObject, isString } from '@/utils/validate'
import { BIET_RENDER_INJECT_NAMESPACE } from '@/constants'
export default {
  /**
   * 基于 el-select
   * @see https://element.eleme.io/#/zh-CN/component/select
   */
  name: 'ElPickView',
  props: {
    scope: {
      type: Object,
      default: () => ({
        row: {},
        column: {}
      })
    }
  },
  data() {
    return {
      options: []
    }
  },
  computed: {
    $editRender() {
      return this.$attrs['edit-render-props']
    },
    $elSelect() {
      const { multiple, options, allowShowValueDirectly, ...restProps } =
        this.$attrs['el-select-props']
      const { change, focus, ...restEvents } = this.$listeners
      return {
        options,
        multiple,
        allowShowValueDirectly, // 批量粘贴时，允许直接回显粘贴的值，而不是在options先筛选一遍
        change,
        focus,
        events: restEvents,
        props: restProps
      }
    },
    proxyOptions() {
      return this.$elSelect.options
    },
    pureLabel() {
      const options = this.options
      const cellValue =
        this.scope.row && this.scope.column?.property
          ? this.scope.row[this.scope.column?.property]
          : null
      try {
        // console.log('cellValue:', cellValue, options)
        // 有下拉选项
        if (isArray(options) && options.length) {
          let cellValues = []
          if (typeof cellValue === 'number') cellValues = [cellValue]
          else if (isString(cellValue) && cellValue.trim()) {
            cellValues = cellValue
              .split(',')
              .filter(
                (v) => typeof v === 'number' || !!(isString(v) && v.trim())
              )
          } else if (isArray(cellValue)) {
            cellValues = cellValue.filter(
              (v) => typeof v === 'number' || !!(isString(v) && v.trim())
            )
          }
          if (cellValues.length === 1) return this.findLabel(cellValues[0])
          else {
            return cellValues
              .map((v) => this.findLabel(v))
              .filter((v) => !!v)
              .join()
          }
        } else {
          // 无下拉选项（异步正在获取、获取失败、获取到空列表, 字符串（联动下拉）需要编辑时初始化等）
          const inject = this.$editRender[BIET_RENDER_INJECT_NAMESPACE]
          const hasInject = isPlainObject(inject)
          if (hasInject) {
            const shim = inject?.shim
            const hasShim = isPlainObject(shim)
            if (hasShim) {
              if (shim?.field && isString(shim.field)) {
                // 显示同一 row 上其他字段
                const label = this.scope.row[shim.field]
                if (label && isString(label)) return label.trim()
              } else if (shim?.label && isString(shim.label)) {
                // 显示外部配置的 label
                return shim.label.trim()
              }
            }
          } else return isArray(cellValue) ? cellValue.join() : cellValue
        }
      } catch (err) {
        console.error('【ElPickView】:', err)
      }

      return cellValue
    }
  },
  mounted() {
    this.initOptions(isString(this.proxyOptions) ? [] : this.proxyOptions)
  },
  methods: {
    async initOptions(options) {
      try {
        if (options instanceof Promise) {
          await this.setOptionsByPromise(options)
        } else if (typeof options === 'function') {
          const res = options()
          if (res instanceof Promise) {
            await this.setOptionsByPromise(res)
          } else this.setOptions(res)
        } else this.setOptions(options)
      } catch (error) {
        this.setOptions([])
        console.error(error)
      }
    },
    setOptions(options) {
      if (isArray(options)) {
        this.options = options
      } else console.error('【ElPickView】: illeagl "options"', options)
    },
    setOptionsByPromise(options) {
      options
        .then(this.setOptions)
        .catch((error) => console.error('【ElPickView】:', error))
    },
    findLabel(value) {
      const _v = isString(value) ? value.trim() : value
      const label =
        this.options.find((item) => item.value === _v)?.label.trim() || ''
      return this.$elSelect.allowShowValueDirectly ? _v : label
    }
  }
}
</script>
<style lang="scss" scoped></style>
