/**
 * @module api-scm:supplierSampleConfirm
 */
import service from '@/service'

/**
 * @typedef {Object} supplierSampleConfirm -- 供应商优化
 * @property {number=} [limit=1] -
 * @property {number} [page=10] - 页码
 * @property {string} sort -
 * @property {string} optimizeType - 优化类型
 * @property {string} supplierName - 供应商名称
 * @property {string} applyTime - 申请时间
 *
 * @param {supplierSampleConfirm}
 */

export function getList(params) {
  return service['api-scm']({
    url: '/scm/supplier/supplierSampleConfirm/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/supplier/supplierSampleConfirm/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/supplierSampleConfirm/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/supplier/supplierSampleConfirm/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/supplier/supplierSampleConfirm/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/supplier/supplierSampleConfirm/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 获取此供应商下的所有商品
 * @param {{keywords:string,supplierCode:string}}
 */
export function getListDown(params) {
  return service['api-scm']({
    url: '/scm/supplier/supplierSampleConfirm/getSupplierProduct',
    method: 'get',
    params
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/supplierSampleConfirm/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/supplier/supplierSampleConfirm/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 作废
 * @param {(string|number)} id
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/supplier/supplierSampleConfirm/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/supplier/supplierSampleConfirm/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
