// eslint-disable-next-line spaced-comment
/// <reference path="typedef.js" />
import { isString } from '@/utils/validate'

/**
 * @module permission/utils
 */

/**
 * 向上遍历父节点
 * @param {PermissionIndexTable} indexTable
 * @param {RolePermissionFlatItem} node
 * @param {Array<string>} iterator
 * @param {string=} key default ''
 * @param {Function=} suffice Include the item when the conditions are met
 * @returns {Array} parent and children children chain array
 */
export function recursiveParents(
  indexTable,
  node,
  iterator,
  key = '',
  suffice = () => true
) {
  const { pid } = node
  const parent = indexTable.idIndex[pid]
  if (parent) {
    if (typeof suffice === 'function') {
      key
        ? parent[key] !== undefined && parent[key] !== null && suffice(parent)
          ? iterator.unshift(parent[key])
          : null
        : suffice(parent)
        ? iterator.unshift(parent)
        : null
    } else {
      key
        ? parent[key] !== undefined && parent[key] !== null
          ? iterator.unshift(parent[key])
          : null
        : suffice(parent)
        ? iterator.unshift(parent)
        : null
    }
    return recursiveParents(indexTable, parent, iterator, key, suffice)
  } else {
    return iterator
  }
}

export function validPath(path) {
  // path === 'nested-nav' && // 内嵌导航同样添加到路由中，隐藏
  return path && isString(path)
}
