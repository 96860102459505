/**
 * @module utils/datetime
 */

import { isNumeric } from './validate'

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {(string|null)}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    ms: date.getMilliseconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/* 获取当前时间 */
export function getNowFormatDate() {
  const date = new Date()
  const seperator1 = '-'
  const seperator2 = ':'
  let month = date.getMonth() + 1 // 月
  let strDate = date.getDate() // 日
  let hours = date.getHours() // 时
  let minutes = date.getMinutes() // 分
  let seconds = date.getSeconds() // 秒
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  if (hours >= 0 && hours <= 9) {
    hours = '0' + hours
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = '0' + minutes
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = '0' + seconds
  }
  var currentdate =
    date.getFullYear() +
    seperator1 +
    month +
    seperator1 +
    strDate +
    ' ' +
    hours +
    seperator2 +
    minutes +
    seperator2 +
    seconds
  return currentdate
}

/**
 *
 * @param {string} date - yyyy-mm-dd hh:mm:ss
 * @param {string} splitor - 分隔符
 * @returns {string}
 * @description 格式化日期
 */
export function ymdFormat(date = '', splitor = '-') {
  return parseTime(date, `{y}${splitor}{m}${splitor}{d}`)
}
// TODO:
// export function increaseByHours(params) {}
/**
 * 日期计算：起始日期+天数
 * @param {(Date|string|number)} start - Date instance, date string , timestamp
 * @param {number} days
 * @returns {Date}
 */
export function increaseByDays(start, days) {
  const date = start instanceof Date ? start : new Date(start)

  if (isNaN(date)) {
    console.error(
      'Illegal "start":',
      start,
      ', must be Date instance, valid date string or timestamp'
    )
    return null
  }
  if (!isNumeric(days)) {
    console.error('Illegal "days":', days, ', must be numeric')
    return null
  }

  date.setDate(date.getDate() + days * 1)
  return date
}

/**
 * 传入日期和天数，计算出截止日期/时间
 * @param {(Date|string|number)} start - Date instance, date string , timestamp
 * @param {number} days
 * @param {string} [format='{y}-{m}-{d}'] 自定义格式 @see parseTime
 * @returns {string}
 */
export function getExpiredDate(start, days, format = '{y}-{m}-{d}') {
  return parseTime(increaseByDays(start, days), format)
}

/**
 * 传入日期和天数，计算出截止时间
 * @param {(Date|string|number)} start - Date instance, date string , timestamp
 * @param {number} days
 * @returns {string}
 */
export function getExpiredDatetime(start, days) {
  return parseTime(increaseByDays(start, days), '{y}-{m}-{d} {h}:{i}:{s}')
}

/**
 * 获取当前日期,并转化为yyyy-MM-dd HH:mm:ss
 */
export function getNowDateTime() {
  return parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}')
}

/**
 *
 * @param {any} time1
 * @param {any} time2
 * @param {{format: string}} options
 * @returns boolean
 * @description 判断time1是否小于time2
 */
export function compareTime(
  time1,
  time2,
  options = {
    format: '{y}-{m}-{d} {h}:{i}:{s}'
  }
) {
  const { format } = options
  return (
    new Date(parseTime(time1, format)).getTime() <
    new Date(parseTime(time2, format)).getTime()
  )
}

/**
 * @description 获取日期并转为六位数的形式
 */
export function getSixDigitsDate(date) {
  // 获取日期
  const _date = date ? new Date(date) : new Date()

  // 获取年、月、日
  const year = _date.getFullYear().toString().slice(2) // 获取年份的后两位
  const month = (_date.getMonth() + 1).toString().padStart(2, '0') // 月份从0开始，需要加1
  const day = _date.getDate().toString().padStart(2, '0')

  // 将年、月、日拼接成指定格式
  const formattedDate = year + month + day

  return formattedDate
}

/**
 * @description 两个日期的年月日是否完全相同
 * @param {*} date1
 * @param {*} date2
 * @returns boolean
 */
export function isSameDate(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}
