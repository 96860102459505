import { default as getPropByStringPath } from 'lodash.get'
import { set as setNestedProp } from 'lodash'
import { isNumeric, isPlainObject, isString } from './validate'
import { rounding, roundingFixed } from './number'
import { getAnyValue } from '.'
import {
  BIET_RENDER_INJECT_NAMESPACE,
  BIET_ROW_EXTERNAL_NAMESPACE
} from '../constants'
/**
 * inject 可以自由的扩展配置 editRender of vxe-table
 * @param {object} obj
 * @returns {Record<string,object>}
 * @description 在 vxe-table 官方 API editRender 等配置上，统一的命名空间上，注入一些用于 BaseInlineEditTable 开展业务或者一些自定义渲染器独有业务的配置
 * @useage `this.$biet.inject(object)`
 * @example
 * ```js
 * this.$biet.inject({ shim:{ field:String, label:String } })
 * ```
 * - shim.field，shim.label => 用于自定义渲染器 ElPicker 在无下拉的时候或者下拉项被禁用或者未通过审核时无法正常匹配回显，可以指定一个后端 xxxName 字段来回显
 * - field 会使用当前行的其他字段 row[field]
 * - label 会直接显示 label
 */
export function inject(obj) {
  return { [BIET_RENDER_INJECT_NAMESPACE]: isPlainObject(obj) ? obj : {} }
}
/**
 * dig， get injections props that via this.$biet.inject
 * @param {Object} editRender
 * @param {string} strPathOfObj eg: 'xxxprop', 'o.a.b'
 * @useage `this.$biet.dig(editRender,'shim.field')`
 */
export function dig(editRender, strPathOfObj) {
  const injected = editRender?.[BIET_RENDER_INJECT_NAMESPACE]
  return isPlainObject(injected) && isString(strPathOfObj) && strPathOfObj
    ? getPropByStringPath(injected, strPathOfObj)
    : undefined
}
/**
 * set row external prop that added by base-inline-edit-table
 * @param {*} row -  row of base-inline-edit-table
 * @param {string} strPathOfObj - eg: 'xxxprop', 'o.a.b'
 */
export function setRowExt(row, strPathOfObj, value) {
  const external = row?.[BIET_ROW_EXTERNAL_NAMESPACE]
  isPlainObject(external) && isString(strPathOfObj) && strPathOfObj
    ? setNestedProp(external, strPathOfObj, value)
    : undefined
}
/**
 * get row external prop that added by base-inline-edit-table
 * @param {*} row -  row of base-inline-edit-table
 * @param {string} strPathOfObj - eg: 'xxxprop', 'o.a.b'
 * @returns {any}
 */
export function getRowExt(row, strPathOfObj) {
  const external = row?.[BIET_ROW_EXTERNAL_NAMESPACE]
  return isPlainObject(external) && isString(strPathOfObj) && strPathOfObj
    ? getPropByStringPath(external, strPathOfObj)
    : undefined
}
/**
 * @param {*}
 * @description vxe table 更新行：比如，biet 统一命名空间字段，挂载行联动下拉
 */
export async function updateRow({
  row = {},
  column = {},
  $table = {},
  payload = {}
}) {
  if (typeof $table?.isInsertByRow !== 'function') {
    console.error('losed $table')
    return false
  }

  const promises = Object.entries(payload).map(async ([k, v]) => {
    const _v = await getAnyValue(v)

    const _setter = (r, k, v) => {
      const _r = r
      /**
       * TODO: 根据字段名 includes('.') 判断来 external 中挂载变量不严谨
       */
      if (k.includes('.')) {
        setRowExt(_r, k, v)
      } else _r[k] = v

      return _r
    }

    if ($table.isInsertByRow(row)) {
      // 新增
      const records = $table.getInsertRecords()
      if (records.length === 1) {
        const record = _setter(records[0], k, _v)
        await $table.createRow(record)
      } else console.warn('Not support mutiple records for insert')
    } else {
      // 编辑：
      const record = _setter({ ...row }, k, _v)
      setRowExt(record, 'customUpdate', true)
      await $table.reloadRow(row, record)
      await $table.updateStatus({ row, column })
    }
  })

  await Promise.all(promises)
}
/**
 * sumNum
 * @param {Array} [list=[]]
 * @param {string} [field='']
 * @param {{fixed:number,start:number}} [options={fixed:0,start:0}]
 * @return {(string|null)}
 * @description 表尾数据求和
 */
export function sumNum(
  list = [],
  field = '',
  options = { fixed: 0, start: 0 }
) {
  try {
    const { start = 0, fixed = 0 } = options || {}

    const sum = roundingFixed(
      list.reduce(
        (prev, curr) => prev + rounding(curr[field], fixed),
        rounding(start, fixed)
      ),
      fixed
    )
    if (isNumeric(sum)) return sum
  } catch (error) {
    console.error(error)
  }

  return null
}

/**
 * @param {Array<string>} fields 字段名
 * @param {Array<string>=} fixes 可选：字段名拼接后缀
 * @returns {Array<{label:string,prop:string,visible:false}>}
 * @description 创建隐藏列,通常用于逻辑组织
 *
 * @useage
 * ```js
 * createHiddenColumns(['a','b'])
 * // 输出
 * [{"label": "a","prop": "a","visible": false},
 * {"label": "b","prop": "b","visible": false}]
 *
 * createHiddenColumns(['a','b'],[1,2])
 * // 输出
 * [{"label": "a1","prop": "a1","visible": false},
 * {"label": "a2","prop": "a2","visible": false},
 * {"label": "b1","prop": "b1","visible": false},
 * {"label": "b2","prop": "b2","visible": false}]
 * ```
 */
export function createHiddenColumns(fields = [], fixes = []) {
  try {
    const hiddenFields = fields
      .map((field) =>
        fixes.length
          ? fixes.map((fix) => ({
              label: `${field}${fix}`,
              prop: `${field}${fix}`,
              visible: false
            }))
          : [
              {
                label: field,
                prop: field,
                visible: false
              }
            ]
      )
      .flat()
    console.log('【createHiddenColumns】当前表头隐藏列', hiddenFields)
    return hiddenFields
  } catch (error) {
    console.error(error)
    return []
  }
}

/**
 * @description 表格所有选择项
 * @param {Object} $table vxeTable 表格属性
 * @param {Boolean} records 当前表格列表页的勾选项
 * @param {Boolean} reserveRecords 除当前表格列表页的勾选项
 * @returns {Array} all checkbox selections
 */
export function allCheckboxSelection(
  $table,
  records = false,
  reserveRecords = false
) {
  try {
    const recordsData = $table.getCheckboxRecords(
      typeof records === 'boolean' ? records : false
    )
    const reserveRecordsData = $table.getCheckboxReserveRecords(
      typeof reserveRecords === 'boolean' ? reserveRecords : false
    )
    return [...recordsData, ...reserveRecordsData]
  } catch (error) {
    console.error('【allCheckboxSelection】', $table, error)
    return []
  }
}

/**
 * @description 表格选择项
 * @param {Object} $table vxeTable 表格属性
 * @param {Boolean} reserve 是否包含记忆
 * @returns {Array} checkbox selections
 */
export function checkboxSelection(
  $table,
  reserve = true,
  config = { full: false, reserveFull: false }
) {
  try {
    return reserve === true
      ? allCheckboxSelection($table, config?.full, config?.reserveFull)
      : $table.getCheckboxRecords(
          typeof config?.full === 'boolean' ? config.full : false
        )
  } catch (error) {
    console.error('【checkboxSelection】', $table, error)
    return []
  }
}

export default {
  inject,
  dig,
  setRowExt,
  getRowExt,
  updateRow,
  sumNum,
  createHiddenColumns,
  checkboxSelection,
  allCheckboxSelection
}
