/**
 * @file Class ButtonGroup
 * 通过创建实例，用可配置化的形式，声明每个页面的按钮组
 * 设计原理以及使用说明 @see /doc/buttonGroup/ButtonGroupClass.md
 */
/* eslint-disable no-dupe-class-members */
import { default as setPropByStringPath } from 'lodash.set'
import { isArray, isPlainObject, isString } from '@/utils/validate'
import { currentPageType } from '@/utils/page-type'
import { flattened } from '@/utils/array'
import { default as config } from './config'
/**
 * @typedef {Object} ButtonGroupEnv
 * @property {('list'|'detail'|'create'|'')} pageType 当前页面类型
 * @property {('create'|'edit'|'view')} mode 当前页面模式
 * @property {('创建'|'重新审核'|'审核中'|'已审核')} documentStatusName 当前单据状态
 * @property {(Record<string,any>|null)} process 待办处理信息，来源：url process query string 解压 or mixins/CRUD/detail 现成计算属性
 * @property {string=} pmNamespace 当前环境通用权限命名的空间，用于拼接最终的权限 fullname
 *
 * @typedef {{
 *  create:Array<(string|undefined)>,
 *  edit:{
 *     '创建':Array<(string|undefined)>,
 *     '重新审核':Array<(string|undefined)>,
 *     '审核中':Array<(string|undefined)>,
 *     '已审核':Array<(string|undefined)>
 *  }
 *  view:{
 *     '创建':Array<(string|undefined)>,
 *     '重新审核':Array<(string|undefined)>,
 *     '审核中':Array<(string|undefined)>,
 *     '已审核':Array<(string|undefined)>
 *  },
 *  process:{
 *     '个人待办':{
 *       todo:{
 *           resolved:Array<(string|undefined)>,
 *           rejected:Array<(string|undefined)>,
 *       },
 *       done:{
 *           resolved:Array<(string|undefined)>,
 *           rejected:Array<(string|undefined)>
 *       }
 *     },
 *     '部门待办':{
 *       todo:{
 *          resolved:Array<(string|undefined)>,
 *          rejected:Array<(string|undefined)>
 *       },
 *       done:{
 *          resolved:Array<(string|undefined)>,
 *          rejected:Array<(string|undefined)>
 *       }
 *     }
 *  }
 * }} ButtonGroupMap
 *
 */

export class ButtonGroup {
  /**
   * @type {Record<string,Record<string,any>>}
   * @description 按钮私有存储区
   */
  #btnSets = {}

  /**
   * @type {Array<string>}
   * @description 原版外部按钮配置
   * e.g: ["associationQuery//workProcess\\@free", "businessOperation//workProcess\\@free,cancellation\\@name", "reverseAudit\\@name"]
   */
  #btnConfsOriginal = []
  /**
   * @type {Array<string>}
   * @description 按钮配置拆分，子按钮按钮声明分割独立，子按钮则带上父按钮
   * e.g: ["associationQuery//workProcess\\@free", "businessOperation//workProcess\\@free", "businessOperation//cancellation\\@name", "reverseAudit\\@name"]
   */
  #btnConfSeparation = []

  /**
   * @return {Array<any>}
   */
  get buttons() {
    const _buttons = []

    this.#btnConfsOriginal.forEach((btnConf) => {
      let button = null
      if (this.#isDropdown(btnConf)) {
        const [parent, children] = this.#getDropdownViaBtnConf(btnConf)
        button = this.#getBtnFromBtnSets(parent)
        if (button) {
          button.dropdown = children
            .split(',')
            .map((child) => this.#getBtnFromBtnSets(`${parent}//${child}`))
            .filter((btn) => !!btn)
        } else {
          console.error(
            `【ButtonGroup】losed parent:${parent} of dropdown`,
            btnConf
          )
        }
      } else button = this.#getBtnFromBtnSets(btnConf)

      button && _buttons.push(button)
    })

    console.groupCollapsed('【ButtonGroup】')
    console.log('instance:\n', this)
    console.log('buttons:\n', _buttons)
    console.log('configs (original):\n', this.#btnConfsOriginal)
    console.log('configs (speration):\n', this.#btnConfSeparation)
    console.groupEnd()

    return _buttons
  }

  /**
   * @param {ButtonGroupEnv} env
   * @param {ButtonGroupMap} map
   */
  constructor(env, map) {
    if (!isPlainObject(env)) {
      return void console.error(
        '【ButtonGroup】illegal "env" for new ButtonGroup',
        env
      )
    }
    if (env.pageType === 'detail') {
      const { mode, process, documentStatusName } = env

      if (!['create', 'edit', 'view'].includes(mode)) {
        return void console.error('Illegal "mode" for new ButtonGroup', env)
      }

      if (
        !['创建', '重新审核', '审核中', '已审核'].includes(documentStatusName)
      ) {
        // TODO: how to design that dynamic keep quiet
        // console.error(
        //   '【ButtonGroup】illegal "documentStatusName" for new ButtonGroup',
        //   env
        // )
        return
      }
      if (process !== null && !isPlainObject(process)) {
        return void console.error(
          '【ButtonGroup】illegal "process" for new ButtonGroup',
          env
        )
      }
    }

    if (!isPlainObject(map)) {
      return void console.error(
        '【ButtonGroup】illegal "map" for new ButtonGroup',
        map
      )
    }

    this.#createButttonGroup(env, map)
  }
  /**
   * @param {object} context - this ref of button's page
   * @param {ButtonGroupEnv} payload
   * @returns
   */
  static env(
    context,
    payload = {
      pageType: '',
      mode: '',
      documentStatusName: '',
      process: null,
      pmNamespace: ''
    }
  ) {
    const {
      pageType = '',
      mode = '',
      documentStatusName = '',
      process = null,
      pmNamespace = ''
    } = payload || {}

    // eslint-disable-next-line no-unused-vars
    const { fullPath /* hack computed prop, don't remove */ } = context.$route

    return {
      context: context,
      pageType: pageType || currentPageType(context.$route),
      mode: mode || context.mode,
      documentStatusName:
        documentStatusName || context.$_detail_cru_documentStatusName,
      process: process || context.$_detail_cru_processInfo,
      pmNamespace
    }
  }
  /**
   * @param {string} btnKey
   * @param {Record<string,any>} propVal
   * @return {object} - this ref to instance
   */
  updateButton(btnKey, propVal) {
    if (!btnKey || !isString(btnKey)) {
      console.error('【ButtonGroup】illegal "btnKey":', btnKey)
      return this
    }

    if (!propVal || !isPlainObject(propVal)) {
      console.error('【ButtonGroup】illegal "propVal":', propVal)
      return this
    }

    const button = this.#getBtnFromBtnSets(btnKey)
    if (!button || !isPlainObject(button)) {
      // TODO: how to design that can keep quiet
      console.warn(
        `【ButtonGroup】couldn't find button via "btnKey":${btnKey} currenttly`
      )
      return this
    }

    Object.entries(propVal).forEach(([propStrPath, value]) => {
      setPropByStringPath(this.#btnSets[btnKey], propStrPath, value)
    })

    // support fuction chain
    return this
  }

  /**
   * @param {ButtonGroupEnv} env
   * @param {ButtonGroupMap} map
   */
  #createButttonGroup(
    { pageType, mode, process, documentStatusName, pmNamespace, context },
    map
  ) {
    const btnConfs = process
      ? this.#createForDetailProcess(process, map) // 详情页：待办审核/处理
      : pageType
      ? pageType === 'list'
        ? this.#createForList(map) // 列表页
        : ['create', 'detail'].includes(pageType)
        ? this.#createForDetailCRU(mode, documentStatusName, map, context) // 创建页/详情页：查看，编辑
        : console.error('【ButtonGroup】not support page type yet', pageType)
      : console.error('【ButtonGroup】unknown page type')
    if (!btnConfs) return
    this.#saveBtnConfs(btnConfs)
    this.#createInstanceButtons(this.#btnConfSeparation, pmNamespace)
  }
  /**
   * 创建 「列表页」 按钮组
   * @returns {(void|Array<string>)}
   */
  #createForList(map) {
    const btnConfs = map['list']
    if (!isArray(btnConfs)) {
      return console.error(
        `【ButtonGroup】 未找到 map.list 下的按钮配置`,
        `\n${window?.location?.pathname}`,
        '\n',
        map
      )
    }
    return btnConfs
  }

  /**
   * 创建 「创建页」和 「详情页」(查看/编辑) 按钮组
   * @param {('create'|'edit'|'view')} mode
   * @param {('创建'|'重新审核'|'审核中'|'已审核')} documentStatusName
   * @param {ButtonGroupMap} map
   * @param {object} context
   * @returns {(void|Array<string>)}
   */
  #createForDetailCRU(mode, documentStatusName, map, context) {
    let btnConfs =
      mode === 'create' ? map[mode] : map[mode]?.[documentStatusName]
    if (!isArray(btnConfs)) {
      /**
       * TODO:
       * 创建模式和编辑模式的按钮 目前不需要配置，默认 []
       */
      if (mode === 'create' || mode === 'edit') {
        btnConfs = []
      } else {
        return console.warn(
          `【ButtonGroup】 未找到 map.${mode}.${documentStatusName} 下的按钮配置`,
          `\n${window?.location?.pathname}`,
          `\n- mode               : ${mode}`,
          `\n- documentStatusName : ${documentStatusName}`,
          '\n',
          map
        )
      }
    }
    // 当前单据“审核中”，且发起人就是当前登录用户，则追加撤销按钮
    return this.#showCalloffBtn(context, documentStatusName)
      ? [...btnConfs] // [...btnConfs, 'callOff\\@free']
      : btnConfs
  }

  // TODO: 撤销按钮 权限未知，目前只根据发起人是否当前用户以及单据状态审核中来显隐藏
  #showCalloffBtn({ $_detail_cru_loginerIsSubmitter }, documentStatusName) {
    return (
      documentStatusName === '审核中' &&
      $_detail_cru_loginerIsSubmitter === true
    )
  }

  /**
   * 创建 详情页(待办任务审核处理) 按钮组
   * @param {(Record<string,any>|null)} proces
   * @param {ButtonGroupMap} map
   * @returns {(void|Array<string>)}
   */
  #createForDetailProcess(process, map) {
    const {
      auditStatus,
      documentUpdated,
      __INJECTION__: { currentTaskType, currentTaskStatus }
    } = process
    let btnSets = map?.process?.[currentTaskType]?.[currentTaskStatus]

    if (!isPlainObject(btnSets) || Object.keys(btnSets).length === 0) {
      /**
       * TODO:
       * 个人待办的 done已办 和部门待办 目前不需要配置，默认 { reject: [], resolve: [] }
       */
      if (
        currentTaskType === 'department' ||
        (currentTaskType === 'personal' && currentTaskStatus === 'done')
      ) {
        btnSets = { reject: [], resolve: [] }
      } else {
        return console.error(
          `【ButtonGroup】 未找到 map.process.${currentTaskType}.${currentTaskStatus} 下的按钮配置`,
          `\n${window?.location?.pathname}`,
          `\n- auditStatus     : ${auditStatus}`,
          `\n- documentUpdated : ${documentUpdated}`,
          '\n',
          map,
          '\n',
          process
        )
      }
    }

    let btnConfs

    if (auditStatus === 1) {
      /**
       * auditStatus 1 为 “被驳回”
       */
      btnConfs = btnSets?.rejected
      btnConfs = btnConfs[documentUpdated === true ? 0 : 1]
    } else btnConfs = btnSets?.resolved

    if (!isArray(btnConfs)) {
      /**
       * TODO:
       * 个人待办的 todo 未拾取，默认 []
       */
      if (currentTaskType === 'personal' && currentTaskStatus === 'todo') {
        btnConfs = []
      } else {
        return void console.error(
          `【ButtonGroup】未找到 map.process.${currentTaskType}.${currentTaskStatus}.${
            auditStatus === 1
              ? 'rejected' + (documentUpdated === true ? '[0]' : '[1]')
              : 'resolved'
          } 下的按钮配置:`,
          `\n${window?.location?.pathname}`,
          '\n',
          map,
          '\n',
          process
        )
      }
    }
    return btnConfs
  }

  /**
   * save btn conf str array => this.#btnConfSeparation
   * @param {Array<string>} btnConfs
   * @return {Array<string>}
   */
  #saveBtnConfs(btnConfs) {
    this.#btnConfsOriginal = this.#removeIllegalBtnConf(btnConfs)
    this.#btnConfSeparation = this.#sperateBtnConfs(this.#btnConfsOriginal)
  }

  /**
   * 校验按钮配置声明
   * @param {string} btnConf
   * @param {{isDropdownParent:boolean}} payload
   * @returns {boolean}
   */
  #validBtnConf(btnConf, payload = { isDropdownParent: false }) {
    if (!isString(btnConf) || btnConf.trim() === '') {
      console.error(
        '【ButtonGroup】illegal btn str conf',
        `\n${window?.location?.pathname}`,
        '\n',
        btnConf,
        '\n',
        this
      )
      return false
    }

    const [name, pm] = this.#getPropsViaBtnConf(btnConf)
    if (!Object.keys(config).includes(name)) {
      console.log('config', config, name)
      console.error(
        '【ButtonGroup】illegal "name":',
        name,
        `\n${window?.location?.pathname}`,
        '\n',
        btnConf,
        '\n',
        this
      )
      return false
    }

    if (!pm) {
      if (payload?.isDropdownParent !== true) {
        console.error(
          '【ButtonGroup】illegal "pm":',
          pm,
          '\n',
          btnConf,
          `\n${window?.location?.pathname}`,
          '\n',
          this
        )
        return false
      }
    }

    return true
  }

  /**
   * 移除非法的按钮配置
   * @returns {Array<string>}
   */
  #removeIllegalBtnConf(btnConfs) {
    const _btnConfs = [...btnConfs]
    _btnConfs.length &&
      _btnConfs.forEach((btnConf, index) => {
        if (btnConf.indexOf(' ') > -1) {
          console.warn(`【ButtonGroup】${btnConf} can't include white space`)
        }

        const _btnConf = btnConf.replaceAll(' ', '')

        if (this.#isDropdown(_btnConf)) {
          const [parent, children] = this.#getDropdownViaBtnConf(_btnConf)
          if (!parent || !children) {
            return void console.error(
              '【ButtonGroup】illegal dropdown button config',
              btnConf
            )
          }

          // 下拉父按钮非法，整个移除
          if (!this.#validBtnConf(parent, { isDropdownParent: true })) {
            _btnConfs[index].replace(_btnConf, '')
          }

          // 下拉子按钮非法，移除子按钮
          children.split(',').forEach((child) => {
            if (!this.#validBtnConf(child)) _btnConfs[index].replace(child, '')
          })
        } else {
          // 下拉普通按钮非法，移除按钮
          if (!this.#validBtnConf(_btnConf)) {
            _btnConfs[index].replace(_btnConf, '')
          }
        }
      })
    return _btnConfs
  }

  /**
   * convert and flattened 转化并扁平化
   * @description 之所以这样做，就是为了方便实例直接扩展属性 ， 比如:
   * - instance['parent//child\\pm'].on = xxx
   * - instance['btn\\pm'].options.documentTypeOpts = xxx
   */
  #sperateBtnConfs(btnConfs) {
    /**
     * @type {Array<(string|Array<string>)>}
     * @description convert `['btn\\pm','parent//child1\\pm,child2\\pm']` to
     */
    let _btnConfs = btnConfs.map((btnConf) => {
      if (this.#isDropdown(btnConf)) {
        const [parent, children] = this.#getDropdownViaBtnConf(btnConf)
        return children.split(',').map((children) => `${parent}//${children}`)
      } else return btnConf
    })
    /**
     *  @type {Array<string>}
     * @description flatten `['btn\\pm',['parent//child1\\pm, parent//child2\\pm']]` to `['btn\\pm','parent//child1\\pm, parent//child2\\pm']`
     */
    _btnConfs = flattened(_btnConfs)

    return _btnConfs
  }

  /**
   * create btn group's btns on this(instance)
   * 创建按钮并存储到私有存储区
   * @param {Array<string>} btnConfs
   * @param {string} pmNamespace
   * @returns {void}
   * e.g:
   * - `this.#btnSets[associationQuery] = {attrs: {…}, iconName: 'i-zoom-out', label: '关联查询', pmName: null}`
   * - `this.#btnSets[associationQuery//workProcess\@free] = {evtName:'工作流程',pmName: null}`
   * - `this.#btnSets['pushDown\\@name'] = {attrs: {…}, iconName: 'i-push-down', evtName: '下推', pmName: 'web:xxx.xxx.xxxx'}`
   */
  #createInstanceButtons(btnConfs, pmNamespace) {
    btnConfs.length &&
      btnConfs.forEach((btnConf, idx) => {
        if (this.#isDropdown(btnConf)) {
          const [parent, child] = this.#getDropdownViaBtnConf(btnConf)
          if (parent && child) {
            this.#createDropdownParent(parent, idx, pmNamespace)

            this.#createNormalOrDropdownChild(child, idx, pmNamespace, {
              dropdownParent: parent,
              isDropdownChild: true
            })
          } else {
            console.error('【ButtonGroup】illegal dropdown btnConf:', btnConf)
          }
        } else {
          this.#createNormalOrDropdownChild(btnConf, idx, pmNamespace, {
            isDropdownChild: false
          })
        }
      })
  }

  /**
   * 创建 下拉父按钮
   * @param {*} btnConf
   * @param {*} btnConfIdx
   * @param {*} pmNamespace
   */
  #createDropdownParent(btnConf, btnConfIdx, pmNamespace) {
    this.#createBtnItem(btnConf, btnConfIdx, pmNamespace, {
      type: 'dropdown-parent'
    })
  }

  /**
   * 创建 普通按钮/下拉子按钮
   * @param {string} btnConf
   * @param {number} btnConfIdx
   * @param {string} pmNamespace
   * @param {{isDropdownChild:boolean,dropdownParent?:string}} payload - isDropdownChild:是否是下拉子按钮 dropdownParent:下拉父按钮名
   */
  #createNormalOrDropdownChild(btnConf, btnConfIdx, pmNamespace, payload) {
    this.#createBtnItem(
      btnConf,
      btnConfIdx,
      pmNamespace,
      payload.isDropdownChild === true
        ? {
            dropdownParent: payload.dropdownParent,
            type: 'dropdown-child'
          }
        : { type: 'normal' }
    )
  }

  /**
   * 创建按钮
   * @param {stirng} btnConf
   * @param {number} btnConfIdx
   * @param {string} pmNamespace
   * @param {{type:('dropdow-parent'|'dropdown-child'|'normal'),dropdownParent?:string}} payload
   * @returns {void}
   */
  #createBtnItem(btnConf, btnConfIdx, pmNamespace, payload) {
    const [name, pm] = this.#getPropsViaBtnConf(btnConf)

    const pmName = this.#getPmNameViaBtnConf(name, pm, pmNamespace, payload)

    const btnKey = this.#getBtnKeyOfBtnSets(name, pm, payload)
    if (btnKey) {
      if (!this.#getBtnFromBtnSets(btnKey)) {
        const button = this.#getBtnFromConfigs(name, pmName)
        if (button) this.#addToBtnSets(btnKey, button)
        else {
          console.error(
            '【ButtonGroup】losed button from "getBtnFromConfigs"',
            `\n${window?.location?.pathname}`,
            `\n- dropdownParent       : ${payload.dropdownParent}`,
            `\n- buttonType           : ${payload.type}`,
            `\n- name   (config.name) : ${name}`,
            `\n- btnKey (this.name)   : ${btnKey}`,
            `\n- original  pm         : ${pm}`,
            `\n- generated pm         : ${pmName}`,
            '\n',
            this
          )
        }
      }
    } else {
      console.error(
        '【ButtonGroup】losed btnKey from "getBtnKeyOfBtnSets"',
        `\n${window?.location?.pathname}`,
        `\n- dropdownParent       : ${payload.dropdownParent}`,
        `\n- buttonType           : ${payload.type}`,
        `\n- name   (config.name) : ${name}`,
        `\n- original  pm         : ${pm}`,
        `\n- generated pm         : ${pmName}`,
        '\n',
        this
      )
    }
  }

  // ================================================== UTILS ==================================================
  /**
   * 生成挂载在按钮组实例的按钮名，便于实例访问修改扩展等按钮
   * @param {string} name
   * @param {string} pm
   * @param {{type:('dropdow-parent'|'dropdown-child'|'normal'),dropdownParent?:string}} payload
   * @returns {string}
   * e.g:
   * - 'associationQuery'
   * - 'associationQuery//workProcess\@free'
   * - 'pushDown\\@name'
   */
  #getBtnKeyOfBtnSets(name, pm, payload) {
    const key = ''
    switch (payload.type) {
      case 'dropdown-parent':
        return pm ? `${name}\\${pm}` : name
      case 'dropdown-child': {
        if (payload.dropdownParent) {
          return `${payload.dropdownParent}//${name}\\${pm}`
        } else {
          console.error(
            '【ButtonGroup】losed "payload.dropdownParent"',
            `\n${window?.location?.pathname}`,
            `\n- name : ${name}`,
            `\n- pm   : ${pm}`,
            '\n',
            payload
          )
        }
        break
      }
      case 'normal':
        return `${name}\\${pm}`
    }
    return key
  }
  /**
   * @param {string} name
   * @param {string} pm
   * @param {string} pmNamespace
   * @param {{type:('dropdow-parent'|'dropdown-child'|'normal'),dropdownParent?:string}} payload
   * @returns {string} 根据约定的 下拉按钮配置字符串上, 生成按钮的权限名
   */
  #getPmNameViaBtnConf(name, pm, pmNamespace, payload) {
    let pmName

    if (pm === '@free') {
      pmName = null
    } else if (pm === '@name') {
      if (
        !isString(pmNamespace) ||
        !pmNamespace.includes(':') ||
        pmNamespace.split('.').length < 2
      ) {
        return void console.error(
          '【ButtonGroup】illegal "pmNamespace" @name',
          pmNamespace,
          name,
          pm
        )
      } else pmName = `${pmNamespace}.${name}`
    } else {
      if (pm) {
        if (pm.includes(':') && pm.includes('.')) {
          pmName = pm
        } else {
          if (
            !isString(pmNamespace) ||
            !pmNamespace.includes(':') ||
            pmNamespace.split('.').length < 2
          ) {
            return void console.error(
              '【ButtonGroup】illegal "pmNamespace" name',
              pmNamespace,
              name,
              pm
            )
          } else pmName = `${pmNamespace}.${pm}`
        }
      } else {
        // dropdown-parent 是唯一一种允许不声明权限的按钮配置
        if (payload.type !== 'dropdown-parent') {
          return void console.error(
            '【ButtonGroup】losed pm',
            pmNamespace,
            name,
            pm
          )
        } else pmName = null
      }
    }
    return pmName
  }
  /**
   * @param {string} btnConf
   * @returns {boolean} 是否是下拉按钮
   */
  #isDropdown(btnConf) {
    return btnConf.includes('//')
  }
  /**
   * @param {string} btnConf
   * @returns {Array<(string|undefined)>}
   * @description 从约定的 下拉按钮配置字符串上 中获取父按钮和所有字按钮 ['parent','child1,child2,...']
   */
  #getDropdownViaBtnConf(btnConf) {
    return btnConf.split('//')
  }
  /**
   * @param {string} btnConf
   * @returns {string}
   * @description 从约定的 下拉按钮配置字符串上 中获取父按钮
   */
  #getDropdownParentViaBtnConf(btnConf) {
    // eslint-disable-next-line no-unused-vars
    const [parent = '', children] = this.#getDropdownViaBtnConf(btnConf)
    return parent
  }
  /**
   * @param {string} btnConf
   * @returns {string}
   * @description 从约定的 下拉按钮配置字符串上 中获取所有子按钮，多个由逗号分隔
   */
  #getDropdownChildrenViaBtnConf(btnConf) {
    // eslint-disable-next-line no-unused-vars
    const [parent, children = ''] = this.#getDropdownViaBtnConf(btnConf)
    return children
  }
  /**
   * @param {string} btnConf
   * @returns {Array<(string|undefined)>}
   * @description 从约定的 按钮配置字符串上 中获取按钮属性，name, pm 等
   */
  #getPropsViaBtnConf(btnConf) {
    return btnConf.split('\\')
  }
  /**
   * @param {string} btnConf
   * @returns {string}
   * @description 从约定的 按钮配置字符串上 中获取按钮 name 属性
   */
  #getNameViaBtnConf(btnConf) {
    // eslint-disable-next-line no-unused-vars
    const [name = '', pm] = this.#getPropsViaBtnConf(btnConf)
    return name
  }
  /**
   * @param {string} btnConf
   * @returns {string}
   * @description 从约定的 按钮配置字符串上 中获取按钮 pm 属性
   */
  #getPmViaBtnConf(btnConf) {
    // eslint-disable-next-line no-unused-vars
    const [name, pm = ''] = this.#getPropsViaBtnConf(btnConf)
    return pm
  }
  /**
   * @param {string} name
   * @param {(string|null)} pmName
   * @returns {object}
   * @description 从 `./config.js` 中获取按钮静态配置
   */
  #getBtnFromConfigs(name, pmName) {
    const button = config[name]
    return button ? { ...config[name], pmName } : null
  }
  /**
   * @param {string} name
   * @param {(string|null)} pmName
   * @returns {object}
   * @description 从 #btnSets 私有存储中获取按钮
   */
  #getBtnFromBtnSets(btnKey) {
    return this.#btnSets[btnKey]
  }
  /**
   * @param {string} name
   * @param {(string|null)} pmName
   * @returns {object}
   * @description 从 #btnSets 私有存储中获取按钮
   */
  #addToBtnSets(btnKey, btnObj) {
    this.#btnSets[btnKey] = btnObj
  }
}
