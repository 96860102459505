/**
 * @module api-web:filter-opts/hr
 * @description common apis : dropdown select options - hr
 */
import service from '@/service'
/**
 * @description 下拉选项: 角色
 */
export function getRoleOpts() {
  return service['api-web']({
    url: '/public/hr/getAllRole',
    method: 'get'
  })
}

/**
 * @description 下拉选项：政治面貌
 */
export function getPoliticsOpts() {
  return service['api-web']({
    url: '/public/hr/getPolitics',
    method: 'get'
  })
}

/**
 * 下拉选项：民族
 */
export function getNationOpts() {
  return service['api-web']({
    url: '/public/hr/getNation',
    method: 'get'
  })
}

/**
 * 下拉选项: 学历
 */
export function getEducationOpts() {
  return service['api-web']({
    url: '/public/hr/getEducation',
    method: 'get'
  })
}

/**
 * 下拉选项: 采购部门下用户下拉框
 */
export function getUserbydepartmentOpts() {
  return service['api-web']({
    url: '/public/hr/getUserByPurchasingDepartment',
    method: 'get'
  })
}
