/**
 * @module api-web:organization/finance
 */
import service from '@/service'

/**
 * 资料管理-组织信息-财务信息-列表
 * @param {{keywords:string,limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/data/companyInfoFinance/getList',
    method: 'get',
    params
  })
}

/**
 * 资料管理-组织信息-财务信息-新增
 * @param {{id:number}} data
 */
export function add(data) {
  return service['api-web']({
    url: '/data/companyInfoFinance/save',
    method: 'post',
    data
  })
}
/**
 * 资料管理-组织信息-财务信息-更新
 * @param {{id:number}} data
 */
export function update(data) {
  return service['api-web']({
    url: '/data/companyInfoFinance/update',
    method: 'post',
    data
  })
}
/**
 * 资料管理-组织信息-财务信息-删除
 * @param {{id:number}} data
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/companyInfoFinance/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
