/**
 * @module api-web:store/index
 * @description api for data/store
 */
import service from '@/service'

/**
 * 查询店铺管理列表
 * @param {{storeName:string,storeType:number}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/data/storeManagement/getList',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
/**
 * 店铺管理保存
 */
export function create(data) {
  return service['api-web']({
    url: '/data/storeManagement/save',
    method: 'post',
    data
  })
}
/**
 * @param {{id:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/storeManagement/delete',
    method: 'delete',
    params
  })
}

/**
 * 店铺管理修改
 */
export function update(data) {
  return service['api-web']({
    url: '/data/storeManagement/update',
    method: 'put',
    data
  })
}
