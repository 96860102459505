/**
 * @module utils/auth/key
 */
import { get, set, remove } from '@/storage'
const STORAGE_KEY = 'ASR-SCM-SYS-AUTH-KEY'
export function getKey() {
  return get('local', STORAGE_KEY)
}
export function setKey(key) {
  set('local', STORAGE_KEY, key)
}
export function removeKey() {
  remove('local', STORAGE_KEY)
}
