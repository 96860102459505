<template>
  <el-dialog
    v-el-drag-dialog
    :visible="show"
    :width="width"
    :title="title"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @update:visible="$emit('update:show', $event)"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-width="labelWidth"
      native-type="submit"
      @submit.native.prevent
    >
      <el-alert
        v-show="disableTips.diffNextAgain"
        title="两次密码输入不一致"
        type="error"
        show-icon
        :closable="false"
      />
      <el-form-item
        v-for="item in passwordFields"
        :key="item"
        :label="formUX[item].label"
        :prop="item"
      >
        <el-tooltip
          v-model="formUX[item].capsTip"
          content="Caps lock is On"
          placement="right"
          manual
        >
          <el-input
            :ref="`${item}-password`"
            v-model.trim="form[item]"
            :type="formUX[item].type"
            :placeholder="formUX[item].placeholder"
            :name="`${item}-password`"
            maxlength="180"
            @keyup.native="checkCapslock($event, item)"
            @blur="formUX[item].capsTip = false"
          >
            <span slot="prefix" class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <!-- <span slot="suffix" class="show-pwd" @click="showPwd(item)">
              <svg-icon
                :icon-class="
                  formUX[item].type === 'password' ? 'eye' : 'eye-open'
                "
              />
            </span> -->
          </el-input>
        </el-tooltip>
        <span class="show-pwd-btn" @click="showPwd(item)">
          <svg-icon
            :icon-class="formUX[item].type === 'password' ? 'eye' : 'eye-open'"
          />
        </span>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="$emit('update:show', false)">取 消</el-button>
      <el-button type="primary" @click="updatePwd">确 定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import * as Cookies from '@/utils/cookies'
import { updatePassword } from '@/service/api-web/user'
import { validPassword } from '@/utils/validate'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    function checkPassword(str) {
      var reg1 = /^[a-zA-Z0-9]{8,20}$/
      return (
        reg1.test(str) &&
        /\d+/.test(str) &&
        /[a-z]+/.test(str) &&
        /[A-Z]+/.test(str)
      )
    }
    function validatePassword(rule, val, cb) {
      if (val) {
        if (validPassword(val)) {
          if (checkPassword(val)) {
            cb()
          } else {
            // console.warn('Invalid password [1]', rule, val)
            cb(new Error('密码须包含大小写字母及数字'))
          }
        } else {
          // console.warn('Invalid password [2]', rule, val)
          cb(new Error('密码长度不小于 8 个字符'))
        }
      } else {
        // console.warn('Invalid password [3]', rule, val)
        cb(new Error(rule.emptyMsg))
      }
    }
    return {
      title: '修改密码', // dialog  标题
      width: '600px', // dialog 宽
      labelWidth: '120px', // 表单 label 宽
      size: Cookies.get('size') || 'mini', // 表单、按钮尺寸
      /**
       * 表单字段
       */
      form: {
        prev: '', // 旧密码
        next: '', // 新密码
        again: '' // 再次输入新密码
      },
      /**
       * 输入域交互信息：label、placeholder、密码是都明文、输入大写提示
       */
      formUX: {
        prev: {
          label: '旧密码',
          placeholder: '请输入旧密码',
          type: 'password',
          capsTip: false
        },
        next: {
          label: '新密码',
          placeholder: '请输入新密码',
          type: 'password',
          capsTip: false
        },
        again: {
          label: '再次输入',
          placeholder: '请再次输入新密码',
          type: 'password',
          capsTip: false
        }
      },
      rules: {
        prev: [
          {
            required: true,
            message: '请输入旧密码',
            trigger: 'blur'
          }
        ],
        next: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword,
            emptyMsg: '请输入新密码'
          }
        ],
        again: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword,
            emptyMsg: '请再次输入新密码'
          }
        ]
      },
      disableTips: {
        // samePrevNext: false,
        diffNextAgain: false
      },
      disableTipsTimer: {
        // samePrevNext: 0,
        diffNextAgain: 0
      }
    }
  },
  computed: {
    passwordFields() {
      return Object.keys(this.form)
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        this.$refs['form']?.resetFields()
        this.$refs['form']?.clearValidate()
      }
    },
    'disableTips.diffNextAgain'(val) {
      if (!val) {
        if (this.disableTipsTimer.diffNextAgain) {
          clearTimeout(this.disableTipsTimer.diffNextAgain)
          this.disableTipsTimer.diffNextAgain = 0
        }
      }
    }
  },
  methods: {
    showPwd(fieldName) {
      if (this.formUX[fieldName].type === 'password') {
        this.formUX[fieldName].type = ''
      } else {
        this.formUX[fieldName].type = 'password'
      }
      console.log(
        'showPwd:',
        fieldName,
        this.formUX[fieldName].type,
        this.$refs[fieldName + '-password']
      )

      this.$nextTick(() => {
        const $input = this.$refs[fieldName + '-password']
        if (!$input) return void console.error(this.$refs, fieldName)
        if (typeof $input?.[0]?.focus !== 'function') {
          return void console.error(this.$refs, $input, fieldName)
        }
        this.$halt(160, () => void $input[0].focus())
      })
    },
    checkCapslock(e, fieldName) {
      const { key } = e
      this.formUX[fieldName].capsTip =
        key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    confirmUpdatePwd() {
      return new Promise((resolve) => {
        this.$confirm(`确认修改密码吗?`, '提示', {
          confirmButtonText: '确  定',
          cancelButtonText: '取 消',
          type: 'success'
        })
          .then(() => resolve(true))
          .catch(() => resolve(false))
      })
    },
    validateUpdatePwd() {
      return new Promise((resolve) => {
        this.$refs['form'].validate((valid) => {
          resolve(valid)
        })
      })
    },
    alertDiffNextAgainTip() {
      this.disableTips.diffNextAgain = true
      if (this.disableTipsTimer.diffNextAgain) {
        clearTimeout(this.disableTipsTimer.diffNextAgain)
      }
      this.disableTipsTimer.diffNextAgain = setTimeout(() => {
        this.disableTips.diffNextAgain = false
        this.disableTipsTimer.diffNextAgain = 0
      }, 2000)
    },
    async updatePwd() {
      if (this.disableTips.diffNextAgain) return

      if (!(await this.validateUpdatePwd())) return
      //   if (this.form.prev === this.form.next) {
      //     this.disableTips.samePrevNext = true
      //     return
      //   }
      if (this.form.next !== this.form.again) {
        return void this.alertDiffNextAgainTip()
      }

      if (!(await this.confirmUpdatePwd())) return

      const { code } = await updatePassword({
        password: this.form.prev,
        newPassword: this.form.next
      })

      if (code === 200) {
        this.$emit('update:show', false)

        this.$confirm('密码修改成功', {
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          closeOnHashChange: false,
          confirmButtonText: '重新登录',
          type: 'success'
        }).finally(() => {
          this.$store.dispatch('user/logout', { request: false }).then(() => {
            Cookies.remove('name')
            Cookies.remove('pwd')
            window.location.reload()
          })
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #051b3d;
$light_gray: #fff;
$cursor: #999;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  input {
    color: $cursor;
  }
}

.el-input {
  display: inline-block;
  background: transparent;
  border-radius: 5px;
  color: #454545;
  width: 80%;

  input {
    background: transparent;
    border: 0px;
    appearance: none;
    border-radius: 0px;
    padding: 10.5px 5px 10.5px 15px;
    color: $bg;
    caret-color: $cursor;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
      -webkit-text-fill-color: $bg !important;
    }
  }
}

.el-alert {
  position: absolute;
  top: 14%;
  width: 66%;
  left: 0;
  right: 0;
  margin: auto;
}

:deep(.el-form-item__content) {
  position: relative;

  .show-pwd-btn {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -14px;
    right: 89px;
    width: 28px;
    height: 28px;
    z-index: 2;
    cursor: pointer;
  }
}
:deep(.el-input__prefix),
:deep(.el-input__suffix) {
  color: #889aa4;
}

:deep(.el-input__prefix) {
  left: 10px;
}

:deep(input) {
  background-color: transparent;
  position: relative;
  z-index: 1;
}
</style>
