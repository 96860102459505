/**
 * @module api-web:clientServe/workOrderSerivice
 */
import service from '@/service'

/**
 * 客服工单 表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * businessState: number ,
 * clientCode: string,
 * documentStatus: number,
 * isMonthly: number,
 * orderCode: string
 * }} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/sell/work/serviceWorkOrder/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 客服工单 待办详情 (无需权限)
 * @param {*} id
 * @returns
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/serviceWorkOrder/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 客服工单 详情
 * @param {*} id
 * @returns
 */
export function info(id) {
  return service['api-web']({
    url: '/sell/work/serviceWorkOrder/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 客服工单 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/sell/work/serviceWorkOrder/save',
    method: 'post',
    data
  })
}

/**
 * 客服工单 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-web']({
    url: '/sell/work/serviceWorkOrder/update',
    method: 'put',
    data
  })
}
/**
 * 客服工单 提交
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/sell/work/serviceWorkOrder/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 客服工单 审核驳回
 * @param {object} data
 */
export function reject(data) {
  return service['api-web']({
    url: '/public/activiti/serviceWorkOrder/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function close(id) {
  return service['api-web']({
    url: '/sell/work/serviceWorkOrder/close',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/sell/work/serviceWorkOrder/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-web']({
    url: '/sell/work/serviceWorkOrder/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
