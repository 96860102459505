/**
 * @module api-activiti:definition
 */
import service from '@/service'
import { isString } from '@/utils/validate'

/**
 * @param {{
 * category:(number|undefined),
 * formId:number,
 * name:string
 * }} data
 * @description  新增流程定义
 */
export function save(data) {
  return service['api-activiti']({
    url: '/sys/process/definition/save',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    /**
     * 请求参数突变：
     * - dropNull 去除 null 字段
     */
    sendMutation: {
      dropNull: true
    }
  })
}

/**
 * @param {{deploymentIds:string}} params
 * @description 删除流程定义
 */
export function remove(params) {
  return service['api-activiti']({
    url: '/sys/process/definition/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{
 * category:(number|undefined),
 * formId:number,
 * name:string
 * }} data
 * @description 修改流程定义
 */
export function update(data) {
  return service['api-activiti']({
    url: '/sys/process/definition/update',
    method: 'put',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    /**
     * 请求参数突变：
     * - dropNull 去除 null 字段
     */
    sendMutation: {
      dropNull: true
    }
  })
}
/**
 * @typedef {('updateCategoryId'|'')} subPathOfPatchApi
 * @param {subPathOfPatchApi} subPath 局部关联更新的 api url sub path
 * @param {object} data like {categoryId:number,id:string}
 * @description 修改流程定义的局部或关联属性，如分类等
 */
export function patch(subPath = '', data) {
  if (subPath && isString(subPath)) {
    return service['api-activiti']({
      url: `/sys/process/definition/${subPath}`,
      method: 'put',
      data
    })
  } else {
    return Promise.reject('Losed "sub-path" for patch')
  }
}
/**
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * category:string,
 * state:number
 * }} params
 *
 * @description  查询流程定义列表
 */
export function getList(params) {
  return service['api-activiti']({
    url: '/sys/process/definition/getList',
    method: 'get',
    params
  })
}
/**
 * @param {{
 * id:string,
 * name:string,
 * multipartFile:File
 * }} data
 * @description  上传流程定义 xml 文件
 */
export function upload(data) {
  return service['api-activiti']({
    url: '/sys/process/definition/upload',
    method: 'put',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @param {{
 * deploymentId:string,
 * fileName:string
 * }} params
 * @description  下载流程定义
 */
export function download(params) {
  return service['api-activiti']({
    url: '/sys/process/definition/getList/exportXML',
    method: 'get',
    params
  })
}

/**
 * @param {{
 * deploymentId:string,
 * fileName:string
 * }} params
 * @description  获取流程定义 XML 字符串
 */
export function read(params) {
  return service['api-activiti']({
    url: '/sys/process/definition/getList/exportXML',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/xml' }
  })
}
/**
 * @param {{
 * id:string,
 * state:(1|2|3)
 * }} data
 * @description 启用/禁用 流程定义
 * 状态：1.草稿、2.已发布、3.未启用
 *
 * 目前后端设计没有启用/禁用，草稿发布后也不可逆，所以固定传 2
 */
export function enable(data) {
  return service['api-activiti']({
    url: '/sys/process/definition/enable',
    method: 'put',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
