/**
 * @module api:auth
 * @description user apis: login/logout, token, user info, user permissions
 */
import service from '@/service'

/**
 * 登录
 * @typedef {Object} LoginParams
 * @property {{publicKey:string,privateKey:string}} keypair 不传递到后端，用于 axios 拦截器的加解密
 * @property {'password'|'captcha'} grant_type
 * @property {string} username rsa 加密 用户名
 * @property {string} password rsa 加密 密码
 * @property {string} captcha rsa 加密 数字图片验证码
 * @property {string} pk rsa 加密 前端生成rsa公钥part1
 * @property {strin} pk2 前端生成rsa公钥part2
 *
 * @param {LoginParams}
 */
export function login({ keypair, ...data }) {
  return service['auth']({
    crypt: {
      type: 'rsa', // 加密算法
      encryption: ['username', 'password', 'captcha', 'pk'], // 加密字段
      decryption: ['accessToken', 'refreshToken', 'key'], // 解密字段
      canSkip: false, // 是否可以绕过
      keypair // 公钥私钥
    },
    url: '/oauth/token',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data
  })
}

/**
 * 退出登录
 */
export function logout() {
  return service['auth']({
    crypt: { type: 'none' },
    url: '/exit',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 获取当前登录用户信息
 */
export function getInfo() {
  return service['auth']({
    crypt: {
      type: 'noaes',
      canSkip: false // 是否可以绕过加解密
    },
    url: '/public/getUserInfo',
    method: 'get'
  })
}
/**
 * 数字图片验证码
 */
export function getNumCaptchaImg() {
  return service['auth']({
    noAuth: true,
    crypt: { type: 'none' },
    url: '/allPublic/getCode',
    method: 'get',
    /**
     * @deprecated
     * 后端返回文件流
     *  ________ start _______
     */
    // responseType: 'blob',
    // saveAs: false,
    // convert: { to: 'blobUrl' },
    /* _________ end ______  */
    /**
     * 后端返回 base64
     */
    base64: {
      /**
       * base64 string of res will be converted, add media type and charset infos
       * @type {{type:string,charset:(string|undefined)}}
       */
      imgCode: { type: 'jpeg' }
    }
  })
}

/**
 * @typedef {Object} SmsCodeParams
 * @property {string} clientId - 客户端ID（getCode 接口返回，用于图片验证码的存储key）
 * @property {string} userName - 用户名输入域的值（需要 RSA 加密）
 * @property {string} graphCode - 数字图片验证码输入域的值（需要 RSA 加密）
 * @param {SmsCodeParams} params
 */
export function getSmsCode(params) {
  return service['auth']({
    noAuth: true,
    crypt: { type: 'rsano', encryption: ['userName', 'graphCode'] },
    url: '/allPublic/sendSms',
    method: 'get',
    params
  })
}
/**
 * 根据当前用户角色获取权限（菜单、资源）
 */
export function getPermissions() {
  return service['auth']({
    crypt: {
      type: 'noaes',
      canSkip: false // 是否可以绕过
    },
    url: '/public/getRoleMenu',
    method: 'get'
  })
}
