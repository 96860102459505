/**
 * @module api-web:businessTodo/csTickets/list
 * @description 首页/联络单待办 列表
 */
import service from '@/service'

/**
 * 我的待办/联络单/列表
 * @param {*} params
 */
export function getMyTodoList(params) {
  return service['api-web']({
    url: '/index/public/interiorLiaison/getList',
    method: 'get',
    params: { ...params, liaisonStatus: 0 }
  })
}

/**
 * 我的待办/联络单/已完成列表
 * @param {*} params
 */
export function getMyDoneList(params) {
  return service['api-web']({
    url: '/index/public/interiorLiaison/getList',
    method: 'get',
    params: { ...params, liaisonStatus: 1 }
  })
}
/**
 * 部门待办/联络单/列表
 * @param {*} params
 */
export function getDeptTodoList(params) {
  return service['api-web']({
    url: '/index/public/interiorLiaison/getDeptTodoList',
    method: 'get',
    params
  })
}

/**
 * 部门待办/联络单/已完成列表
 * @param {*} params
 */
export function getDeptDoneList(params) {
  return service['api-web']({
    url: '/index/public/interiorLiaison/getDeptDoneList',
    method: 'get',
    params
  })
}

export default {
  getMyTodoList,
  getMyDoneList,
  getDeptTodoList,
  getDeptDoneList
}
