export function agelDefaultOption(options = {}) {
  const {
    propName,
    remoteMethod,
    formName = 'baseInfoForm',
    labelName = '',
    events = {}
  } = options

  const form = this[formName]

  if (!propName || !remoteMethod || !form?.data) return

  return {
    options:
      this.mode === 'create'
        ? async () => await remoteMethod()
        : [
            {
              label: form.data[labelName || propName + 'Name'] || '',
              value: form.data[propName]
            }
          ],
    on: {
      ...events,
      'visible-change': async (visible) => {
        if (!visible) return

        const vm = form.getRef(propName)

        vm.loading = true

        const data = await remoteMethod()

        if (Array.isArray(data)) {
          vm.options = data
        }

        vm.loading = false
      }
    }
  }
}
