<template>
  <div>
    <el-popover
      ref="popoverRef"
      :placement="placement"
      v-bind="$attrs"
      :trigger="trigger"
      popper-class="monitor-popover"
      :disabled="showTooltip"
    >
      <div class="popover_content" :style="{ width: tooltipWidth + 'px' }">
        {{ content }}
      </div>
      <slot slot="reference" />
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'BaseTooltip',
  props: {
    content: {
      type: [String, Number],
      default: ''
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    showTooltip: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tooltipWidth: 200
    }
  },

  mounted() {
    this.setTooltipWidth()
  },

  methods: {
    // 设置tooltip的宽度
    setTooltipWidth() {
      const popoverRef = this.$refs.popoverRef // 获取有值时的tooltip宽度的Dom
      const emptyRef = this.$refs.emptyRef // 获取无值时的tooltip宽度的Dom
      if (popoverRef && emptyRef) {
        const contentDom = popoverRef.$children[0]
        const inputDom = emptyRef.children[0]
        this.tooltipWidth = contentDom.$el.clientWidth || inputDom.clientWidth
      }
    }
  }
}
</script>

<style lang="scss">
.monitor-popover {
  border-radius: 4px;
  font-size: 14px;
  color: #606266;
  border-color: #e4e7ed;
}
.monitor-popover .popper__arrow {
  left: 15.5px !important;
}
.popover_content {
  word-wrap: break-word;
}
</style>
