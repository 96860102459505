/**
 * @module api-scm:warehouseIn
 */
import service from '@/service'

/**
 * @typedef {Object} warehouseIn 其他入库单列表
 * @property {number=} [limit=1] -
 * @property {number} page - 页码
 * @property {string} sort -
 * @property {number} deliveryWarehouseCode - 发货仓库
 * @property {string} productCode - 商码编号
 * @property {string} productName - 商品名称
 * @property {string} warehouseOutTimeEnd - 出库时间结束
 * @property {string} warehouseOutTimeStart - 出库时间起始
 *
 * @param {WarehouseOut}
 */

export function getList(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/get/getList',
    method: 'post',
    data
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehouseIn/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/update/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/deleteWarehouseIn',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 树形下拉
 * @param {{code:string}}
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/get/getListDown',
    method: 'get',
    params: { code }
  })
}

/**
 *
 * @param {*} params
 * @returns 质检列表
 */
export function qualityList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/update/getQualityList',
    method: 'get',
    params
  })
}

/**
 *
 * @param {*} data
 * @returns 新增质检
 */
export function saveQuality(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/update/saveQuality',
    method: 'post',
    data
  })
}

/**
 *
 * @param {*} data
 * @returns 修改质检
 */
export function updateQuality(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/update/updateQuality',
    method: 'post',
    data
  })
}

/**
 * @param {number}  - ids
 */
export function removeQuality(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/update/deleteQuality',
    method: 'delete',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *
 * @param {string} id
 * @returns 质检详情
 */
export function qualityInfo(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/update/infoQuality',
    method: 'get',
    params: { id }
  })
}

export function saveQualityInfo(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/update/updateQualityDetail',
    method: 'put',
    data
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseIn/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{number:id}} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 作废
 * @param {{id:Array<string>}} id
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下推
 * @param {object} data
 */
export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/pushDown',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出其它入库单
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/warehouseInData/export',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false // 文件流是否通过浏览器下载
  })
}

/**
 * @description 手动对接
 * @param {string} ids
 */
export function manualDockingOfKingdee(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/handDocking',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 打印其他入库单
 */
export function printBatch(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseIn/print',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 模糊查询库位代码
 */
export function queryInventoryCodeWithLike(params) {
  return service['api-scm']({
    url: '/public/inventory/queryInventoryCodeWithLike',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
