import service from '@/service'

/**
 * 列表查询
 */
export function getList(data) {
  return service['api-web']({
    url: '/designApartment/productionLine/getList',
    method: 'post',
    data
  })
}

/**
 * 新增
 */
export function save(data) {
  return service['api-web']({
    url: '/designApartment/productionLine/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 */
export function update(data) {
  return service['api-web']({
    url: '/designApartment/productionLine/update',
    method: 'post',
    data
  })
}
