import Vue from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import App from '@/App'
import store from '@/store'
import router from '@/router'
import '@/styles/index.scss' // global css
import '@/icons' // icons
import { Dialog } from 'element-ui'

import '@/components' // global auto register component

import * as filters from '@/filters' // global filters
import * as mixins from '@/mixins' // global mixins
import resourcePermissionDirective from '@/directive/permission' // resource permission directive
import '@/directive/dialogDragStretch.js' // dialog stretch and drag
import halt from '@/utils/halt' // sleep with nexttick
import biet from '@/utils/baseInlineEditTable'
import dict from '@/utils/dictionary'
import fmt from '@/utils/formatter'
import resetMessage from '@/utils/resetMessage'
import pageType from '@/utils/page-type'
import { getFileInfo } from '@/utils/request'
import { ButtonGroup } from '@/utils/ButtonGroup'
import { UTable, UTableColumn } from 'umy-ui'

import {
  autoCompleteInputFunc,
  autoCompleteInputEvts,
  autoSelectRemoteFunc,
  agelCreateSelectMode,
  agelSelectNamePair,
  agelDefaultOption,
  divider,
  refreshOptions
} from '@/utils/agelForm'

import numberUtils from '@/utils/number'

// sentry.io
// import sentry from '@/sentry'
// sentry(Vue, router)

// error logs
import errorLogs from '@/utils/error-log'
errorLogs(Vue, store)

// 🟢 plugins
import './plugins/vxe-table'
import './plugins/agel-form'
import './plugins/element-ui'
import './plugins/highlight'
// import './plugins/protal'

import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)

import listenRefresh from '@/refresh'
listenRefresh()

import { extractPathName, uniqueNamedRouteID } from '@/utils/router'
import { set } from './storage'

/**
 * 🟢 mock xhr (offline)
 */
if (
  process.env.VUE_APP_ENV === 'dev' &&
  process.env.VUE_APP_SERVER_TYPE === 'none'
) {
  require('../mock').mockXHR()
}

Vue.use(UTable)
Vue.use(UTableColumn)

/**
 * 🟢 directive
 * permisson control:global directive "resourcePermissionDirective"
 */
Vue.use(resourcePermissionDirective.install)
/**
 * 🟢 mixins
 * - permisson control: global mixin "resourcePermissionMixin"
 * - other global
 */
const { resourcePermissionMixin, ...restMixins } = mixins

// 🟢 资源鉴权
Vue.use(resourcePermissionDirective.install) // global directive "resourcePermissionDirective"
Vue.mixin(resourcePermissionMixin) // global mixin "resourcePermissionMixin"
Object.keys(restMixins).forEach((key) => {
  Vue.mixin(restMixins[key])
})

// 🟢 Global filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

// 🟢 prototye method
Dialog.props.lockScroll.default = false // 打开弹窗防止页面抖动
Vue.prototype.$message = resetMessage
Vue.prototype.$halt = halt
Vue.prototype.$dict = dict
Vue.prototype.$biet = biet
Vue.prototype.$fmt = fmt
Vue.prototype.$routeID = uniqueNamedRouteID
Vue.prototype.$num = numberUtils
Vue.prototype.$pageType = pageType
Vue.prototype.$ButtonGroup = ButtonGroup
Vue.prototype.$getFileInfo = getFileInfo
Vue.prototype.$autoCompleteInputFunc = autoCompleteInputFunc
Vue.prototype.$autoCompleteInputEvts = autoCompleteInputEvts
Vue.prototype.$autoSelectRemoteFunc = autoSelectRemoteFunc
Vue.prototype.$agel = { divider, refreshOptions }
Vue.prototype.$agelCreateSelectMode = agelCreateSelectMode
Vue.prototype.$agelSelectNamePair = agelSelectNamePair
Vue.prototype.$agelSelectNamePair = agelSelectNamePair
Vue.prototype.$agelDefaultOption = agelDefaultOption
Vue.prototype.$openLink = openLink

/**
 *
 * @param {string | object} route
 * @param {{isOpenInNewTab: boolean}} options
 * @returns void
 */
function openLink(route, options = {}) {
  const { isOpenInNewTab = true, isReplace = false } = options

  let paramsId = ''
  let _route = route

  const isSettingParamsId =
    route?.params && Object.keys(route.params).length > 0

  if (isSettingParamsId) {
    paramsId = String(Math.floor(Math.random() * 9000) + 1000)
    _route = {
      ...route,
      query: {
        ...(route.query ?? {}),
        paramsId
      }
    }
  }

  const url = this.$router.resolve(_route).href

  if (isReplace) {
    window.open(url, '_blank')
    return
  }

  if (isSettingParamsId) {
    set('session', url, route.params)
  }
  if (!isOpenInNewTab) {
    window.location.href = url
    return
  }

  const pathName = extractPathName(url)
  if (url?.includes('/detail')) {
    if (url.includes('data/basic')) {
      window.open(url, '_blank')
      return
    }

    // 去掉查询参数
    const pathNameWithoutQueryParams = pathName.split('?')[0]
    const tab = window.open('', pathNameWithoutQueryParams)

    // 当关闭详情页对应的列表页，再点击详情页的退出时，会新开一个列表页，但此时再点击单号无法打开详情页
    const isWrongPathName = !tab.location.href.includes('/detail') // 取反

    const isEmptyPage = tab.location.href === 'about:blank'
    if (isWrongPathName || isEmptyPage) {
      window.open(url, pathNameWithoutQueryParams)
    } else {
      tab.focus()
    }

    return
  }

  if (url?.includes('/list')) {
    window.open(url, pathName)
  } else {
    window.open(url, '_blank')
  }
}

Vue.config.productionTip = false
/**
 * 将app属性全局挂载到window上，跨页面跳转打印获取父级索引
 */
window.application = new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
})

const {
  NODE_ENV,
  VUE_APP_ENV,
  VUE_APP_SERVER_TYPE,
  VUE_APP_TRANSPORT_SECURITY,
  VUE_APP_OPEN_AJAX_LOGS,
  VUE_APP_BASE_API_AUTH,
  VUE_APP_PROXY_PASS_AUTH,
  VUE_APP_BASE_API_WEB,
  VUE_APP_PROXY_PASS_WEB,
  VUE_APP_BASE_API_SCM,
  VUE_APP_PROXY_PASS_SCM,
  VUE_APP_BASE_API_EXTERNAL,
  VUE_APP_PROXY_PASS_EXTERNAL,
  VUE_APP_BASE_API_ACTIVITI,
  VUE_APP_PROXY_PASS_ACTIVITI
} = process.env
if (VUE_APP_ENV === 'dev') {
  const go2dict = () =>
    void router.push(`/system/sysDict/list/${uniqueNamedRouteID()}`)
  // window.app = app
  window.dev = {
    字典: go2dict,
    dict: go2dict
  }
}
console.groupCollapsed('System Info')
console.log('NODE_ENV:', NODE_ENV)
console.log('VUE_APP_ENV:', VUE_APP_ENV)
console.log('VUE_APP_SERVER_TYPE:', VUE_APP_SERVER_TYPE)
console.log('VUE_APP_TRANSPORT_SECURITY:', VUE_APP_TRANSPORT_SECURITY)
console.log('VUE_APP_OPEN_AJAX_LOGS:', VUE_APP_OPEN_AJAX_LOGS)
console.log('VUE_APP_BASE_API_AUTH:', VUE_APP_BASE_API_AUTH)
console.log('VUE_APP_PROXY_PASS_AUTH:', VUE_APP_PROXY_PASS_AUTH)
console.log('VUE_APP_BASE_API_WEB:', VUE_APP_BASE_API_WEB)
console.log('VUE_APP_PROXY_PASS_WEB:', VUE_APP_PROXY_PASS_WEB)
console.log('VUE_APP_BASE_API_SCM:', VUE_APP_BASE_API_SCM)
console.log('VUE_APP_PROXY_PASS_SCM:', VUE_APP_PROXY_PASS_SCM)
console.log('VUE_APP_BASE_API_EXTERNAL:', VUE_APP_BASE_API_EXTERNAL)
console.log('VUE_APP_PROXY_PASS_EXTERNAL:', VUE_APP_PROXY_PASS_EXTERNAL)
console.log('VUE_APP_BASE_API_ACTIVITI:', VUE_APP_BASE_API_ACTIVITI)
console.log('VUE_APP_PROXY_PASS_ACTIVITI:', VUE_APP_PROXY_PASS_ACTIVITI)
console.groupEnd()
