const state = () => ({
  selectionMapper: {}
})

const mutations = {
  ADD_SELECTION: (state, selectionInfo) => {
    const { id, selection } = selectionInfo ?? {}

    if (id && selection) {
      state.selectionMapper[id] = selection.map((item) => item.id)
    } else {
      console.error('缺少id或勾选项')
    }
  },
  CLEAR_SELECTION: (state) => {
    state.selectionMapper = {}
  }
}

const actions = {
  addSelection({ commit }, selectionInfo) {
    commit('ADD_SELECTION', selectionInfo)
  },
  clearErrorLog({ commit }) {
    commit('CLEAR_SELECTION')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
