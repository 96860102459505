import service from '@/service'

/**
 * @description 对接处理结果查询
 */
export function getList(data) {
  return service['api-scm']({
    url: '/dc/thirdParty/warehouse/get/getList',
    method: 'post',
    data
  })
}

/**
 * @description 单据列表导出
 */
export function exportDockOrderList(data) {
  return service['api-scm']({
    url: '/dc/thirdParty/warehouse/export',
    method: 'post',
    data,
    responseType: 'blob',
    saveAs: true // 文件流接收类型声明转成 blob
  })
}
