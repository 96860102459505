/**
 * @module api-web:financialAffairs/accountReceivable/verificationSheet
 */
import service from '@/service'

/**
 * 查询 财务-费用财务应收列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ar/finVerificationSheet/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 查询财务收款单详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ar/finVerificationSheet/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 创建 新增
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/ar/finVerificationSheet/save',
    method: 'post',
    data
  })
}

// /**
//  * 提交审核
//  * @param {Array<number|string >} ids
//  */
// export function submit(ids) {
//   return service['api-web']({
//     url: '',
//     method: 'post',
//     data: { ids },
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }

// /**
//  * 修改
//  * @param {Department} data
//  */
// export function update(data) {
//   return service['api-web']({
//     url: '',
//     method: 'put',
//     data
//   })
// }

// /**
//  * @param {object}
//  * @returns 审核驳回
//  */
// export function reject(data) {
//   return service['api-scm']({
//     url: '',
//     method: 'post',
//     data
//   })
// }
/**
 * @param {{ids:Array<number>}} ids
 * @description 反核销
 */
export function reverseVerification(ids) {
  return service['api-web']({
    url: '/fin/ar/finVerificationSheet/public/reverseVerification',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ar/finVerificationSheet/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
