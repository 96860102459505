/**
 * @module api-web:cc
 * @description
 */
import service from '@/service'

// 列表查询接口
export function getList(params) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 删除接口
export function deleteData(ids) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/delete',
    method: 'delete',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 获取详情接口
export function getInfo(id) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 新增加接口
export function save(data) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/save',
    method: 'post',
    data
  })
}

// 订单中心修改接口
export function update(data) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/update',
    method: 'put',
    data
  })
}

/**
 * @description 获取所有启用的订单标记
 */
export function getAllEnableOrderMark(params) {
  return service['api-web']({
    url: '/sys/orderMark/get/getAllEnableOrderMark',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
