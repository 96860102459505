/**
 * @module api-web:financialAffairs/generalLedger/endOfPeriod/finProfitLossConvert
 */
import service from '@/service'

/**
 * 结转凭证 财务-总账-凭证管理-结转损益
 * @param {object} data
 */
export function convertVoucher(data) {
  return service['api-web']({
    url: '/fin/gl/period/finProfitLossConvert/convertVoucher',
    method: 'post',
    data
  })
}

/**
 * 结转损益-科目选择 财务-总账-凭证管理-结转损益
 * @param {object} params
 */
export function convertSubjectRelation(params) {
  return service['api-web']({
    url: '/public/fin/convertSubjectRelation',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
