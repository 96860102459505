/**
 * @module api-web:data
 * @description
 */
import service from '@/service'
/**
 * 根据组织（供应商，客户，物流商）查询组织在资料中录入的结算方式
 * @param {{orgCode:string,orgType:number}} params
 * @description
 * - orgCode 组织 code
 * - orgType 组织类型
 *    - 0供应商
 *    - 1客户
 *    - 2物流商
 */
export function getClearingFormByOrganization(params) {
  return service['api-web']({
    url: '/public/data/getClearingFormByOrganization',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getParamList(params) {
  return service['api-web']({
    url: '/data/markingLog/getParamList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
