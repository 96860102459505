/**
 * @module api-web:organization
 * @description api for organization management
 */
import service from '@/service'

/**
 * 组织列表查询
 * @param {{keywords:string,limit:number,page:number,parentDept:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/data/companyInfo/getList',
    method: 'get',
    params
  })
}
/**
 * 新增组织信息
 * @param {organization} data
 */
export function add(data) {
  return service['api-web']({
    url: '/data/companyInfo/save',
    method: 'post',
    data
  })
}

/**
 * 提货方式修改
 * @param {organization} data
 */
export function update(data) {
  return service['api-web']({
    url: '/data/companyInfo/update',
    method: 'post',
    data
  })
}
/**
 * 单个/批量 删除组织
 * @param {{ids:Array<number>}} params - ids
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/companyInfo/deleteAll',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 查询组织详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/data/companyInfo/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 组织 禁用/启用
 */
export function enable(data) {
  return service['api-web']({
    url: '/data/companyInfo/updateState',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
