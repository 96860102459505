/**
 * @module BaseInlineEditTable/mixins/rules
 * 内联编辑表格 〉宏 〉校验规则
 */
import { isArray, isPlainObject } from '@/utils/validate'
export default {
  props: {
    editRules: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      /**
       * @type {object}
       * @description 从 item 中收集的校验规则，将与表格 edit-rules 合并覆盖
       */
      columnRules: {}
    }
  },
  computed: {
    /**
     * @returns {object} 表格验证规则
     */
    validRules() {
      return {
        ...this.editRules,
        ...this.columnRules
      }
    }
  },
  methods: {
    /**
     * 从 item 中收集校验的 rules 合并覆盖 edit-rules for vxe-table
     */
    collectItemRules(item) {
      if (item.isComp && item.rule) {
        this.$set(this.columnRules, item.prop, item.rule)
      }
      if (!isPlainObject(item.editRender)) {
        return {}
      }
      if (
        !isPlainObject(item.editRender.rule) &&
        !isArray(item.editRender.rule) &&
        typeof item.editRender.rule !== 'function'
      ) {
        return {}
      }
      this.$set(this.columnRules, item.prop, item.editRender.rule)
      return { rule: item.editRender.rule }
    }
  }
}
