import layout from '@/layout'

import componentsRoutes from './components'
import vueUseRoutes from './vueuse'
import chartsRoutes from './charts'
import tableRoutes from './table'
import articleRoutes from './articles'
import excelRoutes from './excel'

export default {
  path: '/demos',
  component: layout,
  alwaysShow: true, // will always show the root menu
  redirect: '/demos/dashboard',
  name: 'Demos',
  meta: {
    title: 'Devspace',
    icon: 'tree'
  },
  children: [
    articleRoutes,
    {
      path: 'dashboard',
      component: () => import('@/views/demos/dashboard'),
      name: 'Dashboard',
      meta: { title: 'Dashboard', icon: 'dashboard' }
    },
    {
      path: 'profile',
      component: () => import('@/views/demos/profile'),
      name: 'Profile',
      meta: { title: 'Profile', icon: 'user', noCache: true }
    },
    {
      path: 'guide',
      component: () => import('@/views/demos/guide'),
      name: 'Guide',
      meta: { icon: 'guide', title: 'Guide' }
    },
    {
      path: 'icon',
      component: () => import('@/views/demos/icons'),
      name: 'Icons',
      meta: { title: 'Icons', icon: 'icon', noCache: true }
    },
    {
      path: 'tab',
      component: () => import('@/views/demos/tab'),
      name: 'Tab',
      meta: { title: 'Tab', icon: 'tab' }
    },
    {
      path: 'theme',
      component: () => import('@/views/demos/theme'),
      name: 'Theme',
      meta: { title: 'Theme', icon: 'theme' }
    },
    {
      path: 'clipboard',
      component: () => import('@/views/demos/clipboard'),
      name: 'ClipboardDemo',
      meta: { title: 'Clipboard', icon: 'clipboard' }
    },
    {
      path: 'Rightmenu',
      component: () => import('@/views/demos/rightmenu'),
      name: 'RightMenuDemo',
      meta: { title: 'Rightmenu', icon: 'edit' }
    },
    /** when your routing map is too long, you can split it into small modules **/
    componentsRoutes,
    vueUseRoutes,
    chartsRoutes,
    tableRoutes,
    excelRoutes,
    {
      path: 'zip',
      component: () => import('@/views/demos/zip'),
      name: 'ExportZip',
      meta: { icon: 'zip', title: 'Export Zip' }
    },

    {
      path: 'external-link',
      meta: { icon: 'link', title: 'External Link' },
      children: [
        {
          path: 'http://www.safeware.com.cn/',
          meta: { title: '安赛瑞', icon: 'link' }
        },
        {
          path: 'http://www.baidu.com',
          meta: { title: '百度', icon: 'link' }
        }
      ]
    }
  ]
}
