import { DOC_STATUS, DISABLED_STATUS } from '@/constants'

/**
 * @param {object} obj - table row or other object
 * @returns {(number|null)}
 * @description 从对象数据上获取单据状态
 */
export const getFeild = (fields, obj) => {
  const arr = fields
  const len = arr.length
  for (let i = 0; i < len; i++) {
    const field = arr[i]
    const status = obj?.[field]
    if (typeof status === 'number') return status
  }
  return undefined
}

/**
 * @param {object} obj - table row or other object
 * @returns {(number|null)}
 * @description 从对象数据上获取单据状态
 */
export const getDocStatus = (obj) => getFeild(DOC_STATUS, obj)

/**
 * @param {object} obj - table row or other object
 * @returns {(number|null)}
 * @description 从对象数据上获取禁用状态
 */
export const getDisabledStatus = (obj) => getFeild(DISABLED_STATUS, obj)
