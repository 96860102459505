<template>
  <div>
    <!-- 表格激活模式下 renderEdit -->
    <verification-input-by-number
      v-model="proxyValue"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>
<script>
export default {
  name: 'ElInputFigure',
  props: {
    scope: {
      type: Object,
      default: () => ({
        row: {},
        column: {}
      })
    }
  },
  data() {
    return {}
  },
  computed: {
    proxyValue: {
      set(val) {
        const { row, column } = this.scope
        row[column.property] = val
      },
      get() {
        const { row, column } = this.scope
        const _val = row[column.property]
        return _val
      }
    }
  },
  mounted() {}
}
</script>
