/**
 * @type {Record<string,(number|string)>}
 * @description 业务 ID 常量，不同环境始终一致
 */
export const BIZ_ID = {
  上海安赛瑞有限公司: '946774902310961154'
}

/**
 * @type {Record<string,number>}
 * @description 轮询超时阈值 (ms)， max value
 */
export const POLL_TIMEOUT = {
  slotContent: 5 * 1000, // 轮询插槽内容
  baseInlineEditTableTreeExpand: 10 * 1000, // 轮询内联编辑树表格展开状态
  adjustListPageSize: 160, // 列表页面分页行数动态调整
  detailCruLogs: 30 * 1000
}

/**
 * @type {Record<string,number>}
 *  @description 网络通信超时阈值 (ms)， max value
 */
export const NETWORK_TIMEOUT = {
  asyncComponent: 30 * 1000, // 异步组件加载
  request: 60 * 1000, // 网络请求
  upload: 5 * 60 * 1000, // 上传 ( FormData 中 可能包含 file )
  download: 5 * 60 * 1000 // 文件流下载
}
/**
 * @type  {Array<string>}
 * @description 白名单中的接口将不会被取消
 */
export const CANCEL_REQ_WHITE_LIST = [
  '/public/order/getAllGeographicCoordinates'
]
/**
 * @type {Array<string>}
 * @description 编辑页路由名的结尾字符串：创建、详情编辑、流程设计
 */
export const EDIT_PAGE_ROUTE_NAME_ENDS = ['Create', 'Detail', 'ProcessDesign']
/**
 * @type {Array<string>}
 * @description 细分创建页路由名的结尾字符串：创建
 */
export const CREATE_PAGE_ROUTE_NAME_ENDS = ['Create']
/**
 * @type {Array<string>}
 * @description 细分详情页路由名的结尾字符串：详情编辑、流程设计
 */
export const DETAIL_PAGE_ROUTE_NAME_ENDS = ['Detail', 'ProcessDesign']
/**
 * @type {Array<string>}
 * @description 列表页路由名的结尾字符串，不包含非“三件套”结构路由命名，如系统管理下的部门管理、权限管理等
 */
export const LIST_PAGE_ROUTE_NAME_ENDS = [
  'List',
  'SystemDepartment',
  'SystemDictionary',
  'SystemJob',
  'SystemPermission',
  'SystemLog',
  'SystemProcessCategory',
  'SystemProcessTodo',
  'DockCenterKingdee', // 金蝶对接中心
  'TaskCenterQueryListByPage' //  任务管理/下载任务
  // 'FinancialAffairsAccountReceivableEstimateManualOff',
  // 'FinancialAffairsAccountReceivableSummary',
  // 'FinancialAffairsAccountPayableReceiptManualOn',
  // 'FinancialAffairsAccountPayableFinApStatements'
]
/**
 * @type {Array<string>}
 * @description 无需轮询布局的列表页
 */
export const DONOT_POLL_LAYOUT_ROUTENAMES = [
  'HomeMyTasksCategory',
  'SystemDepartment',
  'SystemJob',
  'SystemLog',
  'SystemPermission',
  'SystemProcessCategory',
  'SystemProcessTodo',
  'DataBasicTaxSystemList',
  'FinancialAffairsAccountReceivableFinReceiptCheckoutList',
  'FinancialAffairsAccountPayableFinCashierClosingList',
  'FinancialAffairsAccountPayableFinPaymentCheckoutList',
  'FinancialAffairsGeneralLedgerDataAccountBookList',
  'FinancialAffairsGeneralLedgerDataAccountingCalendarList',
  'FinancialAffairsGeneralLedgerDataAccountingPolicyList',
  'FinancialAffairsGeneralLedgerDataVoucherLetterList',
  'FinancialAffairsGeneralLedgerVoucherManagementVerifyVoucherList',
  'FinancialAffairsGeneralLedgerDataVoucherTemplateList',
  'FinancialAffairsGeneralLedgerReportCenterSubjectBalanceList',
  'FinancialAffairsGeneralLedgerEndOfPeriodFinEndPeriodCheckoutList',
  'FinancialAffairsGeneralLedgerVoucherManagementPostVoucherList',
  'FinancialAffairsCostProfitAnalysisList',
  'FinancialAffairsCostInventoryAccounRepList',
  'FinancialAffairsCostFinStockCostCheckoutList',
  'SupplyChainWarehouseMaterialTransceiverStorageList'
]
/**
 * @type {Record<string,string>}
 * @description 字典类型映射别名
 */
export const DICT_ALIAS = {
  all: null, // 不区分类型
  biz: 0, // 业务类
  sys: 1 // 系统类
}
/**
 * @type {Array<string>}
 * @description 服务端定义的 "单据状态" 字段名
 */
export const DOC_STATUS = [
  'documentStatus',
  'documentState',
  'dataStatus',
  'purchaseState',
  'status'
]
/**
 * @type {Array<string>}
 * @description 服务端定义的 "禁用状态" 字段名
 */
export const DISABLED_STATUS = ['disableStatus', 'disableState']
/**
 * @const {string} BIET_RENDER_INJECT_NAMESPACE
 * @description 渲染器组织逻辑时注入数据的统一命名空间, 如：item.editRender.__BIET_RENDER_INJECT__
 * ⚠️ 此处如果修改，须全局搜索替换
 */
export const BIET_RENDER_INJECT_NAMESPACE = '__BIET_RENDER_INJECT__'
/**
 * @const {string} 表格行数据组织逻辑时添加数据的统一命名空间 row.__BIET_ROW_EXTERNAL__
 * @description ⚠️ 此处修改，须全局搜索替换
 */
export const BIET_ROW_EXTERNAL_NAMESPACE = '__BIET_ROW_EXTERNAL__'
/**
 * @type {Record<string,string>}
 * @description 下推后跳转的目标详情
 * 大部分跳转到其他模块的详情页，小部分特殊情况跳转到当模块详情（如售后单，费用销售订单等）
 */
export const PUSHDOWN_TARGET = {
  /**
   * 供应链
   */
  物流理赔单: '/supplyChain/logistics/logisticsClaim/detail',
  销售售后单: '/supplyChain/orderManage/orderInfo/detail',
  线下销售售后单: '/clientServe/sellManage/sellOrder/detail',
  采购退料申请: '/supplyChain/buyInAndOutWarehouse/buyAfterManage/detail',
  采购售后单: '/supplyChain/procurementManage/purchaseOrder/detail',
  费用采购订单: '/supplyChain/procurementManage/purchaseOrder/detail',
  费用销售订单: '/clientServe/sellManage/sellOrder/detail',
  关联采购订单: '/supplyChain/procurementManage/purchaseOrder/detail',
  关联销售订单: '/clientServe/sellManage/sellOrder/detail',
  /**
   * 应收
   */
  收款单: '/financialAffairs/accountReceivable/receipt/detail',
  特殊红字收款单: '/financialAffairs/accountReceivable/receipt/detail',
  应收单: '/financialAffairs/accountReceivable/financeReceivable/detail',
  财务应收单: '/financialAffairs/accountReceivable/financeReceivable/detail',
  特殊红字应收单:
    '/financialAffairs/accountReceivable/financeReceivable/detail',
  费用红字应收单:
    '/financialAffairs/accountReceivable/expenseReceivable/detail',
  特殊红字付款单: '/financialAffairs/accountPayable/paymentOrder/detail',
  费用财务应收单:
    '/financialAffairs/accountReceivable/expenseReceivable/detail',
  /**
   * 应付
   */
  付款单: '/financialAffairs/accountPayable/paymentOrder/detail',
  财务应付单: '/financialAffairs/accountPayable/finPayableBill/detail',
  特殊红字财务应付单: '/financialAffairs/accountPayable/finPayableBill/detail',
  费用财务应付单: '/financialAffairs/accountPayable/finFeePayableBill/detail',
  特殊红字费用应付单:
    '/financialAffairs/accountPayable/finFeePayableBill/detail',
  红字费用财务应付单:
    '/financialAffairs/accountPayable/finFeePayableBill/detail',
  付款申请单: '/financialAffairs/accountPayable/applicationForm/detail',
  其他业务收款单: '/financialAffairs/accountReceivable/receipt/detail',

  采购调价函: '/supplyChain/procurementManage/purchaseAdjustment/detail',
  销售订单: '/clientServe/sellManage/sellOrder/create',
  收款退款单: '/financialAffairs/accountReceivable/finReceiptOrderBack/detail',
  付款退款单: '/financialAffairs/accountPayable/paymentRefund/detail'
}
/**
 * @type {Array<string>}
 * @description 以下下推类型，多条数据会下推成多条数据，
 * 这样的话会与下推后跳转到单据详情页面的需求有冲突，所以这里罗列出来禁止下推
 */
export const DISABLE_MULTIPLE_PUSHDOWN = [
  '物流理赔单',
  '销售售后单',
  '采购售后单',
  '采购退料申请',
  '线下销售售后单',
  '特殊红字收款单',
  '特殊红字应收单',
  '费用红字应收单',
  '特殊红字财务应付单',
  '红字费用财务应付单'
]
/**
 * @type {number}
 * @description image-size attr for el-empty
 */
export const EL_EMPTY_IMAGE_SIZE = 200

/**
 * @type {number}
 * @description default page size of table pagination
 */
export const DEFAULT_TABLE_ROW_LIMIT = 10
/**
 * @type {Array<number>}
 * @description default page sizes of table pagination
 */
export const DEFAULT_TABLE_ROW_LIMITS = [5, 10, 12, 13, 14, 15, 20, 30, 50, 100]
/**
 * @type {string}
 * @description default table sort for listQuery
 */
export const DEFAULT_TABLE_SORT = ''

/**
 * @type {Array<string>}
 * @description 订单标记预设颜色
 */
export const ORDER_MARK_PREDEFINE_COLOR = [
  '#ECF2FE',
  '#D4E3FC',
  '#BBD3FB',
  '#96BBF8',
  '#027AFF',
  '#266FE8',
  '#0052D9',
  '#0034B5',
  '#001F97'
]

export const SUPPLY_AUTHORIZATION_PUSH_DOWN_TYPE = {
  1: 'TURN_APPLY'
}

export const SYNC_WEBSITE_MAPPER = {
  SYNC_WEBSITE: '同步官网',
  NOT_SYNC_WEBSITE: '不同步官网'
}

export const IN_WAREHOUSE_STATUS_LIST = [
  { label: '未入库', value: 0 },
  { label: '部分入库', value: 1 },
  { label: '已入库', value: 2 }
]

export const IN_WAREHOUSE_STATUS_MAPPER = {
  0: '未入库',
  1: '部分入库',
  2: '已入库'
}

export const GUANYI_OUT_WAREHOUSE_STATUS_MAPPER = {
  0: '未出库',
  1: '已出库'
}

export const GUANYI_OUT_WAREHOUSE_STATUS_OPTIONS = [
  { label: '未出库', value: 0 },
  { label: '已出库', value: 1 }
]

export const GUANYI_IN_WAREHOUSE_STATUS_MAPPER = {
  0: '未入库',
  1: '已入库'
}

export const GUANYI_IN_WAREHOUSE_STATUS_OPTIONS = [
  { label: '未入库', value: 0 },
  { label: '已入库', value: 1 }
]

export const YES_OR_NO_MAPPER = {
  0: '否',
  1: '是'
}

export const YES_OR_NO_OPTIONS = [
  { label: '是', value: 1 },
  { label: '否', value: 0 }
]

export const GUANYI_DOCUMENT_STATUS_OPTIONS = [
  { label: '已审核', value: 1, color: 'success' },
  { label: '未审核', value: 0 }
]

export const GUANYI_DOCUMENT_STATUS_MAPPER = {
  0: '未审核',
  1: '已审核'
}

export const DOCUMENT_STATUS_OPTIONS = [
  { value: 1, label: '创建', dataValue: '1', tagName: '创建' },
  { value: 2, label: '审核中', dataValue: '2', tagName: '审核中' },
  { value: 3, label: '已审核', dataValue: '3', tagName: '已审核' },
  { value: 4, label: '重新审核', dataValue: '4', tagName: '重新审核' }
]

export const LOGISTICS_ABNORMAL_OPTIONS = [
  {
    value: 1,
    label: '是',
    dataValue: '1',
    tagName: '是'
  },
  {
    value: 0,
    label: '否',
    dataValue: '0',
    tagName: '否'
  }
]

export const BUSINESS_STATUS_OPTIONS = [
  {
    value: 0,
    label: '正常',
    dataValue: '0',
    tagName: '正常'
  },
  {
    value: 1,
    label: '关闭',
    dataValue: '1',
    tagName: '关闭'
  }
]

export const DOCKING_FLAG_OPTIONS = [
  {
    value: 0,
    label: '对接成功',
    dataValue: '0',
    tagName: '对接成功'
  },
  {
    value: 1,
    label: '对接失败',
    dataValue: '1',
    tagName: '对接失败'
  },
  {
    value: 2,
    label: '对接失败',
    dataValue: '2',
    tagName: '对接失败'
  }
]

export const DOCKING_STATUS_OPTIONS = [
  {
    value: 0,
    label: '对接失败',
    dataValue: '0',
    tagName: '对接失败'
  },
  {
    value: 1,
    label: '对接成功',
    dataValue: '1',
    tagName: '对接成功'
  },
  {
    value: 2,
    label: '对接失败',
    dataValue: '2',
    tagName: '对接失败'
  },
  {
    value: 3,
    label: '对接失败',
    dataValue: '3',
    tagName: '对接失败'
  }
]

export const LOGISTICS_COST_PAYER_OPTIONS = [
  {
    value: 0,
    label: '需方'
  },
  {
    value: 1,
    label: '供方'
  }
]

export const LOGISTICS_RECEIVING_TYPE_SOPO_OPTIONS = [
  {
    value: 0,
    label: '自提',
    dataValue: '0',
    tagName: '自提'
  },
  {
    value: 1,
    label: '物流',
    dataValue: '1',
    tagName: '物流'
  },
  {
    value: 2,
    label: '供应商垫付',
    dataValue: '2',
    tagName: '供应商垫付'
  },
  {
    value: 3,
    label: '到付',
    dataValue: '3',
    tagName: '到付'
  }
]

export const DOCUMENT_TYPE_OPTIONS = [
  { value: 1, label: '标准采购订单' },
  { value: 2, label: '智能采购订单' },
  { value: 3, label: '补货' },
  { value: 4, label: '退货退款' },
  { value: 5, label: '仅退款' },
  { value: 6, label: '费用采购订单' }
]

export const LOCK_STATUS_OPTIONS = [
  {
    value: 0,
    label: '未锁定',
    dataValue: '0',
    tagName: '未锁定'
  },
  {
    value: 1,
    label: '已锁定',
    dataValue: '1',
    tagName: '已锁定'
  },
  {
    value: 2,
    label: '已释放',
    dataValue: '2',
    tagName: '已释放'
  }
]

export const DOCUMENT_TYPE_OPTIONS_SELL_ORDER = [
  { value: 1, label: '标准销售订单' },
  { value: 2, label: '寄售销售订单' },
  { value: 3, label: '售后补货' },
  { value: 4, label: '退货退款' },
  { value: 5, label: '仅退款' },
  { value: 6, label: '项目销售订单' },
  { value: 7, label: '补货（退A发B)' },
  { value: 8, label: '费用销售订单' }
]

export const RETURN_GOOD_TYPE_OPTIONS_SELL_ORDER = [
  { value: 1, label: '产品质量问题' },
  { value: 2, label: '发错货' },
  { value: 3, label: '运输过程损毁或遗失' },
  { value: 4, label: '产品误导' },
  { value: 5, label: '漏发（少配件或产品）' },
  { value: 6, label: '客户自身原因' },
  { value: 7, label: '物流延迟' },
  { value: 8, label: '其他' }
]
export const PREPAY_TYPE_OPTIONS = [
  { value: 0, label: '按比例' },
  { value: 1, label: '按金额' }
]
