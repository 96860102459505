import store from '@/store'
import { logErr } from './utils'
import { getItem } from './item'
import { dict } from '.'
import { isArray, isString } from '@/utils/validate'

/**
 * get label async, if local missed, get via action
 * @param {string} from
 * @param {number} value
 * @returns {Promise<(string|boolean)>}
 * @description 获取字典项单项标签 ，异步，无需初始化字典项列表
 */
export async function getLabel(from, value) {
  try {
    const label = getLabelSync(from, value)
    if (isString(label) && label.trim()) return label
    else {
      return (
        (await getItem(from, { value /* , force: true  */ }))?.label || false
      )
    }
  } catch (error) {
    logErr([error, from, value])
    return false
  }
}
/**
 * get label sync via getters
 * @param {string} from
 * @param {number} value
 * @returns {(string|boolean)}
 * @description 获取字典项单项标签，同步，须初始化字典项列表
 */
export function getLabelSync(from, value) {
  try {
    const [category, name] = from.split('.')
    return store.getters[`dict/${category}`]?.[name]?.label?.[value] || false
  } catch (error) {
    logErr([error, from, value])
    return false
  }
}

/**
 * get all labels async, if local missed, init dict array via action
 * @param {string} from
 * @returns {Promise<(Array<string>|boolean)>}
 * @description 获取字典所有标签，异步，无需初始化字典项列表
 */
export async function getAllLabel(from) {
  try {
    const labels = getAllLabelSync(from)
    if (labels) return labels
    else {
      await dict(from /* , { force: true } */)
      return getAllLabelSync(from)
    }
  } catch (error) {
    logErr([error, from])
    return false
  }
}
/**
 * get all labels sync via getters
 * @param {string} from
 * @returns {(Array<string>|boolean)}
 * @description 获取字典所有标签，同步，须初始化字典项列表
 */
export function getAllLabelSync(from) {
  try {
    const [category, name] = from.split('.')
    const labels = store.getters[`dict/${category}`]?.[name]?.labels
    return isArray(labels) && labels.length ? labels : false
  } catch (error) {
    logErr([error, from])
    return false
  }
}
