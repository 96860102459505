/**
 * @module api-scm:production/productionInventory
 */
import service from '@/service'

export function getList(params) {
  return service['api-scm']({
    url: '/scm/production/productionInventory/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {Object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/production/productionInventory/save',
    method: 'post',
    data
  })
}
/**
 * 更新
 * @param {{id:(number|string)}}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/production/productionInventory/update',
    method: 'post',
    data
  })
}
/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/production/productionInventory/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 提交
 * @param {number} id
 * @returns {any}
 */
export function submit(data) {
  return service['api-scm']({
    url: '/scm/production/productionInventory/submit',
    method: 'post',
    data
  })
}

/**
 * 删除
 * @param {{ids:string}} params
 * @param {number} params
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/production/productionInventory/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
