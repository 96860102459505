<template>
  <div :class="['content', ...contentClass]" :style="heightControl">
    <slot v-if="isDetailPage" name="detail-form" />
    <SplitedLayout
      v-else-if="isListPage"
      justify="space-between"
      :has-left="hasLeft"
      :left-span="leftSpan"
      :right-span="rightSpan"
      :gutter="gutter"
    >
      <template #left>
        <slot name="left" />
      </template>
      <SysTree
        v-if="nestedNavs.length"
        ref="nested-navs"
        highlight-current
        :data="nestedNavs"
        :expand-on-click-node="false"
        :current-node-key="$route.meta.idspace"
        node-key="id"
        @node-click="onNestedTreeNav"
      />
      <template #right>
        <slot name="right" />
      </template>
    </SplitedLayout>
    <slot />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SplitedLayout from '@/components/SplitedLayout'
import SysTree from '@/components/SysTree'
import { isArray } from '@/utils/validate'
import { uniqueNamedRouteID } from '@/utils/router'
export default {
  components: {
    SplitedLayout,
    SysTree
  },
  props: {
    heightControl: {
      type: Object,
      default: () => ({})
    },
    gutter: {
      type: Number,
      default: 0
    },
    leftSpan: {
      type: Number,
      default: 6
    },
    rightSpan: {
      type: Number,
      default: 18
    }
  },
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['nestedNavsPidx']),

    nestedNavs() {
      // eslint-disable-next-line no-unused-vars
      const { fullPath } = this.$route // don't remove , try hack computed cached
      try {
        const navs = this.nestedNavsPidx[this.$route.meta?.menuspace]
        return isArray(navs) ? navs : []
      } catch (error) {
        console.error(error)
      }

      return []
    },
    hasLeft() {
      return (
        typeof this.$slots.left !== 'undefined' ||
        (isArray(this.nestedNavs) && this.nestedNavs.length > 0)
      )
    },
    isListPage() {
      return !!this.$slots['right']
    },
    isDetailPage() {
      return !!this.$slots['detail-form']
    },
    /**
     * @returns {Array<(string|undefined)>}
     */
    contentClass() {
      switch (true) {
        case this.isDetailPage:
          return ['detail-page']
        case this.isListPage:
          return ['list-page']
        default:
          return []
      }
    }
  },
  async mounted() {
    // try {
    //   await this.$halt()
    //   const $nestedNav = this.$refs['nested-navs'].$refs.tree
    //   if ($nestedNav) {
    //     $nestedNav.setCurrentKey(this.$route.meta.idspace)
    //   }
    // } catch (error) {
    //   console.error(error)
    // }
  },
  methods: {
    onNestedTreeNav(node, $node) {
      // console.log(node, $node)
      this.$openLink({
        name: node.name,
        params: { id: uniqueNamedRouteID() }
      })
    }
  }
}
</script>
<style scoped lang="scss">
// :deep(.left) {
// min-width: 272px;
// }
// 撤销需求2842 全局处理，see bug：9753
//.content.detail-page {
// height: calc(100vh - 182px) !important;
//overflow: auto;
// background: #dfd8d8;
// padding-top: 15px;
//margin-top: -15px;
//}
</style>
