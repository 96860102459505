/**
 * @module custom-renderers:VxeTableSelector
 * @path @/src/plugins/vxe-table/
 */
import VXETable from 'vxe-table'
import VxeTableSelect from './VxeTableSelect'

// 下拉表格：支持关联查询，支持下拉表格分页
VXETable.renderer.add('VxeTableSelector', {
  renderEdit: VxeTableSelectorRender,
  renderCell: (h, renderOpts, params = {}) => {
    const { row, column } = params
    const value = row[column.property]

    return [
      renderOpts.displayAsEditingMode ? (
        <VxeTableSelect renderOpts={renderOpts} scope={params}></VxeTableSelect>
      ) : (
        <span>{value}</span>
      )
    ]
  }
})

function VxeTableSelectorRender(h, renderOpts, params = {}) {
  return [
    <VxeTableSelect renderOpts={renderOpts} scope={params}></VxeTableSelect>
  ]
}
