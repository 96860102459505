/**
 * @module api-web:filter-opts/financialAffairs/generalLedger
 * @description common apis : dropdown select options - data
 */
import service from '@/service'

/**
 * @param {*} params
 * @description 总账-费用项目
 */
export function getConstitemOpts(params) {
  return service['api-web']({
    url: '/public/fin/getFinCostItemGroup',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {*} params
 * @description 总账-账簿
 */
export function getAccountBookOpts(params) {
  return service['api-web']({
    url: '/public/fin/getBookPullList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object} params
 * @description 总账-科目类别下拉
 * TODO:
 * 1. 需要放在 public 中，不然跨页面请求会有 401 权限拦截 2. 如果不存在跨页面请求的场景，需要将此接口迁移到对应的目录中
 */
export function getSubjectsCategoryOpts(params) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountSubjects/getSubjectsCategory',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 总账-资料-费用项目下拉
 * @description 在销售采购订单中使用 for purchase/sales
 * @param  {{flag:(1|2)}} params flag 1销售 2采购
 */
export function getCostItemPSOpts(params) {
  return service['api-web']({
    url: '/public/fin/getCostItemDropdown',
    method: 'get',
    params
  })
}

/**
 * 总账-资料-费用项目下拉 (销售)
 * @description 在销售采购订单中使用 only for sales
 */
export function getCostItemSalesOpts(params = {}) {
  return service['api-web']({
    url: '/public/fin/getCostItemDropdown',
    method: 'get',
    params: { ...params, flag: 1 }
  })
}
/**
 * 总账-资料-费用项目下拉 (采购)
 * @description 在销售采购订单中使用 only for purchase
 */
export function getCostItemPurchaseOpts(params = {}) {
  return service['api-web']({
    url: '/public/fin/getCostItemDropdown',
    method: 'get',
    params: { ...params, flag: 2 }
  })
}
