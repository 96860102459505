<template>
  <div>
    <div class="wrap">
      <svg-icon
        v-if="showExpandButton"
        class="expand-button"
        :icon-class="isExpand ? 'i-spread' : 'i-packUp'"
        @click="onToggleExpand"
      />
      <div v-else class="icon" />

      <div class="title">
        <div class="titleName">{{ titleName }}</div>
        <div v-if="otherInfo" class="other-info">{{ otherInfo }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlueTitleText',
  props: {
    titleName: {
      type: String,
      required: true,
      default: ''
    },
    showExpandButton: {
      type: Boolean,
      default: false
    },

    otherInfo: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isExpand: true
    }
  },

  methods: {
    onToggleExpand() {
      this.isExpand = !this.isExpand
      this.$emit('toggle', this.isExpand)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  margin: 0px;
  display: flex;
  align-content: center;
  height: 28px;

  .icon {
    width: 4px;
    height: 14px;
    background: #027aff;
    margin-right: 8px;
    font-size: 16px;
    margin-top: 3px;
  }

  .titleName,
  .other-info {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }

  .expand-button {
    margin-right: 8px;
    cursor: pointer;
  }

  .title {
    display: flex;
    justify-content: space-between;

    .other-info {
      margin-left: 150px;
    }
  }
}
</style>
