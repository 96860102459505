/**
 * @module api-web:financialAffairs/cost/finStockCostCheckout
 */
import service from '@/service'

/**
 * 查询 财务-总账-成本-存货结账期末结账
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/cost/finStockCostCheckout/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情 财务-总账-成本-存货结账期末结账
 * @param {object} data
 */
export function checkout(data) {
  return service['api-web']({
    url: '/fin/cost/finStockCostCheckout/checkout',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 创建 财务-总账-成本-存货结账期末结账
 * @param {object} data
 */
export function reverseCheckout(data) {
  return service['api-web']({
    url: '/fin/cost/finStockCostCheckout/reverseCheckout',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
