import service from '@/service'
/**
 *  系统树：流程分类
 */
export function getProcessCategoryTree(params) {
  return service['api-activiti']({
    url: '/public/getActCategoryTree',
    method: 'get',
    params
  })
}
