<template>
  <span>
    <i v-if="loading" class="el-icon-minus" />
    <span v-show="!loading" class="label-picker-label">{{ label }}</span>
  </span>
</template>

<script>
export default {
  name: 'LabelPicker',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, null],
      default: null
    },
    source: {
      type: Array,
      required: true
    }
  },
  computed: {
    label() {
      if (this.value !== null && this.source.length) {
        try {
          const find = this.source.find((v) => v.value === this.value)
          return find ? find?.['label'] ?? '⏤' : '⏤'
        } catch (e) {
          console.error(e)
        }
      }
      return '⏤'
    }
  }
}
</script>
<style scoped></style>
