import service from '@/service'

/**
 * 列表查询
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/logistics/electronFaceBill/getList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 */
export function save(data) {
  return service['api-scm']({
    url: '/scm/logistics/electronFaceBill/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/logistics/electronFaceBill/update',
    method: 'post',
    data
  })
}

/**
 * 根据承运商ID 查询日志
 */
export function getOrderLog(id) {
  return service['api-scm']({
    url: '/scm/logistics/electronFaceBill/get/getOrderLog',
    method: 'get',
    params: {
      id
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 批量启用禁用
 */
export function batchUpdateDisable(data) {
  return service['api-scm']({
    url: '/scm/logistics/electronFaceBill/batch/UpdateDisable',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 删除
 */
export function deleteData(data) {
  return service['api-scm']({
    url: '/scm/logistics/electronFaceBill/batch/Delete',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
