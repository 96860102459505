/**
 * @module utils/rsa
 */

import JSEncrypt from 'jsencrypt'
import { isString } from '@/utils/validate'
import { KEY_SIZES, DEFAULT_KEY_SIZE, DEFAULT_PUB_KEY } from './conf'
import { generateKeyPair, splitKeyPair } from './keypair'

/**
 * @typedef {Object} RsaPayload
 * @property {string} key 密钥（加密：公，解密：私）
 * @property {(512|1024|2048|4096)=} size
 * @property {boolean} [encode=true] 是否 URI 编码
 * @property {boolean} [decode=true] 是否 URI 解码
 */
// EG:Object.entries(data).forEach(([k, v]) => data[k] = encrypt(v, { key: xxxx, }))
// { p: xxxx}
/**
 * @param {string} raw 原始字符串
 * @param {RsaPayload} payload @see RsaPayload
 * @returns {string} return encrypted string, and will return 'raw' when empty/failed/error
 */
const encrypt = (
  raw = '',
  payload = { key: '', size: null, encode: true /*  URI 编码，防止中文乱码 */ }
) => {
  if (!raw || !isString(raw)) {
    console.error('【RSA】encrypt: currently only supports string', raw)
    return raw
  }
  try {
    const { key = '', size = null, encode = true } = payload
    if (!key || !isString(key)) {
      console.warn('【RSA】encrypt: use default public key')
    }

    const default_key_size =
      size && KEY_SIZES.includes(size) ? size : DEFAULT_KEY_SIZE
    const rsa = new JSEncrypt({ default_key_size })
    rsa.setPublicKey(isString(key) && key ? key : DEFAULT_PUB_KEY)

    const encrypted = rsa.encrypt(encode ? encodeURIComponent(raw) : raw)

    // ------------------------------- debugger ---------------------------
    // console.groupCollapsed('【RSA】encrypt', `"${raw}"`)
    // console.log('key:', key)
    // console.log('key size:', default_key_size)
    // console.log('raw:', raw)
    // console.log('encoded:', encode)
    // console.log('encrypted:', encrypted)
    // !encrypted && console.error('encryption fails, return the "raw"')
    // console.groupEnd()
    // ------------------------------- debugger ---------------------------

    return encrypted || raw
  } catch (error) {
    console.error('【RSA】encrypt error catched: ' + error)
  }
  return raw
}
/**
 * @param {string} cipher 密文
 * @param {RsaPayload} payload @see RsaPayload
 * @returns {string} return decrypted string, and will return 'cipher' when empty/failed/error
 */
const decrypt = (cipher, payload = { key: '', size: null, decode: true }) => {
  if (!cipher || !isString(cipher)) {
    console.error('【RSA】decrypt: currently only supports string', cipher)
    return cipher
  }
  try {
    const { key = '', size = null, decode = true } = payload
    if (!key || !isString(key)) {
      console.error('【RSA】decrypt: private key is required', payload)
      return cipher
    }

    const default_key_size =
      size && KEY_SIZES.includes(size) ? size : DEFAULT_KEY_SIZE
    const rsa = new JSEncrypt({ default_key_size })
    rsa.setPrivateKey(key)

    const decrypted = rsa.decrypt(cipher)

    // ------------------------------- debugger ---------------------------
    // console.groupCollapsed('RSA decrypt')
    // console.log('cipher:', cipher)
    // console.log('key:', key)
    // console.log('key size:', default_key_size)
    // console.log('decoded:', decode)
    // console.log('decrypted:', decrypted)
    // !decrypted &&
    //   console.error('decryption fails, return the original ciphertext')
    // console.groupEnd()
    // ------------------------------- debugger ---------------------------

    return (
      (decode && decrypted ? decodeURIComponent(decrypted) : decrypted) ||
      cipher
    )
  } catch (error) {
    console.error('【RSA】decrypt error catched: ' + error)
  }
  return cipher
}
/**
 * @description
 * 注意：
 * - RSA 加密不支持长文本加密
 * - 未来如果有长文本加密的需求
 *      1. 可以在场景允许的前提下，换成 AES 加解密
 *      2. 可以对字符串进行压缩， 如 import 'pako', pako.gzip(encodeURIComponent(raw), { to: 'string' })
 *      3. 可以使用一些扩展的长文本加密（分段加密）的方案， 如 const { JSEncrypt } = await import('encryptlong')， 但是在不设置私钥的情况下，是有概率加密失败的
 * - 在加密或者压缩前先对字符串进行 encodeURIComponent 编码，以防中文乱码，后端如 java 解密后先 URLEncoder 解码
 */
export const RSA = {
  generateKeyPair,
  splitKeyPair,
  encrypt,
  decrypt
}

export default RSA
