/**
 * @module api-web:orderMark
 */
import service from '@/service'

/**
 * 查询订单标记列表
 */
export function getList(params) {
  return service['api-web']({
    url: '/sys/orderMark/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增订单标记
 */
export function add(data) {
  return service['api-web']({
    url: '/sys/orderMark/save',
    method: 'post',
    data
  })
}

/**
 * 订单标记修改
 */
export function update(data) {
  return service['api-web']({
    url: '/sys/orderMark/update',
    method: 'put',
    data
  })
}

/**
 * 启用订单标记
 */
export function enableDisable(data) {
  return service['api-web']({
    url: '/sys/orderMark/disable',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 获取日志列表
 */
export function getLogList(params) {
  return service['api-web']({
    url: '/sys/orderMark/get/getLogList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
