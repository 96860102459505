/**
 * @module api-external:filter-opts
 * @description common apis : dropdown select options - basic/warehouseInfomation
 */
import service from '@/service'

/**
 * 物流公司
 */
export function getCloudWarehouseOpts(params) {
  return service['api-external']({
    url: '/public/JDPublic/getCloudWarehouse',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 物流商管理: 承运商下拉框
 * @param {{name:string}} params
 */
export function getCarrierOpts(params) {
  return service['api-external']({
    url: '/public/JDPublic/getPullCarrier',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
