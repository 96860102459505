import VXETable from 'vxe-table'
import ElRemotePickerCustom from './ElRemotePickerCustom'

VXETable.renderer.add('ElRemotePickerCustom', {
  renderEdit: (...args) => ElRemotePickerCustomRender(...args)
})

function ElRemotePickerCustomRender(h, renderOpts, params = {}) {
  const { events = {}, ...rest } = renderOpts
  return [
    <ElRemotePickerCustom scope={params} edit-render-props={rest} on={events} />
  ]
}
