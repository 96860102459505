import axios from 'axios'
import store from '@/store'

import { consoleTheme } from '@/settings'

import { NETWORK_TIMEOUT } from '@/constants'

import { AES, RSA } from '@/utils/crypter'
import { getToken, getKey } from '@/utils/auth'
import { str_to_base64 } from '@/utils/base64'
import { createUniqueString } from '@/utils/string'
import { isArray, isPlainObject, isString } from '@/utils/validate'
import { removeNullValue } from '@/utils/object'

/**
 * @const {string}
 * @description default token
 */
const DEFAULT_TOKEN = `Basic ${str_to_base64('web:123456')}`

/**
 * @const {string}
 * @description default token
 */
const DEFAULT_CONTENT_TYPE = 'application/json;charset=UTF-8'

/**
 * request.config.crypt
 * @typedef {Object} ConfigDotCrypt
 * @property {('aes'|'rsa'|'rsaes'|'aesar'|'aesno'|'rsano'|'noaes'|'norsa'|'none')} [type=aes] 加密类型 see description
 * @property {boolean} [canSkip=true] - true 表示可以跳过加解密（仅在开发环境） ，默认为 true
 * @property {Array<string>=} encryption - 需要加密的字段名数组
 * @property {Array<string>=} decryption - 需要加密的字段名数组
 * @property {{publicKey:(string|undefined),privateKey:(string|undefined)}=} keypair for rsa
 * @description
 * 加密类型:
 * - aes:   request and request all aes , [as default],
 * - rsa:   request and request all rsa
 * - aesar: request aes and response rsa
 * - rsaes: request rsa and response aes
 * - rsano: only request rsa
 * - aesno: only request aes
 * - norsa: only response rsa
 * - noaes: only response aes
 * - none:  all not need encryt/decrtypt
 * - aes(rsano): rsano first, then aes
 *
 *  auth 登录后, 后端发放 key, 用于 aes encryption/decryption
 */

/**
 * @const {ConfigDotCrypt}
 * @description default request.config.crypt
 */
const DEFAULT_CONFIG_CRYPT = { type: 'aes' }

/**
 * Set "Authorization" on request headers
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {undefined}
 */
const attachAuthorization = (config) => {
  const { noAuth = false } = config
  if (noAuth) {
    console.groupCollapsed('Request without Authorization')
    console.log(config)
    console.groupEnd()
    return
  }
  config.headers['Authorization'] = store.getters.token
    ? getToken() || DEFAULT_TOKEN
    : DEFAULT_TOKEN
}
/**
 * Set Content-Type on request headers
 *
 * @description
 * ```java
 * Content-Type: type/<subtype>;<parameter>
 * 1. 文本格式以及媒体格式类型
 *    text/html                         HTML格式
 *    text/plain                        纯文本格式
 *    text/xml                          XML格式
 *    image/gif                         gif图片格式
 *    image/jpeg                        jpg图片格式
 *    image/png                         png图片格式
 *
 * 2. 以 application 开头的媒体格式类型
 *    application/xhtml+xml             XHTML格式
 *    application/xml                   XML数据格式
 *    application/atom+xml              Atom XML聚合格式
 *    application/json                  JSON数据格式
 *    application/pdf                   pdf格式
 *    application/msword                Word文档格式
 *    application/octet-stream          二进制流数据
 *    application/x-www-form-urlencoded form表单数据被编码为key/value格式发送到服务器
 *
 * 3. 包含文件的表单提交
 *    multipart/form-data               需要在表单中进行文件上传
 * ```
 *
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {undefined}
 *
 */
const attachContentType = (config) => {
  config.headers['Content-Type'] =
    config.headers['Content-Type'] ||
    config.headers['content-type'] ||
    config.headers['Content-type'] ||
    config.headers['contentType'] ||
    config.headers['ContentType'] ||
    DEFAULT_CONTENT_TYPE
}

/**
 * add user-id and role-id on request headers
 * in order to baypass gateway
 * only development env
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {undefined}
 */
const attachUserInfosBypassGateway = (config) => {
  const { id, roles } = store.getters
  const { VUE_APP_ATTACH_USER, VUE_APP_ATTACH_ROLE } = process.env
  config.headers['User-Id'] = VUE_APP_ATTACH_USER || id || '1'
  config.headers['Role-Id'] = VUE_APP_ATTACH_ROLE || roles.join(',') || '1'
}

/**
 * 处理请求头
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {undefined}
 */
export const handleRequestHeaders = (config) => {
  attachAuthorization(config)
  attachContentType(config)
}

/**
 * 设置请求超时
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {undefined}
 */
export const handleRequestTimeout = (config) => {
  config.timeout =
    config?.responseType === 'blob'
      ? NETWORK_TIMEOUT.download // 下载
      : config.headers?.['Content-Type']?.includes('multipart/form-data')
      ? NETWORK_TIMEOUT.upload // 上传
      : NETWORK_TIMEOUT.request // 通用
}

export const handleRequestSend = (config) => {
  if (config.sendMutation && isPlainObject(config.sendMutation)) {
    const { dropNull = false } = config.sendMutation
    if (dropNull) {
      if (config.params && Object.keys(config.params).length) {
        config.params = removeNullValue(config.params)
      }
      if (config.data && Object.keys(config.data).length) {
        config.data = removeNullValue(config.data)
      }
    }
  }
}
/**
 * Put request into request pool
 * bind a `cancelToken` for pre-request and save to store
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {undefined}
 */
export const addToRequestPool = (config) => {
  config.id = createUniqueString()
  config.cancelToken = new axios.CancelToken(function executor(cancel) {
    store.dispatch(
      'requestPool/add',
      {
        id: config.id,
        name: config.url,
        payload: JSON.stringify({
          auth: config.headers.Authorization || '',
          data: config.data || '',
          params: config.params || ''
        }),
        cancel,
        stash: false,
        repeatimes: 0
      },
      { root: true }
    )
  })
}

/**
 * 加密发送
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {import('axios').AxiosRequestConfig}
 */
export const encryptResquest = (config) => {
  try {
    config.crypt = isLeaglConfigCrypt(config.crypt)
      ? config.crypt
      : DEFAULT_CONFIG_CRYPT

    const crypt = config.crypt

    typeof crypt?.canSkip !== 'boolean' ? (crypt.canSkip = true) : null

    // 满足开发环境development，开发模式dev，传输安全false,  添加直连服务的请求头
    const { VUE_APP_ENV, VUE_APP_TRANSPORT_SECURITY } = process.env
    if (
      (VUE_APP_ENV === 'dev' || VUE_APP_ENV === 'test') &&
      VUE_APP_TRANSPORT_SECURITY === 'false'
    ) {
      attachUserInfosBypassGateway(config)
      // crypt.canSkip:true 才允许绕过网关
      if (
        crypt?.canSkip === true &&
        isString(crypt?.type) &&
        crypt.type.startsWith('aes')
      ) {
        return config
      }
    }

    switch (crypt.type) {
      case 'aes':
      case 'aesno':
      case 'aesar':
        return AESrequest(config)
      case 'rsa':
      case 'rsano':
      case 'rsaes':
        return RSArequest(config, crypt)
      case 'none':
      case 'noaes':
      case 'norsa':
        return config
      case 'aes(rsano)':
        return AESrequest(RSArequest(config, crypt))
      default:
        return config
    }
  } catch (err) {
    console.error(err)
    return config
  }
}

/**
 * 日志打印
 * @param {import('axios').AxiosResponse} response
 * @param {Res} res
 * @returns {undefined}
 */
export const handleRequestLogs = (config) => {
  const { VUE_APP_OPEN_AJAX_LOGS, VUE_APP_ENV, VUE_APP_TRANSPORT_SECURITY } =
    process.env
  if (
    VUE_APP_OPEN_AJAX_LOGS === 'true' ||
    VUE_APP_OPEN_AJAX_LOGS === true ||
    ((VUE_APP_ENV === 'dev' || VUE_APP_ENV === 'test') &&
      (VUE_APP_TRANSPORT_SECURITY === 'true' ||
        VUE_APP_TRANSPORT_SECURITY === true))
  ) {
    const {
      headers,
      method,
      baseURL,
      url,
      data: sendData = {},
      params: sendParams = {}
    } = config

    console.groupCollapsed(
      `%c ${method}: ${baseURL}${url}`,
      `background: ${consoleTheme.bgColor.transparent}; color: ${consoleTheme.txtColor.blue}; padding: 0 10px; border-radius: 3px; font-size: 10px;`
    )

    const headersInfos = {
      Authorization: headers['Authorization'],
      'Content-Type': headers['Content-Type']
    }
    headers['User-Id'] ? (headersInfos['User-Id'] = headers['User-Id']) : null
    headers['Role-Id'] ? (headersInfos['Role-Id'] = headers['Role-Id']) : null
    console.groupCollapsed('request header')
    console.table(headersInfos)
    console.groupEnd()

    console.group('request payload')
    Object.keys(sendParams).length || Object.keys(sendData).length
      ? (console.table({ ...sendData, ...sendParams }),
        console.log('raw params:', sendParams),
        console.log('raw data:', sendData))
      : console.log('null')

    console.log('origin data type:', Object.prototype.toString.call(sendData))
    console.groupEnd()

    console.groupEnd()
  }
}
/**
 * check config.crypt is legal
 * @param {ConfigDotCrypt} crypt
 * @returns {boolean}
 */
function isLeaglConfigCrypt(crypt) {
  return isPlainObject(crypt)
  // TODO: 根据  @typedef 严格校验 crypt
}

function AESrequest(config) {
  const authKey = getKey()
  if (!authKey) return config

  if (config.params) {
    config.params = {
      p: encodeURIComponent(AES.encrypt(config.params))
    }
  } else if (config.data) {
    const _data = {}
    const _fileBlob = {}
    Object.keys(config.data).forEach((k) => {
      const v = config.data[k]
      if (v instanceof File || v instanceof Blob) _fileBlob[k] = v
      else if (isArray(v)) {
        /**
         * [File,'filename']
         * @see /src/network/configs.js obj2form
         */
        if (v[0] instanceof File || v[0] instanceof Blob) {
          _fileBlob[k] = v
        } else _data[k] = v
      } else _data[k] = v
    })
    config.data = {
      p: AES.encrypt(_data),
      ..._fileBlob
    }
  }
  return config
}

function RSArequest(config, crypt) {
  const sendType =
    isPlainObject(config.params) && Object.keys(config.params).length
      ? 'params'
      : isPlainObject(config.data) && Object.keys(config.data).length
      ? 'data'
      : null
  const send = sendType ? config[sendType] : null
  if (send) {
    const encryptionKey = crypt.keypair ? crypt.keypair.publicKey : ''
    let needEncryptions = crypt.encryption
    if (!isArray(needEncryptions) || !needEncryptions.length) {
      needEncryptions = Object.keys(send)
    }
    needEncryptions.forEach((k) => {
      const v = send[k]
      if (v instanceof File || v instanceof Blob) return true
      else if (isArray(v)) {
        if (v[0] instanceof File || v[0] instanceof Blob) return true
      }

      send[k] = RSA.encrypt(
        v,
        encryptionKey ? { key: encryptionKey } : undefined
      )
    })

    config[sendType] = send
    return config
  } else return config
}
