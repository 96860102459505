/**
 * @module api-scm:payableVerification
 */
import service from '@/service'

/**
 * 查询 应付 核销单列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ap/finPaymentVerificationSheet/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ap/finPaymentVerificationSheet/info',
    method: 'get',
    params: { id }
  })
}

/**
 * @param {{ids:Array<number>}} params - ids
 * @description 财务-应付核销单反核销
 */
export function reverseWriteOff(data) {
  return service['api-web']({
    url: '/fin/ap/finPaymentVerificationSheet/delete',
    method: 'delete',
    params: data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
