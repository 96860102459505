/**
 * @module api-web:filter-opts/product
 * @description common apis : dropdown select options - product
 */
import service from '@/service'

/**
 * 获取产品专员下拉框
 */
export function getProductSpecialistOpts(params) {
  return service['api-web']({
    url: '/public/product/getProductSpecialist',
    method: 'get',
    params
  })
}

/**
 * 商品标签
 */
export function getProductLabelManageOpts(params) {
  return service['api-web']({
    url: '/public/product/getProductLabelManage',
    method: 'get',
    params
  })
}

/**
 * 商品目录下拉框
 */
export function getProductCategoryOpts(params) {
  return service['api-web']({
    url: '/public/product/getProductCategory',
    method: 'get',
    params
  })
}

/**
 * 商品编码 下拉框
 */
export function getProductAncillaryCodeOpts(params) {
  return service['api-web']({
    url: '/public/product/getAncillaryCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
