/**
 * @module api-scm:inventoryDetail
 */
import service from '@/service'

/**
 * 采购入库管理   列表查询
 * @param {{keywords:string,limit:number,page:number,sort:string,documentStatus:number}} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/getLocationList',
    method: 'get',
    params
  })
}

/**
 * 采购入库管理   详情查询
 * @param {string} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/inventoryDetail/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 采购入库管理   新增
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/save',
    method: 'post',
    data
  })
}

/**
 * 采购入库管理   修改
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/update',
    method: 'post',
    data
  })
}

/**
 * @param {Array<number>} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/inventoryDetail/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 启用禁用
 * @param {object} data
 */
export function enableDisable({ id: ids, status }) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/invalid',
    method: 'post',
    data: { ids, status },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{
 * inventoryCode:string
 * limit:number
 * page:number
 * productCode:string
 * productName:string
 * }} params
 * @description 导出库位管理
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryDetail/inventoryDetailData/export',
    method: 'post',
    data,
    responseType: 'blob' // 文件流接收类型声明转成 blob
  })
}
