/**
 * @module api-web:filter-opts/logistics
 * @description common apis : dropdown select options - logistics
 */
import service from '@/service'

/**
 * 使用组织
 */
export function getProvinceOpts(params) {
  return service['api-web']({
    url: '/public/getTreeList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
