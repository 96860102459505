/**
 * @module storage/session/permission
 * 会话管理: 临时追加的权限
 */
import { get, set, remove } from '../index'
import { isArray } from '@/utils/validate'

const STORAGE_KEY = 'ASR-SCM-SYS-TEMP-PMS'
export function getPm(userId) {
  const sessionKey = `${STORAGE_KEY}${userId ? '-' + userId : ''}`
  return get('session', sessionKey)
}

export function setPm(userId, value) {
  const sessionKey = `${STORAGE_KEY}${userId ? '-' + userId : ''}`
  const sessionRecords = get('session', sessionKey)

  if (isArray(sessionRecords) && sessionRecords.length) {
    const currentSessionSources = sessionRecords.map((v) => v.id)
    const notExistInSession = value.filter(
      (v) => !currentSessionSources.includes(v.id)
    )
    notExistInSession.length
      ? set('session', sessionKey, [...sessionRecords, ...notExistInSession])
      : null
  } else set('session', sessionKey, value)
}

export function removePm(userId) {
  const sessionKey = `${STORAGE_KEY}${userId ? '-' + userId : ''}`
  remove('session', sessionKey)
}

export function clearPm() {
  Object.keys(window.sessionStorage).forEach((sessionKey) => {
    sessionKey.split('-')[0] === STORAGE_KEY && remove('session', sessionKey)
  })
}
