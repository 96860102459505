import { isArray, isString } from '@/utils/validate'
import { getSyncAsyncReturns } from '@/utils'

export function agelCreateSelectMode({
  refName, // 弹窗等嵌套 form 的，需要 ref
  formName,
  label,
  prop,
  refreshOptionsBy = () => null,
  options = [],
  collapseTags = true,
  multiple = true,
  filterable = true,
  required = false,
  allowCreate = true,
  defaultFirstOption = true,
  disabled = false,
  multipleLimit = 30,
  display = true,
  placeholder = undefined
}) {
  const error = {
    label: 'AagelCreateSelectMode Error'
  }

  if (!isString(formName) || !formName.trim()) {
    console.error('【agelCreateSelectMode】illegal formName', formName)
    return error
  }

  if (!isString(prop) || !prop.trim()) {
    console.error('【agelCreateSelectMode】illegal prop', prop)
    return error
  }

  /**
   * 回显的值初始化成选项, 然后存在闭包的作用域中
   */
  let initOptions = []
  const refreshOptions = (next, prev) => {
    const form =
      isString(refName) && refName.trim()
        ? this.$refs?.[refName]?.[formName]
        : this?.[formName]
    // 点击清除或者未选中时，清除当前下拉项及prop值
    if (prev !== '|' && next.split('|')[0] === '') {
      if (initOptions?.length > 0) initOptions = []
      if (form?.data) form.data[prop] = ''
    }

    const vm = form?.getRef(prop)
    const initValues = form?.data?.[prop]

    if (isString(initValues)) {
      initOptions = [
        ...initOptions,
        ...initValues
          .trim()
          .split(',')
          .map((o) => (isString(o) ? o.trim() : o))
          .filter((o) => !!o)
      ]
    } else if (isArray(initValues)) {
      initOptions = [
        ...initOptions,
        ...initValues
          .map((o) => (isString(o) ? o.trim() : o))
          .filter((o) => !!o)
      ]
    }
    if (typeof vm?.getOptions === 'function') vm.getOptions()
    else {
      console.error(
        '【agelCreateSelectMode】losed vm for getOptions',
        form,
        prop
      )
    }
  }
  const watchTarget = () => {
    let _refreshOptionsBy = null
    if (typeof refreshOptionsBy === 'function') {
      _refreshOptionsBy = refreshOptionsBy()
    } else {
      console.error('agelCreateSelectMode】refreshOptionsBy only support func')
    }

    let _selfValues = null
    const form =
      isString(refName) && refName.trim()
        ? this.$refs?.[refName]?.[formName]
        : this?.[formName]
    if (form?.data?.[prop]) {
      _selfValues = form.data[prop]
    }

    if (_refreshOptionsBy === null && _selfValues === null) {
      return '|'
    } else return `${_refreshOptionsBy}|${_selfValues}`
  }

  const unwatch = this.$watch(watchTarget, refreshOptions, {
    immediate: false
  })

  this.$once('hook:beforeDestroy', () => {
    unwatch()
    console.info('【agelCreateSelectMode】unwatch.')
  })

  return function () {
    return {
      prop,
      label,
      component: 'agel-select',
      options: async () => {
        /**
         * 固定选项：来源于常规配置的 options
         */
        let fixedOptions = []
        if (isArray(options)) {
          fixedOptions = options.map((v) => v.value)
        } else if (typeof options === 'function') {
          const [err, res] = await getSyncAsyncReturns(options)
          if (err) console.error(err)
          if (isArray(res)) fixedOptions = res.map((v) => v.value)
        }

        /**
         * 当前选项 = 初始化 + 当前创建
         */
        const form =
          isString(refName) && refName.trim()
            ? this.$refs?.[refName]?.[formName]
            : this?.[formName]
        let currentOptions = []
        const currentValues = form?.data?.[prop]
        console.log(
          '🚀 ~ file: agelCreateSelectMode.js:138 ~ options: ~ currentValues:',
          currentValues
        )
        if (isString(currentValues)) {
          currentOptions = [
            ...initOptions,
            ...currentValues
              .trim()
              .split(',')
              .map((o) => (isString(o) ? o.trim() : o))
              .filter((o) => !!o)
          ]
        } else if (isArray(currentValues)) {
          currentOptions = [
            ...initOptions,
            ...currentValues
              .map((o) => (isString(o) ? o.trim() : o))
              .filter((o) => !!o)
          ]
        } else {
          console.error(
            '【agelCreateSelectMode】illegal values',
            refName,
            formName,
            prop,
            currentValues
          )
        }

        /**
         * 最终选项 = 固定 + 当前
         */
        const finalOptions = [...fixedOptions, ...currentOptions]

        /**
         * 去重
         */
        return [...new Set(finalOptions)]
      },
      collapseTags,
      multiple,
      filterable, // 是否可过滤
      allowCreate, // 是否允许用户创建新条目，需配合 filterable 使用
      defaultFirstOption, // 在输入框按下回车，选择第一个匹配项
      disabled,
      required, // 是否必填
      multipleLimit,
      placeholder,
      display,
      on: {
        // change: refreshOptions
      }
    }
  }
}
export default agelCreateSelectMode
