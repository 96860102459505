/**
 * @module BaseInlineEditTable/mixins/init
 * 内联编辑表格 〉宏 〉初始化
 */
export default {
  data() {
    return {
      initDone: false
    }
  },
  created() {
    // this._this = this
    this.initData(true) // 初始化远程回显
  },
  computed: {
    dynamicRefStrReady() {
      return !!(this.tableRefName && this.toolbarRefName)
    },
    baseSlotScope() {
      return this.initDone === true
        ? {
            vm: this,
            $table: this.$table,
            $toolbar: this.$toolbar
          }
        : {}
    }
  },
  watch: {
    dynamicRefStrReady: {
      immediate: true,
      handler: 'init'
    }
  },
  methods: {
    async init(ready) {
      if (ready) {
        await this.initRefs() // 初始化 refs

        this.tableConnectToolbar() // 表格连接工具栏

        logs.call(this) // 日志
      }
      this.initDone = true
      this.tableInitEditing() // 初始化编辑表格
    },
    /**
     * 初始化 data （如果远程回显）
     * init data if need request remote
     * @param {boolean} [vmodel=true] 是否将数据发射到父组件
     * @returns {Promise<Array>}
     */
    async initData(vmodel = true) {
      const data = await this.fetchTableData()
      vmodel !== false ? this.emitData({ data, source: 'initData' }) : null
      return data
    },
    /**
     * 刷新 data
     * @useage this.$refs.xxx.updateData()
     * update data if need request remote
     * @returns {Promise<Array>}
     */
    async updateData() {
      this.emitData({
        data: await this.fetchTableData(),
        source: 'updateData'
      })
    },
    /**
     * 初始化 refs
     * - $table
     * - $toolbar
     */
    async initRefs() {
      try {
        await this.$halt(16)
        this.$table = this.$refs[this.tableRefName]
        await this.$halt(16)
        this.$toolbar = this.$refs[this.toolbarRefName]
      } catch (error) {
        console.error('【BaseInlineEditTable init】refs: ' + error)
      }
    },
    /**
     * 初始化工具栏
     * vxe 表格连接 vxe 工具栏
     * @see https://xuliangzhan_admin.gitee.io/vxe-table/#/table/advanced/toolbar
     */
    async tableConnectToolbar() {
      try {
        if (!this.$table) {
          return void console.error(
            '【BaseInlineEditTable init】losed "this.$table":',
            this.$table
          )
        }

        if (!this.$toolbar) {
          return void console.error(
            '【BaseInlineEditTable init】losed "this.$toolbar":',
            this.$toolbar
          )
        }

        await this.$halt(0)
        this.$table.connect(this.$toolbar)
      } catch (error) {
        console.error(
          '【BaseInlineEditTable init】table connect toolbar error:',
          error
        )
      }
    },
    /**
     * 初始化表格 编辑状态
     *@description this.isTableInitEdit 为true 时，表初始化编辑状态
     */
    tableInitEditing() {
      if (this.isTableInitEdit) {
        this.onClick('insert', null)
      }
    }
  }
}
function logs() {
  console.groupCollapsed('【BaseInlineEditTable init】completed')
  console.log('vm:', this)
  console.log('uuid:', this.uuid)
  console.log('tableRefName:', this.tableRefName)
  console.log('tableRef:', this.$table)
  console.log('toolbarRefName:', this.toolbarRefName)
  console.log('toolbarRef:', this.$toolbar)
  console.log('externalClass:', this.externalClass)
  console.log('tableLoading:', this.tableLoading)
  console.log('tableHeader:', this.tableHeader)
  console.log('tableData:', this.value)
  console.log('crudLocal:', this.crudLocal)
  console.log('crudHandler:', this.crudHandler)
  console.log('crudPmFullname:', this.crudPmFullname)
  console.log('disableOperate:', this.disableOperate)
  console.log('rowConfig:', this.rowConfig)
  console.log('editConfig:', this.editConfig)
  console.log('editConf:', this.editConf)
  console.log('editRules:', this.editRules)
  console.log('validRules:', this.validRules)
  console.groupEnd()
}
