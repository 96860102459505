/**
 * @module plugins:agel-from
 */
import Vue from 'vue'
import {
  agelForm,
  agelUpload,
  agelSelect,
  agelCheckbox,
  agelRadio,
  agelSearchPanel
} from 'agel-form'
import { isArray } from '@/utils/validate'

/**
 * 详细配置 @see https://agrass.gitee.io/agel-form/
 */

const agelConfig = {
  form: {
    labelWidth: '120px',
    span: 24,
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 8 }
  },
  'el-input': function (item) {
    return {
      maxLength: 200,
      clearable: true,
      defaultValue: ''
    }
  },
  'el-select': function (item) {
    updateOptions(item)
    return {
      clearable: true,
      defaultValue: null
    }
  },
  'agel-select': function (item) {
    updateOptions(item)
    return {
      clearable: true,
      filterable: true,
      defaultValue: ''
    }
  },
  'el-autocomplete': function (item) {
    return {
      clearable: true,
      defaultValue: ''
    }
  },
  'el-radio': function (item) {
    return {
      defaultValue: null
    }
  },
  'agel-radio': function (item) {
    return {
      defaultValue: null
    }
  },
  'el-checkbox': function (item) {
    return {
      defaultValue: null
    }
  },
  'agel-checkbox': function (item) {
    return {
      defaultValue: null
    }
  }
  // 'el-date-picker': function (item) {}
}

Vue.use(agelForm, agelConfig)
Vue.use(agelUpload)
Vue.use(agelSelect)
Vue.use(agelCheckbox)
Vue.use(agelRadio)
Vue.use(agelSearchPanel)
/**
 * @param {object} item
 * @description add default remove-tag and clear events,
 * remove option when remove-tag evt and clear options when clear evt
 */
function updateOptions(item) {
  if (item.remote === true && typeof item.remoteMethod === 'function') {
    if (item.multiple === true && !item.on?.['remove-tag']) {
      item.on['remove-tag'] = (val) => {
        if (isArray(item.options) && item.options.length > 0) {
          item.options = item.options.filter((o) => o.value !== val)
        }
      }
    }
    if (!item.on?.clear) {
      item.on.clear = () => {
        item.options = []
      }
    }
  }
}
