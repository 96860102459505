/**
 * @module storage/session/list
 * 会话管理: 列表页面查询参数
 */
import { get, set, remove } from '../index'
import { DEFAULT_TABLE_SORT, DEFAULT_TABLE_ROW_LIMIT } from '@/constants'
import { AES } from '@/utils/crypter'

const STORAGE_KEY = 'ASR-SCM-SYS-TEMP-LIST-QUERY'

/**
 * @param {*} route
 * @param {{type:('all'|'common'|'instance'),keySalt:string}} [options={type:'all',keySalt:''}]
 */
export function setQuery(route, value, options = { type: 'all', keySalt: '' }) {
  const sessionKey = `${STORAGE_KEY}:${route.name}`

  // 1. 列表查询缓存：公用 (table sort and rows limit)
  if (options?.type === 'all' || options?.type === 'common') {
    const commonQ = AES.encrypt(
      {
        sort: value?.sort || DEFAULT_TABLE_SORT,
        limit: value?.limit || DEFAULT_TABLE_ROW_LIMIT
      },
      {
        output: 'Hex'
      }
    )

    set(
      'session',
      `${sessionKey}${options?.keySalt ? ':' + options.keySalt : ''}`,
      commonQ
    )
  }

  // 2. 列表查询缓存：tag页面并行多开细分
  if (options?.type === 'all' || options?.type === 'instance') {
    // eslint-disable-next-line no-unused-vars
    const { sort, limit, ...rest } = value
    if (route?.params?.id) {
      const instanceQ = AES.encrypt(rest, {
        output: 'Hex'
      })

      set(
        'session',
        `${sessionKey}:${route.params?.id}${
          options?.keySalt ? ':' + options.keySalt : ''
        }`,
        instanceQ
      )
    } else console.error('setQuery session: losed "route.params.id"')
  }
}
/**
 * @param {*} route
 * @param {{type:('all'|'common'|'instance'),keySalt:string}} [options={type:'all',keySalt:''}]
 */
export function getQuery(route, options = { type: 'all', keySalt: '' }) {
  switch (options?.type) {
    case 'all':
      return {
        ...getInstanceQuery(route, { keySalt: options?.keySalt || '' }),
        ...getCommonQuery(route, { keySalt: options?.keySalt || '' })
      }
    case 'common':
      return getCommonQuery(route, { keySalt: options?.keySalt || '' })
    case 'instance':
      return getInstanceQuery(route, { keySalt: options?.keySalt || '' })
    default:
      console.error('getQuery session: illegal "type"', options)
      return {}
  }
}

/**
 * @param {*} route
 * @param {{keySalt:string,force:boolean}} [options={keySalt:'',force:fasle}]
 * @description force true 则删除所有查询参数缓存，包括分页信息， false 保留分页信息
 */
export function rmQuery(route, options = { keySalt: '', force: false }) {
  const sessionKey = `${STORAGE_KEY}:${route.name}`

  if (options?.force === true) {
    remove(
      'session',
      `${sessionKey}${options?.keySalt ? ':' + options.keySalt : ''}`
    )
  }

  remove(
    'session',
    `${sessionKey}:${route.params?.id}${
      options?.keySalt ? ':' + options.keySalt : ''
    }`
  )
}
/**
 * @param {{keySalt:string,force:boolean}} [options={force:fasle}]
 * @description force true 则删除所有查询参数缓存，包括分页信息， false 保留分页信息
 * 由于缓存信息使用登录的key 加解密，所以一般都是退出登录的时候刷新key, 才使用 force true 来强制清除所有查询参数
 */
export function clearQuery(options = { force: false }) {
  Object.keys(window.sessionStorage).forEach((sessionKey) => {
    // eslint-disable-next-line no-unused-vars
    const [cachedKey, routeName, id, keySalt] = sessionKey.split(':')
    if (cachedKey === STORAGE_KEY) {
      if (options?.force === true) {
        remove('session', `${sessionKey}${keySalt ? ':' + keySalt : ''}`)
      } else {
        if (typeof id !== 'undefined') {
          remove('session', `${sessionKey}${keySalt ? ':' + keySalt : ''}`)
        }
      }
    }
  })
}
/**
 * @param {*} route
 * @param {{keySalt:string}} [options={ keySalt:''}]
 */
function getCommonQuery(route, options = { keySalt: '' }) {
  try {
    const sessionKey = `${STORAGE_KEY}:${route.name}`
    const cache = get(
      'session',
      `${sessionKey}${options?.keySalt ? ':' + options.keySalt : ''}`
    )
    if (cache) {
      const query = AES.decrypt(cache, { decode: true })
      return query || {}
    }
  } catch (error) {
    console.error(error)
  }
  return {}
}
/**
 * @param {*} route
 * @param {{keySalt:string}} [options={ keySalt:''}]
 */
function getInstanceQuery(route, options = { keySalt: '' }) {
  try {
    const sessionKey = `${STORAGE_KEY}:${route.name}`
    const cache = get(
      'session',
      `${sessionKey}:${route.params?.id}${
        options?.keySalt ? ':' + options.keySalt : ''
      }`
    )

    if (cache) {
      const query = AES.decrypt(cache, { decode: true })
      return query || {}
    }
  } catch (error) {
    console.error(error)
  }

  return {}
}
