/**
 * @module api-activiti:category
 */
import service from '@/service'

/**
 *
 * @typedef {Object} ProcessCategory
 * @property {string} name
 * @property {string=} id
 * @property {string=} pid
 * @property {string} remark
 * @property {number} sort
 */

/**
 * @param {{keywords:string,limit:number,page:number,sort:string}} params
 * @description  根据分类名称查询流程分类列表
 */
export function getList(params) {
  return service['api-activiti']({
    url: '/sys/process/category/getList',
    method: 'get',
    params
  })
}

/**
 * @param {{id:string}} params
 * @description 删除流程分类
 */
export function remove(params) {
  return service['api-activiti']({
    url: '/sys/process/category/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增流程分类
 * @param {ProcessCategory} data
 */
export function save(data) {
  return service['api-activiti']({
    url: '/sys/process/category/save',
    method: 'post',
    data
  })
}

/**
 * 修改流程分类
 * @param {ProcessCategory} data
 */
export function update(data) {
  return service['api-activiti']({
    url: '/sys/process/category/update',
    method: 'put',
    data
  })
}
