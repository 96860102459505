/**
 * @file todo-tasks-list
 * 本地创建首页子路由：我的待办/部门待办 列表
 */

import { isResponsibility } from '@/service/api-web/hr'
import { createUniqueString } from '@/utils/string'

/**
 * 创建 我的待办/部门待办 菜单，添加到指定的 parentId 下
 * @see /api-web/public/getRoleMenu 模拟后端返回数据
 * @param {number} parentId
 * @param {object=} params
 * @returns {array}
 */
export default async (parentId, params) => {
  if (typeof parentId !== 'number') {
    console.error('【Create Router】Please specify "parentId"')
    return []
  }

  const pTodo = { id: createUniqueString(), pid: parentId, sort: 1 } // 个人待办
  const pTodoListId = createUniqueString()
  const todo = [
    {
      path: 'myTasks',
      icon: '',
      label: '我的待办',
      pmType: 1,
      pmUrl: '/local/home/myTasks',
      id: pTodo.id,
      pid: pTodo.pid,
      sort: pTodo.sort,
      noTag: true,
      noCache: true
    },
    {
      path: 'category',
      label: '我的待办 - 分类',
      pmType: 2,
      icon: '',
      pmUrl: '/local/home/myTasks/category',
      id: createUniqueString(),
      pid: pTodo.id,
      sort: 1,
      noTag: true,
      breadcrumb: false,
      hidden: true,
      noCache: true
    },
    {
      path: 'list',
      label: '我的待办 - 列表',
      pmType: 2,
      icon: '',
      pmUrl: '/local/home/myTasks/list',
      id: pTodoListId,
      pid: pTodo.id,
      // noTag: true,
      breadcrumb: false,
      sort: 2,
      hidden: true,
      noCache: true
    },
    {
      path: '',
      label: '我的待办',
      pmType: 3,
      icon: '',
      pmUrl: '/local/home/myTasks/list/todo',
      id: createUniqueString(),
      pid: pTodoListId
    },
    {
      path: '',
      label: '已审核',
      pmType: 3,
      icon: '',
      pmUrl: '/local/home/myTasks/list/done',
      id: createUniqueString(),
      pid: pTodoListId
    }
  ]
  try {
    // 如果是部门负责人，则显示部门待办菜单
    const { data: showDeptTodo } = await isResponsibility()
    if (showDeptTodo === true) {
      const dTodo = { id: createUniqueString(), pid: parentId, sort: 2 } // 部门待办
      const dTodoListId = createUniqueString()
      todo.push(
        {
          path: 'deptTasks',
          icon: '',
          label: '部门待办',
          pmType: 1,
          pmUrl: '/local/home/deptTasks',
          id: dTodo.id,
          pid: dTodo.pid,
          sort: dTodo.sort,
          noTag: true,
          noCache: true
        },
        {
          path: 'category',
          label: '部门待办 - 分类',
          pmType: 2,
          icon: '',
          pmUrl: '/local/home/deptTasks/category',
          id: createUniqueString(),
          pid: dTodo.id,
          noTag: true,
          breadcrumb: false,
          sort: 1,
          hidden: true,
          noCache: true
        },
        {
          path: 'list',
          label: '部门待办 - 列表',
          pmType: 2,
          icon: '',
          pmUrl: '/local/home/deptTasks/list',
          id: dTodoListId,
          pid: dTodo.id,
          // noTag: true,
          breadcrumb: false,
          sort: 2,
          hidden: true,
          noCache: true
        },
        {
          path: '',
          label: '部门待办',
          pmType: 3,
          icon: '',
          pmUrl: '/local/home/deptTasks/list/todo',
          id: createUniqueString(),
          pid: dTodoListId
        },
        {
          path: '',
          label: '已审核',
          pmType: 3,
          icon: '',
          pmUrl: '/local/home/deptTasks/list/done',
          id: createUniqueString(),
          pid: dTodoListId
        }
      )
    }
  } catch (error) {
    console.error(error)
  }
  return todo
}
