/**
 * @module api-web:finPayableBill.js
 */
import service from '@/service'

/**
 * 查询 财务-应收单列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{id}}
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/public/getListDown',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/save',
    method: 'post',
    data
  })
}
/**
 *  下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/public/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 查询财务收款单详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/finPayableBill/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 修改
 * @param {Department} data
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/update',
    method: 'put',
    data
  })
}

/**
 * 修改
 * @param {Department} data
 */
export function todoUpdate(data) {
  return service['api-web']({
    url: '/public/activiti/finPayableBill/update',
    method: 'put',
    data
  })
}

/**
 * 提交审核
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 应收单明细搜索
 * @param {number}
 * id
 * productCodeOrSaleCode
 */
export function getSearchDetail(id, searchKey) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/public/getSearchDetail',
    method: 'get',
    params: { id, searchKey }
  })
}

/**
 * @param {{number:id}} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/public/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *  作废
 * @param {object} data
 */

export function invalid(id) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/public/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{ids:Array<number>}} params - id
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 审核驳回
 * @param {object} data
 */
export function reject(data) {
  return service['api-web']({
    // /fin/ap/finPayableBill/reject
    url: '/public/activiti/finPayableBill/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}}
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 获取成本信息
 */
export function getCostInformation(ids) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/getCostInformation',
    method: 'get',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出数据
 */
export function exportData(params) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/export',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 财务应付单导入（手动导入时使用）
 */
export function importFinPayableBill(data) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/importFinPayableBill',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 *  下载模板
 */
export function downloadExcel() {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/public/downloadExcel',
    method: 'post',
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载， 下载的模板文件名需要有 buttonGroup 配置，所有不通过 axios 下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 供应商
 * @param {{supplierName:string}} params
 */
export function getSupplierInformationPullList(params) {
  return service['api-web']({
    url: '/public/data/getSupplierInformationPullList',
    method: 'get',
    params
  })
}
