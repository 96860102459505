import service from '@/service'
/**
 *
 * @param {{product:string,supplierName:string}} params
 * @description 获取平均价
 */
export function getAveragePurchasePrice(params) {
  return service['api-scm']({
    url: '/public/purchase/save/getAveragePurchasePrice',
    method: 'get',
    params
  })
}
/**
 * @param {{product:string,supplierName:string}} params
 * @description 获取含税采购价
 */
export function getRecommendPrice(params) {
  return service['api-scm']({
    url: '/public/purchase/getRecommendPrice',
    method: 'get',
    params
  })
}
/**
 * @param {string} codeList
 */
export function getLogisticItemsByCodes(codeList) {
  return service['api-scm']({
    url: '/public/logistics/getCodeAndNameMapByCodeList',
    method: 'get',
    params: { codeList }
  })
}

/**
 * 获取所有的省市区地址
 * @param {*} params
 */
export function getAllGeographicCoordinates(params) {
  return service['api-scm']({
    url: '/public/order/getAllGeographicCoordinates',
    method: 'get',
    params
  })
}

/**
 * @param {{rejectReqQOList:Array<{id:string,taskId:string,assignee?:string,remarks?:string}>,rejectTypeEnum:string}} data
 * @description 批量驳回
 */
export function activitiReject(data) {
  return service['api-scm']({
    url: '/public/activiti/batchReject',
    method: 'post',
    data
  })
}

/**
 * @description 查询订单信息 代办悬浮框用
 */
export function findOrderAgentListByCode(params) {
  return service['api-scm']({
    url: '/public/order/findOrderAgentListByCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 检测是否有人正在编辑详情页
 * @param {{documentType: string, lockCode: string}} data
 * @returns {{code: 200 | 500, msg: string}}}
 */
export function lock(data) {
  return service['api-scm']({
    url: '/public/concurrence/lock',
    method: 'post',
    data
  })
}

/**
 * @description 退出编辑详情页
 * @param {{documentType: string, lockCode: string}} data
 * @returns {{code: 200 | 500, msg: string}}}
 */
export function unlock(data) {
  return service['api-scm']({
    url: '/public/concurrence/unlock',
    method: 'post',
    data
  })
}
