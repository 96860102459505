/**
 * @module api-web:finApStatements
 */
import service from '@/service'

/**
 * 查询 应付款汇总表
 * @param {{dateEnd:string,dateStart:string,dealingSuppliers:Array<string>,queryMode:number,receiptOrganization:string}} params
 */
export function getApSummaryList(params) {
  return service['api-web']({
    url: '/fin/ap/finApStatements/getAccountPayableList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
