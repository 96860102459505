import store from '@/store'
import { logErr } from './utils'
import { getItem } from './item'
import { dict } from '.'
import { isArray, isString } from '@/utils/validate'

/**
 *  get value async, if local missed, get via action
 * @param {string} from
 * @param {string} label
 * @returns {Promise<(number|string|boolean)>}
 * @description 获取字典项目单项值，异步，无需初始化字典项列表
 */
export async function getValue(from, label) {
  try {
    const value = getValueSync(from, label)
    if (typeof value === 'number' || (isString(value) && value.trim())) {
      return value
    } else {
      return (
        (await getItem(from, { label /* , force: true */ }))?.value || false
      )
    }
  } catch (error) {
    logErr([error, from, label])
    return false
  }
}
/**
 * get value sync via getters
 * @param {string} from
 * @param {string} label
 * @returns {(number|string|boolean)}
 * @description 获取字典项单项值，同步，须初始化字典项列表
 */
export function getValueSync(from, label) {
  try {
    const [category, name] = from.split('.')
    const value = store.getters[`dict/${category}`]?.[name]?.value?.[label]
    return typeof value === 'number' || (isString(value) && value.trim())
      ? value
      : false
  } catch (error) {
    logErr([error, from, label])
    return false
  }
}

/**
 * get all values async, if local missed, init dict array via action
 * @param {string} from
 * @returns {Promise<(Array<(number|string)>|boolean)>}
 * @description 获取字典所有值，异步，无需初始化字典项列表
 */
export async function getAllValue(from) {
  try {
    const values = getAllValueSync(from)
    if (values) return values
    else {
      await dict(from /* , { force: true } */)
      return getAllValueSync(from)
    }
  } catch (error) {
    logErr([error, from])
    return false
  }
}
/**
 * get all values sync via getters
 * @param {string} from
 * @returns {(Array<(string|number)>|boolean)}
 * @description 获取字典所有值，同步，须初始化字典项列表
 */
export function getAllValueSync(from) {
  try {
    const [category, name] = from.split('.')
    const values = store.getters[`dict/${category}`]?.[name]?.values
    return isArray(values) && values.length ? values : false
  } catch (error) {
    logErr([error, from])
    return false
  }
}
