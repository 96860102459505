// eslint-disable-next-line spaced-comment
/// <reference path="../typedef.js" />

import * as btnOpeartions from './categoary/btn-operations'
import * as nestedNavs from './categoary/nested-navs'

import { isArray, isString } from '@/utils/validate'
/**
 * @module permission/resources
 */

/**
 * 生成角色资源权限(内嵌标签/按钮操作)
 * @param {PermissionIndexTable} indexTable
 * @returns {Promise<ResourcePidIndexes>} 资源 PID 索引集合
 */
export async function createResources(indexTable) {
  let resources_idx = {
    btns: {},
    navs: {}
  }
  const nestedFlatNavs = []
  const normalChildren = []
  try {
    const { pidIndex } = indexTable
    Object.keys(pidIndex).forEach((pid) => {
      resources_idx = pidIndex[pid].reduce((pv, cv, _ci, _arr) => {
        if (cv.pmType > 2) {
          if (cv.isNestedNav) {
            // 内部嵌套导航
            nestedFlatNavs.push(cv)
          } else if (isString(cv.path) && cv.path.trim()) {
            // 子菜单
            normalChildren.push(cv)
          } else {
            // 按钮操作
            if (isArray(pv.btns[pid])) {
              pv.btns[pid].push(btnOpeartions.transItem(cv))
            } else pv.btns[pid] = [btnOpeartions.transItem(cv)]
          }
        }
        return pv
      }, resources_idx)

      resources_idx.btns[pid] &&
        btnOpeartions.filterList(resources_idx.btns[pid])
    })

    // const nestedRoutes = await nestedNavs.createNestedNavs(
    //   nestedFlatNavs,
    //   indexTable
    // )
    // nestedRoutes.forEach((route) => {
    //   const pid = indexTable.idIndex[indexTable.idIndex[route.meta.id].pid].id
    //   if (isArray(resources_idx.navs[pid])) {
    //     resources_idx.navs[pid].push(route)
    //   } else {
    //     resources_idx.navs[pid] = [route]
    //   }
    // })

    await generateResourcesViaRoutes(nestedFlatNavs, indexTable, resources_idx)
    await generateResourcesViaRoutes(normalChildren, indexTable, resources_idx)
  } catch (error) {
    console.error(error)
  }
  return resources_idx
}
async function generateResourcesViaRoutes(routes, indexTable, resources_idx) {
  const nestedRoutes = await nestedNavs.createNestedNavs(routes, indexTable)
  nestedRoutes.forEach((route) => {
    const pid = indexTable.idIndex[indexTable.idIndex[route.meta.id].pid].id
    if (isArray(resources_idx.navs[pid])) {
      resources_idx.navs[pid].push(route)
    } else {
      resources_idx.navs[pid] = [route]
    }
  })
}
export default createResources
