import { isPlainObject, isArray, isString } from '@/utils/validate'
import { getSyncAsyncReturns } from '@/utils'
import { optionsCommonFilter } from '@/utils/options'
import halt from '@/utils/halt'

import * as webAutoCompleteInputApi from '@/service/api-web/auto-complete'
import * as scmAutoCompleteInputApi from '@/service/api-scm/auto-complete'
import * as externalAutoCompleteInputApi from '@/service/api-external/auto-complete'
// import * as activitiAutoCompleteInputApi from '@/service/api-activiti/auto-complete'
const autoCompleteInputApis = {
  web: webAutoCompleteInputApi,
  scm: scmAutoCompleteInputApi,
  external: externalAutoCompleteInputApi
  // activiti: activitiAutoCompleteInputApi
}
/**
 * @param {('web'|'scm'|'external')} service 服务名
 * @param {string} api  请求方法名
 * @param {string} key 请求的传参
 * @param {string} field 下拉显示的字段
 * @param {(Function|object)=} [payload=null] 请求的额外传参
 * @param {Function=} [repack=null] 进一步改变结果的处理方法，返回处理后的列表数据
 * @param {Number} fliterType 1: 获取已审核或已启用或审核与启用状态都不存在的情况；2: 获取已审核的情况；3: 需要时再扩充...
 * @returns closure for el-autocomplete
 */
export const autoCompleteInputFunc = (
  service = '',
  api = '',
  key,
  field,
  payload,
  repack = null,
  filterType = 1
) => {
  if (!Object.keys(autoCompleteInputApis).includes(service)) {
    console.error(
      `【autoCompleteInputFunc】Illeagl "service":${service} for get auto complete api`
    )
    return async (queryString, cb) => {
      typeof cb === 'function' && cb([])
      return []
    }
  }

  const keyName = key && isString(key) && key.trim() ? key : ''

  return async (queryString, cb) => {
    try {
      // if (!queryString.trim()) {
      //   typeof cb === 'function' && cb([])
      //   return []
      // }

      let _payload
      if (payload) {
        if (typeof payload === 'function') {
          _payload = payload()
        } else _payload = payload
      } else _payload = null
      const send = keyName
        ? _payload && isPlainObject(_payload)
          ? {
              [keyName]: queryString,
              /* flag: 1, */
              ..._payload
            }
          : {
              /* flag: 1, */
              [keyName]: queryString
            }
        : _payload && isPlainObject(_payload)
        ? _payload
        : {}

      const response = await autoCompleteInputApis[service][api](send)
      const result = isPlainObject(response)
        ? isArray(response.data)
          ? optionsCommonFilter(
              response.data.map((v) => ({
                ...v,
                value: v[field]
              })),
              filterType ?? 1
            )
          : isPlainObject(response.data)
          ? response.data
          : []
        : []

      if (result.length && typeof repack === 'function') {
        const [err, res] = await getSyncAsyncReturns(repack, null, result)
        if (!err && isArray(res)) {
          await halt(160)
          typeof cb === 'function' && cb(res)
          return res
        } else {
          console.error(
            '【autoCompleteInputFunc】from "getSyncAsyncReturns"',
            err
          )
        }
      }
      await halt(600)
      typeof cb === 'function' && cb(result)
      return result
    } catch (err) {
      console.error('【autoCompleteInputFunc】error cached', err)
      typeof cb === 'function' && cb([])
      return []
    }
  }
}
/**
 * @typedef {{blur?:Function,focus?:Function,change?:Function,input?:Function,clear?:Function,select?:Function}} ElInputEvents
 * @description ElInputEvents:
 *
 * - Input Events @see https://element.eleme.io/#/zh-CN/component/input#input-events
 * - Autocomplete Events @see https://element.eleme.io/#/zh-CN/component/input#autocomplete-events
 *
 * @param {{row?:{valueKey:string,labelKey:string},form?:{modelName:string,valueKey:string,labelKey:string},selected?:{valueKey:string,labelKey:string}}} config
 * @param {ElInputEvents} events
 * @returns {ElInputEvents}
 */
export const autoCompleteInputEvts = function (config = {}, events = {}) {
  const { row = {}, form = {}, selected = {} } = config
  let evts = {}
  switch (true) {
    case row && isPlainObject(row) && Object.keys(row).length > 0:
      evts = createVxeTableRowEvts.apply(this, [row, selected, events])
      break
    case form && isPlainObject(form) && Object.keys(form).length > 0:
      evts = createFormEvts.apply(this, [form, selected, events])
      break
    default:
      console.error(`【autoCompleteInputEvts】error config`, config)
  }
  return evts
}
/**
 * @param {{modelName:string,valueKey:string,labelKey:string}} form
 * @param {{valueKey:string,labelKey:string}} selected
 * @param {ElInputEvents} events
 * @returns {ElInputEvents}
 */
function createFormEvts(form, selected, events) {
  const _config = getConfig('form', form, selected)
  if (!_config) return isPlainObject(events) ? events : {}

  const {
    formName,
    targetValKey,
    targetLabelKey,
    selectedValueKey,
    selectedLabelKey
  } = _config

  const { blur, focus, change, input, clear, select } = events

  const _clear = (e) => {
    this[formName].data[targetLabelKey] = ''
    typeof clear === 'function' && clear(e)
  }

  const evts = {
    clear: _clear,
    blur: (e) => {
      // 在evts对象中blur事件优先与select事件执行，添加nextTick解决失焦后空值的表单验证
      this.$nextTick(() => {
        if (!this[formName].data[targetValKey]) _clear(e)
        typeof blur === 'function' && blur(e)
      })
    },
    focus: typeof focus === 'function' ? focus : undefined,
    change: (value) => {
      this[formName].data[targetValKey] = ''
      typeof change === 'function' && change(value)
    },
    input: typeof input === 'function' ? input : undefined,
    select: (item) => {
      if (!item[selectedValueKey]) {
        console.error(
          `【autoCompleteInputEvts】form losed item.${selectedValueKey}`,
          item
        )
        typeof select === 'function' && select(item)
        return
      }
      if (!item[selectedLabelKey]) {
        console.error(
          `【autoCompleteInputEvts】form losed item.${selectedLabelKey}`,
          item
        )
        typeof select === 'function' && select(item)
        return
      }

      this[formName].data[targetValKey] = item[selectedValueKey] ?? ''
      this[formName].data[targetLabelKey] = item[selectedLabelKey] ?? ''
      typeof select === 'function' && select(item)
    }
  }
  Object.keys(evts).forEach((key) =>
    evts[key] === undefined ? delete evts[key] : null
  )

  return evts
}

/**
 *
 * @param {*} row
 * @param {{valueKey:string,labelKey:string}} selected
 * @param {ElInputEvents} events
 * @returns {ElInputEvents}
 */
function createVxeTableRowEvts(row, selected, events) {
  const _config = getConfig('row', row, selected)
  if (!_config) return isPlainObject(events) ? events : {}

  const { targetValKey, targetLabelKey, selectedValueKey, selectedLabelKey } =
    _config

  const { blur, focus, change, input, clear, select } = events
  const _clear = (e) => {
    e.row[targetLabelKey] = ''
    typeof clear === 'function' && clear(e)
  }
  const evts = {
    clear: _clear,
    blur: (e) => {
      // 在evts对象中blur事件优先与select事件执行，添加nextTick解决失焦后空值的表单验证
      this.$nextTick(() => {
        if (!e.row[targetValKey]) {
          _clear(e)
        }
        typeof blur === 'function' && blur(e)
      })
    },
    focus: typeof focus === 'function' ? focus : undefined,
    input: typeof input === 'function' ? input : undefined,
    change: (e) => {
      e.row[targetValKey] = ''
      typeof change === 'function' && change(e)
    },
    select: (e, item) => {
      if (!item[selectedValueKey]) {
        console.error(
          `【autoCompleteInputEvts】row losed item.${selectedValueKey}`,
          item
        )
        typeof select === 'function' && select(e, item)
        return
      }
      if (!item[selectedLabelKey]) {
        console.error(
          `【autoCompleteInputEvts】row losed item.${selectedLabelKey}`,
          item
        )
        typeof select === 'function' && select(e, item)
        return
      }

      e.row[targetValKey] = item[selectedValueKey] ?? ''
      e.row[targetLabelKey] = item[selectedLabelKey] ?? ''
      typeof select === 'function' && select(e, item)
    }
  }
  Object.keys(evts).forEach((key) =>
    evts[key] === undefined ? delete evts[key] : null
  )

  return evts
}
/**
 *
 * @param {('form'|'row')} type
 * @param {{modelName?:string,valueKey:string,labelKey:string}} target
 * @param {{valueKey:string,labelKey:string}} selected
 * @returns {{formName:string,targetValKey:string,targetLabelKey:string,selectedValueKey:string,selectedLabelKey:string}}
 */
function getConfig(type, target, selected) {
  const {
    modelName: formName = '',
    valueKey: targetValKey,
    labelKey: targetLabelKey
  } = target

  if (type === 'form') {
    if (!formName) {
      console.error(
        `【autoCompleteInputEvts】${type} error config`,
        type,
        target
      )
      return null
    }
  }

  if (!targetValKey || !targetLabelKey) {
    console.error(`【autoCompleteInputEvts】${type} error config`, target)
    return null
  }

  const {
    valueKey: selectedValueKey = targetValKey,
    labelKey: selectedLabelKey = targetLabelKey
  } = selected

  if (!selected?.valueKey) {
    console.warn(
      `【autoCompleteInputEvts】${type} use form's valueKey as selected item's valueKey`,
      selected
    )
  }

  if (!selected?.labelKey) {
    console.warn(
      `【autoCompleteInputEvts】${type} use form's labelKey as selected item's labelKey`,
      selected
    )
  }

  return {
    formName,
    targetValKey,
    targetLabelKey,
    selectedValueKey,
    selectedLabelKey
  }
}

export default { autoCompleteInputFunc, autoCompleteInputEvts }
