/**
 * @module utils/auth/token
 */
import { get, set, remove } from '@/storage'
const TOKEN_KEY = 'ASR-SCM-SYS-AUTH-TOKEN'
export function getToken() {
  return get('local', TOKEN_KEY)
}
export function setToken(token) {
  set('local', TOKEN_KEY, `Bearer ${token}`)
}
export function removeToken() {
  remove('local', TOKEN_KEY)
}
