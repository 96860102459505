/**
 * @module api-scm:documentOut
 */
import service from '@/service'

/**
 * @typedef {Object} logisticsProviderInformation -- 管易退货单
 */

export function getList(data) {
  return service['api-scm']({
    url: '/gy/documentOut/get/getList',
    method: 'post',
    data
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/gy/documentOut/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/gy/documentOut/updateErpInventoryByGuanYiPurchaseReturn',
    method: 'post',
    data
  })
}

/**
 * @description 导出
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/gy/documentOut/export',
    method: 'post',
    data,
    responseType: 'blob' // 文件流接收类型声明转成 blob
  })
}

/**
 * @description 下拉
 */
export function getListDown(params) {
  return service['api-scm']({
    url: '/gy/documentOut/get/getListDown',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
