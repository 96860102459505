import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

import { consoleTheme } from '@/settings'

import { downloadViaFileStream, getFilenameFromHeaders } from '@/utils/files'
import { isArray, isPlainObject, isString } from '@/utils/validate'
import { str_to_base64 } from '@/utils/base64'
import { RSA, AES } from '@/utils/crypter'
import poll from '@/utils/poll'

import Once from '@/utils/once'
import { safeJsonParse } from '@/utils'
import { NETWORK_TIMEOUT } from '@/constants'
const onceHandler = new Once()

/**
 * @typedef {Object} Res
 * @property {number} code
 * @property {string} msg
 * @property {(object|string|number|null)=} data
 */

/**
 * 解密返回
 * @param {import('axios').AxiosResponse} response
 * @param {Res} res
 * @returns {Res}
 */
export const decryptResponse = (response, res) => {
  const { VUE_APP_ENV, VUE_APP_TRANSPORT_SECURITY } = process.env
  const crypt = response.config.crypt
  if (
    (VUE_APP_ENV === 'dev' || VUE_APP_ENV === 'test') &&
    VUE_APP_TRANSPORT_SECURITY === 'false' &&
    crypt.canSkip === true &&
    isString(crypt?.type) &&
    crypt.type.endsWith('aes')
  ) {
    return res
  }
  try {
    switch (crypt.type) {
      case 'aes':
      case 'noaes':
      case 'rsaes':
        return AESresponse(res)
      case 'rsa':
      case 'norsa':
      case 'aesar':
        return RSAresponse(res, crypt)
      case 'none':
      case 'aesno':
      case 'rsano':
        return res
      case 'aes(rsano)':
        return AESresponse(res)
      default:
        return res
    }
  } catch (error) {
    console.error(error)
    return res
  }
}

/**
 * 从 axios response 中 提取 后端的 返回体
 * @param {import('axios').AxiosResponse} response
 * @returns {Res}
 */
export const extractRes = (response) => {
  try {
    return response.data || { code: -1, msg: '', data: null }
  } catch (error) {
    console.error(error)
    return { code: -1, msg: '', data: null }
  }
}
/**
 * 接口返回日志打印
 * @param {import('axios').AxiosResponse} response
 * @param {Res} res
 * @returns {undefined}
 */
export const handleResponseLogs = (response, res) => {
  const { VUE_APP_OPEN_AJAX_LOGS, VUE_APP_ENV, VUE_APP_TRANSPORT_SECURITY } =
    process.env
  if (
    VUE_APP_OPEN_AJAX_LOGS === 'true' ||
    VUE_APP_OPEN_AJAX_LOGS === true ||
    ((VUE_APP_ENV === 'dev' || VUE_APP_ENV === 'test') &&
      (VUE_APP_TRANSPORT_SECURITY === 'true' ||
        VUE_APP_TRANSPORT_SECURITY === true))
  ) {
    const {
      config: {
        /* method, */ url,
        schema = true,
        statusText /* headers: reqHead */
      },
      status,
      headers: resHead
    } = response

    const _res =
      schema === false
        ? {
            code: status,
            data: res,
            msg: statusText
          }
        : res

    const { code } = _res
    console.groupCollapsed(
      `%c [${status},${code}] ${url}`,
      `background: ${consoleTheme.bgColor.transparent}; color: ${
        consoleTheme.txtColor[code === 200 ? 'green' : 'red']
      }; padding: 0 10px; border-radius: 3px; font-size: 10px;`
    )

    const [e, r] = safeJsonParse(JSON.stringify(res))
    e && console.error(e)
    console.log(`source: ${schema === true ? 'raw' : 'wrap by front-end'} `)
    console.log(r)

    console.groupCollapsed('header')
    console.table(resHead)
    console.groupEnd()
    console.groupCollapsed('data')
    isPlainObject(_res)
      ? isPlainObject(_res.data)
        ? Object.entries(_res.data).forEach(([k, v]) => {
            if (isPlainObject(v)) {
              // eslint-disable-next-line no-unused-vars
              const [e, r] = safeJsonParse(JSON.stringify(v))
              console.log(k, ':', r)
            } else console.log(k, ':', v)
          })
        : console.log(_res.data)
      : console.log(_res)
    console.groupEnd()

    console.groupCollapsed('axios')
    console.log(response)
    console.groupEnd()

    console.groupEnd()
  }
}
/**
 * 处理文件流
 * @param {import('axios').AxiosResponse} response
 * @param {Res} res
 * @returns {Res}
 */
export const handleFileStream = async (response, res) => {
  try {
    // 是否浏览发起下载保存
    if (response.config.saveAs === true) {
      if (
        (await downloadViaFileStream(response, response.config.fileName)) ===
        true
      ) {
        return {
          code: 200,
          data: response,
          msg: 'File-Stream Success ( Download )' // 文件流下载成功
        }
      }
    } else {
      const convert = response.config.convert
      if (!isPlainObject(convert)) {
        // 文件流无需转换, 直接返回 Blob
        return {
          code: 200,
          data: res,
          name: getFilenameFromHeaders(response?.headers),
          _response: { ...response, data: res },
          msg: 'File-Stream Without Convert'
        }
      }

      let result = ''
      // 文件流转换类型
      switch (convert.to) {
        case 'base64':
          result = `data:${res.type || convert.type || 'text/plain'};${
            res.charset || convert.charset || 'charset=utf-8'
          };base64,${str_to_base64(
            String.fromCharCode(...new Uint8Array(res))
          )}`
          break
        case 'blobUrl':
          result = URL.createObjectURL(new Blob([res], { type: res.type }))
          break
        // todo convert ot other ext result
        // case
        // break
        default:
          console.warn(
            `【Response Convert】: ${convert.to} conversion not currently supported`
          )
          return response
      }
      return result
        ? {
            code: 200,
            data: result || res,
            msg: 'File-Stream Success ( Convert )' // 文件流转换成功
          }
        : {
            code: 200,
            data: res,
            msg: 'Conversion Not Support Yet' // 文件流转换失败
          }
    }
  } catch (error) {
    console.error(error)
    Message({
      message: 'File-Stream Error ( Convert or Download )',
      type: 'error',
      duration: 5 * 1000,
      showClose: true
    })
  }
  return {
    code: -1,
    data: undefined,
    msg: 'File-Stream Error ( Convert/Download )' // 文件流 转换/下载 失败
  }
}

/**
 * base64 拼接 media-type and charset
 * @param {import('axios').AxiosResponse} response
 * @param {Res} res
 */
const handleBase64MediaType = (response, res) => {
  try {
    const base64 = response.config.base64
    const data = res.data
    if (isPlainObject(base64) && isPlainObject(data)) {
      Object.entries(base64).forEach((kv) => {
        const [key, to = { type: 'jpeg' }] = kv
        if (data[key] && isString(data[key])) {
          res.data[key] = `data:${to.type || 'text/plain'};${
            to.charset || 'charset=utf-8'
          };base64,${data[key]}`
        }
      })
    }
  } catch (error) {
    console.error(error)
  }
}
/**
 * response 处理
 * @param {import('axios').AxiosResponse} response
 * @param {Res} res
 * @returns {Res}
 */
export const handleResult = (response, res) => {
  // base64 转 对应的 media type
  handleBase64MediaType(response, res)
  // others ...
  // ...

  const {
    status,
    statusText,
    config: {
      schema = true,
      tipTypes = 'warning,info,error',
      useWarningTypeWhenCode100
    }
  } = response

  const _res =
    schema === false
      ? {
          code: status,
          data: res,
          msg: statusText
        }
      : res

  const code = _res?.code

  if (code !== 200) {
    // if response custom code is not 200, it is judged as an error.
    const msg = _res?.msg

    const notSuccessCodeTipsMap = {
      //  200:'success' // 成功
      211: 'warning', // 警告
      222: 'info', // 信息
      100: 'error', // 错误 (阻塞)
      101: 'error' // 错误 (不阻塞)
    }
    const tipType = notSuccessCodeTipsMap[code] ?? 'error'

    if (msg) {
      if (tipTypes.includes(tipType)) {
        store.dispatch('clearAxiosErrMsgsByKey', { key: msg }).then(() => {
          store.dispatch('setAxiosErrMsgsByKey', {
            key: msg,
            value: Message({
              message: msg,
              type:
                useWarningTypeWhenCode100 && code === 100 ? 'warning' : tipType,
              duration: 5 * 1000,
              showClose: true
            })
          })
        })
      } else {
        console.groupCollapsed(`状态码:${code}, 错误提示:`)
        console.log(msg)
        console.groupEnd()
      }
    } else {
      console.groupCollapsed('Losed error.message')
      console.log(_res)
      console.groupEnd()
    }
  }

  try {
    const { msg, code, data } = _res
    return {
      code,
      data,
      msg: ['操作成功', '操作失败'].includes(msg) ? '' : msg
    }
  } catch (error) {
    console.error(error)
  }

  return res
}

/**
 * 处理请求池：移除，临时标记
 * @param {import('axios').AxiosResponse} response
 */
export const handleRequestPool = (response) => {
  // console.log('【requestPool】handleRequestPool', response)
  // remove request from requestPool's stage
  isPlainObject(response.config) && response.config.id
    ? store
        .dispatch('requestPool/remove', {
          pool: 'stage',
          id: response.config.id
        })
        .then((target) => {
          // put request into requestPool's stash
          store.dispatch('requestPool/temp', {
            ...target,
            response
          })
        })
    : null
}

/**
 * 从请求池 移除
 * @param {import('axios').AxiosError} error
 */
export const removeFromRequestPool = (error) => {
  // console.log('【requestPool】removeFromRequestPool', error)
  isPlainObject(error.config) && error.config.id
    ? store.dispatch('requestPool/remove', {
        pool: 'stage',
        id: error.config.id
      })
    : null
}
/**
 * 处理取消的请求
 * @param {import('axios').AxiosError} error
 * @returns {Promise<Res>}
 */
export const handleCancel = async (error) => {
  // console.log('【requestPool】handleCancel', error)
  /**
   * @type {string}
   * @description the string 'router-guard' or forword duplication request uuid
   */
  const by = error.message.split(' by:')[1]
  if (by !== 'router-guard') {
    const forwordSameRequest = await poll({
      target: store.getters,
      nested: ['requestPool/stashIdx', by],
      timeout: error.config?.timeout || NETWORK_TIMEOUT.request
    })

    if (!forwordSameRequest) {
      return {
        code: -1,
        data: undefined,
        msg: 'Cancel'
      }
    }
    const forwordSameResponse = forwordSameRequest.response

    // console.groupCollapsed('repeat name:', (forwordSameRequest || {}).name)
    // console.log('repeat by:', by)
    // console.log('repeat times:', (forwordSameRequest || {}).repeatimes)
    // console.log('request stage:', store.state.requestPool.stage)
    // console.log('request stash:', store.state.requestPool.stash)
    // console.log(JSON.parse(JSON.stringify(forwordSameRequest)))
    // console.groupEnd()

    store
      .dispatch('requestPool/patch', {
        pool: 'stash',
        id: by,
        key: 'repeatimes',
        val: forwordSameRequest.repeatimes - 1
      })
      .then((target) => {
        if (target.repeatimes <= 0) {
          store.dispatch('requestPool/remove', { pool: 'stash', id: by })
        }
      })

    // 取消的请求，等到第一个请求返回后，重走一遍响应拦截（请求池处理以及日志打印除外），然后返回结果
    const res = extractRes(forwordSameResponse)
    const { responseType: resType, headers } = forwordSameResponse.config
    if (resType === 'blob') return handleFileStream(forwordSameResponse, res)
    const decrypted = decryptResponse(forwordSameResponse, res)
    if (
      (headers['Content-Type'] || '').includes('application/xml') ||
      (forwordSameResponse.headers['content-type'] || '').includes('text/xml')
    ) {
      return decrypted // xml 直接返回
    }
    return handleResult(forwordSameResponse, decrypted)
  } else {
    return {
      code: -1,
      data: undefined,
      msg: 'Cancel'
    }
  }
}
/**
 * 引导重新登录
 * @param {string} msg
 */
export const guideReLogin = (msg) => {
  if (!['/', '/login'].includes(router.history.current.path)) {
    onceHandler.handle(() => {
      Message({
        message: '请重新登录',
        type: 'warning',
        duration: 5 * 1000,
        showClose: true,
        onClose: () => onceHandler.cancel()
      })

      MessageBox.confirm(
        msg ||
          'You have been logged out, you can cancel to stay on this page, or log in again',
        '访问异常',
        {
          confirmButtonText: '重新登录',
          cancelButtonText: '取 消',
          type: 'warning'
        }
      )
        .then(() => {
          // to re-login
          store
            .dispatch('user/logout', { request: false })
            .then(() => void window.location.reload())
        })
        .catch(() => void onceHandler.cancel())
    })
  }
}

function AESresponse(res) {
  return AES.decrypt(res)
}
/**
 *
 * @param {*} res
 * @param {*} crypt
 * @returns
 */
function RSAresponse(res, crypt) {
  if (isPlainObject(res) && isPlainObject(res.data)) {
    const decryptionKey = crypt?.keypair?.privateKey
    if (!decryptionKey) return res
    let needDecryptions = crypt.decryption
    if (!isArray(needDecryptions) || !needDecryptions.length) {
      needDecryptions = Object.keys(res.data)
    }
    const _data = {}
    needDecryptions.forEach((k) => {
      const v = res.data[k]
      _data[k] = RSA.decrypt(v, { key: decryptionKey })
    })
    return {
      ...res,
      data: {
        ...res.data,
        ..._data
      }
    }
  } else return res
}
