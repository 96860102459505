<template>
  <el-empty
    :image-size="EL_EMPTY_IMAGE_SIZE"
    :description="ENV !== 'dev' ? '抱歉, 加载失败' : '编译失败'"
  >
    <el-button v-if="ENV !== 'dev'" type="primary" @click="refresh">
      刷 新
    </el-button>
  </el-empty>
</template>
<script>
import { EL_EMPTY_IMAGE_SIZE } from '@/constants'
export default {
  data() {
    return {
      EL_EMPTY_IMAGE_SIZE,
      ENV: process.env.VUE_APP_ENV
    }
  },
  mounted() {
    this.ENV === 'dev'
      ? this.$notify.error({
          title: '加载失败',
          duration: 0,
          message:
            '异步组件编译块丢失，确保本地目录与返回的权限菜单一致的前提下，尝试重启本地服务'
        })
      : this.$confirm('检测到版本已更新', '提示', {
          confirmButtonText: '重 启',
          cancelButtonText: '取 消',
          type: 'success'
        }).then(this.refresh)
  },
  methods: {
    refresh() {
      window.location.reload()
    }
  }
}
</script>
