import elVariables from '@/styles/element-variables.scss'
import variables from '@/styles/variables.scss'
import defaultSettings from '@/settings'

const state = () => ({
  theme: elVariables?.theme,
  appMainBg: variables?.appMainBg ?? '#fff',
  titleHeight: parseFloat(variables?.titleHeight ?? '0px'),
  titleMarginBottom: parseFloat(variables?.titleMarginBottom ?? '0px'),
  titleBarPaddingTop: parseFloat(variables?.titleBarPaddingTop ?? '0px'),
  titleBarPaddingBottom: parseFloat(variables?.titleBarPaddingBottom ?? '0px'),
  totalNavTagHeight: parseFloat(variables?.totalNavTagHeight ?? '0px'),
  contentTotalOuterGap: parseFloat(variables?.contentTotalOuterGap ?? '0px'),
  contentTotalInnerGap: parseFloat(variables?.contentTotalInnerGap ?? '0px'),
  contentInnerGap: parseFloat(variables?.contentInnerGap ?? '0px'),
  ...defaultSettings
})
const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value
    } else {
      console.error(
        'Losed key:' + key + ' for "CHANGE_SETTING" that on state.settings'
      )
    }
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
