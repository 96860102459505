<template>
  <AsrRemoteSelect
    v-model="proxyValue"
    placeholder=" "
    v-bind="proxyProps"
    :enter-choose-first="true"
    :select-limit="20"
    v-on="proxyEvents"
    @change="onChange"
  />
</template>
<script>
export default {
  name: 'ElRemotePickerCustom',
  props: {
    scope: {
      type: Object,
      default: () => ({
        row: {},
        column: {}
      })
    }
  },
  data() {
    return {
      row: {},
      column: {}
    }
  },
  computed: {
    proxyProps() {
      const props = this.$attrs['edit-render-props']?.props ?? {}
      // eslint-disable-next-line no-unused-vars
      const { linkage, ...rest } = props
      return rest
    },
    linkage() {
      const props = this.$attrs['edit-render-props']?.props ?? {}
      // eslint-disable-next-line no-unused-vars
      const { linkage = null, ...rest } = props
      return linkage
    },
    rowPairKey() {
      return this.linkage?.row ?? ''
    },
    selectedPairKey() {
      return this.linkage?.selected ?? ''
    },
    proxyEvents() {
      // eslint-disable-next-line no-unused-vars
      const { change, ...rest } = this.$listeners
      return rest
    },
    proxyValue: {
      set(val) {
        this.row[this.column.property] = val
      },
      get() {
        return this.scope.row[this.scope.column.property]
      }
    }
  },
  created() {
    Object.assign(this, this.scope)
  },
  methods: {
    onChange(value, fullval) {
      const { change } = this.$listeners
      if (this.rowPairKey && this.selectedPairKey) {
        if (value) {
          this.row[this.rowPairKey] = fullval[this.selectedPairKey]
        } else this.row[this.rowPairKey] = null
      }
      typeof change === 'function' ? change(this.scope, value, fullval) : null
    }
  }
}
</script>
