/**
 * @module api-web:businessTodo/purchaseReplenishment/list
 * @description 首页/采购补货待办 列表
 */
import service from '@/service'

/**
 * 我的待办列表
 * @param {*} params
 */
export function getMyTodoList(params) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/get/getList',
    method: 'get',
    params
  })
}

/**
 * 我的已完成列表
 * @param {*} params
 */
export function getMyDoneList(params) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/get/getDoneList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 部门待办列表
 * @param {*} params
 */
export function getDeptTodoList(params) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/get/getDeptTodeList',
    method: 'get',
    params
  })
}

/**
 * 部门已完成列表
 * @param {*} params
 */
export function getDeptDoneList(params) {
  return service['api-web']({
    url: '/index/public/todoPurchaseReplenishment/get/getDeptDoneList',
    method: 'get',
    params
  })
}

export default {
  getMyTodoList,
  getMyDoneList,
  getDeptTodoList,
  getDeptDoneList
}
