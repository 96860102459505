// /**
//  * Province City District
//  * @see https://www.npmjs.com/package/element-china-area-data
//  */
// import {
//   provinceAndCityData,
//   regionData,
//   provinceAndCityDataPlus,
//   regionDataPlus,
//   CodeToText,
//   TextToCode
// } from 'element-china-area-data'

import { getAllGeographicCoordinates } from '@/service/api-scm'
import { isArray } from '@/utils/validate'
import { toArrayTree } from 'xe-utils'
const state = () => ({
  /**
   * @type {Boolean}
   * @description 数据请求加载 loading flag
   */
  loading: false,
  /**
   * @type {Array}
   * @description 接口返回全量省市区做坐标等信息数据
   */
  data: []
  // provinceAndCityData, // 本地数据：省市二级联动数据（不带“全部”选项）
  // provinceAndCityDataPlus, // 本地数据：省市二级联动数据（带“全部”选项）, "全部" 选项绑定的 `value` 是空字符串 `''`
  // regionData, // 本地数据：省市区三级联动数据（不带“全部”选项）
  // regionDataPlus, // 本地数据：省市区三级联动数据（带“全部”选项）, "全部" 选项绑定的 `value` 是空字符串 `''`
  // /**
  //  * @type {Object}
  //  * @description `CodeToText` 是个大对象，属性是区域码，属性值是汉字
  //  * @useage
  //  * - `CodeToText['110000']` 输出 `'北京市'`
  //  */
  // CodeToText,
  // /**
  //  * @type {Object}
  //  * @description `TextToCode` 是个大对象，属性是汉字，属性值是区域码
  //  * @useage
  //  * - `TextToCode['北京市'].code` 输出 `'110000'`,
  //  * - `TextToCode['北京市']['市辖区'].code` 输出 `'110100'`
  //  * - `TextToCode['北京市']['市辖区']['朝阳区'].code` 输出 `'11010'`
  //  */
  // TextToCode
})

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading
  },
  SAVE_DATA: (state, data) => {
    state.data = data
  }
}

const actions = {
  async fetchData({ state, commit }) {
    try {
      if (state.loading === true || state.data?.length > 0) return
      commit('SET_LOADING', true)
      const { code, msg, data } = await getAllGeographicCoordinates()
      if (code === 200 && isArray(data)) {
        const _data = toArrayTree(data, {
          key: 'geographyId',
          parentKey: 'parentId',
          sortKey: 'id',
          strict: true
        })
        commit('SAVE_DATA', _data)
      } else {
        commit('SAVE_DATA', [])
        console.error('【PCD】data fetch failed', msg, data)
      }
    } catch (error) {
      console.error('【PCD】', error)
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
