/**
 * @module utils/index
 */
import { isString, isArray } from './validate'
/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}
/**
 * @param {Object} data - indexable that key-val pairs
 * @returns {string}
 */
export function obj2param(data) {
  if (!data) return ''
  return cleanArray(
    Object.keys(data).map((key) => {
      if (data[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
    })
  ).join('&')
}

/**
 * @param {string=} url full url string
 * @returns {Object} object
 */
export function param2obj(url) {
  try {
    if (!url || (url && typeof url !== 'string')) {
      return {}
    }
    const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
    if (!search) {
      return {}
    }
    const obj = {}
    const searchArr = search.split('&')
    searchArr.forEach((v) => {
      const index = v.indexOf('=')
      if (index !== -1) {
        const name = v.substring(0, index)
        const val = v.substring(index + 1, v.length)
        obj[name] = val
      }
    })
    return obj
  } catch (error) {
    console.error(error)
  }
  return {}
}

/**
 * @param {Object} data
 * @returns instance of FormData
 * @description
 *
 * formData.append(name, value);
 * formData.append(name, value, filename);
 *
 * 遇到文件需要添加文件名，则 data 中, value 定义为数组
 * {
 *   ...
 *   file: [File,filename]
 *   ...
 * }
 * 如果满足：
 *  1. 数组长度为 2
 *  2. 第一个是 File 实例
 *  3. 第二个是字符串
 * 那么 formData.append(i, item[0], item[1]);
 * 否则就是 遍历数组并且逐个添加
 *
 */
export function obj2form(obj) {
  const formData = new FormData()
  try {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const val = obj[key]
        if (isArray(obj[key])) {
          if (
            val.length === 2 &&
            (val[0] instanceof File || val[0] instanceof Blob) &&
            typeof val[1] === 'string' &&
            val[1]
          ) {
            formData.append(key, val[0], val[1])
          } else val.forEach((item) => void formData.append(key, item))
        } else formData.append(key, val)
      }
    }
  } catch (error) {
    console.error(error)
  }
  return formData
}

/**
 * @param {(Array<stirng>|string)}
 * @returns {string}
 */
export const arr2str = function (arrOrstr) {
  return isArray(arrOrstr)
    ? arrOrstr.join() || ''
    : isString(arrOrstr)
    ? arrOrstr
    : ''
}

/**
 * @param {string=} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = typeof url === 'string' && url ? url : window?.location?.href
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounceNew(func, wait, immediate) {
  let timeout

  return function (...args) {
    const context = this
    const later = function () {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(context, args)
    }
  }
}
/**
 *
 * @param {string} json
 * @returns {Array}
 */
export function safeJsonParse(json) {
  try {
    return [null, JSON.parse(json)]
  } catch (err) {
    return [err, json]
  }
}
/**
 *
 * @param {string} json
 * @returns {Promise<Array>}
 */
export function safeJsonParseAsync(json) {
  return new Promise((resolve) => void resolve(safeJsonParse(json)))
}

/**
 * @param {*} res
 * @returns {Promise<Array>}
 */
export async function getSyncAsyncResult(res) {
  let result = [
    null, // error
    null // result
  ]

  try {
    if (
      res instanceof Promise ||
      (!!res &&
        (typeof res === 'object' || typeof res === 'function') &&
        typeof res.then === 'function')
    ) {
      await res
        .then((r) => {
          result = [null, r]
        })
        .catch((err) => (result = [err, null]))
    } else {
      result = [null, res]
    }
  } catch (err) {
    result = [err, null]
  }

  return result
}
/**
 * @param {*} fn - function
 * @param {*} [context=null] - this, scope of function, default null
 * @returns {Promise<Array>}
 */
export const getSyncAsyncReturns = async (...args) => {
  // [fn, this , args for fn]
  const [fn = null, context = null, ...rest] = args

  if (typeof fn !== 'function') {
    return [new Error('first argument must be a function'), null]
  }

  if (typeof context !== 'object') {
    return [
      new Error(
        'second argument is context, like "this" or null, must be object type'
      ),
      null
    ]
  }
  const result = await getSyncAsyncResult(fn.apply(context, rest))

  console.groupCollapsed('getSyncAsyncReturns')
  console.log(fn, context, result)
  console.groupEnd()

  return result
}

export async function getAnyValue(value) {
  const [e, v] =
    typeof value === 'function'
      ? await getSyncAsyncReturns(value)
      : await getSyncAsyncResult(value)

  if (e) {
    console.error(e)
    return value
  }
  return v
}

export function uuid() {
  var s = []
  var hexDigits = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4'
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = '-'
  const uuid = s.join('')
  return uuid
}

/**
 * Trigger window resize
 * @see http://codrate.com/questions/how-can-trigger-the-window-resize-event-manually-in-javascript
 */
export function triggerResize() {
  if (typeof Event === 'function') {
    // modern browsers
    window.dispatchEvent(new Event('resize'))
  } else {
    // This will be executed on old browsers and especially IE
    var resizeEvent = window.document.createEvent('UIEvents')
    resizeEvent.initUIEvent('resize', true, false, window, 0)
    window.dispatchEvent(resizeEvent)
  }
}
