/**
 * @module settings
 */

/** SETTINGS */
module.exports = {
  title: '供应链管理系统',

  /**
   * @type {{bgColor:string,txtColor:string}}
   * @description Browser console panel theme, just for developer
   */
  consoleTheme: {
    bgColor: {
      transparent: ''
    },
    txtColor: {
      blue: '#46a6ff',
      green: '#39c184',
      red: '#d93939'
    }
  },

  tableFontColorClass: {
    red: 'red-font'
  },
  /**
   * @type {boolean}
   * @description Whether show the settings right-panel
   */
  showSettings: false,

  /**
   * @type {boolean}
   * @description Whether need tagsView
   */
  tagsView: false,

  /**
   * @type {boolean}
   * @description Whether need title bar
   */
  titleBar: true,

  /**
   * @type {boolean}
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean}
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {(string|Array<string>)}
   * @description PS: need show err logs component first.
   * - stage 开发
   * - test  测试
   * - rc    预发布
   * - prod  生产
   */
  errorLog: ['prod', 'rc', 'test', 'stage']
}
