import VXETable from 'vxe-table'
import ElInputFigure from './ElInputFigure.vue'

VXETable.renderer.add('ElInputFigure', {
  renderEdit: (...args) => ElInputFigureRender(...args)
})

function ElInputFigureRender(h, renderOpts, params = {}) {
  try {
    const { events = {}, ...rest } = renderOpts
    return [
      <ElInputFigure
        scope={params}
        on={events}
        edit-render-props={rest}
      ></ElInputFigure>
    ]
  } catch (error) {
    console.error(error)
  }
  return [<ElInputFigure />]
}
