/**
 * @module api-web:files
 */
import service from '@/service'

/**
 * @param {FormData} data {file:blob}
 * @description 文件上传
 */
export function upload(data) {
  return service['api-web']({
    url: '/public/upload',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @param {{fileName:string}} data
 * @description 文件下载
 */
export function download(data) {
  return service['api-web']({
    url: '/public/download',
    method: 'get',
    data
  })
}
/**
 * @param {{fileNames:string}} params
 * @description 文件删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/public/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} fileName
 * @description 文件拷贝
 */
export function copy(fileName) {
  return service['api-web']({
    url: '',
    method: 'get',
    params: { fileName }
  })
}
/**
 *
 * @typedef {Object} FileStreamConvert
 * @property {('blobUrl'|'base64')=} to - convert to
 * @property {string=} type - media type
 *
 * @param {string} fileUrl
 * @param {FileStreamConvert=} convert
 * @description 文件读取
 */
export function read(fileUrl, convert = undefined) {
  return service['api-web']({
    url: fileUrl,
    method: 'get',
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载
    convert
  })
}
/**
 * @param {FormData} data {file:blob}
 * @description 视频上传
 */
export function uploadVideo(data) {
  return service['api-web']({
    url: '/public/uploadVideo',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
