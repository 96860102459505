import { isString } from './validate'
import { uppercaseFirst } from '@/utils/string'

/**
 * @returns {(number|string)} 命名路由ID
 * 暂定时间戳，后续可以统一修改
 */
export const uniqueNamedRouteID = () => {
  return Date.now()
}

/**
 * 截取路由路径， 指定 endOf 的前面一段
 * @param {string} path -传入的路由
 * @param {string} endOf -指定一个符号,默认为“/”
 * @returns {string} - 返回截取后的路由 path
 *
 * @expamle
 * ```js
 * routeExtract('http://xxx.xxx.xxx/data/customer/list/1680861916475')
 * => 'http://xxx.xxx.xxx/data/customer/list'
 * ```
 */
export function routeExtract(path, endOf = '/') {
  const index = path.lastIndexOf(endOf)
  return path.substring(0, index)
}

/**
 * @param {*} $route
 * @returns {string}
 */
export function getRouteName($route) {
  if (isString($route?.name) && $route.name.trim()) {
    return $route.name
  }

  if (isString($route?.path) && $route.path.trim()) {
    if ($route.path.startsWith('/redirect/')) {
      const name = $route.path
        .split('/')
        .slice(2, -1)
        .map((v) => (v ? uppercaseFirst(v) : ''))
        .join('')
      if (name) return name
    }
  }
  console.error('Failed to get route name from', $route)
  return ''
}

export function extractPathName(url) {
  // 去掉协议、域名和端口
  const regex = /^[a-zA-Z]+:\/\/[^\/]+/
  return url.replace(regex, '')
}

/**
 * @description 去除详情页url上的id和查询参数
 * @param {string} url
 * @returns string
 */
export function discardIdAndQueryInUrl(url) {
  if (typeof url !== 'string') {
    console.error('url类型不是string')
    return ''
  }

  const regex = /(detail\/).*/

  return url.replace(regex, '$1')
}

export default {
  getRouteName,
  routeExtract,
  uniqueNamedRouteID,
  extractPathName,
  discardIdAndQueryInUrl
}
