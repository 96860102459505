/**
 * @module utils/object
 */

import { isPlainObject } from './validate'

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property]
    if (isPlainObject(sourceProperty)) {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}
/**
 * 根据 keys 从对象中提取对应的 property 组成新的对象
 * @param {object} [obj={}] 源对象
 * @param {Array<string>} [keys=[]] 字段名数组
 * @param {boolean} [inverse=false] 是否反选
 * @returns {object}
 */
export function extractSubsetByKeys(obj = {}, keys = [], inverse = false) {
  const picked = inverse
    ? Object.entries(obj).filter(([key]) => !keys.includes(key)) // 反选
    : Object.entries(obj).filter(([key]) => keys.includes(key))

  return picked.reduce(
    (obj, [key, val]) => Object.assign({}, obj, { [key]: val }),
    {}
  )
}

/**
 * recursive remove null vals of obj
 * @param {*} obj
 * @returns obj without null values
 */
export function removeNullValue(obj) {
  try {
    if (!isPlainObject(obj)) return obj
    return Object.keys(obj)
      .filter(function (k) {
        return obj[k] !== null
      })
      .reduce(function (acc, k) {
        acc[k] = isPlainObject(obj[k]) ? removeNullValue(obj[k]) : obj[k]
        return acc
      }, {})
  } catch (error) {
    console.error(error)
  }
  return obj
}

/**
 * Flatten a multidimensional object
 *
 * For example:
 *   flattenObject{ a: 1, b: { c: 2 } }
 * Returns:
 *   { a: 1, c: 2}
 */
export const flattenObject = (obj) => {
  const flattened = {}

  if (!isPlainObject(obj)) {
    console.error('Illeagl "obj" for flatten', obj)
    return flattened
  }

  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    if (isPlainObject(value)) Object.assign(flattened, flattenObject(value))
    else flattened[key] = value
  })

  return flattened
}

// This function takes in an object and a key as parameters
// and returns the value associated with the key if it exists.
// Otherwise, it will return null.
export function getValueByKey(object, key) {
  // Check if the object has a property that matches the key
  if (Object.prototype.hasOwnProperty.call(object, key)) {
    // Return the value associated with the key
    return object[key]
  }
  // Key not found, return null
  return null
}

// Function to get a key by its associated value in an object
export function getKeyByValue(object, value) {
  // Iterate through each key in the object
  for (const key in object) {
    // Check if the key exists in the object and it's value matches the given value
    if (
      Object.prototype.hasOwnProperty.call(object, key) &&
      object[key] === value
    ) {
      // Return the key if there is a match
      return key
    }
  }
  // Return null if no matching value is found
  return null
}
