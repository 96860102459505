/**
 * @module api-web:hr/users
 */

import service from '@/service'

/**
 * @param {{parentDept:number,deptId:number,enrollmentTime:string,isEnable:(1|2),keywords:string,limit:number,page:number,sort:string,workStatus:(1|2)}} params
 * @description  查询用户列表
 */
export function getList(params) {
  return service['api-web']({
    url: '/hr/sysUser/getList',
    method: 'get',
    params
  })
}

/**
 * @param {{rolesIds:Array<number>,sysUser:SysUser,sysUserInfo:SysUserInfo,sysUserJobInfo:SysUserJobInfo}} data
 * @description  创建用户
 */
export function add(data) {
  return service['api-web']({
    url: '/hr/sysUser/save',
    method: 'post',
    data
  })
}

/**
 * @param {{rolesIds:Array<number>,sysUser:SysUser,sysUserInfo:SysUserInfo,sysUserJobInfo:SysUserJobInfo}} data
 * @description  修改用户
 */
export function update(data) {
  return service['api-web']({
    url: '/hr/sysUser/update',
    method: 'put',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{id:number}} params
 * @description 删除单个用户
 */
export function removeSingle(params) {
  return service['api-web']({
    url: '/hr/sysUser/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 批量删除用户
 */
export function remove(params) {
  return service['api-web']({
    url: '/hr/sysUser/deleteAll',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{id:number}} params
 * @description 禁用/启用 用户
 */
export function enable(params) {
  return service['api-web']({
    url: '/hr/sysUser/update/enabled',
    method: 'put',
    params
  })
}
/**
 * @param {number} id
 * @description 根据 ID 查询用户详情
 */
export function getDetailsById(id) {
  return service['api-web']({
    url: '/hr/sysUser/info',
    method: 'get',
    params: { id }
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} userName
 * @description 查询用户名是否存在
 */
export function isRepeatUsername(userName) {
  return service['api-web']({
    url: '/hr/sysUser/isUserNameRepetition',
    method: 'get',
    params: { userName }
  })
}
// /**
//  * @param {number} id 用户ID
//  * 根据用户ID重置用户密码
//  */
// export function resetPasswordById(id) {
//   return service['api-web']({
//     url: `/hr/sysUser/update/resetPassWord/${id}`,
//     method: 'put'
//   })
// }
/**
 * @param {string} id 用户id
 * 根据用户ID重置密码
 */
export function resetPassWord(id) {
  return service['api-web']({
    url: '/hr/sysUser/resetPassWord',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{rolesIds:Array<number>,userID:number}} data
 * 根据用户ID分配角色
 */
export function assginRolesById(data) {
  return service['api-web']({
    url: '/hr/sysUser/update/rolesAllocation',
    method: 'put',
    data
  })
}

/**
 * @param {{rolesIds:Array<number>,userID:number}} data
 * 删除用户组织信息
 */
export function deleteOrgById(data) {
  return service['api-web']({
    url: '/hr/sysUser/deleteUserCompany',
    method: 'post',
    data
  })
}

/**
 * 基本信息
 * @typedef {Object} SysUser
 * @property {number} age
 * @property {string} birthDate
 * @property {string} contact
 * @property {number} deptId
 * @property {string} deptName
 * @property {string} headUrl
 * @property {number} id
 * @property {string} idNumber
 * @property {number} isEnable
 * @property {number} jobId
 * @property {string} jobName
 * @property {string} mail
 * @property {string} name
 * @property {string} password
 * @property {number} sex
 * @property {string} telephone
 * @property {string} updateTime
 * @property {number} updateUser
 * @property {number} userStatus
 * @property {string} username
 * @property {number} workStatus
 * @property {string} workerId
 *
 *
 * @typedef {Object} SysUserInfo
 * @property {string} censusAddress
 * @property {number} education
 * @property {string} emergencyContact
 * @property {string} emergencyPhone
 * @property {number} id
 * @property {string} major
 * @property {number} maritalStatus
 * @property {number} nation
 * @property {string} nativePlace
 * @property {string} nowAddress
 * @property {number} politicsStatus
 * @property {string} relation
 * @property {string} school
 *
 *
 * @typedef {Object} SysUserJobInfo
 * @property {number} accumulationFundAccount
 * @property {string} bankCard
 * @property {number} contractType
 * @property {string} enrollmentTime
 * @property {string} firstAssess
 * @property {number} id
 * @property {string} lastUpdateTime
 * @property {number} lastUpdateUser
 * @property {string} leaseEndTime
 * @property {string} leaseStartTime
 * @property {number} medicalExaminationReport
 * @property {number} medicalInvoice
 * @property {string} medicalReimbursementTime
 * @property {string} positiveTime
 * @property {string} probationTime
 * @property {string} secondAssess
 */
