/**
 * @module api-web:department
 */
import service from '@/service'

/**
 * 部门项
 * @typedef {Object} Department
 * @property {number} deptId
 * @property {string} deptName
 * @property {number} parentDept
 */

/**
 * 查询部门列表
 * @param {{keywords:string,limit:number,page:number,parentDept:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/sys/sysDept/getList',
    method: 'get',
    params
  })
}

/**
 * 增加部门
 * @param {Department} data
 */
export function add(data) {
  return service['api-web']({
    url: '/sys/sysDept/save',
    method: 'post',
    data
  })
}

/**
 * 修改部门
 * @param {Department} data
 */
export function update(data) {
  return service['api-web']({
    url: '/sys/sysDept/update',
    method: 'put',
    data
  })
}

/**
 * 单个/批量 删除部门
 * @param {{ids:Array<number>}} params - ids
 */
export function remove(params) {
  return service['api-web']({
    url: '/sys/sysDept/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 查询部门详情
 * @param {number} id
 */
export function getDetailsById(id) {
  return service['api-web']({
    url: '/sys/sysDept/info',
    method: 'get',
    params: { id }
  })
}
