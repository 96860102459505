// eslint-disable-next-line spaced-comment
/// <reference path="../../typedef.js" />

import { camelCase } from '@/utils/string'

/**
 * @module permission/menus/routes/path
 */
/**
 * @param {RolePermissionTreeItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {string} 路由路径
 */
export function generatePath(node, indexTable) {
  const { pmType, path, pmUrl } = node
  let result = ''
  try {
    if (pmType === 0) {
      result =
        path.includes('-') || path.includes('/')
          ? `/${path}`
          : `/${camelCase(path)}`
    } else {
      result =
        path.includes('-') || path.includes('/') || path.includes(':')
          ? `${path}`
          : `${camelCase(path)}`
    }
  } catch (error) {
    console.error(error)
  }
  return result || `/error${pmUrl}`
}
export default generatePath
