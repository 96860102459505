/**
 * @module utils/get-style
 */

/**
 * @param {HTMLElement} ele html element
 * @param {string} prop css prop name
 * @returns {string} css prop value
 */
export function getStyle(ele, prop) {
  let value = ''
  try {
    if (document.defaultView && document.defaultView.getComputedStyle) {
      value = document.defaultView
        .getComputedStyle(ele, '')
        .getPropertyValue(prop)
    } else if (ele.currentStyle) {
      prop = prop.replace(/\-(\w)/g, (strMatch, p1) => p1.toUpperCase())
      value = ele.currentStyle[prop]
    }
  } catch (error) {
    console.error(error)
  }
  return value
}

export default getStyle
