import { isArray, isString } from '@/utils/validate'
export default {
  /**
   * 下拉按钮渲染
   */
  name: 'dropdownRenderMixin',
  methods: {
    /**
     * @returns {boolean} 是否显示下拉按钮组的基础父级按钮
     */
    showDropdownButton(item) {
      // console.groupCollapsed(`${item.label || item.evtName}`, 'render')
      // console.log('showDropdownButton', JSON.stringify(item))

      // dropdown 无子项目
      if (!isArray(item.dropdown) || !item.dropdown.length) {
        // console.info('无下拉子按钮')
        // console.groupEnd()
        return false
      }

      // dropdown item 有一个需要显示
      const hasChildrenShow = item.dropdown.some((dropdownItem) =>
        this.showDropdownItemButton(dropdownItem)
      )
      if (!hasChildrenShow) {
        // console.info('下拉子按钮均隐藏了')
        // console.groupEnd()
        return false
      }

      // dropdown 不需要权限
      if (item.pmName === null || typeof item.pmName === 'undefined') {
        // console.info('下拉主按钮无需鉴权')
        // console.groupEnd()
        return true
      }

      // dropdown 没有权限
      const parentPermission = this.checkPermission(item)
      if (!parentPermission) {
        // console.info('下拉主按钮无权限')
        // console.groupEnd()
        return false
      }

      // console.groupEnd()
      return true
    },
    /**
     * @returns {boolean} 是否显示下拉按钮子按钮
     */
    showDropdownItemButton(dropdownItem) {
      const name = dropdownItem.evtName || dropdownItem.label
      // 优先过滤 工作流程
      if (name === '工作流程' || name === '工作流程') {
        const { $_detail_cru_procInstIdOfInfo } = this.scope
        const hasProcInstId = !!(
          isString($_detail_cru_procInstIdOfInfo) &&
          $_detail_cru_procInstIdOfInfo
        )
        if (!hasProcInstId) return false
      }

      if (dropdownItem.pmName === null) {
        // console.info('下拉子按钮无需鉴权')
        return true
      }
      return this.checkPermission(dropdownItem)
    },
    /**
     * Dropdown Menu Item Attributes
     * @see https://element.eleme.io/#/zh-CN/component/dropdown#dropdown-menu-item-attributes
     */
    dropdownItemProps(dropdownItem) {
      const { attrs = {} } = dropdownItem
      const { command = dropdownItem, ...rest } = attrs

      return {
        command,
        ...rest
      }
    }
  }
}
