/**
 * @module api-scm:supplierInventory
 */

import service from '@/service'

/**
 * @typedef {object}  Params
 * @property {Array<string>}  inventoryInTime 入库时间
 * @property {string} productCode 商品编码
 * @property {string} productName 商品名称
 * @property {string} purchaseCode  采购单号
 * @property {string} shipper 货主
 *
 * @param {Params} params - 查询采购开票列表请求参数
 * @returns {Promise<any>}
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/purchase/inventorySupplier/getList',
    method: 'get',
    params
  })
}

/**
 * @param {{id:(string|number)}} params
 */
export function info(params) {
  return service['api-scm']({
    url: '/scm/purchase/inventorySupplier/info',
    method: 'get',
    params
  })
}

/**
 * 描述
 * @param {object} data
 */
export function save(data) {
  return service['api-scm']({
    url: '/scm/purchase/inventorySupplier/save',
    method: 'post',
    data
  })
}

/**
 * 更新
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/purchase/inventorySupplier/update',
    method: 'post',
    data
  })
}

/**
 * 删除
 * @param {{ids:string}} params
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/purchase/inventorySupplier/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 扣减
 * @param {object} data
 */

export function updateDeduct(data) {
  return service['api-scm']({
    url: '/scm/purchase/inventorySupplier/updateDeduct',
    method: 'post',
    data
  })
}
