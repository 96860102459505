// eslint-disable-next-line spaced-comment
/// <reference path="../../typedef.js" />

/**
 * @module permission/menus/routes/redirect
 */

import { isArray, isString, validURL } from '@/utils/validate'
import { recursiveParents } from '@/permission/utils'
import { hidden } from '@/router/config'
import { uniqueNamedRouteID } from '@/utils/router'
import { validPath } from '../../utils'
/**
 * @param {RolePermissionFlatItem} node
 * @param {PermissionIndexTable} indexTable
 * @returns {(string|function)} 路由重定向属性
 */
export function generateRedirect(node, indexTable) {
  try {
    const { id, path } = node

    let children = indexTable.pidIndex[id]
    if (!isArray(children)) {
      return ''
    }
    children = children.filter((item) => validPath(item.path))
    if (!children.length) {
      return ''
    }
    children.length > 1
      ? (children = children.sort((a, b) => a.sort * 1 - b.sort * 1))
      : null
    if (
      /**
       * children 所有节点
       * 均是 「外链」
       * 或者
       * 均被「被隐藏但不是 nested-nav, 不是 创建 , 不是本地创建」
       */
      children.every(
        (v) =>
          ((hidden.includes(v.id) ||
            (typeof v.hidden === 'boolean' && v.hidden)) &&
            v.path !== 'create' &&
            !v.pmUrl.includes('/local/') &&
            !v.isNestedNav) ||
          validURL(v.path)
      )
    ) {
      return ''
    }

    const childrenPaths = children.filter((v) => !v.hidden).map((v) => v.path)
    const eldestChildPath = childrenPaths.includes('category')
      ? 'category'
      : childrenPaths.includes('list/:id')
      ? 'list'
      : childrenPaths.includes('create')
      ? 'create'
      : children[0].path || ''

    let recursiveResult = recursiveParents(
      indexTable,
      node,
      eldestChildPath ? [path, eldestChildPath] : [path],
      'path'
    )
    if (isArray(recursiveResult)) {
      recursiveResult = recursiveResult
        .map((v, i) => {
          if (isString(v)) {
            let result = v
            if (i === 0) {
              if (result.charAt(0) !== '/') {
                result = `/${result}`
              }
            }

            if (result.length > 1 && result.substr(-1) === '/') {
              return result.slice(0, -1)
            } else {
              return result
            }
          } else {
            return ''
          }
        })
        .filter((v) => !!v)

      if (recursiveResult.length) {
        if (recursiveResult[0] === '/') recursiveResult[0] = ''

        // 重定向路由如果是 「命名路由」，需要将路径字符串改为 function 返回
        const redirect = isNamedRoute(recursiveResult)
          ? dynamicRedirecting(recursiveResult.join('/'))
          : recursiveResult.join('/')
        return redirect
      } else return ''
    } else {
      return ''
    }
  } catch (error) {
    console.error(error)
    return ''
  }
}

/**
 *
 * @param {Array<string>} redirectPathArr
 * @returns {boolean} 是否是命名路由
 * @see https://v3.router.vuejs.org/guide/essentials/named-routes.html
 */
function isNamedRoute(redirectPathArr) {
  try {
    //  列表 list/:id 为 动态路由
    return redirectPathArr.slice(-1).pop() === 'list'
    // ...
  } catch (error) {
    console.error(error)
    return false
  }
}
/**
 * @param {string} redirectPath
 * @returns {function}
 * @see https://v3.router.vuejs.org/guide/essentials/redirect-and-alias.html#redirect
 * ```js
 * const router = new VueRouter({
 *   routes: [{
 *      path: '/a',
 *      redirect: to => {
 *       // the function receives the target route as the argument
 *       // return redirect path/location here.
 *      }
 *   }]
 * })
 * ```
 */
function dynamicRedirecting(redirectPath) {
  return (to) => `${redirectPath}/${uniqueNamedRouteID()}`
}

export default generateRedirect
