/**
 * @module api-scm:taskCenter/queryListByPage/index.vue
 * @description 任务管理中心
 */
import service from '@/service'

/**
 * 查询 列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/taskCenter/queryListByPage',
    method: 'POST',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
