/**
 * @module custom-renderers:ElFileUploader:mixin
 * @path @/src/plugins/vxe-table/
 */

import { isString, isNumeric } from '@/utils/validate'
import { remove, upload } from '@/service/api-web/files'
const TYPE_LIST = [
  'txt',
  'xlsx',
  'xls',
  'csv',
  'pdf',
  'jpg',
  'jepg',
  'png',
  'pptx',
  'ppt',
  'doc',
  'docx'
]
const TYPES = [...TYPE_LIST, ...TYPE_LIST.map((t) => t.toUpperCase())]

/**
 * - this.setRowExternal
 * - this.getRowExternal
 * @see /src/components/__global-auto-register__/BaseInlineEditTable.vue
 */

export default {
  data() {
    return {
      /**
       * 点击图片预览
       */
      previewImageSrc: '',
      previewImageShow: false
    }
  },
  computed: {
    /**
     * 图片预览列表
     */
    previewImageSrcList() {
      return [this.previewImageSrc]
    }
  },
  methods: {
    /**
     * @returns {string} file name
     */
    $_elFileUploader_getUploadedFileName({ row, column }) {
      try {
        const filename = row[column.property + 'Filename']
        if (isString(filename) && filename) return filename
        else {
          const url = this.$_elFileUploader_getUploadedFileUrl({ row, column })
          if (url) {
            const splited = url.split('/')
            const last = splited[splited.length - 1]
            if (isString(last) && last) return decodeURIComponent(last)
          }
        }
      } catch (error) {
        console.error('【ElFileUploader MIXIN】: ' + error)
        return ''
      }
    },
    /**
     * @returns {string} file name
     */
    $_elFileUploader_getUploadedFileUrl({ row, column }) {
      let url = row?.[column?.property]
      try {
        if (isString(url)) {
          if (url.includes(',https')) url = row[column.property].split(',')[0]
          return url
        } else {
          console.error(
            '【ElFileUploader MIXIN】illegal url:',
            url,
            row,
            column?.property
          )
        }
      } catch (error) {
        console.error(
          '【ElFileUploader MIXIN】error:',
          error,
          url,
          row,
          column?.property
        )
      }
      return ''
    },
    /**
     * 重置上传文件
     */
    $_elFileUploader_resetUploadedFile({ row, column }) {
      row[column.property] = null
      row[column.property + 'Filename'] = null

      this.setRowExternal({ row, key: 'fileChanged', value: 'remove' })
      this.setRowExternal({ row, key: 'removeFile', value: null })
      this.setRowExternal({ row, key: 'hasFileUploaded', value: false })
      this.setRowExternal({ row, key: 'imageFileLoadStatus', value: '' })
    },
    /**
     * 上传图片文件加载状态
     */
    $_elFileUploader_getUploadedImageStatus({ row, column }) {
      return this.getRowExternal({ row, key: 'imageFileLoadStatus' })
    },
    /**
     * 挂载上传图片文件加载状态：成功
     */
    $_elFileUploader_onUploadImageLoaded({ row, column }) {
      this.setRowExternal({ row, key: 'imageFileLoadStatus', value: 'success' })
    },
    /**
     * 挂载上传图片文件加载状态：成失败
     */
    $_elFileUploader_onUploadImageError({ row, column }) {
      this.setRowExternal({ row, key: 'imageFileLoadStatus', value: 'error' })
    },
    /**
     * 单元格图片预览
     * @param  {string} file_url_name
     */
    $_elFileUploader_onPreviewImage(url) {
      this.previewImageSrc = url
      this.previewImageShow = true
    },
    /**
     * 单元格文件上传
     */
    async $_elFileUploader_onUploadFile({ row, column }, fileTypes, limitSize) {
      try {
        this.$nextTick(() => this.$table.setActiveRow(row))

        const result = await this.$table.readFile({
          multiple: false, // TODO: 多个文件，最好的方案是 后端提供多文件上传的接口，而不是前端遍历上传
          TYPES
        })
        console.log('result', result)
        const { files } = result

        if (
          fileTypes &&
          ![...files].every(
            (v) =>
              fileTypes.match(
                v.name.toLowerCase().split('.')[v.name.split('.').length - 1]
              )?.length
          )
        ) {
          return void this.$message.error(`只支持${fileTypes}格式上传`)
        }

        if (typeof limitSize !== 'undefined') {
          const _limitSize = isNumeric(limitSize)
            ? limitSize * 1024 * 1024
            : null
          if (typeof _limitSize !== 'number') {
            return console.error('props中的limitSize只能为数字！')
          }

          if ((files[0].size || 0) > _limitSize) {
            return this.$message.error(`文件不能大于${limitSize}M`)
          }
        }
        const {
          data: { returnFileUrl = '', originalFilename = '' }
        } = await upload({ file: files[0] })

        // if (!validURL(returnFileUrl)) {
        //   this.$_elFileUploader_resetUploadedFile({ row, column })
        //   this.$message.error('抱歉，文件格式不支持')
        //   return void console.error(
        //     'returnFileUrl: ' +
        //       returnFileUrl +
        //       ', originalFilename: ' +
        //       originalFilename
        //   )
        // }

        row[column.property] =
          isString(returnFileUrl) && returnFileUrl.trim() ? returnFileUrl : null
        row[column.property + 'Filename'] =
          isString(originalFilename) && originalFilename.trim()
            ? originalFilename
            : null

        this.setRowExternal({
          row,
          key: 'removeFile',
          value: function () {
            return function () {
              this.$_elFileUploader_removeFile({ row: { ...row }, column })
              this.setRowExternal({ row, key: 'removeFile', value: null })
              this.setRowExternal({ row, key: 'hasFileUploaded', value: false })
            }.bind(this)
          }.call(this)
        })
        this.setRowExternal({ row, key: 'hasFileUploaded', value: true })
        this.setRowExternal({ row, key: 'fileChanged', value: 'add' })
      } catch (error) {
        this.$_elFileUploader_resetUploadedFile({ row, column })

        this.isShowErrorMessageInFileUploader &&
          this.$message.error('抱歉，无法识别文件')

        console.error('【ElFileUploader MIXIN】: ' + error)
      }

      await this.$table.reloadRow(row, { ...row })
      await this.$table.updateStatus({ row, column })
      this.emitData({ source: '$_elFileUploader_onUploadFile' })
    },
    /**
     * 单元格文件删除 step1
     * @param {string} file_url_name
     */
    async $_elFileUploader_onRemoveFile({ row, column }) {
      this.$confirm('确认要永久移除文件吗?', '提示', {
        confirmButtonText: '确  定',
        cancelButtonText: '取 消',
        type: 'error'
      }).then(() => {
        this.$_elFileUploader_removeFile({ row, column })
      })
    },
    /**
     * 单元格文件删除 step2
     * @param {string} file_url_name
     */
    async $_elFileUploader_removeFile({ row, column }) {
      try {
        const url = encodeURI(
          this.$_elFileUploader_getUploadedFileUrl({ row, column })
        )
        if (url) {
          const { code } = await remove({ fileNames: url })
          if (code === 200) {
            this.$_elFileUploader_resetUploadedFile({ row, column })
          }
        } else {
          console.error('【ElFileUploader MIXIN】: Losed file url')
        }
      } catch (err) {
        console.error('【ElFileUploader MIXIN】: ' + err)
      }
    },
    /**
     * 单元格文件预览
     */
    async $_elFileUploader_onPreviewFile({ row, column }) {
      try {
        const fileUrl = this.$_elFileUploader_getUploadedFileUrl({
          row,
          column
        })
        const ext = fileUrl
          .slice(((fileUrl.lastIndexOf('.') - 1) >>> 0) + 2)
          .toLowerCase()
        // 文件：图片
        if (/(png|jpe?g|gif|svg)(\?.*)?$/.test(ext)) {
          return void this.$_elFileUploader_onPreviewImage(fileUrl)
        }
        // 文件：其他
        let type = ''
        switch (ext) {
          case 'doc':
          case 'docx':
            type = 'word'
            break
          case 'pptx':
          case 'ppt':
            type = 'ppt'
            break
          case 'xlsx':
          case 'xls':
            type = 'excel'
            break
          case 'pdf':
          case 'txt':
          case 'csv':
            type = ext
            break
          default:
            console.info('res.data.returnFileUrl:', fileUrl)
            console.error('【ElFileUploader MIXIN】: Unknown file ext:', ext)
            break
        }
        if (type) {
          this.$router.blank({
            path: '/preview-files',
            query: { type, file: window.encodeURI(fileUrl) }
          })
        }
      } catch (error) {
        console.error('【ElFileUploader MIXIN】: ' + error)
      }
    }
  }
}
