export default {
  /**
   * 按钮渲染
   */
  name: 'buttonRenderMixin',
  methods: {
    /**
     * @returns {boolean} 是否显示按钮
     */
    showButton(item) {
      /**
       * @description 先处理按钮的配置项的显隐，再处理权限判断逻辑
       * @see task:995
       */
      const { display = true } = item
      if (!display) return false
      // console.groupCollapsed(`${item.label || item.evtName}`, 'render')
      // console.log(JSON.stringify(item))
      const pm = item.pmName
      if (pm === null) {
        // console.info('无需鉴权')
        // console.groupEnd()
        return true
      }
      const show = this.checkPermission(item)
      // console.groupEnd()
      return show
    }
  }
}
