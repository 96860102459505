import store from '@/store'
import { isArray } from '@/utils/validate'
import { logErr } from './utils'

/**
 * get item by「 label/value/index 」async，if local missed, get via action
 * @param {string} from
 * @param {{label:string,value:(string|number),index:number,force:boolean}}
 * @returns {Promise<(import('../request').DictItem|false)>}
 * @description 获取字典项单项, 异步, 无需初始化字典项列表
 */
export async function getItem(
  from,
  { label = undefined, value = undefined, index = undefined, force = false }
) {
  try {
    const [category, name] = from.split('.')

    if (typeof index === 'number') {
      const dict = await store.dispatch('dict/get', {
        category,
        name,
        label,
        value,
        force
      })
      return isArray(dict) ? dict[index] : false
    } else {
      const item = await store.dispatch('dict/getItem', {
        category,
        name,
        label,
        value,
        force
      })
      return item || false
    }
  } catch (error) {
    logErr(error, arguments)
    return false
  }
}

/**
 * get item by「 label/value/index 」sync via state
 * @param {string} from
 * @param {{label:string,value:(string|number),index:number,force:boolean}}
 * @returns {Promise<(import('../request').DictItem|false)>}
 * @description 获取字典项单项, 同步, 须初始化字典项列表
 */
export function getItemSync(
  from,
  { label = undefined, value = undefined, index = undefined }
) {
  try {
    const [category, name] = from.split('.')
    const dict = store.state.dict?.category?.name
    if (!isArray(dict)) return false
    const dictIdx = store.getters[`dict/${category}`]?.[name]
    if (!dictIdx) return false
    switch (true) {
      case typeof label !== 'undefined':
        return dictIdx?.itemByLabel?.[label] || false
      case typeof value !== 'undefined':
        return dictIdx?.itemByValue?.[value] || false
      case typeof index === 'number':
        return dict[index] || false
      default:
        return false
    }
  } catch (error) {
    logErr(error, arguments)
    return false
  }
}
