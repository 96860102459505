/**
 * @module utils/clipboard
 */

import Clipboard from 'clipboard'
import { Message } from 'element-ui'

function clipboardSuccess() {
  Message({
    message: 'Copy successfully',
    type: 'success',
    duration: 1500
  })
}

function clipboardError() {
  Message({
    message: 'Copy failed',
    type: 'error',
    showClose: true
  })
}

/**
 * @param {string} text
 * @param {Event} event
 */
export function handleClipboard(text, event) {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    clipboardSuccess()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    clipboardError()
    clipboard.destroy()
  })
  clipboard.onClick(event)
}

/**
 *
 * @param {string} value
 * @returns {string}
 */
export function handleCopy(value) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(value) // clipboard api 复制
  } else {
    const textarea = document.createElement('textarea')
    document.body.appendChild(textarea)
    // 隐藏此输入框
    textarea.style.position = 'fixed'
    textarea.style.clip = 'rect(0 0 0 0)'
    textarea.style.top = '10px'
    // 赋值
    textarea.value = value
    // 选中
    textarea.select()
    // 复制
    document.execCommand('copy', true)
    // 移除输入框
    document.body.removeChild(textarea)
  }
  return Message({
    message: `复制成功`,
    type: 'success',
    duration: 1500
  })
}

export default {
  handleClipboard,
  handleCopy
}
