/**
 * @module api-web:finPurchaseOrder
 */
import service from '@/service'

/**
 * 查询 应付 采购订单列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ap/finPurchaseOrder/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{orderCode,id}}
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ap/finPurchaseOrder/public/getListDown',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ap/finPurchaseOrder/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  对账
 * @param {ids:string} data
 */

export function reconciliation(ids) {
  return service['api-web']({
    url: '/fin/ap/finPurchaseOrder/reconciliation',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ap/finPurchaseOrder/info',
    method: 'get',
    params: { purchaseCode: id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function turnOffFin(ids) {
  return service['api-web']({
    url: '/fin/ap/finPurchaseOrder/finClosePushDownFinPayApply',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function reverseCloseFin(ids) {
  return service['api-web']({
    url: '/fin/ap/finPurchaseOrder/finReverseClosePushDownFinPayApply',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
