/**
 * @module api-scm:orderManage/scanInspection
 */
import service from '@/service'

/**
 * 根据出库单编号拿到下面的商品明细
 * @param {{code:string}} params
 */
export function getDetailByCode(params) {
  return service['api-scm']({
    url: '/scm/order/scanningOutbound/getDetailByCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    tipTypes: 'none'
  })
}

/**
 * 发货
 * @param {object} data
 * @returns {object}
 */
export function sendOut(data) {
  return service['api-scm']({
    url: '/scm/order/scanningOutbound/shippingConfirmation',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 确认拆单提醒
 */
export function getRemainRules(params) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/get/getRemainRules',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
