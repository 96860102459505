/**
 * @module api-scm:supplierApplyController
 */
import service from '@/service'

/**
 * @description 获取列表
 */

export function getList(data) {
  return service['api-scm']({
    url: '/scm/supplier/supplierApplyController/getList',
    method: 'post',
    data
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/supplier/supplierApplyController/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/supplier/supplierApplyController/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/supplier/supplierApplyController/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 列表展开子项
 * @param {{code: string}}
 */
export function getListDown(params) {
  return service['api-scm']({
    url: '/scm/supplier/supplierApplyController/getListDropDown',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/supplierApplyController/rejectAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/supplier/supplierApplyController/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 客户资料导出数据-已授权
 * @param {(string|number)} id
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/supplier/supplierApplyController/export',
    method: 'post',
    data
  })
}

/**
 * @param {string} ids
 * @description 撤销
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/supplier/supplierApplyController/withdrawClaim',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
