export default {
  path: 'components',
  component: () => import('@/views/demos/components'),
  redirect: '/demos/components/flow-diagram',
  name: 'Components',
  meta: {
    title: 'Components',
    icon: 'component'
  },
  children: [
    {
      path: 'flow-diagram',
      component: () => import('@/views/demos/components/flow-diagram'),
      name: 'FlowDiagramDemo',
      meta: { title: 'Flow Diagram' }
    },
    {
      path: 'tinymce',
      component: () => import('@/views/demos/components/tinymce'),
      name: 'TinymceDemo',
      meta: { title: 'Tinymce' }
    },
    {
      path: 'json-editor',
      component: () => import('@/views/demos/components/json-editor'),
      name: 'JsonEditorDemo',
      meta: { title: 'JSON Editor' }
    },
    {
      path: 'split-pane',
      component: () => import('@/views/demos/components/split-pane'),
      name: 'SplitpaneDemo',
      meta: { title: 'SplitPane' }
    },
    {
      path: 'avatar-upload',
      component: () => import('@/views/demos/components/avatar-upload'),
      name: 'AvatarUploadDemo',
      meta: { title: 'Upload' }
    },
    {
      path: 'dropzone',
      component: () => import('@/views/demos/components/dropzone'),
      name: 'DropzoneDemo',
      meta: { title: 'Dropzone' }
    },
    {
      path: 'sticky',
      component: () => import('@/views/demos/components/sticky'),
      name: 'StickyDemo',
      meta: { title: 'Sticky' }
    },
    {
      path: 'count-to',
      component: () => import('@/views/demos/components/count-to'),
      name: 'CountToDemo',
      meta: { title: 'Count To' }
    },
    {
      path: 'mixin',
      component: () => import('@/views/demos/components/mixin'),
      name: 'ComponentMixinDemo',
      meta: { title: 'Component Mixin' }
    },
    {
      path: 'back-to-top',
      component: () => import('@/views/demos/components/back-to-top'),
      name: 'BackToTopDemo',
      meta: { title: 'Back To Top' }
    },
    {
      path: 'drag-dialog',
      component: () => import('@/views/demos/components/drag-dialog'),
      name: 'DragDialogDemo',
      meta: { title: 'Drag Dialog' }
    },
    {
      path: 'drag-select',
      component: () => import('@/views/demos/components/drag-select'),
      name: 'DragSelectDemo',
      meta: { title: 'Drag Select' }
    },
    {
      path: 'tooltip-select',
      component: () => import('@/views/demos/components/tooltip-select'),
      name: 'TooltipSelectDemo',
      meta: { title: 'Tooltip Select' }
    },
    {
      path: 'paste-select',
      component: () => import('@/views/demos/components/paste-select'),
      name: 'PasteSelectDemo',
      meta: { title: 'Paste Select' }
    },
    {
      path: 'dnd-list',
      component: () => import('@/views/demos/components/dnd-list'),
      name: 'DndListDemo',
      meta: { title: 'Dnd List' }
    },
    {
      path: 'drag-kanban',
      component: () => import('@/views/demos/components/drag-kanban'),
      name: 'DragKanbanDemo',
      meta: { title: 'Drag Kanban' }
    },
    {
      path: 'table-cell-data',
      component: () => import('@/views/demos/components/table-cell-data'),
      name: 'TableCellData',
      meta: { title: 'Table Cell Data' }
    }
  ]
}
