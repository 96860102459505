/**
 * @module api-web:financialAffairs/generalLedger/voucherManagement/queryVoucher
 */
import service from '@/service'

/**
 * 查询 财务-总账-凭证管理-凭证查询
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 详情 财务-总账-凭证管理-凭证查询
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 创建 财务-总账-凭证管理-凭证查询
 * @param {object} data
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/save',
    method: 'post',
    data
  })
}

/**
 * 修改 财务-总账-凭证管理-凭证查询
 * @param {object} data
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/update',
    method: 'put',
    data
  })
}

/**
 * 删除 财务-总账-凭证管理-凭证查询
 * @param {{ids:Array<number>}} params - ids
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 反审核 财务-总账-凭证管理-凭证查询
 * @param {{ids:Array<number>}} data - ids
 */
export function rollback(ids) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/reverseAudit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 审核 财务-总账-凭证管理-凭证审核
 * @param {{ids:Array<number>}} data - ids
 */
export function audit(ids) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/completedAudit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交 财务-总账-凭证管理-凭证查询
 * @param {{ids:Array<number>}} data - ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 冲销 财务-总账-凭证管理-凭证查询
 * @param {{ids:Array<number>}} data - ids
 */
export function writeOffVoucher(ids) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/writeOffVoucher',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 树形下拉 财务-总账-凭证管理-凭证查询
 * @param {{voucherId:Array<number>}} data - voucherId
 */
export function getListDown(voucherId) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/get/getVoucherPullList',
    method: 'get',
    params: { voucherId },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 打印 财务-总账-凭证管理-凭证查询
 * @param {{ids:Array<number>}} data - ids
 */
export function printVoucher(ids) {
  return service['api-web']({
    url: '/fin/gl/voucher/finVoucher/printVoucher',
    method: 'post',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
