/**
 * @type {Array<number>}
 * @description key size
 * - 512  bits	Low-strength
 * - 1024 bits	Medium-strength
 * - 2048 bits	High-strength
 * - 4096 bits	Very high-strength
 */
export const KEY_SIZES = [512, 1024, 2048, 4096]

/**
 * @type {(512|1024|2048|4096)}
 * @description default key size
 */
export const DEFAULT_KEY_SIZE = KEY_SIZES[1]

/**
 * @type {string}
 * @description default public key (provided from backend)
 */
export const DEFAULT_PUB_KEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC0DI76EL8mg8vSTsB6V7Mhnoky+K0+EnNvOB8N/M5jezHD65x990WtmsWAuHyH+RF2OnsVHsK5etODfJex/kEirMxu79rvEh2K3ZOwBYXTRcT7RgAxxWfbDSrfNnglCp55o4MVuAyQVICbA5Ui+d1j6XEG6l5aRQpKfTYyOo5iUwIDAQAB'
