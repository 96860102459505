/**
 * @module api-scm:purchaseAdjustment
 */

import service from '@/service'

/**
 * @param {{
 * productCode:string,
 * supplier:string}} params
 * @description  查询列表
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/getList',
    method: 'get',
    params
  })
}

/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/info',
    method: 'get',
    params: { id }
  })
}
/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/changePriceBill/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/update',
    method: 'put',
    data
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交审核
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 审核驳回
 * @param {object} data
 */
export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/changePriceBill/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 采购价目表明细新增
 * @param {object} data
 * @returns {object}
 */
export function createDetail(data) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/save/saveDetail',
    method: 'post',
    data
  })
}
/**
 * 修改
 * @param {object} data
 * @returns {object}
 */
export function updateDetail(data) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/update/updateDetail',
    method: 'put',
    data
  })
}
/**
 * @param {{ids:string}} params
 * @description 明细删除
 */
export function removeDetail(params) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/update/deleteDetail',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 采购调价函明细Excel导入
 * 自动上传无需配置 axios 请求，只需要api地址 传递给 @see UploadFileDialog 组件通过 action 自动上传即可
 */
export function importData(data) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/importDetail',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 根据采购调价函名称模糊搜索
 */
export function getChangePriceBillByByChangeName(changeName) {
  return service['api-scm']({
    url: '/public/purchase/getChangePriceBillByByChangeName',
    method: 'get',
    params: { changeName }
  })
}
/**
 * @description 根据采购调价函关联价目表编码模糊搜索
 */
export function getChangePriceBillByPriceCode(priceCode) {
  return service['api-scm']({
    url: '/public/purchase/getChangePriceBillByPriceCode',
    method: 'get',
    params: { priceCode }
  })
}
/**
 * @description 根据采购调价函编码模糊搜索
 */
export function getChangePriceBillByBillCode(changePriceBillCode) {
  return service['api-scm']({
    url: '/public/purchase/getChangePriceBillByBillCode',
    method: 'get',
    params: { changePriceBillCode }
  })
}
/**
 * @description 创建人
 * @param {{keywords:string}} params
 */
export function getUserlists(keywords) {
  return service['api-web']({
    url: '/public/getUserlists',
    method: 'get',
    params: { keywords },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @description 采购调价函模板下载
 */
export function downloadForDetail(data) {
  return service['api-scm']({
    url: '/scm/purchase/changePriceBill/downloadExcel',
    method: 'post',
    data,
    responseType: 'blob',
    saveAs: false, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @description 采购调价函明细删除
 */
export function removeChangePriceDetail(id) {
  return service['api-scm']({
    url: `/scm/purchase/changePriceBill/removeChangePriceDetail/${id}`,
    method: 'delete',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
