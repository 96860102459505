// import * as auditHandleApi from '@/service/api-activiti/process/todo/handle'
import { isPlainObject, isString, validURL } from '@/utils/validate'
import { MAPPER } from './config'
import { downloadViaFileUrl, downloadViaFileStream } from '@/utils/files'

export default {
  /**
   * 下拉按钮操作
   */
  name: 'dorpdownHandlerMixin',
  methods: {
    /**
     * 工作流程
     * @see workProcess.js
     */
    /**
     * 启用禁用
     * 仅限通用情况下
     * TODO: 改造
     */
    async enableDisabledFunc(status) {
      const operation = status ? '禁用' : '启用'
      this.loading(false)
      const isConfirm = await this.$confirm(`是否确定${operation}?`, '提示', {
        type: 'warning'
      })

      if (!isConfirm) {
        return {
          success: false,
          result: null,
          feedback: 'quiet',
          message: '审核: 弹窗二次确认取消',
          description: 'complete process: cancel by user'
        }
      }
      this.loading(true)
      try {
        const {
          id,
          apiList: { enableDisable }
        } = this.scope

        if (typeof enableDisable !== 'function') {
          return {
            success: false,
            result: null,
            feedback: 'console',
            message: `${operation}失败`,
            description: 'losed "enableDisable" function of service'
          }
        }

        const result = await enableDisable({
          id,
          status
        })
        const { code, data, msg, description } = result
        if (code === 200) {
          return {
            success: true,
            result: data,
            message: `${operation}成功`,
            description: 'enableDisable success'
          }
        } else {
          return {
            success: false,
            result,
            feedback: 'console',
            message: `${operation}失败`,
            description: `enableDisable failed, ${msg}, ${description}`
          }
        }
      } catch (error) {
        return {
          success: false,
          result: null,
          feedback: 'toast',
          message: `${operation}失败`,
          description: 'enableDisable error cathed'
        }
      }
    },

    // 启用
    async enable({ config, evtName, methodName, button, parent }) {
      console.log(methodName)
      return await this.enableDisabledFunc(0)
    },

    // 禁用
    async disable({ config, evtName, methodName, button, parent }) {
      console.log(methodName)
      return await this.enableDisabledFunc(1)
    },
    async turnOffReverseCloseFunc({
      operation,
      config,
      evtName,
      methodName,
      button,
      parent
    }) {
      const selections = this.getSelections()
      if (this.currentPageType === 'list') {
        if (!selections.length) {
          return void this.$message.error('请先选择要操作的项')
        }

        if (selections.length > 1) {
          return void this.$message.error('不可批量操作')
        }
      }

      this.loading(false)
      const pmName = this.$_permission_name(button?.pmName)
      const operationName = pmName && pmName !== 'N/A' ? pmName : ''
      const isConfirm = await this.$confirm(
        `是否确定${operationName}?`,
        '提示',
        {
          type: 'warning'
        }
      )

      if (!isConfirm) {
        return {
          success: false,
          result: null,
          feedback: 'quiet',
          message: `${operationName}: 弹窗二次确认取消`,
          description: 'complete process: cancel by user'
        }
      }
      this.loading(true)
      try {
        const {
          id: detail_id = null,
          apiList: { turnOffFin, reverseCloseFin }
        } = this.scope || {}
        const list_id = selections[0]?.id ?? null

        let apiFunc = null
        if (operation === 'off') {
          if (typeof turnOffFin !== 'function') {
            return {
              success: false,
              result: null,
              feedback: 'console',
              message: `${operationName}失败`,
              description: 'losed "turnOffFin" function of service'
            }
          }
          apiFunc = turnOffFin
        } else if (operation === 'on') {
          if (typeof reverseCloseFin !== 'function') {
            return {
              success: false,
              result: null,
              feedback: 'console',
              message: `${operationName}失败`,
              description: 'losed "reverseCloseFin" function of service'
            }
          }
          apiFunc = reverseCloseFin
        } else {
          return {
            success: false,
            result: null,
            feedback: 'console',
            message: `${operationName}失败`,
            description: `illegal "operation" arg:"${operation}" of "turnOffReverseCloseFunc"`
          }
        }

        const result = await apiFunc(
          this.currentPageType === 'list' ? list_id : detail_id
        )

        const { code, data, msg, description } = result || {}
        if (code === 200) {
          return {
            success: true,
            result: data,
            message: `${operationName}成功`,
            description: `${methodName} success`
          }
        } else {
          return {
            success: false,
            result,
            feedback: 'console',
            message: `${operationName}失败`,
            description: `${methodName} failed, ${msg}, ${description}`
          }
        }
      } catch (error) {
        return {
          success: false,
          result: null,
          feedback: 'toast',
          message: `${operation}失败`,
          description: `${methodName} error cathed`
        }
      }
    },

    // 关闭 付款申请单/收款单
    async turnOffFin({ config, evtName, methodName, button, parent }) {
      return await this.turnOffReverseCloseFunc({
        operation: 'off',
        config,
        evtName,
        methodName,
        button,
        parent
      })
    },

    // 反关闭 付款申请单/收款单
    async reverseCloseFin({ config, evtName, methodName, button, parent }) {
      return await this.turnOffReverseCloseFunc({
        operation: 'on',
        config,
        evtName,
        methodName,
        button,
        parent
      })
    },
    // 导入
    importData({ config, evtName, methodName, button, parent }) {
      console.log(methodName, this.scope)
      this.$emit('showImportData', button?.config || {}, parent, config)
      return {
        feedback: 'console',
        message: '"showImportData" event had emit',
        description:
          'please listen in your page, like <button-group @showImportData="showImportData" ...'
      }
    },
    // 导出/下载
    async downloadFile({ config, evtName, methodName, button, parent }) {
      try {
        const _config = { ...config, ...(button?.config || {}) }
        // TODO: file stream, 根据用户选择目录后下载文件流保存到目标路径
        // if (_config?.showDirPicker === true) {
        // 弹窗引导用户选择保存目录
        // const dirPicker = await window.showSaveFilePicker()
        // console.log(dirPicker)
        // }

        const { success, result, /*  feedback, */ message, description } =
          await this.commonRequest({
            config,
            evtName,
            methodName,
            button,
            parent
          })
        if (success !== true) {
          return {
            success,
            result,
            feedback:
              result === 'cancel' || message.includes('api')
                ? 'console'
                : 'toast',
            message:
              result === 'cancel'
                ? '取消下载'
                : message.includes('api')
                ? '暂未对接'
                : '下载失败',
            description
          }
        }
        if (_config?.type === 'url') {
          if (validURL(result?.data?.url)) {
            downloadViaFileUrl(result.data.url)
          }
        } else {
          if (_config.taskCenterHandle === true) {
            return {
              success: true,
              result: null,
              feedback: 'quiet',
              message: '已经发送到任务中心下载',
              description: 'download file request send to task center already'
            }
          }

          if (result?.config?.saveAs === true && result?.status === 200) {
            return result.data instanceof Blob
              ? {
                  success: true,
                  result: null,
                  feedback: 'toast',
                  message: '下载成功',
                  description:
                    'download file stream success (by "interceptors" of axios)'
                }
              : {
                  success: false,
                  result: null,
                  feedback: 'toast',
                  message: '下载失败',
                  description:
                    'download file stream error (by "interceptors" of axios)'
                }
          } else {
            return (await downloadViaFileStream(
              result?._response,
              button?.config?.title
            )) === true
              ? {
                  success: true,
                  result: null,
                  feedback: 'toast',
                  message: '下载成功',
                  description:
                    'download file stream success (by "downloadFile" of buttonGroup)'
                }
              : {
                  success: false,
                  result: null,
                  feedback: 'toast',
                  message: '下载失败',
                  description:
                    'download file stream error (by "downloadFile" of buttonGroup)'
                }
          }
        }
      } catch (error) {
        console.error(error)
        return {
          success: false,
          result: error,
          feedback: 'toast',
          message: `下载异常`,
          description: 'downloadFile error catched'
        }
      } finally {
        this.loading(false)
      }
    },
    /**
     * 结账
     */
    async checkout({ config, evtName, methodName, button }) {
      const selections = this.getSelections()
      if (!selections.length) {
        return {
          success: false,
          result: null,
          feedback: 'toast',
          message: `请先选择要${evtName}的项`,
          description: ''
        }
      }
      const filterQueryForm = this.scope.filterQueryForm.data
      if ('checkoutTime' in filterQueryForm) {
        if (!this.scope.filterQueryForm.data?.checkoutTime) {
          return void this.$message.error('请选择关账日期')
        }
      }
      const finReceiptCheckoutListDTO = {
        finReceiptCheckoutDTOList: selections
      }
      const inventoryCheckoutListDTO = {
        inventoryCheckoutDTOS: selections
      }
      try {
        const isConfirm = await this.$confirm(`是否确定${evtName}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (!isConfirm) {
          return {
            success: false,
            result: null,
            feedback: 'quiet',
            message: `${evtName}: 弹窗二次确认取消`,
            description: 'complete process: cancel by user'
          }
        }
        const result = (await !filterQueryForm.checkoutTime)
          ? await this.scope.apiList.checkout(finReceiptCheckoutListDTO)
          : Array.isArray(inventoryCheckoutListDTO.inventoryCheckoutDTOS)
          ? await this.scope.apiList.checkout({
              inventoryCheckoutDTOS:
                inventoryCheckoutListDTO.inventoryCheckoutDTOS.map((v) => ({
                  checkoutTime: filterQueryForm.checkoutTime,
                  ...v
                }))
            })
          : []
        const { code, data, msg, description } = result
        if (code === 200) {
          if (this.scope.filterQueryForm.data.checkoutTime) {
            this.scope.filterQueryForm.data.checkoutTime = null
          }
          this.scope.$_list_crud_table_refresh({ selectionClear: true })
          return {
            success: true,
            result: data,
            message: `${evtName}成功`,
            description: 'enableDisable success'
          }
        } else {
          return {
            success: false,
            result,
            feedback: 'console',
            message: `${evtName}失败`,
            description: `enableDisable failed, ${msg}, ${description}`
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    /**
     *
     * 反结账
     */
    async reverseCheckout({ config, evtName, methodName, button }) {
      const selections = this.getSelections()
      if (!selections.length) {
        return {
          success: false,
          result: null,
          feedback: 'toast',
          message: `请先选择要${evtName}的项`,
          description: ''
        }
      }
      const finReceiptCheckoutListDTO = {
        finReceiptCheckoutDTOList: selections
      }
      const inventoryCheckoutListDTO = {
        inventoryCheckoutDTOS: selections
      }
      try {
        const isConfirm = await this.$confirm(`是否确定${evtName}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (!isConfirm) return
        const result = await this.scope.apiList.reverseCheckout(
          button.pmName.includes('inventoryCheckout')
            ? inventoryCheckoutListDTO
            : finReceiptCheckoutListDTO
        )
        const { code, data, msg, description } = result
        if (code === 200) {
          this.scope.$_list_crud_table_refresh({ selectionClear: true })
          return {
            success: true,
            result: data,
            message: `${evtName}成功`,
            description: 'enableDisable success'
          }
        } else {
          return {
            success: false,
            result,
            feedback: 'console',
            message: `${evtName}失败`,
            description: `enableDisable failed, ${msg}, ${description}`
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    async close() {
      const orderCodeList = [this.scope.detail_cru_info?.orderCode]
      try {
        const isConfirm = await this.$confirm('是否确定整单关闭?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (!isConfirm) return
        console.log('orderCodeList', orderCodeList)
        const { code } = await this.scope.apiList.close(orderCodeList[0])
        if (code === 200) {
          this.currentPageType === 'list'
            ? this.scope.$_list_crud_table_refresh({ selectionClear: true })
            : this.scope.queryDetailById(this.scope.id)
        }
        return {
          success: code === 200,
          result: null,
          feedback: 'toast',
          message: `整单关闭${code === 200 ? '成功' : '失败'}`
        }
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * 下拉子按钮点击事件
     */
    async hanldeClickDropDown({ subItem, parentItem }) {
      if (!isPlainObject(parentItem)) {
        console.groupCollapsed(`【buttonGroup】`)
        console.error(
          `
错误类型: 下拉父按钮数据类型错误
来源:     ${window?.location?.pathname},
URL:     ${window?.location?.href}`,
          parentItem
        )
        return void console.groupEnd()
      }

      const parnetName =
        parentItem.evtName && isString(parentItem.evtName)
          ? parentItem.evtName
          : parentItem.label

      if (!isPlainObject(subItem)) {
        console.group(`【buttonGroup】${parnetName}`)
        console.error(
          `
父按钮:   ${parnetName}
错误类型: 子按钮数据类型错误
来源:     ${window?.location?.pathname}
URL:     ${window?.location?.href}`,
          subItem
        )
        return void console.groupEnd()
      }
      const evtName =
        subItem.evtName && isString(subItem.evtName)
          ? subItem.evtName
          : subItem.label

      console.group(`【buttonGroup】${parnetName} > ${evtName}`)
      console.log('parent button:', parentItem)
      console.log('sub button:', subItem)

      this.$emit('click', { parentItem, subItem }) // 外部的 @click 事件永远出发

      if (!evtName || !isString(evtName)) {
        console.warn(`
警告: 未提供 "evtName" 或者 "label"
备注: 可以 <button-group @click=... 监听处理`)
        return void console.groupEnd()
      } else {
        console.info(`
信息: 已经发射了 click 事件到外部
备注: 可以 <button-group @click=... 监听处理`)
      }

      if (typeof subItem?.on?.click === 'function') {
        subItem.on.click.call(this.eventContext, subItem)
        return void console.groupEnd()
      }

      const config = MAPPER[evtName] || {}
      const { methodName = '' } = config

      if (
        isString(methodName) &&
        methodName &&
        typeof this[methodName] === 'function'
      ) {
        this.loading(true)
        const handlerArgs = {
          config,
          evtName,
          methodName,
          button: subItem,
          parent: parentItem
        }
        const handlerResult = await this[methodName](handlerArgs)
        if (!handlerResult) {
          console.info(`
信息:   已执行但没有返回结果
备注:   弹窗二次确认点击了取消`)
          // loading end...
          this.loading(false)
          return void console.groupEnd()
        }
        const {
          res = null,
          success = false,
          result = null,
          feedback = 'console',
          message = '',
          description = ''
        } = handlerResult

        const callbackArgs = {
          res,
          config,
          evtName,
          methodName,
          result,
          feedback,
          message,
          description,
          button: subItem,
          parent: parentItem
        }

        success
          ? typeof subItem?.callback?.success === 'function'
            ? subItem.callback.success(callbackArgs)
            : this.handleSuccess(callbackArgs)
          : typeof subItem?.callback?.fail === 'function'
          ? subItem.callback.fail(callbackArgs)
          : this.handleFail(callbackArgs)
        // loading end...
        this.loading(false)
        // @success or @fail 事件永远出发， 与 handleSuccess 、handleFail 叠加
        const emitCallbackName = success ? 'success' : 'fail'
        this.$emit(emitCallbackName, {
          ...callbackArgs,
          ...subItem,
          ...parentItem
        })
        console.info(`
信息:   已发射了 ${emitCallbackName} 回调事件到外部,
备注:   可以通过 <button-group @${emitCallbackName}=...  监听处理`)
      } else {
        console.error(`
父按钮:   ${parnetName}
子按钮:   ${evtName}
方法:     ${methodName || 'N/A'}
错误类型: 内部未配置按钮点击处理器
来源:     ${window?.location?.pathname}
URL:     ${window?.location?.href}
备注:    如果外部配置了事件或者监听事件并处理，请忽略此错误`)
        // this.$emit('fail', {
        //   evtName,
        //   methodName,
        //   parent: parentItem,
        //   button: subItem,
        //   result: null,
        //   description: '未配置点击事件处理器 (下拉)',
        //   ...subItem,
        //   ...parentItem
        // })
      }
      console.groupEnd()
    }
  }
}
