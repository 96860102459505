/**
 * @module utils/base64
 */

/**
 * @param {string} str - string
 * @returns {string} base64 编码字符串
 */
export function str_to_base64(str) {
  try {
    return window.btoa(str)
  } catch (error) {
    console.error(error)
  }
  return str
}

/**
 * @param {string} base64
 * @returns {string} base64 解码字符串
 */
export function base64_to_str(base64) {
  try {
    return window.atob(base64)
  } catch (error) {
    console.error(error)
  }
  return base64
}
