<template>
  <div>
    <el-input
      v-model="proxyNumeric"
      :disabled="disabled"
      v-bind="$attrs"
      @input="digitalCorrection"
      @change="onChange"
    >
      <template :slot="slotType">
        {{ slotData }}
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'VerificationInputByNumber',
  props: {
    value: {
      type: [Number, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    slotType: {
      type: String,
      default: ''
    },
    // 复合型输入框内容
    slotData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    // demoState:{
    //   ...mapGetters({get:'xxx'}),
    //   ...mapMutation({set:'xxxx'})
    // },
    proxyNumeric: {
      get() {
        return this.value
        // return this.$store.state.xxx
      },
      set(v) {
        this.$emit('input', v)
        // this.$store.commit('xxx',x)
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    /**
     * @description 数字校正
     * @param { string }
     */
    digitalCorrection(val) {
      // 输入长度>=2时，记录最后两位字符串
      const contrastVal = val.length > 1 ? val.substring(val.length - 2) : val
      // 关于 . 的计数,只能输入一个点
      const countArr = val.split('').filter((item) => item === '.')
      // 允许首位输入 '-' ，当首位输入项有更多其他字符的选择时，将这些字符存放在数组中，再进行对比
      if (val.length === 1 && val === '-') {
        this.proxyNumeric = val
        return
      }

      if (val === '') {
        this.proxyNumeric = ''
      } else if (isNaN(contrastVal) || countArr.length === 2) {
        this.proxyNumeric = val.substring(0, val.length - 1)
      }
    },
    onChange(e) {
      this.$emit('change', e)
    }
  }
}
</script>

<style scoped lang="scss"></style>
