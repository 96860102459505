import JSEncrypt from 'jsencrypt'
import { chunkString } from '@/utils/string'
import { KEY_SIZES, DEFAULT_KEY_SIZE } from './conf'

/**
 * @param {(number|null)} [size=null] - the size for the private key in bits. Default: 2048.
 * @returns {Promise<{publicKey:string,privateKey:string}>}
 */
export const generateKeyPair = (size = null) => {
  return new Promise((resolve) => {
    try {
      const rsa = new JSEncrypt({
        default_key_size:
          size && KEY_SIZES.includes(size) ? size : DEFAULT_KEY_SIZE
      })
      resolve({
        publicKey: rsa
          .getPublicKey()
          .replace('-----BEGIN PUBLIC KEY-----', '')
          .replace('-----END PUBLIC KEY-----', '')
          .replace(/(\r\n|\n|\r)/gm, ''),
        privateKey: rsa
          .getPrivateKey()
          .replace('-----BEGIN RSA PRIVATE KEY-----', '')
          .replace('-----END RSA PRIVATE KEY-----', '')
          .replace(/(\r\n|\n|\r)/gm, '')
      })
    } catch (error) {
      console.error(error)
      resolve({ publicKey: '', privateKey: '' })
    }
  })
}

/**
 * @param {string} key
 * @return {Array<string>}
 */
export const splitKeyPair = (key) => {
  try {
    const splited = chunkString(key, Math.round(key.length / 5))
    return [splited.shift(), splited.join('')]
  } catch (error) {
    console.error(error)
    return [key, '']
  }
}
export default {
  generateKeyPair,
  splitKeyPair
}
