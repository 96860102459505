/**
 * @module plugins:vxe-table:custom-renderers
 */
import Vue from 'vue'

import 'xe-utils'
import VXETable from 'vxe-table'
import '@/styles/vxe-table-variables.scss'
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'
/**
 * 1. 自定义渲染器 @see https://xuliangzhan_admin.gitee.io/vxe-table/#/table/renderer/edit
 */
// normal
// import 'xxx'
// import 'xxx'

// 基于 vxe-*
import './custom-renderers/VxeTableSelector'
import './custom-renderers/VxeEditButton'
import './custom-renderers/VxeToolTipInput'

// 基于 el-element
import './custom-renderers/ElFileUploader'
import './custom-renderers/ElEditSpan'
import './custom-renderers/ElPicker'
import './custom-renderers/ElRemotePicker'
import './custom-renderers/ElRemotePickerCustom'
import './custom-renderers/ElAutoCompletor'
import './custom-renderers/ElInputFigure'

// filter renderer
import './custom-renderers/FilterContent'

import './formatter'

/**
 * 2. 事件拦截器 @see https://xuliangzhan_admin.gitee.io/vxe-table/#/table/interceptor/api
 */
// import './event-interceptors'

VXETable.use(VXETablePluginElement)
Vue.use(VXETable)
