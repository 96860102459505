<template>
  <section :class="['app-main']">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="routerVeiwKey" />
    </transition>
    <!-- </template> -->
  </section>
</template>

<script>
// import { isDetailPage } from '@/utils/page-type'
// import { destroyKeepAliveVm } from '@/utils/virtual-dom'
export default {
  name: 'AppMain',
  props: {},
  data() {
    return {}
  },
  computed: {
    routerVeiwKey() {
      const { /* name,  */ path, fullPath } = this.$route
      // return name && isDetailPage(name) ? fullPath || path : path
      return fullPath || path
    },
    isNestedNav() {
      try {
        const {
          $route: {
            fullPath, // eslint-disable-line no-unused-vars
            meta: { isNestedNav }
          }
        } = this
        return typeof isNestedNav === 'boolean' ? isNestedNav : false
      } catch (error) {
        console.error(error)
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
@import '@/styles/utils.scss';
/* app-main 's min-height */
@mixin appMainMinHeight($total: 100vh, $decrement: 0px) {
  min-height: calc(#{$total} - #{$decrement});
}

.app-main {
  @include appMainMinHeight($decrement: $navBarHeight);
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fixed-header + .app-main {
  padding-top: $navBarHeight;
}
.hasTagsView {
  .app-main {
    @include appMainMinHeight(
      $decrement: sumVars($navBarHeight, $tagBarHeight)
    );
  }
  .fixed-header + .app-main {
    padding-top: sumVars($navBarHeight, $tagBarHeight);
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
