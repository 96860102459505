/**
 * @module api-scm:estimationReceivable
 */
import service from '@/service'

/**
 * 查询 财务-暂估应收手工核销记录查询列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ar/manualWriteOffRecord/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  反核销（取消手工标记）
 * @param {object} data
 */

export function reverseWriteOff(data) {
  return service['api-web']({
    url: '/fin/ar/manualWriteOffRecord/cancelVerify',
    method: 'post',
    data
  })
}

/**
 *  树形下拉
 * @param {{id}}
 */
// export function getListDown(id) {
//   return service['api-web']({
//     url: '/fin/ar/manualWriteOffRecord/getListDown',
//     method: 'get',
//     params: { id },
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }
