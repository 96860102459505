/**
 * @module api-web:financialAffairs/generalLedger/reportCenter/subjectBalance
 */
import service from '@/service'

/**
 * 财务-总账-报表中心-科目余额表->科目余额表过滤
 * @param {object} data
 */
export function getSubjectBalanceList(data) {
  return service['api-web']({
    url: '/fin/gl/report/finSubjectBalance/generateSubjectBalance',
    method: 'post',
    data
  })
}

/**
 * 财务-总账-报表中心-科目余额表->明细分类帐(详情页面)
 * @param {object} params
 */
export function getSubjectBalanceInfo(params) {
  return service['api-web']({
    url: '/fin/gl/report/finSubjectBalance/getSubjectBalanceInfo',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 财务-总账-报表中心-科目余额表->导出
 * @param {object} data
 */
export function exportData(data) {
  return service['api-web']({
    url: '/fin/gl/report/finSubjectBalance/exportData',
    method: 'post',
    data
  })
}

/**
 * 财务-总账-报表中心-科目余额表->根据级别查询科目下拉框（123级 不传返回所有科目）
 * @param {Number} level
 */
export function getAccountSubjectByLevel(level) {
  return service['api-web']({
    url: '/public/fin/getAccountSubjectByLevel',
    method: 'get',
    params: { level }
  })
}
