/**
 * @module api-web:financialAffairs/accountReceivable/receipt
 * @description 财务管理/应收/收款单
 */
import service from '@/service'

/**
 * 查询 财务-收款单列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{id}}
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/get/getListDown',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 查询财务收款单详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/finReceiptOrder/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 创建 财务-收款单新增
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/save',
    method: 'post',
    data
  })
}

/**
 * 收款单-提交审核
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 财务-收款单修改
 * @param {Department} data
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/update',
    method: 'put',
    data
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    url: '/public/activiti/finReceiptOrder/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 财务-收款单删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object} data -id
 * @description 财务-收款单下推红字收款单
 */
export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/pushDown',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}}
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 待办修改
 * @param {Department} data
 */
export function todoUpdate(data) {
  return service['api-web']({
    url: '/public/activiti/finReceiptOrder/update',
    method: 'put',
    data
  })
}

/**
 * @description 手动对接
 */
export function manualDocking(data) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrder/manualDocking',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 获取结算组织下对应的银行账号
 * @param { companyId } 组织Id
 */
export function getCompanyInfo(params) {
  return service['api-web']({
    url: '/public/data/getCompanyInfoFinance',
    method: 'get',
    params
  })
}
