/**
 * @module api-web:financialAffairs/accountReceivable/finReceiptOrderBack
 * @description 财务管理/应收/收款退款单
 */
import service from '@/service'

/**
 * 财务-收款退款单-列表
 * @param {{
 * businessTimeEnd:string,
 * businessTimeStart:string,
 * cancellationType:number,
 * dealingClientList:array,
 * dealingClientType:number,
 * documentStatusList:array,
 * receiptOrderBackCodeList: array,
 * remark:string
 * }} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 财务-收款退款单-删除
 * @param {{ ids:Array<number> }} params
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 财务-收款退款单-审核完成
 * @param {{ id:string,state:number }} params
 */
export function completedAudit(data) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/completedAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 财务-收款退款单-详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 财务-收款退款单-查询列表下拉
 * @param {{ ids:number }} params
 */
export function getListDown(data) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/public/getListDown',
    method: 'get',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 财务-收款退款单-新增
 */
export function save(data) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/save',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}
/**
 * 财务-收款退款单-提交审核
 * @param {{ ids:string }} params
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 财务-收款退款单-修改
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/update',
    method: 'put',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}
/**
 * 财务-收款退款单-撤销申请
 * @param {{ ids:string }} params
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(number|string)} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/fin/ar/finReceiptOrderBack/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
