/**
 * @module BaseInlineEditTable/consts
 * 内联编辑表格 〉常量
 */
/**
 * @const {Array<string>}
 */
export const BORDER_TYPES = [
  'full',
  'outer',
  'inner',
  'default',
  'none',
  true /** full */,
  false /** default */
]
/**
 * @const {Array<string>}
 */
export const CRUD = [
  'create', // '增'
  'delete', // '删'
  'update', // '改'
  'read', // '查'
  'multi-delete', // '删'(批量)
  'multi-del', // '删'(批量)
  'c', // alias, '增'
  'd', // alias, '删'
  'u', // alias, '改'
  'r', // alias, '查'
  'md' // alias, '删'(批量)
]
/**
 * @type {object}
 * @description 默认 flag 标识
 */
export const DEFAULT_FLAGS = Object.freeze({
  /**
   * @type {boolean}
   * @description 表格是否"污染"过 flag
   * 初始化从来没有激活过编辑状态的视为"纯净没有污染过"
   */
  isDirty: false,
  /**
   * @type {(''|'insert'|'save'|'edit'|'cancel'|'delete'|'append')=}
   * @description edit close by buttonClicked
   */
  buttonClicked: '',
  /**
   * @type {boolean}
   * @description  鼠标是否在表格单元格内
   */
  mouseOnTableCell: false,
  /**
   * @type {boolean}
   * @description 表格是否为编辑状态 flag
   */
  tableEditing: false,
  /**
   * @type {boolean}
   * @description 是否跳过校验 flag
   */
  skipValidation: false,
  /**
   * @type {boolean}
   * @description 校验是否未通过 flag
   */
  validError: false,
  /**
   * @type {boolean}
   * @description 阻止新增并发
   */
  emptyRowRemoving: false, // 正在自动移除空行
  /**
   * @type {boolean}
   * @description 阻止新增并发
   */
  illegalRowRemoving: false, // 正在自动移除非法行
  /**
   * tree is lazy loading
   * @type {boolean}
   * @description 阻止懒加载添加并发
   */
  treeExpanding: false,
  /**
   * @type {boolean}
   * @description 是否 有 Querymaybe  错误 by crudHandler.read
   */
  readError: false,
  /**
   * @type {boolean}
   * @description 是否 有 Create 错maybe 误 by crudHandler.create
   */
  createSaveError: false,
  /**
   * @type {boolean}
   * @description 是否 有 Update 错maybe 误 by crudHandler.update
   */
  updateSaveError: false,
  /**
   * @type {boolean}
   * @description 是否 有 Delete 错maybe 误 by crudHandler.delete
   */
  deleteError: false,
  /**
   * @type {boolean}
   * @description 是否 有 MultiDel 错误 maybe by crudHandler.multiDel
   */
  multiDelError: false
})

/**
 * @type {object}
 * @description default configs for crudHandler
 */
export const DEFAULT_CRUD_HANDLER_CONF = Object.freeze({
  useApi: true
})
