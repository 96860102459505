/**
 * @module api-scm:production/ProductionOrder
 */
import service from '@/service'

/**
 * 列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * date:Array<string>,
 * productCode:string,
 * productName:string,
 * saleOrder:string
 * }} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/production/productionOrder/get/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/production/productionOrder/save',
    method: 'post',
    data
  })
}

/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/production/productionOrder/info',
    method: 'get',
    params: { id }
  })
}

/**
 *修改
 * @param {object} data
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/production/productionOrder/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(purchaseCodes) {
  return service['api-scm']({
    url: '/scm/production/productionOrder/delete',
    method: 'delete',
    params: { purchaseCodes },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出生产单
 */
export function exportData(params) {
  return service['api-scm']({
    url: '/scm/production/productionOrder/dataExport',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/production/productionOrder/cancellation',
    method: 'delete',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *生产单手动对接金蝶
 */
export function manualDockingOfKingDee(data) {
  return service['api-scm']({
    url: '/scm/production/productionOrder/manualDockingOfKingDee',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
