/**
 * @module mixins:permission
 * @description 权限控制混入
 */

import store from '@/store'
import { isArray, isString } from '@/utils/validate'
export const resourcePermissionMixin = {
  name: 'PermissionMixin',
  methods: {
    /**
     * 鉴权
     *
     * @param {Array<(string|number)>} resources - resource {id|name} list
     * @param {('every'|'some')} type - default every
     * @param {('id'|'fullname'|'name')} by - default name
     * @returns {boolean} 是否拥有权限
     * @description
     *
     * 须传入资源 `id` 或者 `name` 的数组，支持多资源同时控制权限
     * 如果需要根据 资源 `id`， 需要额外传入 `by` 参数，如 `v-if="$_permission([1],{by:id})"`， `by` 不传默认为 `name`
     *
     * 如果需要多个资源联合控制 UI 层的按钮等元素，可以传参数 `type`,  `some` 或 `every` 来定义 `和` 、`或`，
     * 如 `$_permission(['getList','save'], { type: 'some' })`，`type` 不传则默认为 `every`
     * - `every`: 需拥有所有传入的资源权限
     * - `some`:  拥有其中一个资源权限即可
     *
     * @useage
     *
     * ```html
     *  <div v-if="$_permission(['delete'])"></div>
     * ```
     * ```js
     *  if(this.$_permission(['getList','save'], { type:'some' })){
     *  //...
     *  }
     *  if(this.$_permission(['1','2'], { by: 'id' })){
     *  //...
     *  }
     * ```
     */
    $_permission(resources, option = { type: 'every', by: 'name' }) {
      try {
        const { type = 'every', by = 'name' } = option
        if (typeof type !== 'string' || !['some', 'every'].includes(type)) {
          console.error(
            'Please check "type" argument, only support "every" or "some"'
          )
          return false
        }
        if (
          typeof by !== 'string' ||
          !['id', 'name', 'fullname'].includes(by)
        ) {
          console.error(
            'Please check "by" argument, only support "id" 、"name" and "fullname"',
            resources
          )
          return false
        }
        if (isArray(resources) && resources.length) {
          const resourcePool =
            by === 'id'
              ? Object.keys(store.getters.pmIdx).map((v) => +v)
              : Object.keys(store.getters.pmNamex)

          if (isArray(resourcePool) && resourcePool.length) {
            const checkingResources =
              by === 'name'
                ? resources.map(
                    (resource) => `${this.$route.meta.namespace}.${resource}`
                  )
                : resources
            // console.log('checkingResources', checkingResources)
            const hasPermission =
              type === 'some'
                ? checkingResources.some((resource) =>
                    resourcePool.includes(resource)
                  )
                : checkingResources.every((resource) =>
                    resourcePool.includes(resource)
                  )
            // console.groupCollapsed('当前鉴权信息(mixin):')
            // console.groupCollapsed('current resource pool')
            // console.log(resourcePool)
            // console.groupEnd()
            // console.log('hasPermission:', hasPermission)
            // console.log('checkingResources:', checkingResources)
            // console.groupEnd()
            return hasPermission
          } else {
            console.warn('Empty "resourceSet" [mixins]', resources)
          }
        } else {
          console.error('Permission Control Error [mixins]: ', resources)
        }
      } catch (error) {
        console.error(error)
      }
      return false
    },
    /**
     * 根据 Name 从 资源Name索引 中获取资源名
     * @param {string} resourceNa dme - 资源 name  ( like 'getList' , 'sys.permission.getLis', pmUrl field from db, )
     * @return {(string|'N/A')}  资源 label 或者 'not available'
     */
    $_permission_name(resourceName) {
      try {
        if (!isString(resourceName)) {
          console.error('Illegal "resourceName"', resourceName)
          return 'N/A'
        }

        const resourceNameIndex = store.getters && store.getters.pmNamex
        // 根据传入的 resourceName 是否是 "对象链式调用字符串（xxx.xxx.xx）" 来判断是否为全名
        const isFullName = resourceName.split('.').length > 1
        const resource =
          resourceNameIndex[
            isFullName
              ? resourceName
              : `${this.$route.meta.namespace}.${resourceName}`
          ]
        return resource ? resource['label'] : 'N/A'
      } catch (error) {
        console.error(error)
      }
      return 'N/A'
    }
  }
}
export default resourcePermissionMixin
