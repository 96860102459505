/**
 * @module api-web:financialAffairs/generalLedger/data/accountingDimension
 */
import service from '@/service'

/**
 * 查询 财务-总账-资料-核算维度
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/data/finAccountDimension/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *  列表树形下拉
 * @param {{id}}
 */
// export function getListDown(id) {
//   return service['api-web']({
//     url: '/fin/gl/data/finAccountDimension/getListDown',
//     method: 'get',
//     params: { id },
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }
