/**
 * @module api-web:financialAffairs/accountReceivable/financeReceivable
 * @description 财务管理/应收/财务应收管理
 */
import service from '@/service'

/**
 * 新增
 * @param {object} data
 */
export function save(data) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/save',
    method: 'post',
    data
  })
}

/**
 * 查询 财务-应收单列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{id}}
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/public/getListDown',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/public/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 查询财务收款单详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/finReceivableBill/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 修改
 * @param {Department} data
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/update',
    method: 'put',
    data
  })
}

/**
 * 提交审核
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 应收单明细搜索
 * @param {number}
 * id
 * productCodeOrSaleCode
 */
export function getSearchDetail(id, productCodeOrSaleCode) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/public/getSearchDetail',
    method: 'get',
    params: { id, productCodeOrSaleCode }
  })
}
/**
 * @param {{ids:Array<number>}} params - ids
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  作废
 * @param {object} data
 */

export function invalid(id) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/public/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{number:id}} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/public/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 待办修改
 * @param {Department} data
 */
export function todoUpdate(data) {
  return service['api-web']({
    url: '/public/activiti/finReceivableBill/update',
    method: 'put',
    data
  })
}

/**
 * @param {object}
 * @description 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: '/fin/ar/finReceivableBill/reject'
    url: '/public/activiti/finReceivableBill/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}}
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 财务应收单获取成本信息
 */
export function getCostInformation(ids) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/getCostInformation',
    method: 'get',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 财务应收单导入（手动导入时使用）
 */
export function importEstimationReceivablesData(data) {
  return service['api-web']({
    url: 'fin/ar/finReceivableBill/importFinReceivableBill',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 财务应收单导出
 */
export function finReceivableBillExport(params) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/export',
    method: 'get',
    params,
    responseType: 'blob',
    saveAs: true,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 手动对接
 */
export function manualDocking(data) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/manualDocking',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 客户信息
 * @param {{name:string,flag:(0|1)}}
 */
export function getClientPull(params) {
  return service['api-web']({
    url: '/public/data/getClientPull',
    method: 'get',
    params
  })
}
