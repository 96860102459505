/**
 * @module api-scm:saleOrder
 */
import service from '@/service'

/**
 * 查询 财务-销售订单列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ar/finSaleOrder/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{orderCode,id}}
 */
export function getListDown(id, orderCode) {
  return service['api-web']({
    url: '/fin/ar/finSaleOrder/public/getListDown',
    method: 'get',
    params: { id, orderCode },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ar/finSaleOrder/public/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  对账
 * @param {string} ids
 */

export function reconciliation(ids) {
  return service['api-web']({
    url: '/fin/ar/finSaleOrder/reconciliation',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: { ids }
  })
}
/**
 * @param {string} orderCodeList
 * @description 关闭
 */
export function close(orderCodeList) {
  return service['api-web']({
    url: '/fin/ar/finSaleOrder/wholeOrderClose',
    method: 'post',
    data: { orderCodeList },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function turnOffFin(idList) {
  return service['api-web']({
    url: '/fin/ar/finSaleOrder/orderClose',
    method: 'post',
    data: { idList },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function reverseCloseFin(idList) {
  return service['api-web']({
    url: '/fin/ar/finSaleOrder/reverseOrderClose',
    method: 'post',
    data: { idList },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 导出
 */
export function exportData(data) {
  return service['api-web']({
    url: '/fin/ar/finSaleOrder/export',
    method: 'post',
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true // 文件流是否通过浏览器下载
  })
}
