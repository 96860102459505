/**
 * @module api-scm:production/productionInventoryOutbound
 */
import service from '@/service'

/**
 * 采购入库   列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * date:Array<string>,
 * sort:string,
 * productCode:string,
 * productName:string,
 * productionOrder:string
 * }} params
 */

export function getList(params) {
  return service['api-scm']({
    url: '/scm/production/productionInventoryOutbound/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/production/productionInventoryOutbound/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
