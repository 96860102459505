/**
 * @module api-web:filter-opts/sys
 * @description common apis : dropdown select options - system
 */
import service from '@/service'
/**
 * @param {{keywords:string=,parentJob:number=,deptId:number=}} params
 * @description 下拉选项：职位 (根据上级职位或者部门关联查询)
 */
export function getJobOpts(params) {
  return service['api-web']({
    url: '/public/sys/getJobList',
    method: 'get',
    params
  })
}

/**
 * @param {{keywords:string=,parentDept:number=,jobId:number=}} params
 * @description 下拉选项：部门 (根据上级部门或者职位关联查询)
 */
export function getDepartmentOpts(params) {
  return service['api-web']({
    url: '/public/sys/getDeptList',
    method: 'get',
    params
  })
}

/**
 * @param {{keywords:string=,parentId:number=}} params
 * @description 下拉选项：字典
 */
export function getDictionaryOpts(params) {
  return service['api-web']({
    url: '/public/sys/getSysInfoDictionaryList',
    method: 'get',
    params
  })
}
