/**
 * @module api-web:goods/group
 * @description api for data goods group management
 */
import service from '@/service'
/**
 * 保存商品分组
 */
export function save(data) {
  return service['api-web']({
    url: '/data/product/Category/save',
    method: 'post',
    data
  })
}

/**
 * 编辑商品分组
 */
export function update(data) {
  return service['api-web']({
    url: '/data/product/Category/update',
    method: 'post',
    data
  })
}

/**
 * 删除商品分组
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/product/Category/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
