import service from '@/service'
/**
 *  流程名模糊查询：流程列表
 * @param {{keywords:string}} params
 */
export function getProcessDefinition(params) {
  return service['api-activiti']({
    url: '/public/getListDown',
    method: 'get',
    params
  })
}
