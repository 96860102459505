import Vue from 'vue'
import { DICT_ALIAS } from '@/constants'
import { getDict } from '@/utils/request'
import { isArray, isPlainObject, isString, isNumeric } from '@/utils/validate'
import poll from '@/utils/poll'
import { NETWORK_TIMEOUT } from '@/constants'
import { deweight } from '@/utils/array'

/**
 * @module store/modules/dict
 * @description 字典
 */

/**
 * @typedef {Object} DictItem 字典项
 * @property {string} id -  eg: "16"
 * @property {sting} dictName - eg: "shoppingStatus"
 * @property {number} dataSort -  eg: 1
 * @property {(string|null)} dictComment -  eg: "I'm a comment"
 * @property {string=} dictDescribe -  eg: "I'm a desc"
 * @property {(number|string)} dataValue - eg: 1
 * @property {string} tagName - eg: "I'm a label"
 * @description 这是后端返回原始字段，除了这些固定字段外，还会生成动态映射字段， 如 value, label 默认映射 dataValue, tagName
 */

/**
 * @returns {Record<string,Record<string,Array<DictItem>>}
 * @see /system/sysDict/list
 */
const state = () => ({
  /**
   * 系统类字典
   */
  sys: {},
  /**
   * 业务类字典
   */
  biz: {},
  /**
   *  不区分分类
   */
  all: {}
})
/**
 * @description 字典索引， 如遇到性能问题，可以关闭次索引功能，同时须将项目中所有通过 getters 获取字典的方式的改成 action
 *
 * @example
 * ```js
 * // getters
 * this.$store.getters['dict/sys'].{{dict name}}.value[{{label name}}] //=> value
 * this.$store.getters['dict/biz'].{{dict name}}.label[{{value}}] //=> label
 *
 * // getters map
 * import { mapGetters } from 'vuex'
 * computed: {
 * ...mapGetters({
 *    sysDict: 'dict/sys',
 *    bizDict: 'dict/biz'
 * }),
 * }
 * this.sysDict.{{dict name}}.value[{{label name}}] //=> value
 * this.bizDict.{{dict name}}.label[{{value}}] //=> label
 * ```
 */
const getters = {
  /**
   * 系统类字典索引
   */
  sys: (state) => createIndex(state.sys),
  /**
   * 业务类字典索引
   */
  biz: (state) => createIndex(state.biz),
  /**
   * 全量字典索引
   */
  all: (state) => createIndex(state.all)
}

const mutations = {
  EMPTY_DICT(state) {
    Object.keys(state).forEach((key) => {
      state[key] = {}
    })
  },
  SET_DICT(state, { category, key, value }) {
    if (Object.prototype.hasOwnProperty.call(state[category], key)) {
      state[category][key] = value
    } else Vue.set(state[category], key, value) // 响应
  }
}

/**
 * ```js
 *  // action
 * const value = await this.$store.dispatch('dict/getValue',['sys', '{{dict name}}', {{label}}])
 * const label = await this.$store.dispatch('dict/getLabel',['biz', '{{dict name}}', {{label}}])
 * // action map
 * import { mapActions } from 'vuex'
 * methods: {
 * ...mapActions({
 *    getDictValue: 'dict/getValue'
 *    getDictLabel: 'dict/getLabel'
 *  }),
 * }
 * const value = await this.getDictValue(['sys', '{{dict name}}', {{label}}])
 * const label = await this.getDictLabel(['biz', '{{dict name}}', {{value}}])
 *
 * // getItem 可以传入 label 或者 value, 获取字典项，如果获取不到结果，还将将驱动一次字典更新
 * const { value, ...rest } = await this.$store.dispatch('dict/getItem',{ category:'sys', name: 'dict name', label:'xxx'})
 * const item = await this.$store.dispatch('dict/getItem',{ category:'biz', name: 'dict name', value:'xxx'})
 * // action map
 * import { mapActions } from 'vuex'
 * methods: {
 * ...mapActions({
 *    getDictItem: 'dict/getItem',
 * }),
 * }
 * const { label, ...rest } = await this.getDictItem({ category:'biz', name: 'dict name', value:'xxx'})
 * ```
 */
const actions = {
  clear({ commit }) {
    commit('EMPTY_DICT')
  },
  /**
   * 获取字典, 支持批量 get dictionary
   * @typedef {Object} Match
   * @property {(string|number)} [value='dataValue']
   * @property {string} [label='tagName']
   *
   * @typedef {Object} GetDictPayload
   * @property {('biz'|'sys')} category biz:业务类，public/sys/businessDict sys:系统类, sysDict
   * @property {string} name 字典名，Page sysDict > the "dictName" of field of list item
   * @property {boolean=} [force=false] ⚠️ true:强制远程获取最新数据 false:使用 store 现存数据，没有则远程获取
   * @property {boolean=} [tc=true] 是否对 value 类型转换 type conversion， 数字字符串 转 数字
   * @property {object} [query={}] 请求字典接口，携带除了 dictName 和 type 外的其他参数， see fetchDict of src/service/api-web/system/sysDict
   * @property {Match=} [match={value:'dataValue',label:'tagName'}] 字段映射
   * @description 使用的时候，传入 force:true 可以提高获取字典的实时性，另外：自定义字段映射 match 不会存储到 state
   *
   * @param {import('vuex').ActionContext} ctx
   * @param {GetDictPayload|Array<GetDictPayload>} payload
   * @returns {Promise<Array<DictItem|undefined>|Array<Array<DictItem|undefined>>>}
   */
  async get(ctx, payload) {
    const _payloads = isArray(payload) ? payload : [payload]

    const results = await Promise.all(
      _payloads.map((p) => getDictPromise(ctx, p))
    )
    return _payloads.length === 1 ? results[0] : results
  },
  /**
   * 获取字典项的标签 get label
   * @param {import('vuex').ActionContext}
   * @param {Array<string>} payload [('sys'|'biz'), '{{dict name}}', {{value}}]
   * @returns {string}
   * @description 根据 value 获取对应的 label
   */
  async getLabel({ state }, payload) {
    let label = ''
    try {
      const [category = '', name = '', value = null] = payload
      if (isString(category) && isString(name)) {
        const _category = category.trim()
        const _name = name.trim()
        if (_category && _name) {
          const dict = state[_category][_name]
          if (isArray(dict) && dict.length) {
            label = dict.find((v) => v.value === value)?.label || ''
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
    if (label === '') console.error('【dict】getLabel', payload)
    return label
  },
  /**
   * 获取字典项的值 get value
   * @param {import('vuex').ActionContext}
   * @param {Array<string>} payload [('sys'|'biz'), '{{dict name}}', {{label string}}]
   * @returns {(number|string|null)}
   * @description 根据 label 获取对应的 value
   */
  async getValue({ state }, payload) {
    let value
    try {
      const [category = '', name = '', label = ''] = payload
      if (isString(category) && isString(name) && isString(label)) {
        const _category = category.trim()
        const _name = name.trim()
        const _label = label.trim()
        if (_category && _name && _label) {
          const dict = state[_category][_name]
          if (isArray(dict) && dict.length) {
            value = dict.find((v) => v.label === _label)?.value || null
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
    if (value === null) console.error('【dict】getValue', payload)
    return value
  },
  /**
   * 获取字典项整个ITEM
   * @typedef {Object} getItemPayload payload for getItem
   * @property {('biz'|'sys')} category biz:业务类 sys:系统类
   * @property {string} name 字典名，Page sysDict > the "dictName" of field of list item
   * @property {string=} label
   * @property {number=} value
   * @property {boolean=} [force=false]
   *
   * @param {import('vuex').ActionContext}
   * @param {getItemPayload} payload
   * @returns {(DictItem|null)}
   * @description label 和 value 只需要二选一， 如果都传入，以 value 为参照找寻 item
   */
  async getItem({ state, dispatch }, payload) {
    let item = null
    try {
      const {
        category = '',
        name = '',
        label = '',
        value = null,
        force = false
      } = payload

      if (force === true) {
        const fetchedDict = await dispatch('get', {
          category,
          name,
          force: true
        })
        item = findItem(payload, fetchedDict, label, value)
      } else {
        const cachedDict = state[category][name]
        if (isArray(cachedDict) && cachedDict.length) {
          item = findItem(payload, cachedDict, label, value)
        }

        if (
          !item ||
          !isPlainObject(item) ||
          (isPlainObject(item) && Object.keys(item).length === 0)
        ) {
          const fetchedDict = await dispatch('get', {
            category,
            name,
            force: true
          })
          item = findItem(payload, fetchedDict, label, value)
        }
      }
    } catch (e) {
      console.error(e)
    }

    return item
  }
}
/**
 * @param {Array<DictItem>} dict
 * @param {string=} label
 * @param {number=} value
 * @returns {(DictItem|null)}
 */
function findItem(payload, dict, label, value) {
  let item = null
  if (isArray(dict)) {
    if (typeof value === 'number') {
      item = dict.find((v) => v.value === value) || null
    } else {
      if (isString(label)) {
        const _label = label.trim()
        if (_label) {
          item = dict.find((v) => v.label === _label) || null
        }
      }
    }
  }
  if (item === null) {
    console.warn(
      `【dict】can't find item by label:${label}, value:${value} by:`,
      payload,
      'from:',
      dict
    )
  }
  return item
}

/**
 * 创建字典索引
 * @param {Record<string,Array<DictItem>} dict
 * @returns {Record<string,Record<string,Record<string,(string|number)>>>}
 * ```js
 *  {
 *   // ...
 *   dictName: {
 *     label: {
 *       '否': 0,
 *       '是': 1
 *     },
 *     value: {
 *       0: '否'
 *       1: '是'
 *     }
 *   },
 *   // ...
 * }
 * // dictList.syc.dictName.lable['否'] // => 0
 * // dictList.giz.dictName.value[0] // => '否'
 *```
 */
function createIndex(dict) {
  const index = {}
  Object.entries(dict).forEach(([dictName, dictList]) => {
    index[dictName] = {
      // data: [], // [item, item, item,...]
      labels: [], // [label, label, label, ...]
      values: [], // [value, value, value,...]
      label: {}, // {value:label, value:label, ...}
      value: {}, // {label:value, label:value, ...}
      itemByValue: {}, // {value:item, value:item, ...}
      itemByLabel: {} // {label:item, label:item, ...}
    }
    dictList.forEach(({ value: val, label: key, ...rest }) => {
      index[dictName].label[val] = key
      index[dictName].value[key] = val

      const item = { value: val, label: key, ...rest }
      index[dictName].itemByLabel[key] = item
      index[dictName].itemByValue[val] = item
      // index[dictName].data.push(item)

      index[dictName].labels.push(key)
      index[dictName].values.push(val)
    })
  })
  return index
}
/**
 * 获取字典
 * @typedef {Object} Match
 * @property {string} [value='dataVal']
 * @property {string} [label='tagName']
 *
 * @param {import('vuex').ActionContext}
 * @param {GetDictPayload} payload
 * @returns {Promise<(Array<(DictItem|undefined)>|undefined)>}
 */
async function getDictPromise({ state, commit }, payload) {
  try {
    const { category = '', name = '' } = payload || {}

    if (
      !category ||
      !isString(category) ||
      (isString(category) && !category.trim()) ||
      !Object.keys(DICT_ALIAS).includes(category)
    ) {
      console.error(`Illegal params for "getDictPromise" category:${category}`)
      return undefined
    }

    if (!name || !isString(name) || (isString(name) && !name.trim())) {
      console.error(`Illegal params for "getDictPromise" name:${name}`)
      return undefined
    }

    const { value = 'dataValue', label = 'tagName' } = payload?.match || {}

    const isDefaultMatch = value === 'dataValue' && label === 'tagName'
    if (value && isString(value) && label && isString(label)) {
      const _value = value.trim()
      const _label = label.trim()
      if (_value && _label) {
        let force = payload?.force ?? false
        const { query = {} } = payload
        if (Object.keys(query).length > 0) force = true
        if (force !== true && isDefaultMatch) {
          // 默认 match 配置 才会从 state 获取
          const stored = state[category][name]
          if (isArray(stored)) {
            if (stored.length > 0) {
              // console.groupCollapsed(`【dict】${category}.${name} (local)`)
              // console.log(JSON.parse(JSON.stringify(stored)))
              // console.groupEnd()
              return state[category][name]
            } else {
              await poll({
                target: state,
                nested: [category, name, length],
                timeout: NETWORK_TIMEOUT.request
              })
              // const stored = state[category][name]
              // console.groupCollapsed(`【dict】${category}.${name} (poll)`)
              // console.log(JSON.parse(JSON.stringify(stored)))
              // console.groupEnd()
            }
            return state[category][name]
          }
        }

        /**
         * 必须先初始化字典，否则多个并发会导致发送多个请求
         * 会把压力全部汇聚到请求池并发拦截上
         */
        commit('SET_DICT', { category, key: name, value: [] })
        const result = await getDict({
          category,
          name,
          query,
          skipValid: true,
          match: {
            value: _value,
            label: _label
          }
        })
        if (isArray(result)) {
          // 默认 match 配置 才会存入 state
          console.groupCollapsed(`【dict】${category}.${name} (remote)`)
          console.log(JSON.parse(JSON.stringify(result)))
          console.groupEnd()

          /**
           * ❗️字典为了兼容大量历史接口，value 值默认将数字字符串转成数字
           */
          const { tc = true } = payload
          tc === true &&
            result.forEach((item) => {
              item.value = isNumeric(item.value)
                ? Number(item.value)
                : item.value
            })

          if (isDefaultMatch) {
            commit('SET_DICT', {
              category,
              key: name,
              value: deweight(result, 'value', { quiet: false })
            })
            return state[category][name]
          } else return deweight(result, 'value', { quiet: false })
        } else console.error('【dict】illegal response from "getDict"', result)
      } else {
        console.error(
          '【dict】illegal params for "getDictPromise" match[1]:',
          payload?.match
        )
      }
    } else {
      console.error(
        '【dict】illegal params for "getDictPromise" match[2]:',
        payload?.match
      )
    }
  } catch (error) {
    console.error(
      '【dict】catched error when "getDictPromise"',
      error,
      payload,
      `\npath:${window?.location?.pathname}`,
      `\nurl:${window?.location?.href}`
    )
  }

  return undefined
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
