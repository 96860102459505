// 对象序列化，undefined和函数丢失问题
export const JSONStringify = (option) => {
  return JSON.stringify(
    option,
    (key, val) => {
      // 处理函数丢失问题
      if (typeof val === 'function') {
        if (val.toString().indexOf('function') === -1) {
          return `${'arrow' + val}`
        } else {
          return `${val}`
        }
      } // 处理undefined丢失问题
      if (typeof val === 'undefined') {
        return 'undefined'
      }
      return val
    },
    2
  )
}
// 对象序列化解析
export const JSONParse = (objStr) => {
  return JSON.parse(objStr, (k, v) => {
    if (
      typeof v === 'string' &&
      v.indexOf &&
      (v.indexOf('function') > -1 || v.indexOf('arrow') > -1)
    ) {
      if (v.indexOf('arrow') > -1) {
        const str = v.replace(/arrow/g, '')
        // eslint-disable-next-line
        return eval(`(function(){return ${str}})()`)
      } else {
        // eslint-disable-next-line
        return eval(`(function(){return ${v}})()`)
      }
    }
    return v
  })
}
