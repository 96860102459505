/**
 * @module api-web:paymentOrder
 */
import service from '@/service'

/**
 * 查询 财务-付款单
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ap/finPayment/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{id}}
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ap/finPayment/public/getListDown',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 付款单详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ap/finPayment/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/finPayment/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 修改
 * @param {Department} data
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/ap/finPayment/update',
    method: 'put',
    data
  })
}

/**
 * 提交财务付款订单
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/ap/finPayment/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-web']({
    url: '/fin/ap/finPayment/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{number:id}} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/fin/ap/finPayment/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/fin/ap/finPayment/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {object}
 * @returns {Promise<Array>}
 * @description 付款单下推特殊红字付款单
 */
export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ap/finPayment/pushDown',
    method: 'post',
    data
  })
}

/**
 * 待办修改
 * @param {Department} data
 */
export function todoUpdate(data) {
  return service['api-web']({
    url: '/public/activiti/finPayment/update',
    method: 'put',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params - id
 * @description 删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ap/finPayment/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: fin/ap/finPayment/reject
    url: '/public/activiti/finPayment/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
