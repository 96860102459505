/**
 * @module api-web:warehouseInformation
 * @description api for data/basic/warehouseInformation
 */
import service from '@/service'

/**
 * 表查询
 * @param {{keywords:string,limit:number,page:number,sort:string,documentStatus:number}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/data/basic/warehouseInformation/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 计量单位信息 详情
 */
export function info(id) {
  return service['api-web']({
    url: '/data/basic/warehouseInformation/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/warehouseInformation/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 新增
 */
export function save(data) {
  return service['api-web']({
    url: '/data/basic/warehouseInformation/save',
    method: 'post',
    data
  })
}

/**
 * 修改
 */
export function update(data) {
  return service['api-web']({
    url: '/data/basic/warehouseInformation/update',
    method: 'post',
    data
  })
}
/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: '/data/basic/warehouseInformation/reject',
    url: '/public/activiti/warehouseInformation/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(string|number)} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/data/basic/warehouseInformation/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:string}} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/data/basic/warehouseInformation/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 启用禁用
 * @param {(string|number)} id - id
 * @param {(0|1)} status - 0:禁用 1:启用
 */
export function enableDisable(data) {
  return service['api-web']({
    url: '/data/basic/warehouseInformation/updateDisableStatus',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
