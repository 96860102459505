/**
 * @module api-web:dictionary
 */
import service from '@/service'

/**
 * 列表
 * @param {{keywords:string,limit:number,page:number,parentId:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/sys/sysInfoDictionary/getList',
    method: 'get',
    params
  })
}

/**
 * 新增
 * @param {{msgName:string,parentId:number=}} data
 */

export function add(data) {
  return service['api-web']({
    url: '/sys/sysInfoDictionary/save',
    method: 'post',
    data
  })
}

/**
 * 详情
 * @param {number} id
 */
export function getDetailsById(id) {
  return service['api-web']({
    url: '/sys/sysInfoDictionary/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 删除
 * @param {object} params
 */
export function remove(params) {
  return service['api-web']({
    url: '/sys/sysInfoDictionary/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
