/**
 * @module utils/request
 * @description common request
 */
import { Message, MessageBox } from 'element-ui'
import { isString, isPlainObject, isArray /* , validURL */ } from './validate'

import { fetchDict } from '@/service/api-web/system/sysDict'
import { DICT_ALIAS } from '@/constants'

import { read as fetchDefinition } from '@/service/api-activiti/process/definition'

import {
  getXML as fetchInstance,
  getHistory
} from '@/service/api-activiti/process/instance'

import {
  getFileStream,
  getSupplierItemsByCodes,
  getClientItemsByCodes
} from '@/service/api-web'
import { getLogisticItemsByCodes } from '@/service/api-scm'
import { createBlobURL } from '@/utils/files'

/**
 * @typedef {Object} DictItem
 * @property {string} id -  eg: "16"
 * @property {sting} dictName - eg: "shoppingStatus"
 * @property {number} dataSort -  eg: 1
 * @property {(string|null)} dictComment -  eg: "I'm a comment"
 * @property {string=} dictDescribe -  eg: "I'm a desc"
 * @property {number} dataValue - eg: 1
 * @property {string} tagName - eg: "I'm a label"
 *
 * @typedef {Object} Match
 * @property {string} [value='dataVal']
 * @property {string} [label='tagName']
 *
 * @typedef {Object} GetDictPayload
 * @property {boolean} [skipValid=false] 跳过字段校验
 * @property {('biz'|'sys')} category biz:业务类 sys:系统类
 * @property {string} name 字典名
 * @property {Match=} [match={value:'dataValue',label:'tagName'}] 字段映射
 */
/**
 * @param {GetDictPayload}
 * @returns {Promise<Array<DictItem|undefined>>}
 */
export const getDict = async (payload) => {
  let result = []
  try {
    if (payload?.skipValid === true) {
      const {
        query = {},
        category,
        name,
        match: { value, label }
      } = payload
      const type = DICT_ALIAS[category]
      const { code, data } = await fetchDict({
        dictName: name,
        type,
        ...query
      })
      if (code === 200 && isArray(data) && data.length > 0) {
        result = data.map((v) => ({
          value: v[value],
          label: v[label],
          ...v
        }))
      } else {
        console.error(
          'Illegal response from "getDict"[1]',
          `\ncategory:${category}`,
          `\nname:${name}`,
          `\ntype:${type}`,
          `\ndata:`,
          data,
          `\npath:${window?.location?.pathname}`,
          `\nurl:${window?.location?.href}`
        )
      }
    } else {
      const { category = '', name = '' } = payload || {}

      if (
        !category ||
        !isString(category) ||
        (isString(category) && !category.trim()) ||
        !Object.keys(DICT_ALIAS).includes(category)
      ) {
        console.error(
          'Illegal params for "getDict"',
          `\ncategory:${category}`,
          `\npath:${window?.location?.pathname}`,
          `\nurl:${window?.location?.href}`
        )
        return result
      }

      if (!name || !isString(name) || (isString(name) && !name.trim())) {
        console.error(
          'Illegal params for "getDict"',
          `\nname:${name}`,
          `\npath:${window?.location?.pathname}`,
          `\nurl:${window?.location?.href}`
        )
        return result
      }

      const { value = 'dataValue', label = 'tagName' } = payload?.match || {}

      if (value && isString(value) && label && isString(label)) {
        const _value = value.trim()
        const _label = label.trim()
        if (_value && _label) {
          const type = DICT_ALIAS[category]
          const { code, data } = await fetchDict({
            dictName: name,
            type,
            ...(payload?.query ?? {})
          })
          if (code === 200 && isArray(data) && data.length > 0) {
            result = data.map((v) => ({
              value: v[value],
              label: v[label],
              ...v
            }))
          } else {
            console.error(
              'Illegal response from "getDict"[2]',
              `\ncategory:${category}`,
              `\nname:${name}`,
              `\ntype:${type}`,
              `\ndata:`,
              data,
              `\npath:${window?.location?.pathname}`,
              `\nurl:${window?.location?.href}`
            )
          }
        } else {
          console.error(
            'Illegal params for "getDict" match[1]',
            payload?.match,
            `\npath:${window?.location?.pathname}`,
            `\nurl:${window?.location?.href}`
          )
        }
      } else {
        console.error(
          'Illegal params for "getDict" match[2]',
          payload?.match,
          `\npath:${window?.location?.pathname}`,
          `\nurl:${window?.location?.href}`
        )
      }
    }
  } catch (error) {
    console.error(
      'Catched error when "getDict"',
      payload,
      error,
      `\npath:${window?.location?.pathname}`,
      `\nurl:${window?.location?.href}`
    )
  }
  return result
}

/**
 * toggleEnable 启用禁用请求封装
 * @typedef {Object} ToggleEnableOptions
 * @property {boolean} [confrim=true] - show confirm message box
 * @property {Function} [api=null] - request function
 * @property {Function} [id={ key: 'id', val: null }] - id 的 key,val
 * @property {Function} [state={ key: 'state', val: null }] - state 的 key,val
 * @property {Function} [map={ 1: '启用', 0: '禁用' }] - 启用禁用的映射
 * @property {Function} [cb={ success: null, error: null, complete: null }] - 回调
 * @property {Function} [message=true] - 是否使用 element ui message 提示启用成功失败
 * @property {Object} [payload={}] - 载荷, id,state 之外的请求参数
 *
 * @param {ToggleEnableOptions} options
 * @returns { Promise<import('axios').AxiosResponse<any>|{code:number,data:any,msg:string}>}
 */
export const toggleEnable = async (options) => {
  const {
    confirm = true,
    api = null, // api request
    id = { key: 'id', val: null }, // request send id key,val
    state = { key: 'state', val: null }, // request send state key,val
    map = { 0: '启用', 1: '禁用' }, // mapper
    cb = { success: null, error: null, complete: null }, // callback
    message = true, // show element-ui message
    payload = {} // reqeuset send payloads
  } = options

  let res

  try {
    if (typeof api !== 'function') {
      console.error(options)
      throw new Error('【toggleEnable】losed "api"')
    }

    if (!isString(id.key) || !id.key) {
      console.error(options)
      throw new Error('【toggleEnable】losed "key" of "id"')
    }
    if (typeof id.val === 'undefined' || id.val === null) {
      console.error(options)
      throw new Error('【toggleEnable】losed "val" of "id"')
    }

    if (!isString(state.key) || !state.key) {
      console.error(options)
      throw new Error('【toggleEnable】losed "key" of "state"')
    }
    if (typeof state.val === 'undefined' || state.val === null) {
      console.error(options)
      throw new Error('【toggleEnable】losed "val" of "state"')
    }
    if (!isPlainObject(payload)) {
      console.error(options)
      throw new Error('【toggleEnable】illegal "payload"')
    }

    if (confirm === true) {
      const isConfirm = await MessageBox.confirm(
        `是否确定${map[state.val]}?`,
        '提示',
        {
          type: 'warning'
        }
      )
      if (!isConfirm) {
        return { code: -1, data: null, msg: 'cancel' }
      }
    }

    res = await api({
      [id.key]: id.val,
      [state.key]: state.val,
      ...payload
    })
    const { code, msg } = res
    message &&
      code === 200 &&
      Message({
        type: 'success',
        message: msg || `${map[state.val]}成功`,
        showClose: true
      })
    code === 200
      ? typeof cb.success === 'function'
        ? cb.success(res)
        : null
      : typeof cb.error === 'function'
      ? cb.error(res)
      : null
  } catch (err) {
    console.error(err)
    res = { code: -1, data: null, msg: 'Toggle Enable State Error' }
    typeof cb.error === 'function' && cb.error(err)
  } finally {
    typeof cb.complete === 'function' && cb.complete(res)
  }

  return res
}

/**
 * 读取流程图 XML
 * @param {'definition'|'instance'} type 定义图 or 实例图
 * @param {string} processId
 * @param {string} fileName
 * @returns xml string
 */
export const getProcessDiagramXML = async function (
  type = '',
  processId = '',
  fileName = ''
) {
  try {
    let xml
    if (type === 'definition') {
      if (processId && fileName) {
        xml = await fetchDefinition({
          deploymentId: processId,
          fileName
        })
      } else {
        console.error('"getProcessDiagram":', processId, fileName)
        return ''
      }
    } else if (type === 'instance') {
      if (processId) {
        xml = await fetchInstance(processId)
      } else {
        console.error('"getProcessDiagram":', processId)
        return ''
      }
    } else {
      console.error('"getProcessDiagram": Illegal "type"', type)
      return ''
    }
    const success = isString(xml)
    !success &&
      void console.error('"getProcessDiagram": Diagram XML Fetched Failed', xml)
    return success ? xml : ''
  } catch (error) {
    console.error('"getProcessDiagram": Diagram XML Fetched Error ' + error)
  }
  return ''
}

/**
 * @param {(number|string)} instanceId
 * @param {('both'|'node'|'line')} [type='both'] 历史记录类型：环节(节点)/流(线)/两者都
 * @returns {Promise<Record<('node'|'line'),Array>>} 流程实例流转历史列表，第一个元素为当前节点
 */
export const getProcessInstHistory = async function (
  instanceId,
  documentCode,
  type = 'both'
) {
  const history = {
    node: [],
    line: []
  }
  /**
   * get
   * @param {string} instanceId
   * @param {('line'|'node')} type
   * @returns {Promise<Array>}
   */
  const get = async (instanceId, documentCode, type) => {
    const { code, data } = await getHistory(instanceId, documentCode, type)
    if (code === 200 && isArray(data)) {
      return data
    } else return []
  }

  try {
    switch (type) {
      case 'line':
      case 'node':
        history[type] = await get(instanceId, documentCode, type)
        break
      case 'both': {
        const types = Object.keys(history)
        const results = await Promise.all(
          types.map(async (type) => await get(instanceId, documentCode, type))
        )
        types.forEach((type, idx) => {
          history[type] = results[idx]
        })
        break
      }
      default:
        console.error('Illeagl "type"', type)
    }
  } catch (error) {
    console.error(error)
  }
  return history
}

/**
 * @param {string} address
 * @returns {Promise<{url:string,name:string}>} file url and name
 * @description 根据文件地址，请求文件流，获取文件名以及转 blob url
 */
export const getFileInfo = async (address) => {
  if (address) {
    if (
      isString(address) &&
      address.trim() &&
      (address.startsWith('http://') || address.startsWith('https://'))
    ) {
      try {
        const { data: stream, name } = await getFileStream(address)
        return { url: createBlobURL(stream), name }
      } catch (error) {
        console.error('getFileInfo catched error:', error)
      }
    } else console.error('getFileInfo illegal address:', address)
  } else console.warn('getFileInfo empty file address')

  return { url: address, name: address }
}
/**
 * @type {Record<string,Function>}
 * @description api and for "getOptsByCodes"
 */
const GET_OPTS_BY_CODES_CONFS = {
  1: { category: 'client', api: getClientItemsByCodes },
  2: { category: 'supplier', api: getSupplierItemsByCodes },
  3: { category: 'logistic', api: getLogisticItemsByCodes }
}
/**
 * codes 转 options (label,value)
 * @param {(1|2|3)} concatUnitCategory 1:client 2:supplier 3:logistic
 * @param {sting} codes
 * @param {{label:string,value:string}} mapping
 * @returns {Promise<Array<({value:(string|number),label:string}|undefined)>>}
 */
export async function getOptsByCodes(concatUnitCategory, codes, mapping = {}) {
  try {
    const { api = null, category = '' } =
      GET_OPTS_BY_CODES_CONFS[concatUnitCategory] || {}

    if (typeof api !== 'function') {
      console.error('losed api getOptsByCodes', concatUnitCategory)
      return []
    }

    const { code, data } = await api(codes)
    if (code === 200) {
      // 接口数据格式是 map
      if (concatUnitCategory === 3) {
        if (isPlainObject(data) && Object.keys(data).length > 0) {
          return Object.entries(data).map(([k, v]) => ({ value: k, label: v }))
        }
      } else {
        // 接口数据为正常的 list
        if (isArray(data)) {
          return data.map((v) => ({
            label: mapping?.label ? v[mapping.label] : v[`${category}Name`],
            value: mapping?.value ? v[mapping.value] : v[`${category}Code`]
          }))
        }
      }
    }
  } catch (error) {
    console.error(error)
  }
  return []
}

// export default {
//   toggleEnable,
//   dict: { get: getDict },
//   file: { get: getFileInfo },
//   process: {
//     getDiagramXML: getProcessDiagramXML,
//     getFlowHistory: getProcessInstHistory
//   }
// }
