/**
 * @module api-scm:purchaseExportDetail.js
 */

import service from '@/service'

/**
 * @param {{
 * keywords:string,
 * limit:number,page:number,
 * sort:string}} params
 * @description  列表
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/purchase/inventorySupplierDetail/getList',
    method: 'get',
    params
  })
}

/**
 * @description 导出
 */
export function exportData(params) {
  return service['api-scm']({
    url: '/scm/purchase/inventorySupplierDetail/export',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}
