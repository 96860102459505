import VXETable from 'vxe-table'
import ElAutoComplete from './ElAutoComplete'

VXETable.renderer.add('ElAutoCompletor', {
  renderEdit: (...args) => ElAutoCompleteRender(...args)
})

function ElAutoCompleteRender(h, renderOpts, params = {}) {
  const { events = {}, ...rest } = renderOpts
  return [
    <ElAutoComplete scope={params} edit-render-props={rest} on={events} />
  ]
}
