import todoTasksList from './todo-tasks-list'
import todoTasksHandle from './todo-tasks-handle'
import liaison from './liaison'
/**
 * @typedef {Object} CreateRouteDesc
 * @property {number} parentId 指定父级ID那个
 * @property {string} createKey 创建本地路由的 key string, 用于映射为对应的 create function
 *
 * @type {Array<CreateRouteDesc>}
 * @description 配置项，前端本地创建添加菜单
 * @see /src/router/create/*
 */
export const createRoutesConfigs = [
  /**
   * 首页：我的待办/部门待办
   * 待办分组+待办列表
   */
  { parentId: 2, createKey: 'todo-tasks-list' /* createParams: {} */ },
  /**
   * 首页：特殊待办处理 (针对无对应详情页的待办处理)
   * 如：补货待办
   */
  { parentId: 2, createKey: 'todo-tasks-handle' /* createParams: {} */ }
  // { parentId: 209, createKey: 'liaison' /* createParams: {} */ }
  /**
   * Dynamic add others route...
   */
  // ...
]

export const createRoutesHandlers = {
  todoTasksList,
  todoTasksHandle,
  liaison
  // ...
}

export default {
  createRoutesConfigs,
  createRoutesHandlers
}
