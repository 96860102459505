/**
 * @module api-web:clientServe/ContractShipment
 */
import service from '@/service'

/**
 * 合同订单查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * contractBatchCode: string ,
 * platformCode: string,
 * contractCode: string,
 * createUser: string,
 * productName: string,
 * documentStatus: number,
 * orderCode: number,
 * |number
 * @param {string}: string
 * }} params
 */
export function getList(data) {
  return service['api-web']({
    url: '/sell/order/contractShipmentSummary/get/getList',
    method: 'post',
    data
  })
}
// 商品明细 -金额 查询
export function getListOrderMoneyDetail(contractBatchCode) {
  return service['api-web']({
    url: '/sell/order/contractShipmentSummary/listOrderDetailAndAmount',
    method: 'get',
    params: { contractBatchCode }
  })
}
// 商品明细 - 查询
export function getListOrderDetail(contractBatchCode) {
  return service['api-web']({
    url: '/sell/order/contractShipmentSummary/listOrderDetail',
    method: 'get',
    params: { contractBatchCode }
  })
}
// 商品明细 - 查询
export function getListWarehouseOrderOutDetail(contractBatchCode) {
  return service['api-web']({
    url: '/sell/order/contractShipmentSummary/listWarehouseOrderOut',
    method: 'get',
    params: { contractBatchCode }
  })
}

/**
 *
 * @param {(string|number)} params
 * @description 树形表格下拉
 */
export function getListDown(params) {
  return service['api-web']({
    url: '/sell/order/contractShipmentSummary/get/getPullList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @description 导出商品明细（￥）
 */
export function exportOrderDetailAndAmount(data) {
  return service['api-web']({
    url: '/sell/order/contractShipmentSummary/exportOrderDetailAndAmount',
    method: 'post',
    data
  })
}

/**
 *
 * @description 导出商品明细
 */
export function exportOrderDetail(data) {
  return service['api-web']({
    url: '/sell/order/contractShipmentSummary/exportOrderDetail',
    method: 'post',
    data
  })
}

/**
 *
 * @description 导出出库单明细
 */
export function exportWarehouseOrderOutDetail(data) {
  return service['api-web']({
    url: '/sell/order/contractShipmentSummary/exportWarehouseOrderOutDetail',
    method: 'post',
    data
  })
}
