/**
 * @module api-web:financialAffairs/accountReceivable/financeReceivable
 * @description 财务管理/应收/财务应收 选单-(从暂估应收中选)
 */
import service from '@/service'
/**
 * 选单- 财务-暂估应付单列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/getSelectionBillList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 确认选单
 * @data
 * estimateIds -暂估应付单id集合
 * receivableCode -	财务应付/付单编码code
 * receivableId -	财务应付/付单id
 */
export function selectionConfirm(data) {
  return service['api-web']({
    url: '/fin/ap/finPayableBill/public/selectionConfirm',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *   树形表格下拉
 * @param {code} code
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ap/finEstimatePurchase/public/getPullList',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
