/**
 * @module api-web:financialAffairs/accountPayable/applicationForm
 * @description 财务管理 付款退款单
 */
import service from '@/service'

/**
 * 查询 列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ap/finPaymentBack/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{id}}
 */
// export function getListDown(id) {
//   return service['api-web']({
//     url: '/fin/ap/finPaymentBack/getListDown',
//     method: 'get',
//     params: { id },
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }

/**
 * 根据 ID 查询财务付款退款单详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/fin/ap/finPaymentBack/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 创建 财务-付款退款单新增
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/ap/finPaymentBack/save',
    method: 'post',
    data
  })
}

/**
 * 提交审核
 * @param {Array<number|string >} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/fin/ap/finPaymentBack/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 财务-付款退款单修改
 * @param {Department} data
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/ap/finPaymentBack/update',
    method: 'put',
    data
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: '/fin/ap/finPaymentApply/reject',
    url: '/public/activiti/finPaymentBack/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/fin/ap/finPaymentBack/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<number>}} params - id
 * @description 财务-付款退款单删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ap/finPaymentBack/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object} data -id
 * @description 财务付款申请单下推成->应付单
 */
export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ap/finPaymentApply/pushDown',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 撤销
 * @param {{ids:string}}
 * @returns {string}
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/fin/ap/finPaymentBack/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  作废
 * @param {object} data
 */

export function invalid(id) {
  return service['api-web']({
    url: '/fin/ap/finPaymentApply/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 待办修改
 * @param {Department} data
 */
export function todoUpdate(data) {
  return service['api-web']({
    url: '/public/activiti/finPaymentBack/update',
    method: 'put',
    data
  })
}
/**
 * 明细搜索
 * @param {Department} data
 */
export function getListBySourceCode(codes) {
  return service['api-web']({
    url: '/fin/ap/finPaymentApply/getListBySourceCode',
    method: 'get',
    params: { codes },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {type: string}
 * @description 通过付款退款单位获取获取结算方式
 */
export function getClearingForm(params) {
  return service['api-web']({
    url: '/public/data/getSettlementModeByPayeeCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/json' }
  })
}
/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/finPaymentBack/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
