/**
 * @module api-scm:inventoryProduct
 */
import service from '@/service'

/**
 * 库存明细   表查询
 * @param {{keywords:string,limit:number,page:number,sort:string,documentStatus:number}} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryProduct/get/getList',
    method: 'get',
    params
  })
}

/**
 * 库存明细 下拉  表查询
 * @param {{inventoryDetailId:string}} params
 */
export function getDetailList(inventoryDetailId) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryProduct/get/getDetailList',
    method: 'get',
    params: { inventoryDetailId },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{
 * limit:number
 * page:number
 * productCode:string
 * productName:string
 * warehouseName:string
 * }} params
 * @description 导出库存明细
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryProduct/inventoryProductData/export',
    method: 'post',
    data,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false // 文件流是否通过浏览器下载
  })
}

/**
 * @description 获取合计数据
 * @param {{keywords:string,limit:number,page:number,sort:string,documentStatus:number}} params
 */
export function getInventoryNumber(params) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryProduct/get/getInventoryNumber',
    method: 'get',
    params
  })
}

/**
 * 组织列表查询
 * @param {{keywords:string,limit:number,page:number,parentDept:number,sort:string}} params
 */
export function getOrgList(params) {
  return service['api-web']({
    url: '/data/companyInfo/getList',
    method: 'get',
    params
  })
}

/**
 *  @param {Object} params - 参数对象
 *  @param {string[]} options.productCodeList - 商品编码
 *  @param {string[]} options.orderCodeList - 单据编号
 *  @param {number[]} options.warehouseCodeList - 仓库名称
 *  @param {string[]} options.shipperList - 货主名称
 */
export function getOrderUsingNumber(params) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryProduct/getOrderUsingNumber',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 导出
 */
export function exportIntoCenter(params) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryProduct/exportOrderUsingNumber',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 生产临时加库存
 */
export function manualAddInventory(data) {
  return service['api-scm']({
    url: '/scm/warehouse/inventoryProduct/manualAddInventory',
    method: 'post',
    data
  })
}

/**
 * 依据商品编码 查询 库位 仓库
 */
export function getInventoryBySku(params) {
  return service['api-scm']({
    url: '/public/inventory/getInventoryBySku',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
