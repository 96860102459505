/**
 * @module api-web:financialAffairs/accountReceivable/financeReceivable
 * @description 财务管理/应收/财务应收 选单-(从暂估应收中选)
 */
import service from '@/service'
/**
 * 选单- 财务-暂估应收单列表
 * @param {{limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/getSelectionBillList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 确认选单
 * @data
 * estimateIds -暂估应收单id集合
 * receivableCode -	财务应收/付单编码code
 * receivableId -	财务应收/付单id
 */
export function selectionConfirm(data) {
  return service['api-web']({
    url: '/fin/ar/finReceivableBill/public/selectionConfirm',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  树形下拉
 * @param {{id}}
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/public/getPullList',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
