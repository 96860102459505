import { isPlainObject } from '@/utils/validate'
export default {
  /**
   * 按钮监听
   */
  name: 'buttonListenerMixin',
  methods: {
    /**
     * 获取按钮点击处理器
     * @description 优先绑定外部 on 配置的处理器，降级绑定 内部处理 + $emit('click')
     * @returns {Record<string,Function>}
     */
    getButtonHandler(item) {
      const events = {}

      const evtName = item.evtName || item.label
      // console.groupCollapsed(`${evtName} listener`)
      // console.log(JSON.stringify(item))
      if (isPlainObject(item.on)) {
        const { click, ...rest } = item.on

        // 挂载 click 之外的事件处理器
        Object.entries(rest).forEach(([event, handler]) => {
          if (typeof handler !== 'function') return
          events[event] = ($event) => {
            console.log('event context:', this.eventContext)
            handler.call(this.eventContext, { $event, item })
          }
        })

        if (typeof click === 'function') {
          if (evtName === '下推') {
            // 目前请通过 配置 item.customEvent:true 然后在 <button-group @pushDown 实现自定义事件
            console.warn('下推按钮暂不支持 item.on 配置')
          } else {
            // 点击绑定外部处理器
            events.click = ($event) => {
              console.log('event context:', this.eventContext)
              click.call(this.eventContext, { $event, item })
            }
          }
        }
      }

      if (typeof events.click !== 'function') {
        // console.info(
        //   `「${evtName || JSON.stringify(item)}」按钮未配置外部点击事件处理器`
        // )
        // 绑定内部处理器
        events.click = ($event) => this.hanldeClickButton({ $event, item })
      } else {
        // console.info(
        //   `「${evtName || JSON.stringify(item)}」按钮配置了外部点击事件处理器`
        // )
      }
      // console.groupEnd()

      return events
    }
  }
}
