/**
 * @module filters/permission
 */
import store from '@/store'

/**
 * @deprecated 不再建议使用过滤器，优先使用 mixin 中 的 $_permission_name
 * @see /src/mixin/permission/$_permission_name
 */

/**
 * 过滤器：根据 资源ID 从 资源ID索引 中获取资源名称
 * @param {number} pmId - 权限 ID
 * @return {(string|'not available')}  资源 label 或者 'not available'
 * @useage
 * ```html
 * <button>{{ 1 | pmLabelByIdFilter }}</button>
 * ```
 */
export function pmLabelByIdFilter(pmId) {
  try {
    return store?.getters?.pmIdx?.[pmId]?.['label'] || 'not available'
  } catch (error) {
    console.error(error)
    return 'not available'
  }
}
/**
 * 过滤器：根据 资源name 从 资源Name索引 中获取资源名称
 * @param {string} pmFullname - 权限 ID
 * @return {(string|'not available')}  资源 label 或者 'not available'
 * @useage
 * ```html
 * <button>{{ 'web:sys.sysLog' | pmLabelByNameFilter }}</button>
 * ```
 */
export function pmLabelByNameFilter(pmFullname) {
  try {
    return store?.getters?.pmNamex?.[pmFullname]?.['label'] || 'not available'
  } catch (error) {
    console.error(error)
    return 'not available'
  }
}
