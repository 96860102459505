/**
 * @module utils/session
 */

import { safeJsonParse } from '@/utils'
import { isArray, isPlainObject } from '@/utils/validate'

/**
 * @param {('session' | 'local')} type
 * @param {string} key
 * @param {any} val
 */
export const set = (type, key, val) => {
  if (!legalType(type)) return void console.error('illegal "type"', type)
  try {
    if (isPlainObject(val) || isArray(val)) {
      window[`${type}Storage`].setItem(key, JSON.stringify(val))
    } else window[`${type}Storage`].setItem(key, val)
  } catch (error) {
    console.error(error)
  }
}

/**
 * @param {('session' | 'local')} type
 * @param {string} key
 */
export const get = (type, key) => {
  if (!legalType(type)) return void console.error('illegal "type"', type)
  try {
    const value = window[`${type}Storage`].getItem(key)
    if (value) {
      // eslint-disable-next-line no-unused-vars
      const [e, result] = safeJsonParse(value)
      return result || ''
    }
  } catch (error) {
    console.error(error)
  }
  return ''
}

/**
 * @param {('session' | 'local')} type
 * @param {string} key
 */
export const remove = (type, key) => {
  if (!legalType(type)) return void console.error('illegal "type"', type)
  try {
    window[`${type}Storage`].removeItem(key)
  } catch (error) {
    console.error(error)
  }
}

/**
 * @param {('session' | 'local')} type
 */
export const clear = (type) => {
  if (!legalType(type)) return void console.error('illegal "type"', type)
  try {
    window[`${type}Storage`].clear()
  } catch (error) {
    console.error(error)
  }
}

export default {
  set,
  get,
  remove,
  clear
}

function legalType(type) {
  return ['session', 'local'].includes(type)
}
