/**
 * @module api-scm:estimationReceivable
 */
import service from '@/service'

/**
 * 查询 财务-暂估应收单列表
 * @param {{limit:number,page:number,sort:string}} data
 */
export function getList(data) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/getList',
    method: 'post',
    data
  })
}

/**
 *  树形下拉
 * @param {{orderCode,id}}
 */
export function getListDown(id, orderCode) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/public/getPullList',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/public/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/*
详情
*/
export function info(id) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  回冲单反核销
 * @param {object} data
 */

export function reverseWriteOff(data) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/public/reverseWriteOff',
    method: 'post',
    data
  })
}

/**
 *  作废
 * @param {object} data
 */

export function invalid(id) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/public/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 获取成本信息
 */
export function getCostInformation(ids) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/getCostInformation',
    method: 'get',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 暂估应收单数据Excel导出
 */
export function exportEstimationReceivablesData(data) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/exportEstimationReceivablesData',
    method: 'post',
    data,
    responseType: 'blob',
    saveAs: true,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 暂估应收单导入（手动导入时使用）
 */
export function importEstimationReceivablesData(data) {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/importEstimationReceivablesData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 暂估应收单数据Excel模板下载
 */
export function downloadExcel() {
  return service['api-web']({
    url: '/fin/ar/finEstimationReceivables/public/downloadExcel',
    method: 'post',
    responseType: 'blob',
    saveAs: false, // 文件流是否通过浏览器下载， 下载的模板文件名需要有 buttonGroup 配置，所有不通过 axios 下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
