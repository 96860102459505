/**
 * @module api-web:financialAffairs/generalLedger/data/voucherLetter
 */
import service from '@/service'

/**
 * 查询 财务-总账-资料-凭证字
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/data/finVoucherLetter/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
