import { isPlainObject } from '@/utils/validate'
export default {
  /**
   * 下拉按钮监听
   */
  name: 'dropdownListenerMixin',
  methods: {
    /**
     * 下拉按钮 command 处理
     * @description 优先绑定外部 on 配置的处理器，降级绑定 内部处理 + $emit('click')
     * @returns {{command:Function}}
     */
    getDropdownHandler(parentItem) {
      // const evtName = parentItem.evtName || parentItem.label
      // console.log('parentItem', parentItem)
      const itemOnCommand =
        isPlainObject(parentItem) &&
        isPlainObject(parentItem.on) &&
        typeof parentItem.on.command === 'function'
          ? parentItem.on.command
          : null

      const defaultCommandHandler = function (subItem) {
        this.hanldeClickDropDown({ parentItem, subItem })
      }.bind(this)

      if (itemOnCommand) {
        // console.info(
        //   `「${
        //     evtName || JSON.stringify(parentItem)
        //   }」下拉按钮组配置了外部点击事件处理器`,
        //   parentItem
        // )
        return {
          command: (subItem) => {
            const subEvtName = subItem.evtName || subItem.label
            if (subEvtName === '工作流程') {
              // 工作流程下拉子按钮暂不支持自定义事件
              this.hanldeClickDropDown({ parentItem, subItem })
              console.warn('工作流程按钮暂不支持自定义事件')
            } else {
              console.log('event context:', this.eventContext)
              itemOnCommand.call(this.eventContext, {
                parent: parentItem,
                button: subItem,
                defaultHandler: defaultCommandHandler
              })
            }
          }
        }
      } else {
        // console.info(
        //   `「${
        //     evtName || JSON.stringify(parentItem)
        //   }」下拉按钮组未配置外部点击事件处理器`,
        //   parentItem
        // )
        return {
          command: defaultCommandHandler
        }
      }
    }
  }
}
