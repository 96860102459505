/**
 * @module api-scm:filter-opts
 * @description common apis : dropdown select options
 */
import service from '@/service'

/**
 * @param {{Object}} params
 * @returns
 * @description 物流商
 */
export function getLogisticsCompanyOpts(params) {
  return service['api-scm']({
    url: '/public/logistics/getLogisticsNoContact',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 根据商品编码和仓库编码获取库位代码
export function getLocationCodeOpts(params) {
  return service['api-scm']({
    url: '/public/inventory/getAllInventoryCodeByProductAndWarehouseCode',
    method: 'get',
    params
  })
}

// 根据库位管理主键id获取所有库存明细信息(含批次号)
export function getInventoryInfoOpts(params) {
  return service['api-scm']({
    url: '/public/inventory/getAllInventoryProductInfoByDetailId',
    method: 'get',
    params
  })
}

/**
 * @typedef {Object} params
 * @property {string} batchCode -- 批次号(可选模糊查询)
 * @property {string} inventoryCode -- 库位代码
 * @property {string} productCode -- 商品编码
 * @property {string} shipper -- 调出货主
 * @property {string} warehouseCode -- 调出仓库
 * 根据调出仓库、调出货主、商品编码、库位代码获取库存数量、可配数信息
 */
export function getAllInventoryProductInfoByBatchCodeOpts(params) {
  return service['api-scm']({
    url: '/public/inventory/getAllInventoryProductInfoByBatchCode',
    method: 'get',
    params
  })
}
/**
 * @param {object} params - 源单订单号
 * @returns {Promise<Array>}
 * @description 根据源单单号查询所有关联的物流单号
 */
export function getLogisticsNumberOpts(params) {
  return service['api-scm']({
    url: '/public/logistics/getLogisticsNumberByCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {*} params
 * @returns
 * @description 物流公司(能够带出账期基本信息)
 */
export function getLogisticsNoContactOpts(params) {
  return service['api-scm']({
    url: '/public/logistics/getLogisticsNoContact',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 京东快递物流商
 */
export function getJdlogisticsOpts(params) {
  return service['api-scm']({
    url: '/public/logistics/getJDLogistics',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 获取承运商
 */
export function getLogisticsCarrierOpts(params) {
  return service['api-scm']({
    url: '/public/logistics/findCarrierList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
