import Vue from 'vue'
const state = () => ({
  /**
   * @description print用于存储浏览器打印时的数据，每次打印都会存储最新的数据
   */
  print: []
})

const mutations = {
  /**
   * @description 为print设置数据
   * @param {*} state
   * @param {*} data
   */
  SET_PRINT_INFO: (state, data) => {
    state.print = data
  },

  /**
   * @description 设置突发情况下的变量（仅存在的print并不满足需求时）
   * @param {*} state
   * @param {Array} payload  要创建的变量集合
   */
  SET_PRINT_VARIABLE: (state, payload) => {
    payload.forEach((v) => {
      Vue.set(state, v.variable, v.data)
    })
  }
}

const actions = {
  getChildWindow({ commit }, data) {
    commit('SET_PRINT_INFO', data)
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
