/**
 * @module api-web:user
 * @description  user info, user permissions
 */
import service from '@/service'

/**
 * @description 查询当前登录的用户具体详情
 */
export function getDetails() {
  return service['api-web']({
    url: '/public/infoOne',
    method: 'get'
  })
}
/**
 * @param {{newPassword:string,password:string}} data
 * @description 直接修改当前登录的用户密码
 */
export function updatePassword(data) {
  return service['api-web']({
    crypt: { type: 'aes(rsano)', canSkip: false },
    url: '/public/password',
    method: 'put',
    data
  })
}
