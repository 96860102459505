/**
 * @module api-web:billPeriodSetup
 */
import service from '@/service'

/**
 * 查询 财务-1应收账龄分析 2-预收款报表动态表头
 * @param {number} type -- 列表类型 1-应收账龄分析 2-预收款报表
 */
export function getTableHead(params) {
  return service['api-web']({
    url: '/fin/ar/finBillPeriodSetup/public/getTableHead',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 查询 财务-过滤报表账龄分组设置列表
 */
export function read(params) {
  return service['api-web']({
    url: '/fin/ar/finBillPeriodSetup/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *  财务-过滤报表账龄分组设置删除
 */
export function remove(params) {
  return service['api-web']({
    url: '/fin/ar/finBillPeriodSetup/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  财务-过滤报表账龄分组设置新增
 */
export function create(data) {
  return service['api-web']({
    url: '/fin/ar/finBillPeriodSetup/save',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  财务-过滤报表账龄分组设置修改
 */
export function update(data) {
  return service['api-web']({
    url: '/fin/ar/finBillPeriodSetup/update',
    method: 'put',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
