// eslint-disable-next-line spaced-comment
/// <reference path="../../typedef.js" />

import { isArray } from '@/utils/validate'

/**
 * @module permission/resources/categoary/btn-operations
 */

/**
 * @param {RolePermissionFlatItem}
 * @returns {{name:string,id:(number|null),label:string}} 资源权限之按钮操作单项
 */
export const transItem = ({ id, label, pmUrl }) => {
  try {
    const spliced = pmUrl.split('/').splice(2)
    return {
      id,
      label,
      name: spliced.length ? spliced.join('.') : ''
    }
  } catch (error) {
    console.error(error)
    return { name: '', id: null, label: '' }
  }
}
/**
 * @param {Array<{name:string,id:(number|null),label:string}>} list 按钮列表
 * @returns {Array<{name:string,id:number,label:string}>} 按钮列表
 */
export const filterList = (list) => {
  if (!isArray(list)) {
    console.error('list illegal (btn-operations)')
    return []
  }
  return list.filter((v) => !!v.name && typeof v.id === 'number')
}
