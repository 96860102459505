/**
 * @description
 * __global-auto-register__ 自动全局注册组件专属目录:
 *
 * - 目前只支持 “__global-auto-register__/大驼峰.vue” 和 “__global-auto-register__/大驼峰目录/index.vue(嵌套一层)”
 * - 应当只放置全局大范围使用的基础组件
 * - 组件将自动全局注册，无须局部重复注册
 */

import Vue from 'vue'
import { uppercaseFirst, camelCase } from '@/utils/string'
const requireComponent = require.context(
  // 自动全局注册组件
  './__global-auto-register__',
  // 是否查询其子目录
  true,
  // 匹配基础组件文件名的正则表达式;
  /(?:vue)$/
)

console.groupCollapsed('全局自动组册组件')
requireComponent.keys().forEach((fileName) => {
  let componentName

  const splited = fileName.split('/')
  switch (splited.length) {
    case 2: {
      const theCamelCaseReg = /[A-Z]\w+\.vue$/
      const theNeedsPart = splited.pop()
      if (theCamelCaseReg.test(theNeedsPart)) {
        componentName = uppercaseFirst(
          camelCase(theNeedsPart.replace(/\.\w+$/, ''))
        )
      } else {
        console.error(
          '【__global-auto-register__】: please use "CamelCase.vue"',
          fileName
        )
      }
      break
    }
    case 3: {
      if (splited[0] === '.' && splited[2] === 'index.vue') {
        const theCamelCaseReg = /[A-Z]\w+$/
        const theNeedsPart = splited[1]
        if (theCamelCaseReg.test(theNeedsPart)) {
          componentName = uppercaseFirst(camelCase(theNeedsPart))
        } else {
          console.error(
            '【__global-auto-register__】: please use "CamelCase"',
            fileName
          )
        }
      } else {
        console.error(
          '【__global-auto-register__】: please use "CamelCase/index.vue"',
          fileName
        )
      }
      break
    }
    default:
      Vue.nextTick(function () {
        console.error(
          '【__global-auto-register__】: Not support',
          `/src/componetns/__global-auto-register__${fileName.split('.')[1]}`
        )
      })
      break
  }

  if (componentName) {
    // 获取组件配置
    const componentConfig = requireComponent(fileName)
    // 全局注册组件
    Vue.component(
      componentName,
      // 如果这个组件选项是通过 `export default` 导出的，
      // 那么就会优先使用 `.default`，
      // 否则回退到使用模块的根。
      componentConfig.default || componentConfig
    )
    // 日志
    console.log(componentName)
  }
})
console.groupEnd()
