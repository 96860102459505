/**
 * @module api-activiti:home/todo/process
 * @description 首页/流程待办
 */
import service from '@/service'

/**
 * 我的待办列表
 * @param {*} params
 */
export function getMyTodoList(data) {
  return service['api-activiti']({
    url: '/sys/process/task/getMyTask',
    method: 'post',
    data
  })
}

/**
 * 我的已完成列表
 * @param {*} params
 */
export function getMyDoneList(data) {
  return service['api-activiti']({
    url: '/sys/process/task/getMyComplete',
    method: 'post',
    data
  })
}
/**
 * 部门待办列表
 * @param {*} params
 */
export function getDeptTodoList(params) {
  return service['api-activiti']({
    url: '/sys/process/task/getDeptTask',
    method: 'get',
    params
  })
}

/**
 * 部门已完成列表
 * @param {*} params
 */
export function getDeptDoneList(params) {
  return service['api-activiti']({
    url: '/sys/process/task/getDeptComplete',
    method: 'get',
    params
  })
}

export default {
  getMyTodoList,
  getMyDoneList,
  getDeptTodoList,
  getDeptDoneList
}
