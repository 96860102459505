// eslint-disable-next-line spaced-comment
/// <reference path="../typedef.js" />

import { isArray, isString } from '@/utils/validate'
import { camelCase } from '@/utils/string'
import { unflattenAsync } from '@/utils/tree'
import { validPath } from '../utils'
import { tailRoutes, createRoutesHandlers } from '@/router'
import { transformFirstToRoot, generateRoutes } from './routes'
import { uniqueObjArr } from '@/utils/array'
/**
 * @module permission/menus
 */

/**
 * 预处理后端返回的列表
 * @param {RolePermissionFlat} rolePermissions
 * @returns {RolePermissionFlat}
 */
export function preProcess(rolePermissions) {
  /**
   * pmUrl 映射器
   */
  const PM_URL_MAPPER = {
    '/public/**': '',
    '/save/**': '/save',
    '/saveAll/**': '/saveAll',
    '/delete/**': '/delete',
    '/deleteAll/**': '/deleteAll',
    '/update/**': '/update',
    '/updateAll/**': '/updateAll',
    '/get/**': '/getList',
    '/getList/**': '/getList',
    '/info/**': '/info',
    '/infoAll/**': '/infoAll'
  }

  /**
   * path 映射器
   */
  const PATH_MAPPER = {
    list: 'list/:id', // 列表页使用 id 命名路由，支持同一列表页多开并行
    detail: 'detail/:id', // 详情页使用 id 命名路由，支持多详情页并行
    liaison: 'liaison/:id', // 供应链》订单管理》待拆单》联络单
    processDesign: 'processDesign/:id', // 流程设计页使用 id 命名路由，支持多流程页并行
    'nested-nav': (v) => camelCase(v.pmUrl.split('/').slice(-1)[0]), // 内嵌导航 path 字段均为 nested-nav, 改为 pmUrl 末位路径
    estimateManualOff: 'estimateManualOff/:id', // 财务》应收款管理》暂估应收手工核销 过滤报表
    summary: 'summary/:id', // 财务》应收款管理》应收款汇总表 过滤报表
    analyze: 'analyze/:id', // 财务》应收款管理》应收账龄分析 过滤报表
    report: 'report/:id', // 财务》应收款管理》预收款报表 过滤报表
    receiptManualOff: 'receiptManualOff/:id', // 财务》应收款管理》应收收款手工核销 过滤报表
    receiptManualOn: 'receiptManualOn/:id', // 财务》应付款管理》应付付款手工核销 过滤报表
    finApStatements: 'finApStatements/:id', // 财务》应付款管理》应付款报表 过滤报表
    scanInspection: 'scanInspection/:id', // 财务》应付款管理》应付款报表 过滤报表
    generateVoucher: 'generateVoucher/:id', // 财务》总账》凭证管理》凭证生成 过滤报表
    finProfitLossConvert: 'finProfitLossConvert/:id', // 财务》总账》期末处理》结转损益 结转损益
    queryListByPage: 'queryListByPage/:id', // 任务管理中心》下载任务中心
    kingdee: 'kingdee/:id' // 系统对接》金蝶对接中心
  }

  return rolePermissions.map((v) => {
    const { path: originalPath, pmUrl: originPm, ...rest } = v
    const isNestedNav = originalPath === 'nested-nav'
    let path = originalPath?.trim()
    let pmUrl = originPm
    try {
      if (path) {
        // pre-process "path"

        const pathReplacement = PATH_MAPPER[path]

        if (pathReplacement) {
          if (typeof pathReplacement === 'function') {
            path = pathReplacement(v).trim()
          } else if (isString(pathReplacement)) {
            path = pathReplacement.trim()
          }
        }

        // pre-process "pmUrl"
        /**
         * @description 权限类逻辑确保可控，暂不使用 repalce('/**','')
         * 另外也有特殊情况，如 /get/**  => /getList
         */
        const pmUrlKeys = [
          originPm.slice(-7),
          originPm.slice(-8),
          originPm.slice(-10),
          originPm.slice(-11),
          originPm.slice(-13)
        ]
        pmUrlKeys.forEach((slice) => {
          /**
           * slice: key of PM_URL_MAPPER
           * @see PM_URL_MAPPER
           */
          const target = PM_URL_MAPPER[slice]
          if (isString(target)) pmUrl = originPm.replace(slice, target)
        })
      }
    } catch (error) {
      console.errror(error)
    }

    return {
      isNestedNav,
      pmUrl,
      path,
      sort: 0, // default sort, will covered by 'rest'
      ...rest
    }
  })
}
/**
 * 动态创建追加菜单
 * 虽无角色权限控制，但需要条件判断
 * @param {RolePermissionFlat} rolePermissions
 * @param {Array<{parentId:number,createKey:string,createParams:any}>} descriptions
 * @returns {RolePermissionFlat}
 */
export const supplement = async (rolePermissions, descriptions = []) => {
  if (!isArray(descriptions)) {
    console.error('Illeagl "descriptions" for "supplement"')
    return Promise.resolve(rolePermissions)
  }

  if (!descriptions.length) {
    console.warn('Empty "descriptions" for "supplement"')
    return Promise.resolve(rolePermissions)
  }

  /**
   * @param {{{parentId:number,createKey:string,createParams:any}}} desc
   * @returns {Promise<boolean>}
   */
  const descriptionMapper = (desc) =>
    new Promise((resolve) => {
      const createFnName = camelCase(desc.createKey)
      const createFn = createRoutesHandlers[createFnName]
      if (typeof createFn === 'function') {
        createFn(desc.parentId, desc.createParams)
          .then((created) => {
            if (isArray(created) && created.length) {
              rolePermissions.push(...created)
              resolve(true)
            } else {
              console.error(`Created illegal children by ${createFnName}`)
              resolve(false)
            }
          })
          .catch((err) => {
            console.error(err)
            resolve(false)
          })
      } else {
        console.error(`Can't find ${createFnName}`)
        resolve(false)
      }
    })

  const addChildrenPromises = descriptions.map(descriptionMapper)
  return new Promise((resolve) => {
    Promise.all(addChildrenPromises)
      .catch((err) => console.error(err))
      .finally(() => resolve(rolePermissions))
  })
}
/**
 * 生成角色菜单权限 (路由/侧边栏)
 * @param {RolePermissionFlat} rolePermissions
 * @param {PermissionIndexTable} indexTable
 * @returns {Array<import('vue-router').Route>} 路由列表
 */
export async function createMenus(rolePermissions, indexTable) {
  // const _rolePermissions = rolePermissions.sort((a, b) => a.id - b.id)
  // covert flat-list  to tree-list (just routes, node that has "path")
  const tree = await unflattenAsync(
    rolePermissions.filter(({ path }) => validPath(path) && path !== '/')
  )
  // map the role permission tree to vue route list
  const routes = generateRoutes(tree, indexTable)
  // splice first route and covert to root
  const root = transformFirstToRoot(routes)

  // done
  const result = root
    ? [{ path: '/', redirect: root.path }, root, ...routes, ...tailRoutes]
    : [{ path: '/', redirect: routes[0].path }, ...routes, ...tailRoutes]

  // 'path' of item maybe duplidation ('path' must unique)
  const unique = uniqueObjArr(result, 'path')

  if (result.length !== unique.length) {
    console.error('Find duplidation item when create menus.')
  }

  return unique
}
export default createMenus
