import { isNumeric } from '@/utils/validate'

/**
 * 数字前补零
 * @param {*} num
 * @param {*} length
 * @returns
 */
export function prefixZero(num, length) {
  return ('0000000000000000' + num).substr(-length)
}

/**
 * fixed
 * @param {number} num
 * @param {number} [digits=2]
 * @returns {string}
 * @description just call toFixed
 */
export function fixed(num, digits = 2, options = {}) {
  const { isDefaultValueZero = false } = options

  let _num = num

  if (!_num && isDefaultValueZero) {
    _num = 0
  }

  return isNumeric(_num) ? (_num * 1).toFixed(digits) : _num
}
/**
 * rounding
 * @param {number} num
 * @param {number} [digits=2]
 * @returns {number}
 * @description 精确小数点后位精度
 */
export function rounding(num, digits = 2) {
  try {
    const places = Math.pow(10, digits)
    const value =
      Math.round(
        (num * 1 >= 0 ? num * 1 + 0.000000001 : num * 1 - Number.EPSILON) *
          places
      ) / places
    // console.log('rounding:', num, digits, places, value)
    if (isNumeric(value)) return value
    else {
      console.error('rounding error', num, digits, places, value)
    }
  } catch (error) {
    console.error('rounding error', num, digits, error)
  }
  return num
}

/**
 * roundingFixed
 * @param {number} num
 * @param {number} [digits=2]
 * @returns {string}
 * @description  精确小数点后位精度后, 返回 toFixed 字符串
 */
export function roundingFixed(num, digits = 2) {
  try {
    const result = rounding(num, digits)
    if (isNumeric(result)) return result.toFixed(digits)
    else console.error('roundingFixed error', num, digits, result)
  } catch (error) {
    console.error('roundingFixed error', num, digits, error)
  }
  return num
}
/**
 * dividing
 * @param {(string|number)} n 数字或者数字型字符串
 * @returns {('-'|'0'|'+'|undefined)} '-'负数  '0'零  '+'正数
 * @description 判断数字正、负、零
 */
export function dividing(n) {
  const _num = isNumeric(n) ? n * 1 : NaN
  if (isNaN(_num)) {
    console.error('illegal number for dividing:', n)
    return undefined
  }
  switch (true) {
    case _num === 0:
      return '0'
    case _num > 0:
      return '+'
    case _num < 0:
      return '-'
  }
}
// 生成一个随机ID的函数
export function generateRandomId() {
  return Math.random().toString(36).substr(2, 9) // 生成一个长度为 9 的随机字符串
}

export default {
  prefixZero,
  rounding,
  roundingFixed,
  fixed,
  dividing,
  generateRandomId
}
