/**
 * @module api-scm:warehouseCheck
 */
import service from '@/service'

/**
 * 库存盘点   表查询
 * @param {{limit:number,page:number,sort:string,warehouseCheckName:string,warehouseCheckTimeEnd:string,warehouseCheckTimeStart:string,warehouseCode:string}} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/get/getList',
    method: 'get',
    params
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/info/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehouseCheck/info/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增
 * @param {object} data
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/save',
    method: 'post',
    data
  })
}

/**
 * @description 修改(盘亏/盈单)
 * @param {object} data
 */
export function updateInventory(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/update/update',
    method: 'post',
    data
  })
}

/**
 * @description 库存盘点明细修改（基本信息）
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/update',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseCheck/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 库存盘点   goods下拉查询
 */
export function getGoodsListPull(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/update/getListDown',
    method: 'get',
    params
  })
}
/**
 * 作废
 * @param {{ids:Array<string>}} ids
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 *  查询库存盘点详情中 的列表
 */
export function getWarehouseCheckDetailList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/update/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *   树形表格下拉
 * @param {{productCode:string}} productCode
 */
export function getListDown(productCode) {
  return service['api-web']({
    url: '/scm/warehouse/warehouseCheck/update/getListDown',
    method: 'get',
    params: { productCode },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 新增 库存盘点详情中 的列表
 * @param {object} data
 */
export function createList(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/warehouseCheckDetailData/saveDetail',
    method: 'post',
    data
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出库存盘点明细(列表按钮)
 */
export function exportData(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/warehouseCheckDetailData/export',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false // 文件流是否通过浏览器下载
  })
}

/**
 * @description 导出库存盘点明细(详情按钮)
 */
export function exportDetailData(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/exportDetail',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false // 文件流不通过浏览器自动下载 此时自己定义名字
  })
}

// /* 下载模版 TODO暂时没有下载模版*/
// export function downloadExcel() {
//   return service['api-scm']({
//     url: '',
//     method: 'post',
//     responseType: 'blob', // 文件流接收类型声明转成 blob
//     saveAs: false, // 文件流是否通过浏览器下载， 下载的模板文件名需要有 buttonGroup 配置，所有不通过 axios 下载
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }
/**
 * @description 库存盘点明细Excel导入
 * 自动上传无需配置 axios 请求，只需要api地址 传递给 @see UploadFileDialog 组件通过 action 自动上传即可
 */
export function importData() {
  return '/scm/warehouse/warehouseCheck/importDetail'
}

/**
 * @description 库存盘点明细Excel导入手动导入时使用）
 */
export function importDetailDetails(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/importDetail',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 查询可以盘点的数据
 */
export function queryCanCheckData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/queryCanCheckData',
    method: 'post',
    data
  })
}

/**
 * @description 生成库存盘点信息（盘亏盈）
 */
export function generateWarehouseCheckData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseCheck/generateWarehouseCheckData',
    method: 'post',
    data
  })
}
