// eslint-disable-next-line spaced-comment
/// <reference path="typedef.js" />

/**
 * @module permission/index
 */
import store from '@/store'
import { createRoutesConfigs } from '@/router'
import createIndexes from './indexes'
import { preProcess, supplement, default as createMenus } from './menus'
import createResources from './resources'
/**
 * 生成角色权限
 * @param {RolePermissionFlat} rolePermissions Fetch from remote service
 * @param {('create'|'append')} [type='create'] Initially create or dynamically append at runtime
 * @returns {{idIndex:Object,nameIndex:Object,menus:Array,resource:Object}|{}}  Role permissions
 */
export async function generateRolePermissions(
  rolePermissions,
  type = 'create'
) {
  try {
    if (!['create', 'append'].includes(type)) {
      console.error('Illegal "type" for "generateRolePermissions"', type)
      return {}
    }

    if (type === 'create') {
      // 0. local create and inject into source rolePermissions
      const permissionListPlus = await supplement(
        rolePermissions,
        createRoutesConfigs
      )
      // 1. store: create
      await store.dispatch('permission/setSource', permissionListPlus)
    } else if (type === 'append') {
      // 1. store: append
      await store.dispatch('permission/increaseSource', rolePermissions)
    }

    // 2. pre-process remote role permisson list and local dynamic created route
    const permissionList = store.getters.pmList
    const permissionListProcessed = preProcess(permissionList)

    // 3. create role permission indexes
    const indexes = await createIndexes(permissionListProcessed, type)

    // 4. create role menus (routes)
    const menus = await createMenus(permissionListProcessed, indexes)

    // 5. create role resources (nested-navs/btn-operations)
    const resources = await createResources(indexes)

    // logs
    console.groupCollapsed(`生成角色权限 (${type})`)
    console.log('- 菜单路由:')
    console.log(JSON.parse(JSON.stringify(menus)))
    console.log('- 资源索引:')
    console.log('    按钮操作:', JSON.parse(JSON.stringify(resources.btns)))
    console.log('    内嵌导航:', JSON.parse(JSON.stringify(resources.navs)))
    console.groupEnd()

    return { ...indexes, menus, resources }
  } catch (error) {
    console.error('"generateRolePermissions"', error)
  }
  return {}
}

export default generateRolePermissions
