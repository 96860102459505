import Sortable from 'sortablejs'
import { JSONStringify, JSONParse } from '@/utils/jsonModification'
import { getUserTableSort, setUserTableSort } from '../../service/tableDrag'
export default {
  bind: function (el, binding, vnode) {
    if (binding.value) {
      getUserTableSort(
        vnode.context.$store.state.user.id,
        'erp',
        vnode.context.$store.state.user.jobId,
        binding.value
      ).then((res) => {
        if (res.code === 200 && res.info && res.info.columns) {
          var serverCol = JSONParse(res.info.columns)
          var oldRenderCol = vnode.child.getColumns()

          var newRenderCol = []
          newRenderCol = serverCol.map((item2) => {
            return oldRenderCol.find(
              (item1) => (item1.property ?? item1.type) === item2.field
            )
          })

          // 判断如果没有任何匹配数据，则使用老的表头
          if (newRenderCol.length > 0) {
            vnode.context.$nextTick(() => {
              vnode.child.loadColumn(newRenderCol)
            })
          }
        }
      })
    }

    // 拖拽时，生成dom
    var dragLoading = document.createElement('div')
    dragLoading.className = 'vxe-table--loading vxe-loading is--visible'
    dragLoading.style.display = 'none'
    dragLoading.innerHTML = `
			<div class="vxe-loading--spinner">

			</div>
		`
    el.querySelector('.vxe-table--header-wrapper').append(dragLoading)
    // el.style.cursor = 'move'
    new Sortable(el.querySelector('.vxe-header--row'), {
      draggable: 'th',
      handle: '.vxe-cell',
      animation: 500,
      direction: 'vertical',
      ghostClass: 'sortable-ghost',
      chosenClass: 'sortable-chosen',
      dragClass: 'sortable-drag',
      forceFallback: true,
      filter: '.col--checkbox',

      onEnd: async (e) => {
        var oldCol = vnode.child.getColumns()
        var oldTarget = oldCol.splice(e.oldIndex, 1)
        oldCol.splice(e.newIndex, 0, oldTarget[0])

        vnode.child.refreshColumn()
        await vnode.child.loadColumn([])
        vnode.context.$nextTick(() => {
          vnode.child.loadColumn(oldCol)
          // 保存排序信息
          var saveCol = []
          for (var i = 0; i < oldCol.length; i++) {
            saveCol.push({
              field: oldCol[i]['property'] ?? oldCol[i]['type'],
              fixed: oldCol[i]['fixed'] === 'right',
              fixedLeft: oldCol[i]['fixed'] === 'left',
              title: oldCol[i]['title'] || ''
            })
          }
          if (binding.value) {
            setUserTableSort({
              userId: vnode.context.$store.state.user.id,
              projectName: 'erp',
              systemType: vnode.context.$store.state.user.jobId,
              tableId: binding.value,
              columns: JSONStringify(saveCol)
            })
          }

          dragLoading.style.display = 'none'
        })
      }
    })
  }
}
