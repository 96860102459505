/**
 * @module api-scm:warehouseAllot
 */
import service from '@/service'

/**
 * 库存调拨单   表查询
 * @param {{limit:number,page:number,sort:string,productCode:string,productName:string,warehouseCode:string}} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/get/getList',
    method: 'get',
    params
  })
}

/**
 * 库存调拨单   树形表格下拉
 * @param {{code:string}} params
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/get/getListDown',
    method: 'get',
    params: { code },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 库存调拨单   新增
 * @param {object} params
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/save',
    method: 'post',
    data
  })
}

/**
 * 库存调拨单   修改
 * @param {object} params
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/update',
    method: 'post',
    data
  })
}

/**
 * 库存调拨单   详情
 * @param {{id:string}} params
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehouseAllot/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:Array<number>}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// 根据调出仓库、调出货主、商品编码、库位代码、批次号获取 库存数量以及可配数
export function getNumByAll(params) {
  return service['api-scm']({
    url: '/public/inventory/getNumByAll',
    method: 'get',
    params
  })
}

/**
 * @param {object}
 * @returns 审核驳回
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseAllot/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} ids
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 作废
 * @param {{ids:Array<string>}} id
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {(number|string)} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 导出库存调拨单
 */
export function exportData(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseAllot/warehouseAllotData/export',
    method: 'get',
    params,
    responseType: 'blob' // 文件流接收类型声明转成 blob
  })
}
