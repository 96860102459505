/**
 * @typedef {Object} Config
 * @property {string} methodName
 * @property {string} apiName
 * @property {boolean} defaultCallback
 * @property {('number'|'string')} idType
 *
 * @typedef {Record<string,Config>} Mapper
 * @description 字段均可被 buttonGroup 'item config 覆盖
 */

/**
 * @type {Mapper}
 * @description dropdown sub-button 映射器
 */
export const MAPPER = {
  /**
   * 父级：选项
   */
  导入: {
    methodName: 'importData'
  },
  导出: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  下载模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  下载销售订单模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },

  商品资料导入模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  渠道价格导入模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  采购价格导入模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  库存盘点明细模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  暂估应付单导入模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  暂估应收单导入模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  销售订单导入模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  合同订单导入模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  供应商信息导入模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  sku信息导入模板: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  /* 这2个公用权限需要指定中文名无法转译 */
  导入库存盘点明细: {
    methodName: 'importData'
  },
  导出库存盘点明细: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  /**
   * 父级：关联查询
   */
  /**
   * @see workProcess.js
   */
  工作流程: { methodName: 'workProcess' },
  /**
   * 父级：凭证
   */
  生成凭证: {
    methodName: '',
    defaultCallback: true
  },
  查看业务凭证: {
    methodName: '',
    defaultCallback: true
  },
  查看总账凭证: {
    methodName: '',
    defaultCallback: true
  },
  /**
   * 父级：结账
   */
  结账: {
    methodName: 'checkout',
    defaultCallback: true
  },
  反结账: {
    methodName: 'reverseCheckout',
    defaultCallback: true
  },
  /**
   * 父级：收款单/付款申请单
   */
  关闭财务收付单: {
    methodName: 'turnOffFin',
    defaultCallback: true
  },
  反关闭财务收付单: {
    methodName: 'reverseCloseFin',
    defaultCallback: true
  },
  /**
   * 父级：业务操作
   */
  启用: {
    methodName: 'enable',
    defaultCallback: true
  },
  禁用: {
    methodName: 'disable',
    defaultCallback: true
  },
  导出合同: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  导出报价单: {
    methodName: 'downloadFile',
    showDirPicker: false,
    defaultCallback: false
  },
  上传收款凭证: {
    methodName: 'uploadVoucher',
    defaultCallback: true
  },
  /**
   * 使用通用处理器
   */
  作废: {
    methodName: 'commonHandler',
    apiName: 'invalid',
    idType: 'number',
    defaultCallback: true
  },
  /**
   * 使用通用处理器
   */
  对账: {
    methodName: 'commonHandler',
    apiName: 'reconciliation',
    idType: 'string',
    defaultCallback: true
  },
  /**
   * 使用通用处理器
   */
  关闭: {
    methodName: 'commonHandler',
    apiName: 'close',
    idType: 'number',
    defaultCallback: true
  },
  整单关闭: {
    methodName: 'close',
    apiName: 'close',
    idType: 'string',
    defaultCallback: true
  }
  // 反关闭:{ methodName:''}
  // 整单关闭:{ methodName:''}
  /**
   * 订单拦截
   */
  // 拦截订单: { methodName: '' },
  // 取消拦截: { methodName: '' },
}
