/**
 * @module api-web:financialAffairs/generalLedger/voucherManagement/generateVoucher
 */
import service from '@/service'

/**
 * 选择账簿列表 财务-总账-凭证管理-凭证生成
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/voucherGenerate/selectAccountBook',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 来源单据列表 财务-总账-凭证管理-凭证生成
 * @param {object} params
 */
export function getSourceList(params) {
  return service['api-web']({
    url: '/fin/gl/voucherGenerate/getSourceList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 确定生成 财务-总账-凭证管理-凭证生成
 * @param {object} data
 */
export function generate(data) {
  return service['api-web']({
    url: '/fin/gl/voucherGenerate/generate',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
