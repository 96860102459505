/**
 * @module BaseInlineEditTable/mixins/valid
 * 内联编辑表格 〉宏 〉表格校验
 */
import { isArray, isPlainObject } from '@/utils/validate'
import { getSyncAsyncReturns } from '@/utils'

export default {
  methods: {
    /**
     * 校验行内表单输入域未通过事件
     * @emits valid-error
     */
    onValidError(scope) {
      !this.flags.validError ? (this.flags.validError = true) : null
      this.$emit('valid-error', scope)
    },
    /**
     * 校验行内表单输入域前钩子函数
     * @param {import('vxe-table').RowInfo} row
     * @returns {{row:(import('vxe-table').RowInfo|Array<import('vxe-table').RowInfo>|null),skip:boolean}}
     * @description row 转移后的校验目标 skip 是否直接跳过校验
     */
    async beforeValidRow(row) {
      const res = {
        row: null,
        skip: false
      }
      try {
        if (isPlainObject(row)) {
          if (this.isTree) {
            if (typeof this.treeBeforeValid === 'function') {
              const currentTableData = this.$table.getTableData().tableData
              const [error, result] = await getSyncAsyncReturns(
                this.treeBeforeValid,
                null,
                {
                  $table: this.$table,
                  row,
                  isParent: this.isTreeParentRow(row),
                  hasChildren: this.treeIsLazy
                    ? row[this.treeHasChildField]
                    : isArray(row?.[this.treeChildrenField]) &&
                      row[this.treeChildrenField].length > 0,
                  tableData: currentTableData,
                  parentRows: currentTableData.filter((row) =>
                    this.isTreeParentRow(row)
                  ),
                  childRows: currentTableData.filter(
                    (row) => !this.isTreeParentRow(row)
                  )
                }
              )
              if (error) {
                console.error(
                  '【BaseInlineEditTable】】valid: "beforeValidRow" ',
                  error
                )
              } else {
                if (result && isPlainObject(result)) {
                  const { validTransfer = null, skipValid = false } = result
                  if (skipValid === true) {
                    res.skip = true
                  } else if (
                    validTransfer &&
                    ((isArray(validTransfer) && validTransfer.length > 0) ||
                      (isPlainObject(validTransfer) &&
                        Object.keys(validTransfer).length > 0))
                  ) {
                    res.row = validTransfer
                  }
                }
              }
            }
          } else {
            // TODO: 非树表格校验后钩子
            // res.row = row
          }
        } else {
          // TODO: 暂不支持多行 rows or true
          console.warn('Not support multiple rows')
        }
      } catch (error) {
        console.error(error)
      }
      return res
    },
    async afterValidRow(err) {
      try {
        if (this.isTree) {
          if (this.treeValidErrFilter) {
            const currentTableData = this.$table.getTableData().tableData
            const filteredErr = err.filter((e) => {
              const row = Object.values(e)[0][0].row

              const result = this.treeValidErrFilter({
                $table: this.$table,
                row,
                isParent: this.isTreeParentRow(row),
                hasChildren: this.treeIsLazy
                  ? row[this.treeHasChildField]
                  : isArray(row.children) && row.children.length > 0,
                tableData: currentTableData,
                parentRows: currentTableData.filter((row) =>
                  this.isTreeParentRow(row)
                ),
                childRows: currentTableData.filter(
                  (row) => !this.isTreeParentRow(row)
                )
              })
              // API 命名 ignoreValidErr 便于理解，所以这边 filter 过滤 需要取反
              if (typeof result === 'boolean') return !result
              else {
                console.error(
                  '【BaseInlineEditTable】】valid: "ignoreValidErr" must be sync and return boolean ' +
                    result
                )
                return false
              }
            })

            if (!filteredErr.length) {
              await this.clearTableActived(null, { by: 'ignore-valid' })
            }

            return filteredErr
          }
        } else {
          // TODO: 非树表格校验后钩子
        }
      } catch (error) {
        console.error(
          '【BaseInlineEditTable】valid: "treeValidErrFilter" ' + error
        )
      }
      return err
    },
    /**
     * 校验行内表单输入域
     * @param {(boolean|import('vxe-table').RowInfo)=} row
     * @param {{source:string}} paylaod - source:调用来源, ...
     * @returns {{result:boolean,err:Array}} 是否通过校验
     * @description 没有校验规则则始终返回 true
     */
    async validateRow(row, { source = 'unknown' }, that) {
      this.loading(true, { type: 'button' })
      if (this.removeTemporaryDataStorage) {
        return { result: true }
      }

      try {
        if (this.flags.skipValidation || !Object.keys(this.validRules).length) {
          this.flags.validError = false
          return { result: true }
        }

        if (row) {
          const { row: _row, skip = false } = await this.beforeValidRow(row)
          if (skip === true) {
            this.flags.validError = false
            return { result: true }
          }
          await Promise.all([
            this.$table.validate(_row || row || true)
            // this.$table.validate()
          ])
        } else if (!this.saveSingleRowNotGlobal) {
          // 表格保存一行之后是否继续走全局校验
          await Promise.all([this.$table.validate()])
        }

        this.flags.validError = false
        return { result: true }
      } catch (err) {
        if (isArray(err) || isPlainObject(err)) {
          const _err = await this.afterValidRow(isArray(err) ? err : [err])

          if (_err.length) {
            console.log(
              `【BaseInlineEditTable】valid error from source, ${source}`,
              _err
            )
            this.flags.validError = true
            return { result: false, err: _err }
          } else {
            console.log(
              `【BaseInlineEditTable】 ignore valid error from source, ${source}`,
              err
            )
          }
        }
        this.flags.validError = false
        return { result: true }
      } finally {
        this.loading(false, { type: 'button' })
      }
    }
  }
}
