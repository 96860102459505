import VXETable from 'vxe-table'
import ElRemotePick from './ElRemotePick'

VXETable.renderer.add('ElRemotePicker', {
  renderEdit: (...args) => ElRemotePickRender(...args)
})

function ElRemotePickRender(h, renderOpts, params = {}) {
  const { events = {}, ...rest } = renderOpts
  return [<ElRemotePick scope={params} edit-render-props={rest} on={events} />]
}
