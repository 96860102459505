/**
 * @module api-activiti:todo/config
 */
import service from '@/service'

/**
 * @typedef {Object} TodoConfig
  @property {number} deptId - 部门 ID,
  @property {number} id - 主键
  @property {(0|1)} isEnable - 0不启用 1启用
  @property {(0|1)} personOrDept - 0部门待办 1个人待办,
  @property {string} processKey - 流程 key
  @property {string} processName - 流程名称
  @property {string} waitModule - 待办模块名称
 */

/**
 * 查询待办配置列表
 * @param {{
 * waitModule:string,
 * limit:number,
 * page:number,
 * deptId:number,
 * sort:string
 * }} params
 */
export function getList(params) {
  return service['api-activiti']({
    url: '/sys/process/sysWaitConfig/getList',
    method: 'get',
    params
  })
}

/**
 * 增加待办配置
 * @param {TodoConfig} data
 */
export function add(data) {
  return service['api-activiti']({
    url: '/sys/process/sysWaitConfig/save',
    method: 'post',
    data
  })
}

/**
 * 修改待办配置
 * @param {TodoConfig} data
 */
export function update(data) {
  return service['api-activiti']({
    url: '/sys/process/sysWaitConfig/update',
    method: 'put',
    data
  })
}

/**
 * 单个删除待办配置
 * @param {{id:number}} params
 */
export function remove(params) {
  return service['api-activiti']({
    url: '/sys/process/sysWaitConfig/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据 ID 查询待办配置详情
 * @param {number} id
 */
export function getDetailsById(id) {
  return service['api-activiti']({
    url: '/sys/process/sysWaitConfig/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 根据 名称 查询待办配置详情
 * @param {string} name
 */
export function getDetailsByName(name) {
  return service['api-activiti']({
    url: '/sys/process/sysWaitConfig/infoByName',
    method: 'get',
    params: { name }
  })
}
