/**
 * @see https://github.com/nodeca/pako
 */
import pako from 'pako'

import { str_to_base64, base64_to_str } from './base64'
import { safeJsonParse } from '.'
import { isPlainObject, isString } from './validate'
/**
 * @param {(string|object)=} raw
 * @param {{uri:boolean}} options
 * @returns {(string|undefined)}
 */
export const compress = (raw, options = { uri: true }) => {
  try {
    if (typeof raw === 'undefined') {
      console.error('Losed "raw" for compress')
      return raw
    }

    const isStr = isString(raw)
    const isObj = isStr ? false : isPlainObject(raw)

    if (!isStr && !isObj) {
      console.error('Illegal "raw" for compress', raw)
      return raw
    }

    const { uri = true } = options || {}
    const uriEncode = uri ? (i) => encodeURIComponent(i) : (i) => i
    const jsonStringify = isObj ? JSON.stringify : (i) => i
    let _raw = null
    if (isObj) {
      _raw = {}
      Object.entries(raw).forEach(([k, v]) => {
        _raw[k] = isString(v) ? uriEncode(v) : v
      })
    }
    const source = jsonStringify(_raw || raw)
    const binary = pako.deflate(source)
    return uriEncode(str_to_base64(String.fromCharCode.apply(null, binary)))
  } catch (error) {
    console.error(error)
    return raw
  }
}
/**
 * @param {string} str
 * @param {{uri:boolean}} options
 * @returns {string}
 */
export const decompress = (str, options = { uri: true }) => {
  try {
    if (typeof str === 'undefined') {
      console.error('Losed "str" for decompress')
      return str
    }

    if (!isString(str)) {
      console.error('Illegal "str" for decompress', str)
      return str
    }
    const { uri = true } = options || {}
    const uriDecode = uri ? (i) => decodeURIComponent(i) : (i) => i

    const source = base64_to_str(uriDecode(str))
      .split('')
      .map((x) => x.charCodeAt(0))
    const binary = pako.inflate(new Uint8Array(source))
    const string = Utf8ArrayToStr(binary)
    if (string.charAt(0) === '{' || string.charAt(0) === '[') {
      const [e, jsonPared] = safeJsonParse(string)
      if (!e && jsonPared) {
        if (uri) {
          Object.entries(jsonPared).forEach(([k, v]) => {
            jsonPared[k] = isString(v) ? uriDecode(v) : v
          })
        }
        return jsonPared
      }
    }
    return string
  } catch (error) {
    console.error(error)
    return str
  }
}

function Utf8ArrayToStr(array) {
  let out = ''

  let i = 0
  let c
  const len = array.length

  let char2, char3

  while (i < len) {
    c = array[i++]
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c)
        break
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++]
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
        break
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++]
        char3 = array[i++]
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        )
        break
    }
  }
  return out
}
