/**
 * @module api-web:businessTodo/csTickets/list
 * @description 首页/客服工单待办 列表
 */
import service from '@/service'

/**
 * 我的待办/客服工单/列表
 * @param {*} params
 */
export function getMyTodoList(params) {
  return service['api-web']({
    url: '/index/public/todoServiceWorkOrder/get/getTodoList',
    method: 'get',
    params
  })
}

/**
 * 我的待办/客服工单/已完成列表
 * @param {*} params
 */
export function getMyDoneList(params) {
  return service['api-web']({
    url: '/index/public/todoServiceWorkOrder/get/getDoneList',
    method: 'get',
    params
  })
}
/**
 * 部门待办/客服工单/列表
 * @param {*} params
 */
export function getDeptTodoList(params) {
  return service['api-web']({
    url: '/index/public/todoServiceWorkOrder/get/getDeptTodoList',
    method: 'get',
    params
  })
}

/**
 * 部门待办/客服工单/已完成列表
 * @param {*} params
 */
export function getDeptDoneList(params) {
  return service['api-web']({
    url: '/index/public/todoServiceWorkOrder/get/getDeptDoneList',
    method: 'get',
    params
  })
}

export default {
  getMyTodoList,
  getMyDoneList,
  getDeptTodoList,
  getDeptDoneList
}
