/**
 * @module api-web:businessTodo/productionOrder/handle
 * @description 首页/生产订单待办  处理
 */
import service from '@/service'

/**
 * 生产待办处理：通过
 * @param {object} id
 */
export function confirmProductionOrder(data) {
  return service['api-web']({
    url: '/index/public/todoProductionOrder/get/confirmTodo',
    method: 'post',
    data
  })
}
/**
 * 生产待办处理：驳回
 * @param {object} id
 */
export function rejectProductionOrder(data) {
  return service['api-web']({
    url: '/index/public/todoProductionOrder/get/rejectTodo',
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data
  })
}

/**
 * 获取当前生产待办正在处理的商品信息表
 * @param {nubmer} id
 */
export function getHandleProcutList(id) {
  return service['api-web']({
    url: '/index/public/todoProductionOrder/get/getProduct',
    method: 'get',
    params: { id }
  })
}

/**
 * 确认拆单提醒
 */
export function getRemainRules(params) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/get/getRemainRules',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
