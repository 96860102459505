/**
 * @module api-scm:buyInAndOutWarehouse/buyAfterOutTable
 */
import service from '@/service'

/**
 * 采购退换货出库   列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * outboundCode:string,
 * productName:string,
 * returnsType:number,
 * date:Array<string>
 * }} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/get/getList',
    method: 'get',
    params
  })
}

/**
 *   树形表格下拉
 * @param {code} code
 */
export function getListDown(code) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/get/getPullList',
    method: 'get',
    params: { outboundCode: code }
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/save',
    method: 'post',
    data
  })
}
/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 更新
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/update',
    method: 'post',
    data
  })
}

/**
 *  作废
 * @param {(string|number)} id
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 提交
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 审核驳回
 * @param {object} data
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehousePurchaseOut/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/** 详情
 * @param {number} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/info',
    method: 'get',
    params: { id }
  })
}

/**
 *
 * @param {{string}} id
 * @returns
 * @description  待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/warehousePurchaseOut/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出
 */
export function exportData(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/export',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {{string}} ids
 * @description 打印
 */
export function print(ids) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/print',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {{string}} ids
 * @description 手动对接
 */
export function manualDock(ids) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehousePurchaseOut/manualDock',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
