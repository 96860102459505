import vxtableDrag from './vxtableDrag'

const install = function (Vue) {
  Vue.directive('vxtableDrag', vxtableDrag)
}

if (window.Vue) {
  window.vxtableDrag = vxtableDrag
  window.Vue.use(install)
}

vxtableDrag.install = install
export default vxtableDrag
