/**
 * @module api-web:financialAffairs/cost/inventoryAccounRep
 */
import service from '@/service'

/**
 * 查询 财务-成本-存货核算明细报告
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/cost/finStockAccountingDetail/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出数据
 */
export function exportData(params) {
  return service['api-web']({
    url: '/fin/cost/finStockAccountingDetail/export',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
