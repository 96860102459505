import { isArray, isNumeric } from '@/utils/validate'

/**
 * ## 树转化 flatten 树结构列表 => 扁平化列表
 * @param {Array} tree - list 树结构列表
 * @param {{childrenKey:string}} options
 * @returns {Array} flat array
 * @description  树结构列表 => 扁平化列表
 */
export const flatten = (tree, options = { childrenKey: 'children' }) => {
  const childrenKey = options.childrenKey || 'children'

  const stack = [...tree]

  const result = []
  while (stack.length !== 0) {
    const pop = stack.pop()
    const restProps = Object.keys(pop)
      .filter((key) => key !== childrenKey)
      .reduce((obj, key) => {
        obj[key] = pop[key]
        return obj
      }, {})
    result.push(restProps)
    const nodeChildern = pop[childrenKey]
    if (nodeChildern) {
      for (let i = nodeChildern.length - 1; i >= 0; i--) {
        stack.push(nodeChildern[i])
      }
    }
  }
  return result
}
/**
 * ## 树转化 unflattenSync 扁平化列表 => 树结构列表
 * @param {Array} list - flat list 扁平化列表
 * @param {{idKey:string,parentIdKey:string,childrenKey:string}} options -
 * @returns {Array} tree array, 根据 pid,id 生成嵌套结构的树列表
 * @description 根据 PID 和 ID 进行列表转化：扁平化列表 => 树结构列表
 */
export const unflattenSync = (
  list,
  options = {
    idKey: 'id',
    parentIdKey: 'pid',
    childrenKey: 'children'
  }
) => {
  options.idKey = options?.idKey || 'id'
  options.parentIdKey = options?.parentIdKey || 'pid'
  options.childrenKey = options?.childrenKey || 'children'

  const tree = []
  try {
    const map = {} // a hash table mapping to the specific array objects with their ids as key
    list.forEach((node) => {
      const nodeId = node[options.idKey]
      const nodeParentId = node[options.parentIdKey]
      // set up current node data in map
      map[nodeId] = {
        [options.childrenKey]: [], // init a children property
        ...node, // add other propertys
        ...map[nodeId] // children will be replaced if this node already has children property which was set below
      }
      map[nodeParentId] = map[nodeParentId] || {
        [options.childrenKey]: []
      } // if it's not exist in map, init an object with children property
      map[nodeParentId][options.childrenKey].push(map[nodeId]) // add reference to current node object in parent node object
      map[nodeParentId][options.childrenKey].sort((a, b) => {
        const as = isNumeric(a.sort) ? +a.sort : 0
        const bs = isNumeric(b.sort) ? +b.sort : 0
        return as - bs
      })
    })
    // find root nodes
    Object.values(map).forEach((obj) => {
      if (typeof obj[options.idKey] === 'undefined') {
        tree.push(...(obj?.[options.childrenKey] || []))
      }
    })
  } catch (error) {
    console.error(error)
  }
  return tree
  // return arrayToTree(list, options)
}
/**
 * ## 树转化 unflattenAsync 扁平化列表 => 树结构列表
 * @param {Array} list - flat list 扁平化列表
 * @param {{idKey:string,parentIdKey:string,childrenKey:string}} options -
 * @returns {Array} tree array, 根据 pid,id 生成嵌套结构的树列表
 * @description 根据 PID 和 ID 进行列表转化：扁平化列表 => 树结构列表
 */
export const unflattenAsync = (list, options) => {
  return new Promise((resolve) => {
    resolve(unflattenSync(list, options))
  })
}

/**
 * ## 树操作：查节点
 * @param {object} node 树节点
 * @param {(number|string)} nodeId 节点 ID
 * @returns {(null|object)} 树节点
 */
const getFromNode = (node, nodeId) => {
  if (node.id === nodeId) {
    return node
  } else if (isArray(node.children)) {
    let result = null
    for (let i = 0; result == null && i < node.children.length; i++) {
      result = getFromNode(node.children[i], nodeId)
    }
    return result
  }
  return null
}
/**
 * ## 树操作：查节点
 * @param {array} treeArr 树列表
 * @param {(number|string)} nodeId 节点 ID
 * @returns {(null|object)} 树节点
 */
export const getById = (treeArr, nodeId) => {
  for (let i = 0; i < treeArr.length; i++) {
    const node = getFromNode(treeArr[i], nodeId)
    if (node) return node
  }
}
/**
 * ## 树操作：在目标节点下添加子节点
 * @param {object} node 树节点
 * @param {(number|string)} nodeId 父节点 ID
 * @param {object} newNode 子节点
 * @returns {boolean} 是否添加成功
 */
// const addChild = (node, nodeId, newNode) => {
//   if (node.id === nodeId) {
//     if (newNode) {
//       /** Your logic to generate new Id **/
//       // newNode.id = 'xxx'
//       // newNode.children = []

//       node.children.push(newNode)
//       return true
//     }
//   } else if (isArray(node.children)) {
//     for (let i = 0; i < node.children.length; i++) {
//       if (addChild(node.children[i], nodeId, newNode)) {
//         return true
//       }
//     }
//   }
//   return false
// }
/**
 * ## 树操作：在目标节点后插入新节点
 * @param {array} treeArr 树列表
 * @param {(number|string)} nodeId 父节点 ID
 * @param {object} newNode 插入的树节点
 * @returns {array} 更新后的树列表(副本)
 */
// export const addById = (treeArr, nodeId, newNode) => {
//   const clone = [...treeArr]
//   for (let i = 0; i < clone.length; i++) {
//     const result = addChild(clone[i], nodeId, newNode)
//     if (result) return clone
//   }
//   return clone
// }

/**
 * ## 树操作：更新节点
 * @param {object} node 树节点
 * @param {(number|string)} nodeId 父节点 ID
 * @param {object} newNode 子节点
 * @returns {boolean} 是否更新成功
 */
const updateNode = (node, nodeId, newNode) => {
  if (node.id === nodeId) {
    return Object.assign({}, node, newNode)
  }

  if (Array.isArray(node.children)) {
    const updatedChildren = node.children.map((child) =>
      updateNode(child, nodeId, newNode)
    )
    if (updatedChildren !== node.children) {
      return Object.assign({}, node, { children: updatedChildren })
    }
  }

  return node
}

/**
 * ## 树操作：更新节点
 * @param {array} treeArr 树列表
 * @param {(number|string)} nodeId 目标节点 ID
 * @param {object} newNode 新树节点
 * @returns {array} 更新后的树列表(副本)
 */
export const updateById = (treeArr, nodeId, newNode) => {
  return treeArr.map((node) => updateNode(node, nodeId, newNode))
}
/**
 * ## 树操作：删除节点
 * @param {object} node 树节点
 * @param {(number|string)} nodeId 父节点 ID
 * @param {object} newNode 子节点
 * @returns {number}  删除结果码(-1,0,1) -1 删除自身节点 | 0 删除失败 | 1 删除成功
 */
const removeFromNode = (node, nodeId) => {
  if (node.id === nodeId) {
    return -1
  } else if (isArray(node.children)) {
    for (let i = 0; i < node.children.length; i++) {
      const filtered = node.children.filter((v) => v.id === nodeId)
      if (filtered && filtered.length > 0) {
        node.children = node.children.filter((v) => v.id !== nodeId)
        return 1
      }
      const result = removeFromNode(node.children[i], nodeId)
      if (result) return result
    }
  }
  return 0
}
/**
 * ## 树操作：删除节点
 * @param {array} treeArr 树列表
 * @param {(number|string)} nodeId 目标节点 ID
 * @returns {array} 更新后的树列表(副本)
 */
export const removeById = (treeArr, nodeId) => {
  const clone = [...treeArr]
  for (let i = 0; i < clone.length; i++) {
    const result = removeFromNode(clone[i], nodeId)
    if (result === -1) {
      clone.splice(i, 1)
      return clone
    }
  }
  return clone
}
/**
 * ## 树操作：批量删除节点
 * @param {object} node 树节点
 * @param {Array<(number|string)>} nodeIds  需删除的节点 IDs
 * @returns {number}  删除结果码(-1,number) -1 删除自身节点 | number 删除的数量
 */
const batchRemoveFromNode = (node, nodeIds) => {
  if (nodeIds.includes(node.id)) {
    return -1
  } else if (isArray(node.children)) {
    for (let i = 0; i < node.children.length; i++) {
      const filtered = node.children.filter((v) => nodeIds.includes(v.id))
      if (filtered && filtered.length > 0) {
        node.children = node.children.filter((v) => !nodeIds.includes(v.id))
        return filtered.length
      }
      const result = batchRemoveFromNode(node.children[i], nodeIds)
      return result
    }
  } else return 0
}
/**
 * ## 树操作：批量删除节点
 * @param {array} treeArr 树列表
 * @param {Array<(number|string)>} nodeIds  需删除的节点 IDs
 * @returns {array} 更新后的树列表(副本)
 */
export const batchRemoveByIds = (treeArr, nodeIds) => {
  const clone = [...treeArr]
  let removedNodesCount = 0
  for (let i = 0; i < clone.length; i++) {
    const count = batchRemoveFromNode(clone[i], nodeIds)
    if (count === -1) {
      removedNodesCount += 1
      clone.splice(i, 1)
    } else {
      removedNodesCount += count
    }
    if (removedNodesCount === nodeIds.length) {
      return clone
    }
  }
  return clone
}

/**
 * @param {Array} arr
 * @returns {array}
 */
export const deleteChildren = (arr) => {
  const childs = arr
  for (let i = childs.length; i--; i > 0) {
    if (childs[i].children) {
      if (childs[i].children.length) {
        deleteChildren(childs[i].children)
      } else {
        delete childs[i].children
      }
    }
  }
  return arr
}

/**
 * 获取当前节点的层级
 * @param {*} node 树节点
 * @returns {number} 节点层级
 */
const getDepth = function (node) {
  let depth = 0
  if (node.children) {
    node.children.forEach(function (d) {
      const tmpDepth = getDepth(d)
      if (tmpDepth > depth) {
        depth = tmpDepth
      }
    })
  }
  return 1 + depth
}
/**
 * 获取当前树的最大层级
 * @param {*} treeArr
 * @returns {number} 树层级
 */
export const getMaxDepth = function (treeArr) {
  try {
    const depths = treeArr.map((x) => getDepth(x))
    const maxDepth = Math.max(...depths)
    return maxDepth
  } catch (error) {
    console.error(error)
    return 0
  }
}

export default {
  flatten,
  deleteChildren,
  unflattenAsync,
  unflattenSync,
  getMaxDepth,
  getById,
  // addById,
  // updateById,
  removeById,
  batchRemoveByIds
}
