// eslint-disable-next-line spaced-comment
/// <reference path="../../typedef.js" />

/**
 * @module permission/menus/routes/index
 */
import { lazyLoadView } from '.'
import layout from '@/layout'
// import { isArray, isString, validURL } from '@/utils/validate'
// import { recursiveParents, validPath } from '@/permission/utils'
// import { hidden } from '@/router/config'
/**
 * @param {RolePermissionFlatItem} node
 * @param {PermissionIndexTable} indexTable
 * @param {(string|null)} recursivePath
 * @returns {import('vue').Component} 路由组件（异步组件）
 */
export function generateComponent(node, indexTable, recursivePath) {
  try {
    if (recursivePath) {
      const component = () => lazyLoadView(recursivePath)
      return component
    } else if (recursivePath === '') return layout
  } catch (error) {
    console.error(error, recursivePath)
  }
  return null
}

export default generateComponent
