/**
 * @module utils/dropdown-map
 */
import { isArray, isPlainObject } from '@/utils/validate'

/**
 * @typedef {Array<{label:string,value:number}>} SelectOptions
 *
 * @typedef {Record<number, import('element-ui/types/option').ElOption>} IdIndex (key:id,value:el-option item)
 * @typedef {Record<number, SelectOptions>} PidIndex (key:pid,value:children)
 *
 *
 * @typedef {Object} IndexTable
 * @property {IdIndex} idx
 * @property {PidIndex} pidx
 * @property {SelectOptions} all
 * @property {SelectOptions} parents
 * @property {SelectOptions} children
 *
 * @typedef {{pid:string,id:string}} KeyMap
 *
 * @typedef {{label:string,value:string}} SelectMap
 *
 * @typedef {Object} FieldsMap
 * @property {KeyMap} key
 * @property {SelectMap} select
 */

/**
 * @returns {{label:string,value:(string|number)}}
 * @description 映射字段: 统一 label,value
 */
const mapFields = (item, { labKey = 'name', valKey = 'id' }) => ({
  label: item[labKey],
  value: item[valKey]
})

/**
 * @param {Array} list
 * @param {FieldsMap=} fieldsMap
 * @returns {IndexTable}
 *
 * @description
 *
 * 1. idx: id index table,
 * 2. pidx: pid index table,
 * 3. parents: parent list ,
 * 4. children: child list
 */
export function mapList(list, fieldsMap) {
  const idx = {}
  const pidx = {}
  const all = []
  const parents = []
  const children = []
  if (isArray(list) && list.length) {
    try {
      const _fieldsMap = isPlainObject(fieldsMap) ? fieldsMap : {}

      const { key, select } = _fieldsMap

      const keyMap = isPlainObject(key)
        ? key
        : {
            pid: 'parentId',
            id: 'id'
          }
      const selectMap = isPlainObject(select)
        ? select
        : {
            label: 'name',
            value: 'id'
          }

      const idKey =
        typeof keyMap.id === 'string' && keyMap.id ? keyMap.id : 'parentId'
      const pidKey =
        typeof keyMap.pid === 'string' && keyMap.pid ? keyMap.pid : 'id'
      const labKey =
        typeof selectMap.label === 'string' && selectMap.label
          ? selectMap.label
          : 'name'
      const valKey =
        typeof selectMap.value === 'string' && selectMap.value
          ? selectMap.value
          : 'id'

      list.forEach((item) => {
        const id = item[idKey]
        const pid = item[pidKey]

        const mappedItem = mapFields(item, { labKey, valKey })

        idx[id] = mappedItem
        pidx[pid] = pidx[pid] ? [...pidx[pid], mappedItem] : [mappedItem]

        all.push(mappedItem)

        pid === null ||
        pid === 'null' ||
        typeof pid === 'undefined' ||
        (typeof pid === 'number' && pid < 0)
          ? parents.push(mappedItem)
          : children.push(mappedItem)
      })
    } catch (error) {
      console.error(error)
    }
  }
  return {
    /**
     * 索引
     */
    idxs: { idx, pidx },
    /**
     * el-options
     */
    opts: { all, parents, children }
  }
}

export default mapList
