export * from './agelCreateSelectMode'
export * from './agelSelectNamePair'
export * from './autoCompleteInput'
export * from './autoSelectRemote'
export * from './agelDefaultOption'

import { isString } from '../validate'
/**
 * @param {string} contentPosition
 * @param {number} span
 */
export const divider = (contentPosition = 'right', span = 24) => ({
  component: 'el-divider',
  labelWidth: '0',
  contentPosition,
  span
})

/**
 * @param {object} form
 * @param {string} field
 * @description 刷新下拉
 */
export async function refreshOptions(form = {}, field = '') {
  const { getRef } = form
  if (typeof getRef !== 'function') {
    return void console.warn('Invalid form', form, field)
  }
  if (!field || !isString(field)) {
    return void console.warn('Invalid field', form, field)
  }
  const $vm = getRef(field)
  if (typeof $vm?.getOptions !== 'function') {
    return void console.warn(`Invalid selectable field:${field} of form:`, form)
  }
  $vm.getOptions()
}
