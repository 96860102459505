<template>
  <div
    :style="{ height: calcHeight, zIndex: zIndex }"
    class="title-bar-sticky-container"
  >
    <div
      :class="[className, 'title-bar-sticky-wrapper']"
      :style="{
        top: isSticky ? stickyTop + 'px' : '',
        zIndex: zIndex,
        position: position,
        width: width,
        height: calcHeight
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils'
import { mapState } from 'vuex'
export default {
  name: 'Sticky',
  props: {
    stickyTop: {
      type: Number,
      default: 0
    },
    zIndex: {
      type: Number,
      default: 1
    },
    className: {
      type: String,
      default: ''
    },
    supportExpandCollapse: {
      type: Boolean,
      default: false
    },
    isExpand: {
      type: Boolean,
      default: false
    },
    noTit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      resizeHandler: null,
      isSticky: false,
      active: false,
      position: '',
      width: 'auto',
      formHeight: null,
      defaultFormHeight: 36, //  默认表单高度（默认单行）
      queryButtonRowHeight: null,
      defaultQueryButtonRowHeight: 36 //  默认按钮行高
    }
  },
  computed: {
    ...mapState({
      titleHeight: (state) => state.settings.titleHeight,
      titleMarginBottom: (state) => state.settings.titleMarginBottom,
      titleBarPaddingTop: (state) => state.settings.titleBarPaddingTop,
      titleBarPaddingBottom: (state) => state.settings.titleBarPaddingBottom
    }),
    formElHeight() {
      return this.supportExpandCollapse === true
        ? this.isExpand === false
          ? `${this.defaultFormHeight}px`
          : 'auto'
        : 'auto'
    },
    calcHeight() {
      const isList = this.$pageType.isListPage(this.$route.name)
      /**
       * 去除标题，所以titleHeight应该为0
       * this.titleHeight + this.titleMarginBottom 替换成 0
       */
      const titleHeight = isList ? (this.noTit ? 0 : 0) : this.titleHeight

      const formHeight =
        this.supportExpandCollapse === true
          ? isList
            ? this.formHeight ?? this.defaultFormHeight
            : 0
          : this.formHeight

      const queryButtonHeight = isList
        ? this.queryButtonRowHeight ?? this.defaultQueryButtonRowHeight
        : 0

      return `${
        titleHeight +
        formHeight +
        queryButtonHeight +
        this.titleBarPaddingTop +
        this.titleBarPaddingBottom
      }px` // 表单的高度 + 标题的高度 + 标题下外边距 + 标题栏容器上内边距 + 标题栏容器下内边距
    }
  },
  async mounted() {
    this.updateHeight()

    window.addEventListener('scroll', this.handleScroll)
    this.resizeHandler = debounce(() => {
      this.handleResize()
    }, 160)
    window.addEventListener('resize', this.resizeHandler)
  },
  activated() {
    this.handleScroll()
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.resizeHandler)

    this.resizeHandler = null
  },
  methods: {
    sticky() {
      if (this.active) {
        return
      }
      this.position = 'fixed'
      this.active = true
      this.width = this.width + 'px'
      this.isSticky = true
    },
    handleReset() {
      if (!this.active) {
        return
      }
      this.reset()
    },
    reset() {
      this.position = ''
      this.width = 'auto'
      this.active = false
      this.isSticky = false
    },
    handleScroll() {
      const width = this.$el?.getBoundingClientRect().width
      this.width = width || 'auto'
      const offsetTop = this.$el?.getBoundingClientRect().top
      if (offsetTop < this.stickyTop) {
        this.sticky()
        return
      }
      this.handleReset()
    },
    handleResize() {
      try {
        if (this.isSticky) {
          this.width = this.$el?.getBoundingClientRect().width + 'px'
        }
        this.updateHeight()
      } catch (error) {
        console.error(error)
      }
    },
    async updateHeight() {
      try {
        await this.$halt(16)

        const $form = this.$el?.querySelector('form')

        this.formHeight = $form
          ? $form?.getBoundingClientRect()?.height ?? this.defaultFormHeight
          : 0

        const $operations = this.$el?.querySelector(
          '.filter-query-form-operations'
        )
        this.queryButtonRowHeight = $operations
          ? $operations?.getBoundingClientRect()?.height ??
            this.defaultQueryButtonRowHeight
          : 0
        await this.$halt(16)

        this.$emit('form-layout', {
          $form,
          $formItems: $form?.children ?? null,
          $formItemsChildren:
            this.$el.querySelector('.agel-form-inline')?.children ??
            this.$el.querySelector('.el-form--inline')?.children ??
            null,
          width: $form?.getBoundingClientRect()?.width
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title-bar-sticky-container,
.title-bar-sticky-wrapper {
  transition: height 160ms;
  :deep(form) {
    height: v-bind(formElHeight);
    overflow: hidden;
    transition: all 1000ms;
  }
  :deep(.el-autocomplete-suggestion) {
    left: 0 !important;
  }
}
</style>
