/**
 * @module api-scm:profitAndLoss
 */
import service from '@/service'

/**
 * @typedef {Object} profitAndLoss 盘盈盘亏单
 * @property {number=} [limit=1] -条数
 * @property {number} page - 页码
 * @property {string} productCode - 商码编号
 * @property {string} productName - 商品名称
 * @property {string} profitAndLossCode - 盘盈盘亏单据编号
 * @property {string} profitAndLossType - 单据类型 0盘盈 1盘亏
 * @property {string} shipper - 货主
 * @property {string} startTime - 	开始日期
 * @property {string} endTime - 结束日期
 * @property {string} warehouseCode - 仓库编码
 * @property {string} checkCode -	盘点方案编码
 *
 * @param {WarehouseOut}
 */

export function getList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/profitAndLoss/getList',
    method: 'get',
    params
  })
}

/**
 * 详情
 * @param {(number|string)} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/profitAndLoss/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 明细列表查询
 * @typedef {Object} profitAndLoss
 * @property {number=} [limit=1] -条数
 * @property {number} page - 页码
 * @property {string} profitAndLossCode - 盘盈盘亏单据编号
 */
export function getListDown(params) {
  return service['api-scm']({
    url: '/scm/warehouse/profitAndLoss/info/getList',
    method: 'get',
    params
  })
}

// /**
//  *  下推
//  * @param {object} data
//  */
// export function pushDown(data) {
//   return service['api-scm']({
//     url: '/scm/warehouse/profitAndLoss/pushDown',
//     method: 'post',
//     data,
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//   })
// }

/**
 * 盘盈盘亏单手动对接金蝶
 */
export function manualDockingOfKingDee(data) {
  return service['api-scm']({
    url: '/scm/warehouse/profitAndLoss/manualDockingOfKingDee',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出盘盈盘亏单
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/profitAndLoss/export',
    method: 'post',
    data,
    responseType: 'blob' // 文件流接收类型声明转成 blob
  })
}
