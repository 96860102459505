/**
 * @module api-scm:presentedBlack
 */
import service from '@/service'

/**
 *  列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * clientPhone:(string|number),
 * productName:string
 * }} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/presented/presentedRule/getList',
    method: 'get',
    params
  })
}
/**
 *新增
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/presented/presentedRule/save',
    method: 'post',
    data
  })
}
/**
 *复制
 * @param {object} data
 * @returns {object}
 */
export function copy(data) {
  return service['api-scm']({
    url: '/scm/presented/presentedRule/copy',
    method: 'post',
    data
  })
}
/**
 * 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/presented/presentedRule/update',
    method: 'post',
    data
  })
}

/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/presented/presentedRule/info/infoAll',
    method: 'get',
    params: { id }
  })
}

/**
 * 启用禁用
 * @param {object} data
 */
export function enableDisable(data) {
  return service['api-scm']({
    url: '/scm/presented/presentedRule/updateDisableStatus',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/presented/presentedRule/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 商品参与切换
 * @param {object} data
 */
export function updateInHandoverParticipate(data) {
  return service['api-scm']({
    url: '/scm/presented/presentedRule/info/updateInHandoverParticipate',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 赠送商品是否存在在数据库
 * @param {object} data
 */
export function checkGoodsIsExist(data) {
  return service['api-scm']({
    url: '/public/inventory/checkIsExist',
    method: 'post',
    data
  })
}
