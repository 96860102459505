/**
 * @module api-web:financialAffairs/accountReceivable/receiptManualOff
 */
import service from '@/service'
// 应收收款过滤-费用财务应付单过滤
export function getFeePayableFilterList(params) {
  return service['api-web']({
    url: '/fin/ar/finReceivableManualVerification/public/getFeePayableFilterList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// 应收收款过滤-应收过滤
export function getReceiptFilterList(params) {
  return service['api-web']({
    url: '/fin/ar/finReceivableManualVerification/public/getReceiptFilterList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 应收收款过滤-收款单过滤
export function getReceiptOrderList(params) {
  return service['api-web']({
    url: '/fin/ar/finReceivableManualVerification/public/getReceiptOrderList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// 手动核销
export function chargeOff(data) {
  return service['api-web']({
    url: '/fin/ar/finVerificationSheet/save',
    method: 'post',
    data
  })
}
