/**
 * @module api-web:permission&&role
 */
import service from '@/service'

/**
 * 权限管理：角色：查询
 * @param {{keywords:string,limit:number,page:number,sort:string}} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/sys/permission/getList',
    method: 'get',
    params
  })
}

/**
 *   权限管理：角色：新增
 * @param {{roleName:string}} data
 */
export function add(data) {
  return service['api-web']({
    url: '/sys/permission/save',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  权限管理：角色：更新
 * @param {{id:number,roleName:string}} data
 */
export function update(data) {
  return service['api-web']({
    url: '/sys/permission/update',
    method: 'put',
    data
  })
}

/**
 *  权限管理：角色：删除
 * @param {Array<number>} params - ids
 */
export function remove(params) {
  return service['api-web']({
    url: '/sys/permission/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 权限管理：权限：获取系统权限树
 */
export function getSysPermissionTree() {
  return service['api-web']({
    url: '/sys/permission/assignPm/getPmTree',
    method: 'get'
  })
}

/**
 * 权限管理：权限：根據角色ID 查询 權限列表
 * @param {number} id 角色ID
 */
export function getRolePermissionList(id) {
  return service['api-web']({
    url: '/sys/permission/assignPm/getPmList',
    method: 'get',
    params: { id }
  })
}

/**
 * 权限管理： 权限：根据角色ID 分配/更新 權限
 * @param {{id:number,pmList:Array<number>}} data
 */
export function assignRolePermission(data) {
  return service['api-web']({
    url: '/sys/permission/assignPm',
    method: 'put',
    data
  })
}
