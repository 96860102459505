// 根据项目名称和table Id 获取column实例
const getUserTableSort = (userId, projectName, systemType, tableId) => {
  return new Promise((resolve, reject) => {
    try {
      const localName =
        userId + '-' + projectName + '-' + systemType + '-' + tableId
      const data = localStorage.getItem(localName)
      if (data) {
        resolve({
          code: 200,
          info: {
            columns: data
          }
        })
      } else {
        reject('No data found in LocalStorage')
      }
    } catch (error) {
      reject(error)
    }
  })
}

// 设置每个表格的信息实例
const setUserTableSort = ({
  userId,
  projectName,
  systemType,
  tableId,
  columns
}) => {
  const localName =
    userId + '-' + projectName + '-' + systemType + '-' + tableId
  localStorage.setItem(localName, columns)
}

export { getUserTableSort, setUserTableSort }
