import service from '@/service'
/**
 * @typedef {Object} Param
 * @property {('Person'|'Dept')} taskType
 * @property {*} params
 *
 * 待办 获取分类/分组列表
 * @param {Param}
 */
export function getGroups({ taskType, params }) {
  /**
   * @type {('/public/getPersonWait'|'/public/getDeptWait')} url
   */
  const url = `/public/get${taskType}Wait`
  return service['api-activiti']({
    url,
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
