import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { isString, isNumeric } from '@/utils/validate'
import { AES } from '@/utils/crypter'
import { consoleTheme } from '@/settings'
/**
 * @description 请求拦截器
 */
import {
  /**
   * 1. 处理请求头信息
   */
  handleRequestHeaders,
  /**
   * 2. 设置请求超时
   */
  handleRequestTimeout,
  /**
   * 3. 预处理请求参数
   */
  handleRequestSend,
  /**
   * 4. 打印请求日志
   */
  handleRequestLogs,
  /**
   * 5. 添加到请求池
   */
  addToRequestPool,
  /**
   * 6. 加密请求
   */
  encryptResquest
} from './request'

/**
 * @description 响应拦截器
 */
import {
  /**
   * ****************  响应成功拦截  ****************
   */
  /**
   * 1. 处理请求池，从池中移除以及打上标记
   */
  handleRequestPool,
  /**
   * 2. 从 axios response 中提取服务端返回体 response.data
   */
  extractRes,
  /**
   * 3. 处理文件流
   */
  handleFileStream,
  /**
   * 4. 解密返回
   */
  decryptResponse,
  /**
   * 5. 打印响应日志
   */
  handleResponseLogs,
  /**
   * 6. 其他处理（base64 图片媒体信息处理等）
   */
  handleResult,
  /**
   * ****************  响应错误拦截  ****************
   */
  /**
   * 1. 取消请求处理
   */
  handleCancel,
  /**
   * 2. 状态 401 引导登录
   */
  guideReLogin,
  /**
   * 3.移除取消请求
   */
  removeFromRequestPool
} from './response'
export default {
  request: () => [
    (config) => {
      handleRequestHeaders(config)
      handleRequestSend(config)
      handleRequestTimeout(config)
      handleRequestLogs(config)
      addToRequestPool(config)
      encryptResquest(config)
      return config
    },
    (error) => Promise.reject(error)
  ],
  response: () => [
    (response) => {
      handleRequestPool(response)
      const res = extractRes(response)
      const { responseType: resType, headers } = response.config
      if (resType === 'blob') return handleFileStream(response, res)
      const decrypted = decryptResponse(response, res)
      if (
        (headers['Content-Type'] || '').includes('application/xml') ||
        (response.headers['content-type'] || '').includes('text/xml')
      ) {
        return decrypted // xml 直接返回
      }
      handleResponseLogs(response, decrypted)
      return handleResult(response, decrypted)
    },
    async (error) => {
      /**
       * @description
       * if is canceled request, will wait first result that same request
       */
      const isCanceled = axios.isCancel(error)
      if (isCanceled) return await handleCancel(error)

      const response = error?.response || {}
      const url = response?.config?.url || ''
      console.groupCollapsed(
        `%c error: ${url || '【Axios Error】'}`,
        `background: ${consoleTheme.bgColor.transparent}; color: ${consoleTheme.txtColor['red']}; padding: 0 10px; border-radius: 3px; font-size: 10px;`
      )

      console.log('【axios】error.response:', response)

      console.log('【axios】:', response)

      const res = response?.data || {}
      console.log('【axios】error.response.data:', res)
      // 🔴 message
      const resMsg = isString(res?.msg) ? res.msg.trim() : ''
      const axiosMsg = isString(response?.statusText)
        ? response.statusText.trim()
        : ''
      const errMsg = isString(error?.message) ? error.message.trim() : ''
      const msg = resMsg || axiosMsg || errMsg || ''
      console.log(`【axios】msg: ${resMsg} || ${axiosMsg} || ${errMsg} || ''`)

      // 🔴 code
      const resCode = isNumeric(res?.code) ? res.code * 1 : null
      const axiosCode = isNumeric(response?.status) ? response.status * 1 : null
      const errCode = isNumeric(error?.code) ? error.code * 1 : null
      const code = resCode ?? axiosCode ?? errCode ?? null
      console.log(
        `【axios】code: ${resCode} ?? ${axiosCode} ?? ${errCode} ?? null`
      )
      console.groupEnd()

      /**
       * @type {boolean}
       * @description 是否错误消息提示
       * 1. code:499 token 异常的错误消息提示放在 guideReLogin 中处理
       *
       * 2. code:500
       * 在非 login 页面刷新页面的时候，会自动调用 getUserInfo 接口,
       * 在 token 失效的状态下，接口可能未返回约定的 499， 而是直接报 500 并重定向到登录页了
       * 针对此情况下，直接静默掉错误提示
       */
      const showErrMsgTips =
        code !== 499 &&
        !(code === 500 && url.includes('/auth/public/getUserInfo'))

      /**
       * @description code:401
       * - Illegal token
       * - Token expired;
       * - Other clients logged in;
       *
       * @deprecated 改为 499
       * @see #6342 bug
       */
      // if (code === 401 || msg.includes('401')) guideReLogin(msg)
      /**
       * @description code:499
       * - Illegal token
       * - Token expired;
       * - Other clients logged in;
       */
      if (code === 499) guideReLogin(msg)

      removeFromRequestPool(error) // remove request from requestPool's stage

      if (showErrMsgTips) {
        if (msg) {
          store.dispatch('clearAxiosErrMsgsByKey', { key: msg }).then(() => {
            store.dispatch('setAxiosErrMsgsByKey', {
              key: msg,
              value: Message({
                message: msg,
                type: 'error',
                duration: 5 * 1000,
                showClose: true
              })
            })
          })
        }
      }

      new Promise((resolve, reject) => {
        try {
          console.error(error)
          if (isString(res)) console.error(AES.decrypt(res))
          else console.error(res)
          resolve()
        } catch (e) {
          reject(e)
        }
      })

      return error
    }
  ]
}
