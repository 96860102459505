/**
 * @typedef {'Hex'|'Base64'|'Latin1'|'Utf8'|'Utf16'|'Utf16LE'} CryptoEncoders
 * @typedef {'Pkcs7'|'Iso97971'|'AnsiX923'|'Iso10126'|'ZeroPadding'|'NoPadding'} CryptoPadding
 * @typedef {'CBC'|'CFB'|'CTR'|'OFB'|'CB'} CryptoMode
 */

/**
 * @type {string}
 * @description iv 向量
 */
export const DEFAULT_IV = 'QVDpmQLZFcErRvC6'
/**
 * @type {CryptoMode}
 * @description CryptoJS supports the following modes
 */
export const DEFAULT_MODE = 'CBC'
/**
 * @type {CryptoPadding}
 * @description CryptoJS supports the following padding schemes
 */
export const DEFAULT_PAD = 'Pkcs7'
/**
 * @type {{encryption:CryptoEncoders,decryption:CryptoEncoders}}
 * @description Encoders: convert from encoding formats
 */
export const DEFAULT_INPUT = { encryption: 'Utf8', decryption: 'Hex' }
/**
 * @type {{encryption:CryptoEncoders,decryption:CryptoEncoders}}
 * @description Decoders: convert from decoding formats
 */
export const DEFAULT_OUTPUT = { encryption: 'Base64', decryption: 'Utf8' }
/**
 * @type {{key:CryptoEncoders,iv:CryptoEncoders}}
 * @description key and iv parser
 */
export const DEFAULT_KV_PARSER = { key: 'Utf8', iv: 'Utf8' }
/**
 * @type {{encryption:CryptoEncoders,decryption:CryptoEncoders}}
 * @description format for encrypt/decrypt
 */
export const DEFAULT_FORMAT = { encryption: 'Utf8', decryption: 'Hex' }
