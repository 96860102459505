/**
 * @module api-web:financialAffairs/generalLedger/endOfPeriod/finEndPeriodCheckout
 */
import service from '@/service'

/**
 * 查询 财务-总账-期末处理-总账期末结账
 * @param {object} params
 */
export function getList(params) {
  return service['api-web']({
    url: '/fin/gl/period/finEndPeriodCheckout/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 结账 财务-总账-期末处理-总账期末结账
 * @param {object} data
 */
export function postCheckout(data) {
  return service['api-web']({
    url: '/fin/gl/period/finEndPeriodCheckout/checkout',
    method: 'post',
    data
  })
}

/**
 * 反结账 财务-总账-期末处理-总账期末结账
 * @param {object} data
 */
export function reversePostCheckout(data) {
  return service['api-web']({
    url: '/fin/gl/period/finEndPeriodCheckout/reverseCheckout',
    method: 'post',
    data
  })
}
