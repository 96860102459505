/**
 * @module BaseInlineEditTable/mixins/renders
 * 内联编辑表格 〉宏 〉VXE 表格单元格渲染
 * renders and formatter for table cell item
 * @see https://xuliangzhan_admin.gitee.io/vxe-table/#/column/api
 * content-render
 * cell-render
 * edit-render
 * formatter
 */
import { objectMerge } from '@/utils/object'
import { isArray, isPlainObject, isString } from '@/utils/validate'

export default {
  methods: {
    isExpandItem(item) {
      return item.type === 'expand'
    },
    /**
     * @param {*} item
     * @returns {boolean} 自定义插槽
     */
    isSlotItem(item) {
      return item.isComp === true
    },
    /**
     * content-render
     */
    itemContentRender(item) {
      if (!isPlainObject(item.contentRender)) {
        return undefined
      } else {
        return item.contentRender
      }
    },
    /**
     * cell-render
     */
    itemCellRender(item) {
      if (!isPlainObject(item.cellRender)) {
        return undefined
      } else {
        return item.cellRender
      }
    },
    /**
     * edit-render
     */
    itemEditRender(item, columnIndex) {
      if (item.cellRender) {
        return undefined
      }

      if (item.isComp || !item.editRender) {
        return {}
      }

      if (item.editRender && !isPlainObject(item.editRender)) {
        return {}
      }

      let editRender = { ...item.editRender }
      switch (editRender.name) {
        case 'ElFileUploader': {
          editRender = objectMerge(
            {
              events: {
                getUploadedFileName: this.$_elFileUploader_getUploadedFileName,
                getUploadedFileUrl: this.$_elFileUploader_getUploadedFileUrl,
                getUploadedImageStatus:
                  this.$_elFileUploader_etUploadedImageStatus,
                onUploadImageLoaded: this.$_elFileUploader_onUploadImageLoaded,
                onUploadImageError: this.$_elFileUploader_onUploadImageError,
                onUploadFile: this.$_elFileUploader_onUploadFile,
                onRemoveFile: this.$_elFileUploader_onRemoveFile,
                onPreviewFile: this.$_elFileUploader_onPreviewFile,
                onChange: editRender?.events?.change
              }
            },
            editRender
          )
          break
        }
        case 'VxeTableSelector': {
          editRender = objectMerge(
            {
              events: {
                onRevert: this.revertRow,
                onUpdate: this.updateRow
              }
            },
            editRender
          )
          break
        }
        case 'ElEditSpan': {
          editRender = objectMerge(
            {
              attrs: {
                showOverflow:
                  typeof item.showOverflow === 'boolean' ||
                  isString(item.showOverflow)
                    ? item.showOverflow
                    : this.showOverflow,
                tooltipConfig: this.tooltipConfig,
                showLoading: true // 避免加载icon显示
              },
              events: {
                // ...
              }
            },
            editRender
          )
          break
        }
        case 'ElPicker':
        case 'VxeEditButton': {
          editRender = objectMerge(
            {
              events: {
                // ...
              }
            },
            editRender
          )
          break
        }
      }
      return editRender
    },
    /**
     * formatter
     * @returns {Function|undefined}
     */
    itemFormatter(item) {
      if (item?.isComp === true) return undefined
      /**
       * @see https://vxetable.cn/v3/#/table/formats/api
       */

      return typeof item?.formatter === 'function' ||
        (isArray(item?.formatter) && item.formatter.length > 0) ||
        isString(item?.formatter)
        ? item.formatter
        : this.cellPlaceholder
        ? (row) =>
            typeof row.cellValue === 'undefined' ||
            row.cellValue === null ||
            row.cellValue === ''
              ? this.cellPlaceholder
              : row.cellValue
        : undefined
    },
    /**
     * column
     * @returns {Record<string,any>}
     * @see https://vxetable.cn/v3/#/column/api
     * @description 优先级：“item” 大于 “继承 table” 大于 “默认”
     */
    itemColumn(item) {
      // eslint-disable-next-line no-unused-vars
      const { showOverflow, showOverflowTooltip, ...rest } = item
      /**
       * 自定义区别于官方的自定义默认值
       * @description 如果默认与官方 API 一致，则无需声明
       */
      const defaultColumnConfig = {
        resizable: true
        // ...
      }

      /**
       * 继承自 vxe table props
       */
      const inheritFromTable = {
        resizable:
          typeof this.$attrs?.resizable === 'boolean'
            ? this.$attrs.resizable
            : undefined
        // ...
      }

      return {
        ...defaultColumnConfig,
        ...inheritFromTable,
        ...rest
      }
    },
    /**
     * @returns {(boolean|'ellipsis'|'title'|'tooltip')}
     * @description 兼容误使用 element-ui 中的 show-overflow-tooltip 的情况
     * 开发的时候有些表格实例配置错了，还是使用 element-ui 的 show-overflow-tooltip 来配置 vxe-table 的 show-overflow
     */
    getItemShowOverflow(item) {
      return item?.type || item?.prop === 'operation'
        ? true
        : item?.showOverflow ?? item?.showOverflowTooltip ?? true
    }
  }
}
