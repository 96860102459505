/**
 * @module api-scm:materialTransceiverStorage
 */
import service from '@/service'

/**
 * 物料收发存明细  表查询
 */
export function getMaTranStorageList(params) {
  return service['api-scm']({
    url: '/scm/warehouse/materialTransceiverStorage/getList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 导出
 */
export function exportIntoCenter(data) {
  return service['api-scm']({
    url: '/scm/warehouse/materialTransceiverStorage/export',
    method: 'post',
    data
  })
}
