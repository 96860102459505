export default {
  path: 'table',
  component: () => import('@/views/demos/table'),
  redirect: '/demos/table/dynamic-table',
  name: 'Table',
  meta: {
    title: 'Table',
    icon: 'table'
  },
  children: [
    {
      path: 'vxe-table',
      component: () => import('@/views/demos/table/vxe-table'),
      name: 'VXETable',
      meta: { title: 'VXE Table' }
    },
    {
      path: 'dynamic-table',
      component: () => import('@/views/demos/table/dynamic-table'),
      name: 'DynamicTable',
      meta: { title: 'Dynamic Table' }
    },
    {
      path: 'drag-table',
      component: () => import('@/views/demos/table/drag-table'),
      name: 'DragTable',
      meta: { title: 'Drag Table' }
    },
    {
      path: 'inline-edit-table',
      component: () => import('@/views/demos/table/inline-edit-table'),
      name: 'InlineEditTable',
      meta: { title: 'Inline Edit' }
    },
    {
      path: 'complex-table',
      component: () => import('@/views/demos/table/complex-table'),
      name: 'ComplexTable',
      meta: { title: 'Complex Table' }
    }
  ]
}
