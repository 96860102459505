/**
 * @module api-scm:warehouseTransferOrder
 */
import service from '@/service'

/**
 * 同仓移库单   列表查询
 * @param {{keywords:string,limit:number,page:number,sort:string,documentStatus:number}} params
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/get/getList',
    method: 'post',
    data
  })
}

/**
 * 同仓移库单   详情查询
 * @param {string} id
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 同仓移库单   新增
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/save',
    method: 'post',
    data
  })
}

/**
 * 同仓移库单   修改
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/update',
    method: 'post',
    data
  })
}

/**
 * @param {Array<number>} params
 * @description 批量删除
 */
export function remove(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/batchDelete',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array<string>}} id
 */
export function submit(id) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/submit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 撤销
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/withdrawClaim',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{
 * inventoryCode:string
 * limit:number
 * page:number
 * productCode:string
 * productName:string
 * }} params
 * @description 导出
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/export',
    method: 'post',
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: false // 文件流是否通过浏览器下载
  })
}

/**
 * @description 选择明细
 */
export function pickDetail(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/pickDetail',
    method: 'post',
    data
  })
}

/**
 * @description 打印
 */
export function printPicklist(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/print',
    method: 'post',
    data
  })
}

/**
 * @param {string} inventoryProductIds
 * @description 刷新库存
 */
export function refreshInventory(inventoryProductIds) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/refreshInventory',
    method: 'post',
    data: { inventoryProductIds },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据仓库编码获取库位代码
 * @param {string} id
 */
export function getRecommendInventoryCode(params) {
  return service['api-scm']({
    url: '/public/inventory/getRecommendInventoryCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 下载同仓移库单导入模版
 */
export function downloadWarehouseTransferOrderDetailExcel(params) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/downloadWarehouseTransferOrderDetailExcel',
    method: 'post',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    saveAs: true
  })
}

/**
 * @description 同仓移库单明细导入
 */
export function importWarehouseTransferOrderDetail(data) {
  return service['api-scm']({
    url: '/scm/warehouse/warehouseTransferOrder/importWarehouseTransferOrderDetail',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
