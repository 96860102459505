/**
 * @module BaseInlineEditTable/mixins/rules
 * 内联编辑表格 〉宏 〉回调刷新
 */
import { batchRemoveByIds, removeById } from '@/utils/tree'
import { isArray, isPlainObject, isString } from '@/utils/validate'
import { deepClone } from '@/utils/object'
import { DEFAULT_FLAGS } from '../consts'
export default {
  props: {
    editRules: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    /**
     * reset flags
     * refresh data
     * clear all vxe-table status
     * @param {('create'|'update'|'delete'|'multi-del'|'')} [by='']
     * @param {('hard'|'soft')} [mode='soft']
     * @param {{removeBy?:('normal'|'cancel'),row:any,rows:any}} payload
     * @returns {Promise<{type:('inner'|'external'),data:any}>}
     */
    async refresh(by = '', mode = 'soft', payload) {
      let result = {
        type: '',
        data: null
      }
      if (!by) {
        console.error('【BaseInlineEditTable】: "refresh" losed "by"')
        return result
      }

      if (!['create', 'update', 'delete', 'multi-del'].includes(by)) {
        console.error('【BaseInlineEditTable】: "refresh" illegal "by"', by)
        return result
      }
      switch (by) {
        case 'create':
        case 'update':
          result = await this.onCreateUpdateSuccess(payload)
          break
        case 'delete':
          result = await this.onDeleteSuccess(payload)
          break
        case 'multi-del':
          result = await this.onMultiDelSuccess(payload)
          break
      }

      if (mode === 'hard') {
        this.$halt(16, () => {
          this.flags.isDirty && this.resetFlags('all')
          this.$table.clearAll()
        })
      }

      return result
    },
    /**
     * re-fetch table data and rollback expanded
     * @param {string=} [flow='positive'] positive/negative positive:正向，从外到内，外部处理改变数据后通过props输入, negative: 反向，从内而外，数据内部改变后发射出去再流入
     * @param {object} row - 发起刷新的行
     * @param {{data:(Array|null),type:'cancel'|'remove'|'create-update',ids:(Array|null)}=} [options={data:null,type:'',ids:null}] data: 直接更新的数据
     * @returns {Promise<void>}
     */
    async refreshTreeTable(
      rows = null,
      flow = 'positive',
      options = { data: null, type: '', ids: null }
    ) {
      try {
        if (!['positive', 'negative'].includes(flow)) {
          return void console.error(
            '【BaseInlineEditTable】: "refreshTreeTable" illegal "flow"',
            flow
          )
        }

        let _data = null
        let emitSource = ''
        /**
         * @type {Array} treeExpandRecords
         * @description 用于刷新数据后恢复展开记录
         * 1. 由于存在多节点同时展开的情况，考虑到懒加载的树下拉列表接口并发限制，只展开最后一个记录
         * 2. 由于删除行与展开行存在交集，所以需要在后续删除的时候判断更新 treeExpandRecords
         */
        const treeExpandRecords = this.$table.getTreeExpandRecords()

        if (flow === 'positive') {
          const fetched = await this.initData(false)
          if (
            !fetched ||
            !isArray(fetched) ||
            (isArray(fetched) && !fetched.length)
          ) {
            console.error(
              '【BaseInlineEditTable】: "refreshTreeTable" fetched failed',
              fetched
            )
            emitSource = 'refreshTreeTable.positive.fetch.error'
          } else emitSource = 'refreshTreeTable.positive'
        } else if (flow === 'negative') {
          const { data = null, type = '', ids = null } = options || {}
          if (data && isArray(data)) {
            _data = data
            emitSource = 'refreshTreeTable.negative'
          } else {
            if (type === 'remove') {
              if (ids && isArray(ids)) {
                if (ids.length === 1) {
                  _data = removeById(this.tableData, ids[0])
                  emitSource = 'refreshTreeTable.negative.singleRemove'
                } else if (ids.length > 1) {
                  _data = batchRemoveByIds(this.tableData, ids)
                  emitSource = 'refreshTreeTable.negative.multiRemove'
                } else {
                  emitSource = 'refreshTreeTable.negative.remove.error'
                  console.error(
                    '【BaseInlineEditTable】: "refreshTreeTable" via remove , error ids.length',
                    ids
                  )
                }
              } else {
                emitSource = 'refreshTreeTable.negative.options.error:ids'
                console.error(
                  '【BaseInlineEditTable】: "refreshTreeTable" illegal "ids"',
                  ids,
                  options
                )
              }
            } else {
              emitSource = `refreshTree.negative.${type}`
            }
          }
        }
        await this.emitData({
          data: _data,
          source: emitSource
        })
        this.reserveTreeExpandedManual({
          prev: treeExpandRecords,
          rows,
          flow,
          ...options
        })
      } catch (error) {
        console.error(
          '【BaseInlineEditTable】: "refreshTreeTable" catched: ',
          error
        )
      }
    },
    /**
     *
     * @param {*}
     * @returns {void}
     * @descrtiption 手动恢复树展开记录，懒加载的话目前只支持恢复一个节点
     */
    async reserveTreeExpandedManual({
      prev: prevExpandRecords = [],
      rows = null,
      flow,
      data = null,
      type = '',
      ids = []
    }) {
      await this.$table.clearTreeExpandLoaded()
      await this.$table.clearTreeExpand()
      await this.$table.setTreeExpand(prevExpandRecords, false)

      let _treeExpandRecords = [...prevExpandRecords]

      if (_treeExpandRecords.length) {
        if (type === 'remove') {
          if (ids && isArray(ids)) {
            _treeExpandRecords = _treeExpandRecords.filter(
              (r) => !ids.includes(r[this.treeRowField])
            )
          } else {
            console.error(
              '【BaseInlineEditTable】: "reserveTreeExpandedManual" error ids:',
              ids
            )
          }
        } else {
          // create,update,cancel
        }

        const operationSourceRow = isPlainObject(rows)
          ? rows
          : isArray(rows)
          ? rows[rows.length - 1]
          : null

        if (operationSourceRow) {
          if (
            _treeExpandRecords
              .map((r) => r[this.treeRowField])
              .includes(operationSourceRow[this.treeRowField])
          ) {
            _treeExpandRecords = [
              _treeExpandRecords.find(
                (r) =>
                  r[this.treeRowField] === operationSourceRow[this.treeRowField]
              )
            ].filter((r) => isPlainObject(r))
          } else {
            const childrenArray2d = _treeExpandRecords.map(
              (r) => r[this.treeChildrenField]
            )
            for (let i = 0; i < childrenArray2d.length; i++) {
              const children = childrenArray2d[i]
              if (
                children
                  .map((cr) => cr[this.treeRowField])
                  .includes(operationSourceRow[this.treeRowField])
              ) {
                _treeExpandRecords = [_treeExpandRecords[i]].filter((r) =>
                  isPlainObject(r)
                )
                break
              }
            }
            console.log(
              '【BaseInlineEditTable】tree expanded records (update via "operationSourceRow" infos):',
              _treeExpandRecords
            )
          }
        } else {
          console.error(
            '【BaseInlineEditTable】"reserveTreeExpandedManual" illegal "operationSourceRow":',
            operationSourceRow
          )
        }
      }

      if (!_treeExpandRecords.length) {
        console.warn(
          '【BaseInlineEditTable】no expanded records need re-expand'
        )
        return
      }

      if (_treeExpandRecords.length > 1) {
        // 懒加载如需要放开限制，需要接口首先支持并发
        if (this.treeIsLazy) {
          _treeExpandRecords = [[..._treeExpandRecords].pop()].filter((r) =>
            isPlainObject(r)
          )
        }
      }

      await this.$halt(160)

      if (flow === 'positive') {
        // 刷新过的数据，需要重新匹配
        _treeExpandRecords = _treeExpandRecords.map((r) => {
          return this.$table
            .getTableData()
            .tableData.find(
              (nr) => nr[this.treeRowField] === r[this.treeRowField]
            )
        })
      }
      console.log(
        '【BaseInlineEditTable】tree expanded records (finally):',
        _treeExpandRecords
      )

      await this.$halt(160)

      await Promise.all(
        _treeExpandRecords
          .filter((r) => isPlainObject(r))
          .map(async (r) => {
            if (typeof this.$table?.reloadTreeChilds === 'function') {
              await this.$table.reloadTreeExpand(r)
            } else if (typeof this.$table?.reloadTreeChilds === 'function') {
              await this.$table.reloadTreeChilds(r)
            } else {
              console.error(
                '【BaseInlineEditTable】losed tree expanded.lazy method:',
                this.$table
              )
            }
            await this.onTreeRowLazyLoaded(r)
          })
      ).catch((e) =>
        console.error('【BaseInlineEditTable】reload tree expand error', e)
      )

      await this.$halt(160)
      await this.$table.setTreeExpand(_treeExpandRecords, true)
    },
    /**
     * only re-fetch table data
     * @returns {Promise<void>}
     */
    async refreshTable() {
      await this.initData(true)
    },
    /**
     * @returns {Promise<{type:('inner'|'external'),data:any}>}
     */
    async onCreateUpdateSuccess(scope) {
      let type = ''
      let data = null

      if (this.externalQuery) {
        type = 'external'

        if (this.isTree) {
          await this.refreshTreeTable(scope.row, 'positive', {
            type: 'create-update'
          })
        } else await this.refreshTable()
      } else {
        type = 'inner'

        this.setRowExternal({
          row: scope.row,
          key: 'hasSaved',
          value: true
        })
      }
      data = await this.updateRow(scope.row, scope.column)
      return { type, data }
    },
    /**
     * @returns {Promise<{type:('inner'|'external'),data:any}>}
     * @emits after-remove-row
     */
    async onDeleteSuccess({ removeBy, row }) {
      let type = ''
      if (removeBy === 'cancel' || !this.externalQuery) {
        if (this.isTree) {
          await this.refreshTreeTable(row, 'negative', {
            type: 'remove',
            ids: [row[this.treeRowField || this.rowId || 'id']]
          })
        } else {
          await this.$table.remove(row)
          await this.$table.setCheckboxRow(row, false)
          await this.$halt(16)
          this.emitData({ source: 'onDeleteSuccess' })
          await this.$halt(160)
        }
        type = 'inner'
      } else {
        type = 'external'
        this.isTree
          ? await this.refreshTreeTable(row, 'positive', {
              type: 'remove',
              ids: [row[this.treeRowField || this.rowId || 'id']]
            })
          : await this.refreshTable()
      }

      return { type, data: null }
    },
    /**
     * @returns {Promise<{type:('inner'|'external'),data:any}>}
     */
    async onMultiDelSuccess({ removeBy, rows }) {
      let type = ''

      new Promise((resolve) => {
        rows.forEach((row) => {
          const removeFile = this.getRowExternal({ row, key: 'removeFile' })
          if (typeof removeFile === 'function') removeFile()
        })
        resolve()
      })
      if (removeBy === 'cancel' || !this.externalQuery) {
        if (this.isTree) {
          await this.refreshTreeTable(rows, 'negative', {
            type: 'remove',
            ids: rows.map((row) => row[this.treeRowField || this.rowId || 'id'])
          })
        } else {
          await this.$table.setCheckboxRow(rows, false)
          await this.$table.removeCheckboxRow()
          await this.$halt(16)
          this.emitData({ source: 'onMultiDelSuccess' })
        }
        type = 'inner'
      } else {
        type = 'external'
        this.isTree
          ? await this.refreshTreeTable(rows, 'positive', {
              type: 'remove',
              ids: rows.map(
                (row) => row[this.treeRowField || this.rowId || 'id']
              )
            })
          : await this.refreshTable()
      }
      return { type, data: null }
    },

    /**
     * reset flags
     */
    resetAllFlags() {
      this.flags = deepClone({ ...DEFAULT_FLAGS })
    },
    reset() {
      this.resetAllFlags()
      this.$table.clearAll()
    },
    /**
     * @param {(Array<string>|string)} keys - e.g: 'tableEditing'  or 'tableEditing,skipValidation, ...' or ['tableEditing','skipValidation', ...]
     */
    resetFlags(keys) {
      let _keys = null
      if (isArray(keys)) {
        if (keys.length > 0) _keys = keys
        else console.error('【BaseInlineEditTable】: empty keys for reset')
      } else if (isString(keys)) {
        if (keys === 'all') return void this.resetAllFlags()
        else {
          if (keys.includes(',')) {
            _keys = keys.split(',')
          } else {
            _keys = [keys]
          }
        }
      } else {
        console.error('【BaseInlineEditTable】: illegal keys for reset', keys)
      }

      _keys &&
        _keys.forEach((key) => {
          if (typeof this.flags?.[key] === 'boolean') {
            if (this.flags[key] === true) {
              this.flags[key] = false
            }
          } else {
            console.error('【BaseInlineEditTable】: illegal key of flags', key)
          }
        })
    }
  }
}
