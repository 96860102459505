import service from '@/service'
/**
 *  下拉：流程
 */
export function getProcessDefinitionOpts(params) {
  return service['api-activiti']({
    url: '/public/getProcessList',
    method: 'get',
    params
  })
}
