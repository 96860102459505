/**
 * @module utils/array
 */
import { isArray, isPlainObject, isString } from './validate'
/**
 * @description determine if an array(a) contains one or more items from another array(b).
 * @param {array} aArr the array to search.
 *  @param {array} bArr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export function includeSome(aArr, bArr) {
  try {
    return bArr.some((v) => aArr.includes(v))
  } catch (error) {
    console.error(error)
  }
  return false
}

/**
 * @description determine if an array(a) contains every items from another array(b).
 * @param {array} aArr the array to search.
 *  @param {array} bArr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export function includeAll(aArr, bArr) {
  try {
    return bArr.every((v) => aArr.includes(v))
  } catch (error) {
    console.error(error)
  }
  return false
}

/**
 * 两个数组是否相等 (排序后)
 * @param {array} arr1
 * @param {array} arr2
 * @returns {boolean}
 */
export function arrayEqual(arr1, arr2) {
  try {
    if (!isArray(arr1) || !isArray(arr2)) {
      return false
    }

    // 指向相同的内存地址
    if (arr1 === arr2) {
      return true
    }

    if (arr1.length !== arr2.length) {
      return false
    }

    const a1 = [...arr1].sort()
    const a2 = [...arr2].sort()

    return JSON.stringify(a1) === JSON.stringify(a2)
  } catch (error) {
    console.error(error)
    return false
  }
}
/**
 * @param {Array<any>} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return [...new Set(arr)]
}
/**
 *
 * @param {Array<Object>} arr
 * @param {string} objKey
 * @returns {Array}
 */
export function uniqueObjArr(arr, objKey) {
  return [...new Map(arr.map((item) => [item[objKey], item])).values()]
}
/**
 * @param {Array<Object>} arr
 * @returns {Array}
 */
export function uniqueId(arr) {
  const res = new Set()
  return arr.filter((a) => !res.has(a.id) && res.add(a.id))
}

/**
 * @param {Array<Object>} arr
 * @param {string} field
 * @param {{quiet:boolean}} [payload={quiet:true}]
 * @returns {Array<Object>}
 */
export const deweight = (arr, field, payload = { quiet: true }) => {
  if (!isArray(arr)) {
    console.error('【deweight】illegal arr:', arr)
    return []
  }

  if (arr.length === 0) return arr

  if (!isString(field) || (isString(field) && field.trim() === '')) {
    console.error('【deweight】illegal field:', field, "can't deweight")
    return arr
  }

  if (arr.some((v) => !isPlainObject(v))) {
    console.error('【deweight】illegal item of "arr":', arr, "can't deweight")
    return arr
  }

  const result = uniqueObjArr(arr, field)

  if (payload?.quiet !== true) {
    if (result?.length !== arr?.length) {
      console.warn(`【deweight】存在重复的 ${field}, 已去重`)
      console.warn('【deweight】原始', [...arr])
      console.warn('【deweight】结果', [...result])
    }
  }

  return result
}

/**
 * @param {Array} array
 * @param {Function} findHandler
 * @returns {number}
 */
export function findLastIndex(array, findHandler) {
  var index = array.slice().reverse().findIndex(findHandler)
  var count = array.length - 1
  var finalIndex = index >= 0 ? count - index : index
  return finalIndex
}

/**
 * @param {Array<Object>} source
 * @param {string} key
 * @param {Array<string>} vals
 * @returns {Array}
 *
 * filterSubsetKeyVals([{prop:1},{prop:2},{prop:3}],'prop',[1,2])
 * => [{prop:1},{prop:2}]
 */
export function filterSubsetKeyVals(source = [], key = '', vals = []) {
  try {
    return source.filter((v) => vals.includes(v[key]))
  } catch (error) {
    console.error(error)
  }
  return []
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function flattened(arr) {
  return [].concat(...arr)
}

/**
 * @param {Array} array - source array
 * @param {number} index - insert at index
 * @param {Array} items - insert items
 * @returns {Array}
 */
export function insert(source = [], index = 1, items) {
  if (index > source.length) {
    console.warn('insert: "index" more than the length of "source"')
  }

  const _idx = Math.min(index, source.length)
  return [
    // part of the source before the specified index
    ...source.slice(0, _idx),
    // inserted items
    ...items,
    // part of the source after the specified index
    ...source.slice(_idx)
  ]
}

/**
 * @param {Array<object>} array
 * @param {string} field
 * @description 判断数组中每个对象的某个字段是否都相等
 */
export const isEqualField = (array, field) => {
  return array.every((o) => o?.[field] === array[0]?.[field])
}

/**
 * @description 将pullList中的对象提取出来并压缩成一个新数组
 * @param {array, {childrenField,treeIdentifier}} arr
 * @returns {array}
 */
export function flattenChildrenArray(
  arr,
  { childrenField = 'pullList', treeIdentifier = 'parentId' } = {}
) {
  const result = []

  arr.forEach((item) => {
    // eslint-disable-next-line no-unused-vars
    const { [childrenField]: pullList, ...rest } = item
    result.push({ ...rest, _specialId: item.id, [treeIdentifier]: null })
    item[childrenField]?.forEach((subItem, subIndex) => {
      result.push({
        ...subItem,
        _specialId: `${item.id}.${subIndex + 1}`,
        [treeIdentifier]: item.id
      })
    })

    delete item[childrenField]
  })
  return result
}
