/**
 * @module api-scm:presentedBlack
 */
import service from '@/service'

/**
 *  列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string,
 * clientPhone:(string|number),
 * productName:string
 * }} params
 */
export function getList(params) {
  return service['api-scm']({
    url: '/scm/presented/presentedBlacklist/getList',
    method: 'get',
    params
  })
}
/**
 *新增
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/presented/presentedBlacklist/save',
    method: 'post',
    data
  })
}

/**
 * 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/presented/presentedBlacklist/update',
    method: 'post',
    data
  })
}

/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/presented/presentedBlacklist/info',
    method: 'get',
    params: { id }
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/presented/presentedBlacklist/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 启用禁用
 * @param {(string|number)} id - id
 * @param {(0|1)} status - 0:禁用 1:启用
 */
export function enableDisable(data) {
  return service['api-scm']({
    url: '/scm/presented/presentedBlacklist/updateDisableStatus',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
